import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Style
import styles from './index.module.css';

// Store
import ProjectActions from '~/store/project/actions';
import ProjectSelectors from '~/store/project/selectors';
import UserActions from '~/store/user/actions';

// Utils
import checkIfOwner from '~/utils/access/checkIfOwner';

// Data
import ROUTES, { goToDashboard } from '~/ROUTES';

// Components
import Icon from '~/components/base/Icon';
import SuccessModal, { REQUEST_SUCCESS, REQUEST_FAILED } from '../projectAddUserView/components/SuccessModal';
import Spinner from '~/components/base/Spinner';
import KvistLogo from '~/components/miscellaneous/KvistLogo/KvistLogo';
import { Button } from '~/components/base/Buttons';
import { userCollection } from '~/services/firebase';
import ConfirmationModal from '~/components/modals/ConfirmationModal';

const errorMessages = {
    couldNotFindUser: 'Kunne ikke finne brukeren',
    couldNotFindProject: 'Kunne ikke finne prosjektet',
    somethingWentWrong: 'Noe gikk galt, prøv gjerne på nytt',
}

const messages = {
    askingAccess: 'ber om tilgang til eksport av prosjektet',
    alreadyAccess: 'Brukeren har allerede tilgang til eksport av prosjektet',
    areYouSure: 'Er du sikker?',
}

const ProjectExportGiveAccessView = () => {

    // Constants
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const userId = params.userId;
    const projectId = params.projectId;
    const project = useSelector(ProjectSelectors.getProjectById(projectId));

    const [isLoading, setIsLoading] = useState(false);
    const [upLoading, setUploading] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [successModalConfig, setSuccessModalConfig] = useState({
        show:false,
        status: REQUEST_FAILED,
        errorMessage: messages.somethingWentWrong,
    });
    const [confirmationModalConfig, setConfirmationModalConfig] = useState({
        show: false,
        error: null,
        successMessage: 'Tilgang ble gitt til ',
        errorMessage: errorMessages.couldNotGiveAccess,
        status: REQUEST_FAILED,
    })


    // Functions
    useEffect(() => {
        UserActions.fetchUserInfoById(userId)
            .then((result) => {
                if(result) {
                    setUser(result);
                } else {
                    setUser({})
                    setError(errorMessages.couldNotFindUser);
                }
            })
            .finally(() => {
            })
    }, [])

    useEffect(() => {
        if(!project) {
            setIsLoading(true);
            ProjectActions.fetchProjectById(projectId)(dispatch)
            .catch(err => {
                UserActions.signOut();
            })
            .finally(() => {
                setIsLoading(false);
            })
        } else {
            if(!checkIfOwner(project)) {
                UserActions.signOut();
            }
        }
    }, [project])

    const giveAccess = () => {
        setUploading(true);
        ProjectActions.exportAccessGrant(projectId, userId)
        .then(() => {
            setSuccessModalConfig({...successModalConfig, show: true, status:REQUEST_SUCCESS});
        })
        .catch((error) => {
            setSuccessModalConfig({...successModalConfig, show: true, status:REQUEST_FAILED})
            console.error();
        })
        .finally(() => {
            setUploading(false);
        })
    }

    if(isLoading || !user || !project) {
        return (
            <div className='absolute-center'>
                <Spinner size='sm' />
            </div>
        )
    }


    return (
        <div className = 'p-3'>
        <KvistLogo
                className="pb-4 cursor-pointer"
                onClick={() => history.push(goToDashboard())}
            />
        <div className={styles.root}>
            <div className={styles.name}>
                <div className = 'paper'>
                    <KvistLogo
                        className='m-auto mb-4'
                    />
                    { error ?
                    <div className='bg-red-400 p-3 mb-3 shadow rounded'>
                                <p>{error}</p>
                            </div>
                    :
                    <>

                    <div className = ''>
                        <div className = ''>
                            <div className='text-center text-lg'>
                                <b>{`${user.firstName || ''} ${user.lastName || ''} (${user.email}) `}</b>
                                <br />
                                {`${messages.askingAccess} `}
                                <br />
                                <b>{project.title}</b>
                            </div>
                        </div>
                        <div className='p-2'>
                            <Button
                                className='w-full mt-4'
                                onClick = {() => setConfirmationModalConfig({...confirmationModalConfig, show: true})}
                                disabled = {isLoading || !user.email}
                            >
                                <Icon
                                    className='mr-2'
                                    icon='unlock'
                                    size='1x'
                                />
                                Gi tilgang
                            </Button>
                        </div>
                    </div>
                    </>
                    }
                </div>
            </div>
            <ConfirmationModal
                loading = {upLoading}
                show = {confirmationModalConfig.show}
                errorMessage = {confirmationModalConfig.error}
                onClose = {() => setConfirmationModalConfig({
                    ...confirmationModalConfig,
                    show: false
                })}
                description = ''
                title = {messages.areYouSure}
                onConfirm = {giveAccess}
            />
            <SuccessModal
                show = {successModalConfig.show}
                status = {successModalConfig.status}
                successMessage = {`${user.firstName || 'Brukeren'} fikk tilgang til eksport av prosjektet`}
                onClose = {() => successModalConfig.status === REQUEST_SUCCESS ? history.replace(goToDashboard()) : setSuccessModalConfig({...successModalConfig, show: false})}
                errorMessage = {successModalConfig.errorMessage}
            />
        </div>
        </div>
    )
}

export default ProjectExportGiveAccessView;