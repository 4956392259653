import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import TopBar from '~/components/navigation/SectionTopBar';
import MemberList from './components/MemberList';
import CriteriaModule from './components/CriteriaModule';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';
import ProjectSelectors from '~/store/project/selectors';

// Utils
import checkIfOwner from '~/utils/access/checkIfOwner';

const ProjectSectionEditView = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const sectionId = params.sectionId;
    const sectionCriteria = manual.sections.find(section => section.id === sectionId).assessmentCriteria || []
    const criteriaMetas = useSelector(ProjectSelectors.getCurCriteriaMetaDataBySectionAsArray(sectionId));
    const isOwner = checkIfOwner(project) && !project.archived;


    useEffect(() => {
        dispatch(ProjectActions.setCurCategory(params.categoryId));
        dispatch(ProjectActions.setCurSection(params.sectionId));

        return function cleanup() {
            dispatch(ProjectActions.setCurCategory(null));
            dispatch(ProjectActions.setCurSection(null));
        }
    }, [dispatch, params])

    return (
        <div className='p-3 page'>
            <TopBar projectId={project.id} />
            <MemberList 
                owner={isOwner}
            />
            <CriteriaModule 
                owner={isOwner}
                criteriaMetaData={criteriaMetas} 
                sectionId={sectionId} 
                sectionCriteria={sectionCriteria}
            />
        </div>
    )
}

export default ProjectSectionEditView