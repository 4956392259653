import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.css';

const Tabs = ({
    className = '',
    tabClassName = '',
    tabs = [],
    value,
    color = 'primary',
    onChange,
    vertical,
}) => {

    return (
        <div className={`flex items-center relative ${className} ${vertical ? 'flex-col': ''}`}>
            {
                tabs.map((tab) => (
                    <div 
                        className={`
                            flex items-center justity-center flex-grow p-2 relative
                            ${tabClassName}
                            ${tab.disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer hover:bg-gray-100'}`
                        }
                        key={tab.value}
                        onClick={() => !tab.disabled && onChange(tab.value)}
                    >
                        <h6 className={`text-center mb-0 ${tab.value === value ? `text-${color}` : ''} ${styles.heading} w-full`}>
                            { tab.label || '' }
                        </h6>
                        <div
                            className={`${styles['underline']} ${tab.value === value && !tab.disabled ? styles['underline-selected'] : ''} bg-${color}`}
                        />
                    </div>
                ))
            }
        </div>
    )
};

Tabs.propTypes = {
    className: PropTypes.string,
    tabClassName: PropTypes.string,
    tabs: PropTypes.array, // Value/Label objects
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string,
    vertical: PropTypes.bool,
}

export default Tabs;