import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { eventTypes as EVENT_ACTIONS } from '~/data/config';

// Style
import style from './style.module.css';

// Store imports
import store from '~/store';
import { useSelector, useDispatch } from 'react-redux';
import OccurrenceActions from '~/store/occurrence/actions';
import OccurrenceSelectors from '~/store/occurrence/selectors';
import ProjectSelectors from '~/store/project/selectors';

// STOLEN COMPONENTS - TODO: Make generic components instead
import CommentContainer from '~/components/modules/TimelineModule/components/CommentContainer';
import CommentInputBox from '~/components/miscellaneous/CommentInputBox/CommentInputBox';
import ReplyItem from '~/components/modules/TimelineModule/components/ReplyItem';

// Core components
import Spinner from '~/components/base/Spinner';
import OccurrenceMessageItem from '~/components/miscellaneous/OccurrenceMessageItem';

// Module components
import OccurenceChatContainer from './components/OccurenceChatContainer';
import RevisionItem from './components/RevisionItem';

// Custom Hooks
import scrollHandler from './utils/ScrollHandler'

// Data
import SECTIONS from '~/data/sections.json';

// Constants
const CHAT_LIMIT = 12;

const ChatContext = React.createContext({});

const ChatField = ({ projectId, sectionId }) => {
    const dispatch = useDispatch();
    const containerRef = useRef(null);

    // State
    const [state, setState] = useState({ projectId, sectionId });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);

    // Selectors
    /**
     * @type {Array<Occurrence>}
     */
    const occurrences = useSelector(OccurrenceSelectors.getOccurrencesByStorageLocation(projectId, sectionId, 'descending'));
    const archived = useSelector(ProjectSelectors.getCurrentArchived);
    const scrollStateRef = useRef(occurrences);


    /**
     * Helper function that scrolls to bottom in chat.
     */
    function scrollToBottom(element) {
        let elementRef = element;

        const thereAreNewerChildren = !elementRef && containerRef.current && containerRef.current.children.length > 0;

        if (thereAreNewerChildren) {
            elementRef = containerRef.current.children[containerRef.current.children.length - 1];
        }

        const chatBox = document.querySelector(`.${style.chatMessages}`);
        if (chatBox) {
            chatBox.scrollTo(0, chatBox.scrollHeight)
        }
    }

    /**
     * @param {HTMLElementObject} elementAfterLoad 
     * @param {HTMLElementObject} elementBeforeLoad 
     * @description Calculates the difference between the position after and before load
     * @returns Number
     */
    const calculateSamePositionAfterFetch = (positionAfterFetch, positionBeforeFetch) => {
        return (positionAfterFetch - positionBeforeFetch + 10) // The 10 is a little extra offset
    }

    /**
     * @author Anders Iversen & Mathias Picker
     * @description Loads in more messages for the chat-box (used when user has scrolled to top)
     */
    function loadMoreMessages() {
        const { occurrences } = scrollStateRef.current;
        const occurrencesExist = occurrences.length > 0;

        if (occurrencesExist) {
            const firstElement = containerRef.current.children.length > 0 ? containerRef.current.children[0] : null;
            const chatBox = document.querySelector(`.${style.chatMessages}`);
            const chatBoxScrollHeightBeforeFetch = chatBox.scrollHeight;

            setIsLoadingPage(true);

            fetchOccurrences(occurrences[0].timestamp)
                .then((newOccurrences) => {
                    const areThereNewOcurrences = newOccurrences.length > 0
                    setHasNextPage(areThereNewOcurrences);
                })
                .finally(() => {
                    setIsLoadingPage(false);
                    if (firstElement) {
                        const userHasNotScrolledDown = chatBox.scrollTop <= 20

                        if (userHasNotScrolledDown) {
                            const chatBoxScrollHeightAfterFetch = chatBox.scrollHeight
                            const scrollY = calculateSamePositionAfterFetch(chatBoxScrollHeightAfterFetch, chatBoxScrollHeightBeforeFetch);
                            chatBox.scrollTo(0, scrollY)
                        }
                    }
                })
        }
    }


    const onSubmit = ({ input, resolve, reject }) => {
        OccurrenceActions.addCommentAsOccurence(
            projectId,
            sectionId,
            input
        )(dispatch)
            .then((data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
        setTimeout(scrollToBottom, 200);
    }


    useEffect(() => {
        scrollHandler(style.chatMessages, (scrolledDistance) => {
            const { isLoading, hasNextPage } = scrollStateRef.current;

            const readyToLoadMoreMessages = scrolledDistance < 20 && !isLoading && hasNextPage

            if (readyToLoadMoreMessages) {
                loadMoreMessages();
            }
        })
    }, [occurrences])


    useEffect(() => {
        setState({ projectId, sectionId });

        if (occurrences.length !== 0) {
            scrollToBottom()
        }
        let unsubFuncPromise = null;
        if (!occurrences || occurrences.length === 0) {
            unsubFuncPromise = fetchOccurrencesAndListen();
        } else {
            unsubFuncPromise = Promise.resolve(listenToChanges(occurrences[0].timestamp));
        }

        return function cleanup() {
            if (unsubFuncPromise) {
                unsubFuncPromise.then((unsubFuncs) => {
                    if (Array.isArray(unsubFuncs)) {
                        unsubFuncs.forEach((f) => f());
                    } else {
                        unsubFuncs();
                    }
                })

            }
        }
    }, [projectId, sectionId])


    useEffect(() => {
        scrollStateRef.current = { occurrences, isLoading: isLoadingPage, hasNextPage };
    }, [occurrences, isLoadingPage, hasNextPage])


    const fetchOccurrencesAndListen = () => {
        setIsLoading(true);
        fetchOccurrences()
            .then(() => {
                return listenToChanges();
            })
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
                scrollToBottom()
            })
    }

    const fetchOccurrences = (fromTime) => {
        const options = {
            sectionId: sectionId,
            limit: CHAT_LIMIT,
            fromTime: fromTime,
        };

        return OccurrenceActions.fetchOccurrences(projectId, options)(dispatch);
    }

    const listenToChanges = (fromTime) => {
        // Listen to changes
        // console.log("Starting to listen to changes")
        return OccurrenceActions.listenToOccurrences(projectId, {
            sectionId: sectionId,
            timestamp: fromTime,
        })(dispatch);
    }

    return (
        <ChatContext.Provider value={{ state, setState }}>
            <div
                className="bg-white p-4 pt-0 overflow-hidden rounded-md col-span-2 relative"
            >
                <header className={style.chatHeader}><h5><b>Chat:</b> {SECTIONS[sectionId]}</h5></header>
                <section
                    className={`${style.chatMessages} mb-4 pt-0 pl-1 rounded-md overflow-y-scroll w-full`}
                    ref={containerRef}
                >
                    {
                        isLoadingPage &&
                        <div className='my-4'>
                            <Spinner size='xs' />
                        </div>
                    }
                    {
                        isLoading &&
                        <div className='absolute-center'>
                            <Spinner size='md' />
                        </div>
                    }
                    {
                        (occurrences && occurrences.length > 0) &&
                        occurrences.map((occ) => ( // TODO: Make support both Messages and STATUS_CHANGE-Occurrences
                            <div key={occ.id} id={occ.id}>
                                {
                                    occ.eventAction === EVENT_ACTIONS.CRITERION_STATUS_CHANGE ?
                                        <RevisionItem
                                            className='my-4 mx-4'
                                            projectId={projectId}
                                            sectionId={sectionId}
                                            occurrence={occ}
                                        /> :
                                        <OccurrenceMessageItem
                                            key={occ.id}
                                            projectId={projectId}
                                            sectionId={sectionId}
                                            user={{ id: occ.createdBy, ...((occ.users || {})[occ.createdBy] || {}) }}
                                            messageObject={occ}
                                        />
                                }
                            </div>
                        ))
                    }
                    {
                        (!isLoading && (!occurrences || occurrences.length === 0)) &&
                        <div className='font-light'>
                            Ingen meldinger
                        </div>
                    }

                </section>
                <CommentInputBox
                    color='gray-400'
                    borderWidth='2'
                    onSubmit={onSubmit}
                    disabled={Boolean(archived)}
                    //  type={'SECTION_CHAT'}
                    avatarSrc={'https://avatars3.githubusercontent.com/u/31648998?s=88&u=a7a4c55b87de845fd6e59e96af3d4040a1138e3e&v=4'}
                    placeholder='Trykk her for å skrive en kommentar...'
                    errorMessage='Vi fikk ikke videresendt meldingen din. Vennligst prøv på nytt.'
                />
            </div>
        </ChatContext.Provider>
    )
};


export default ChatField;