/**
 * timeAgo converts time to text in the format of '{X} {time} ago'
 * @param {Date} date
 */
export default (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000); // One year
  const plural = (extension) => interval === 1 ? '' : extension;

  if (interval >= 1) {
    return `${interval} år siden`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return (`${interval} måned${plural('er')} siden`);
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return (`${interval} dag${plural('er')} siden`);
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return (`${interval} time${plural('r')} siden`);
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return (`${interval} minutt${plural('er')} siden`);
  }
  return 'mindre enn et minutt siden';
}