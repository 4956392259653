
import {member as MEMBER} from '~/data/config';
import checkIfOwnerOrAuditor from './checkIfOwnerOrAuditor';


const checkIfAccessToCategory = (project, manual, userMemberData, categoryId) => {
    return (
        checkIfOwnerOrAuditor(project)
        ||
        manual.sections
            .filter(section => section.category === categoryId)
            .some((section)=> {
               return userMemberData.sections[section.id]===MEMBER.SECTIONS_VALUE.ACCESS 
               || userMemberData.sections[section.id]===MEMBER.SECTIONS_VALUE.RESPONSIBLE
        })
    )
}

export default checkIfAccessToCategory;