import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import UserItem from './Components/UserItem';
import Icon from '~/components/base/Icon';
import PartialCollapse from '~/components/base/PartialCollapse';
import AddUserModal from './Components/AddUserModal';
import RemoveUserModal from './Components/RemoveUserModal';

// State imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';

// Utils
import splitId from '~/utils/subCriteria/splitId';
import { Button } from '~/components/base/Buttons';
import roleToText from '~/utils/text/roleToText';

const MemberList = (props) => {
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const params = useParams();
    const containerRef = useRef();

    const members = useSelector(UserSelectors.getAllMembersWithSectionAccess(project, params.sectionId));
    const responsibleMembers = useSelector(UserSelectors.getAllMembersWithSectionResponsibility(project, params.sectionId));

    const [removeUserModal, setRemoveUserModal] = useState({});

    const [onlyResponsible, setOnlyResponsible] = useState(false);
    const [chevronUp, setChevronUp] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [addUserModal, setAddUserModal] = useState({});
    const [height, setHeight] = useState(400);

    const getCriteriaForMember = (member) => {
        return Object.keys(member.criteriaResponsible || {}).reduce((subs, subCriteria) => {
            if(member.criteriaResponsible[subCriteria]) {
                const split = splitId(subCriteria);
                if(split[1] === params.sectionId) {
                    subs.push(split[2])
                }
            }
            return subs;
        }, [])
        .sort((a,b) => {return a - b})
        .join(', ');
    };

    const which = () => {
        if(onlyResponsible) {
            return chevronUp ? responsibleMembers : responsibleMembers.reverse();
        } else {
            return chevronUp ? members : members.reverse();
        };
    };

    useEffect(() => {
          setHeight(containerRef.current.clientHeight)
      });


    const handleOnClick = (member) => {
        setRemoveUserModal({
            show: true,
            member: member,
        })
    };

    return (
        <div className="paper">
            <h6>Tilgangsliste</h6>
            <div className="flex items-center">
                <div className="p-4 w-3/12">
                        <b>Navn</b>
                        <Icon
                            icon={chevronUp === false ? 'sort-alpha-down-alt' : 'sort-alpha-up'}
                            size={'sm'}
                            className='ml-2 cursor-pointer'
                            onClick={() => setChevronUp(!chevronUp)}
                        />
                </div>
                <div className="p-4 w-3/12">
                    <b>Rolle</b>
                </div>
                <div className="p-4 w-2/12 cursor-pointer"
                    onClick={() => {
                        setIsOpen(false);
                        setOnlyResponsible(!onlyResponsible);
                    }}
                >
                    <b>Ansvar </b>
                    <Icon
                        size={'sm'}
                        icon={onlyResponsible ? 'check' : 'filter'
                    }/>
                </div>
                <div className="p-4 w-4/12 flex justify-center">
                    {
                        props.owner ?
                        <b>Administrer</b>
                        :
                        <b>Purr</b>
                    }
                </div>
            </div>
            <PartialCollapse
                visible={isOpen}
                partial='15rem'
            >
                <div
                    ref={containerRef}
                >
                {
                    which().map((member) => (
                        <UserItem
                            member={member}
                            key={member.id}
                            id={member.id}
                            firstname={member.firstName}
                            lastname={member.lastName}
                            role={roleToText(project.roles[member.id])}
                            subCriteria={
                                getCriteriaForMember(member)
                            }
                            owner={props.owner}
                            sectionId={params.sectionId}
                            removeUser={() => {
                                handleOnClick(member)
                            }}
                            archived = {project.archived}
                        />
                    ))
                }
                </div>
            </PartialCollapse>
            <div className='flex mt-4'>
                {
                    height < 300 ? <div className='flex-grow'/> :
                    <Icon className='flex-grow cursor-pointer'
                        icon={isOpen ? 'chevron-up' : 'chevron-down'}
                        size='2x'
                        onClick={() => {
                            isOpen ? setIsOpen(false) : setIsOpen(true);
                        }}
                    />
                }
                {
                    props.owner ?
                    <Button onClick={() => setAddUserModal({show: true})}>Legg til bruker</Button>
                    : null
                }
            </div>
            <AddUserModal
                show={Boolean(addUserModal.show)}
                onClose={() => setAddUserModal({show: false})}
                members={members}
                sectionId={params.sectionId}
            />
            <RemoveUserModal
                show={Boolean(removeUserModal.show)}
                onClose={() => setRemoveUserModal({show: false})}
                sectionId={params.sectionId}
                member={removeUserModal.member}
            />
        </div>
    );
};

export default MemberList;