import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { goToProjectNotificationHub } from '~/ROUTES';

// Styles
import styles from './NotificationModule.module.css';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import EventSelectors from '~/store/events/selectors';
import EventActions from '~/store/events/actions';
import UserActions from '~/store/user/actions';

// Core components
import Popup from '~/components/base/Popup';
import Spinner from '~/components/base/Spinner';
import Icon from '~/components/base/Icon';

// Internal components
import NotificationItem from './components/NotificationItem/NotificationItem';
import { Button } from '~/components/base/Buttons';

// Constants
const LIMIT = 20;

/**
 * NotificationModule is a module that displays incoming notifications in a "dropdown" list.
 */
const NotificationModule = ({
    projectId,
    anchorEl,
    isOpen,
    onClose
}) => {
    const dispatch = useDispatch();
    const paginationRef = useRef({isLoading: false, hasNextPage: true, notifications: null});

    // Selectors
    const notifications = useSelector(EventSelectors.getProjectNotifications(projectId));

    // State
    const [isPopUpOpen, setIsPopUpOpen] = useState(isOpen);
    const [openTimeout, setOpenTimeout] = useState(null);
		const [isLoading, setIsLoading] = useState(false);
		const containerRef = useRef(null);

    const onClick = () => {
        EventActions.seenAllNotifications(projectId)(dispatch);

        if(onClose) {
            onClose();
        }
    }

    /**
     * To make a fade-out effect when one closes the module.
     */

    useEffect(() => {
        if (openTimeout) {
            clearTimeout(openTimeout);
        };

        if (isOpen) {
            setIsPopUpOpen(true);
            setTimeout(initPaginationOnScroll, 100);
        } else {
            setOpenTimeout(
                setTimeout(() => setIsPopUpOpen(isOpen), 500)
            );
        };

    }, [isOpen]);

    useEffect(() => {
        paginationRef.current.notifications = notifications;
    }, [notifications]);

    useEffect(() => {
        UserActions.fetchAllMembersInProject(projectId)(dispatch);
    }, [])

    const fetchNotifications = () => {
        const notfData = paginationRef.current.notifications;
        let fromTime = null;
        if(notfData && notfData.length > 0) {
            fromTime = notfData[notfData.length - 1].timestamp || null;
        }

        setIsLoading(true);
        paginationRef.current.isLoading = true;
        return EventActions.fetchNotifications(projectId, {
            from: fromTime,
            limit: LIMIT,
        })(dispatch)
            .then((data) => {
                paginationRef.current.isLoading = false;
                paginationRef.current.hasNextPage = data && data.length >= LIMIT;
            })
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
		}



    const initPaginationOnScroll = () => {
				const containerRef = document.getElementById('notification-module-content');

				if(containerRef) {
        containerRef.onscroll = function(e) {

            if(containerRef.offsetHeight + containerRef.scrollTop >= containerRef.scrollHeight - 200 &&
                !paginationRef.current.isLoading &&
                paginationRef.current.hasNextPage
            ) {
                fetchNotifications();
            }
				}
			}
    }

		const animClasses = isOpen ? 'opacity-100' : 'opacity-0';

    return (
        <Popup
            className={styles.popup}
            anchorEl={anchorEl}
            isOpen={isPopUpOpen}
						onClickAway={onClose}
            isFixed
            placement='bottom-left'
        >
            <div
                id='notification-module-content'
                className={`bg-white shadow-2xl rounded-md transition-opacity duration-500 ease-in-out overflow-y-scroll ${animClasses} ${styles.root}`}
					 			ref={containerRef}
					 >
                <div className='flex items-center'>
                    <h4 className='p-2 mb-0'>Varsler</h4>
                    <div className='flex-grow' />
                    <Button
                        className='mr-2'
                        variant='icon'
                        color='black'
                        onClick={onClick}
                    >
                        <Icon icon='check' size='lg'/>
                    </Button>
                    <Link to={goToProjectNotificationHub(projectId)} className="mr-4 hover:underline">
                        Gå til varselpanel
                    </Link>
                </div>
                <hr />
                <div>
                    {
                        notifications
                            .sort((a, b) => -(a.timestamp || '').localeCompare(b.timestamp))
                            .map((notf) => (

                                <NotificationItem
                                    key={notf.id}
                                    projectId={projectId}
                                    notificationItem={notf}
                                    onClose={onClose}
                                />
                            ))
                    }
                </div>
                {
                    isLoading &&
                    <div>
                        <Spinner className='m-auto' size='xs' />
                    </div>
                }
            </div>
        </Popup>
    )
}

NotificationModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    anchorEl: PropTypes.instanceOf(Element).isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default NotificationModule;