import { roles as ROLES } from '~/data/config';

export default (role) => {

    switch(role) {

        case ROLES.owner: {
            return "Eier";
        }

        case ROLES.auditor: {
            return "Revisor";
        }

        case ROLES.member: {
            return "Medlem";
        }

        default: {
            return "";
        }
    }

}