import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from "react-router-dom";

// Styles
import styles from './Manual.module.css';

// Components
import { Button } from '~/components/base/Buttons';
import Spinner from '~/components/base/Spinner';

// Store
import { useDispatch, useSelector } from 'react-redux';
import DocumentActions from '~/store/documents/actions';

import ProjectSelectors from '~/store/project/selectors';



const Manual = (sectionId = null) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const dispatch = useDispatch();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [manualPdf, setManualPdf] = useState('');

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const downloadManual = () => {
        setIsLoading(true);
        DocumentActions.downloadFileBySource(manual.source)
        .then((manualPDFURL) => {
            setManualPdf(manualPDFURL)
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        downloadManual();
        setPageNumber(query.get("section") ? manual.pageNumbers[query.get("section")] : 1);
    }, [])


    return (
        <div className='shadow-md mt-3'>
            {
                isLoading
                ?
                <Spinner />
                :
                <div className={`h-screen ${styles.content}`}>
                        <iframe src={`${manualPdf}#page=${pageNumber}`} width="100%" height="100%"></iframe>
                </div>
            }

        </div>
    )
}

export default Manual;