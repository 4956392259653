
/**
 * @param {Date} a
 * @param {Date} b
 * @returns {Boolean}
 */
export default (a, b) => {
    if(a && b) {
        return a.getTime() === b.getTime();
    }
    return a === b;
}
