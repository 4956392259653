import { MUTATIONS } from './reducer';

const setDocAggregations = (projectId, aggregations) => ({
    type: MUTATIONS.SET_DOC_AGGREGATIONS,
    payload: aggregations,
    projectId: projectId,
})

const setCreditAggregations = (projectId, aggregations) => ({
    type: MUTATIONS.SET_CREDIT_AGGREGATIONS,
    payload: aggregations,
    projectId: projectId,
})

export default {
    setDocAggregations,
    setCreditAggregations,
}