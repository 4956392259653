import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../ROUTES';

// State imports
import projectActions from '../../store/project/actions';

// Page components
import Step01 from './components/Step01';
import Step02 from './components/Step02';
import project from '~/store/project/reducer';

const ProjectCreate = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [step, setStep] = useState(0);
	const [projectInfo, setProjectInfo] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const createProject = (project) => {
		// Temporarily create project without selecting subjects
		setIsLoading(true);
		projectActions
			.createProject(dispatch, project)
			.then((data) => {
				history.push(ROUTES.PROJECT.concat('/', data));
			})
			.catch((err) => {
				console.error(err);
				setError(err);
			})
			.finally(() => setIsLoading(false));
	};

	const onStep01Completed = (data) => {
		setStep(1);
		setProjectInfo(data);
	};

	const onStep02Completed = (data) => {
		createProject({
			...projectInfo,
			criterions: data,
		});
	};

	return (
		<div className="px-4 max-w-screen-md m-auto mt-10">
			<h3>Nytt prosjekt</h3>

			<div>
				{step === 0 ? (
					<Step01 onChange={onStep01Completed} isLoading={isLoading} />
				) : step === 1 ? (
					<Step02 onChange={onStep02Completed} isLoading={isLoading} />
				) : null}
			</div>
		</div>
	);
};

export default ProjectCreate;