import { settings, auth } from './firebase'; 

class CallableError extends Error {
    constructor(error = {}) {
        super(error.message);
        this.status = error.status;
        this._error = error;
    }
}

const DEFAULT_OPTIONS = {
    region: 'europe-west1',
    projectId: settings.projectId,
}

const onCall = async (functionName, data = {}, options = DEFAULT_OPTIONS) =>  {
    const URL = `https://${options.region}-${options.projectId}.cloudfunctions.net/${functionName}`;

    return fetch(URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
        },
        body: JSON.stringify({
            data: data,
        }),
    })
    .then((res) => res.json())
    .then((result) => {
        if(result.error) {
            throw new CallableError(result.error);
        } 
        return result;
    })
}

export default onCall;