import { mapOccurrence } from "./mappers";
import project from "../project/reducer";

export const MUTATIONS = {
    ADD_COMMENT_ON_REVISION: 'ADD_COMMENT_ON_REVISION',
    SET_OCCURRENCE: 'OCCURRENCE_SET_OCCURRENCE',
    SET_OCCURRENCE_MESSAGE: 'OCCURRENCE_SET_OCCURRENCE_MESSAGE',
    CLEAR_STATE: 'OCCURRENCE_CLEAR_STATE',
}


const initialState = {
    occurrences: {}, // { [projectId]: { [occurrenceId]: occurrence } }
    occurrenceIds: {}, // { [projectId]: { [subCriterionId]: [occId, occId2, occId3...] } } 
}

const reducer = (state = initialState, action) => {

    const payload = action.payload;

    switch (action.type) {

        case MUTATIONS.CLEAR_STATE: {
            return initialState;
        }

        case MUTATIONS.SET_OCCURRENCE: {
            /**
             * Set or update a occurrence inside the store. Also storing the id
             * of a given storageLocation
             */
            const projectId = action.projectId;
            const storageLocation = action.storageLocation; // Can for example be either a subCriterionId or a sectionId

            let occurrences = [payload];

            if (Array.isArray(payload)) {
                occurrences = payload;
            }
            const childOccurrences = occurrences.filter(occ => occ.parent);
            const newStorageLocationOccurrences = occurrences
                .filter(occ => !occ.parent)
                .map(occ => ({ [occ.id]: occ.timestamp }))
                .reduce((prev, occ) => ({ ...prev, ...occ }), {});

            occurrences = occurrences
                .filter(occ => !occ.parent)
                .map(occ => mapOccurrence(occ))
                .reduce((acc, occ) => ({ ...acc, [occ.id]: occ }), {});
            const newProjectOccurrences = Object.assign({}, state.occurrences[projectId] || {}, occurrences);

            for (let child of childOccurrences) {
                const parent = newProjectOccurrences[child.parent] || {};
                parent.messages = Object.assign({}, parent.messages || {}, child.messages);
            }


            const storageLocationOccurrences = (state.occurrenceIds[projectId] || {})[storageLocation] || {};


            return {
                ...state,
                occurrences: {
                    ...state.occurrences,
                    [projectId]: newProjectOccurrences,
                },
                occurrenceIds: {
                    ...state.occurrenceIds,
                    [projectId]: {
                        ...(state.occurrenceIds[projectId] || {}),
                        [storageLocation]: Object.assign({}, storageLocationOccurrences, newStorageLocationOccurrences),
                    }
                }
            }
        }


        case MUTATIONS.SET_OCCURRENCE_MESSAGE: {
            /**
             * Adds a message-object into a occurrence's the message-bank.
             * Mostly only relevant for CRITERIA_STATUS_CHANGE occurrences.
             */
            const projectId = action.projectId;
            const occurrenceId = action.occurrenceId;
            const message = payload;

            const projectOccurrences = Object.assign({}, state.occurrences[projectId] || {});
            const occurrence = projectOccurrences[occurrenceId];
            if (!occurrence || !message.id || !occurrence.messageIds) {
                return state;
            }

            occurrence.messages = Object.assign(occurrence.messages || {}, { [message.id]: message });

            const existingIndex = occurrence.messageIds.findIndex((messageId) => messageId === message.id);
            if (existingIndex === -1) {
                occurrence.messageIds.push(message.id);
            }

            return {
                ...state,
                occurrences: {
                    ...state.occurrences,
                    [projectId]: projectOccurrences,
                }
            }
        }

        default: {
            return state;
        }

    }

}

export default reducer;