import categoriesByName from '~/data/categories.json';

export default (sections) => {
    const categoriesByKey = sections.reduce((prev, sectionId) => {
        const categoryId = sectionId.split("_").slice(0, 1)[0];
        return {
            ...prev,
            [categoryId]: categoriesByName.filter(categoryElem => categoryElem.key === categoryId)[0].name, 
        }
    }, {});
    return categoriesByKey;
}