import {MUTATIONS} from './reducer';
import * as firebase from '../../services/firebase';
import { functions as FUNCTIONS, member } from '../../data/config'
import EventSelectors from './selectors';
import store from '../index';
import project from '../project/reducer';

const setEvents = (projectId, events) => ({
    type: MUTATIONS.SET_EVENTS,
    projectId: projectId,
    payload: events,
});

const setEvent = (projectId, event) => ({
    type: MUTATIONS.SET_EVENT,
    projectId: projectId,
    payload: event,
});

const setNotifications = (projectId, notifications) => ({
    type: MUTATIONS.SET_NOTIFICATIONS,
    projectId: projectId,
    payload: notifications,
})

const setNotification = (projectId, notification) => ({
    type: MUTATIONS.SET_NOTIFICATION,
    projectId: projectId,
    payload: notification,
})

const setNotificationCount = (projectId, notificationCount, time = null) => ({
    type: MUTATIONS.SET_NOTIFICATION_COUNTS,
    projectId: projectId,
    payload: notificationCount,
    time: time,
})

const decreaseNotificationCount = (projectId, countKey) => ({
    type: MUTATIONS.DECREASE_NOTIFICATION_COUNTS,
    projectId: projectId,
    payload: countKey,
})

const clearState = () => ({
    type: MUTATIONS.CLEAR_STATE,
})


const listenToEvents = (projectId, limit = 10) => (dispatch) => {

    const unsubscribeFunc = firebase.projectCollection
        .doc(projectId)
        .collection('events')
        .limit(limit)
        .orderBy('timestamp')
        .onSnapshot((eventSnapshot) => {
            const changes = eventSnapshot.docChanges();

            if(changes.length > 0) {
                changes.forEach((change) => {
                    if(change.type === 'added') {
                        dispatch(setEvent(projectId, {id: change.doc.id, ...change.doc.data()}))
                    }
                })
            } else {
                dispatch(setEvents(projectId, eventSnapshot.docs))
            }
        })
    
    return unsubscribeFunc;
}

const listenToNotifications = (projectId) => (dispatch) => {
    let initialState = true;

    const unsubscribeFunc = firebase
        .notificationCollection(projectId, firebase.auth.currentUser.uid)
        .orderBy('timestamp', 'desc')
        .limit(20)
        .onSnapshot((notfSnapshot) => {
            
            if(!initialState) {
                const changes = notfSnapshot.docChanges();
                changes.forEach((change) => {
                    if(change.type === 'added') {
                        dispatch(setNotification(projectId, {id: change.doc.id, ...change.doc.data()}))
                    }
                })
            } else {
                initialState = false;
                dispatch(setNotifications(projectId, notfSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))))
            }
        });

    return unsubscribeFunc;
}

/**
 * 
 * @param {String} projectId 
 * @param {Object} filters 
 * @param {String} filters.targetUser - The id of a target user's notifications
 * @param {String} filters.from - From a specific timestamp
 * @param {String} filters.to - Upto a specific timestamp
 * @param {String} filters.limit - The max amount of notifications to fetch
 * @param {String} filters.sectionId 
 * @param {String} filters.subCriterionId
 * @param {String} filters.flag
 * @param {String} filters.eventAction 
 * @param {Boolean} filters.orderBySection
 */
const fetchNotifications = (projectId, filters = {}) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.NOTIFICATION_LIST)({
        projectId: projectId,
        ...filters,
    })
    .then((result) => {
        const docs = result.data;
        dispatch(setNotifications(projectId, docs));
        return docs;
    })

}

const seenNotification = (projectId, notificationId) => (dispatch) => {
    const uid = firebase.auth.currentUser.uid;

    return firebase.notificationCollection(projectId, uid)
        .doc(notificationId)
        .update({
            seen: new Date().toISOString()
        })
        .then(() => {
            dispatch(decreaseNotificationCount(projectId, 'total'))
            const notification = EventSelectors.getProjectNotificationById(projectId, notificationId)(store.getState())
            if(notification) {
                notification.seen = new Date().toISOString(); // Make sure the notification is set as seen.
                dispatch(setNotification(projectId, notification));
            }
        });
}

const seenAllNotifications = (projectId) => (dispatch) => {
    dispatch(setNotificationCount(projectId, 0, new Date().getTime()));
    const notifications = EventSelectors.getProjectNotifications(projectId)(store.getState());
    if(notifications) {
        notifications.map((notification) => {
            if(!notification.seen) {
                notification.seen = new Date().toISOString();
                dispatch(setNotification(projectId, notification));
            }
        })
    }
    return firebase.functions.httpsCallable(FUNCTIONS.NOTIFICATION_SEEN_ALL)({
            projectId: projectId
        })
        .catch((err) => {
            console.error(err);
        })
}

const sendReminder = (projectId, memberId, sectionId) => {
    return firebase.functions.httpsCallable(FUNCTIONS.NOTIFICATION_REMINDER)({
        projectId: projectId,
        UID: memberId,
        sectionId: sectionId
    })
}

/**
 * @param {string} projectId - id of the project
 * @param {string} notificationId - id of the notification
 * @param {number} when - When the user want the notification, An ISO String
 */
const remindMe = (projectId, notificationId, when) => {
    return firebase.functions.httpsCallable(FUNCTIONS.NOTIFICATION_REMINDME)({
        projectId: projectId,
        notificationId: notificationId,
        when: when,
    })
    .then(() => {
        const oldNotification = EventSelectors.getProjectNotificationById(projectId, notificationId)(store.getState());
        if(!oldNotification) {
            return;
        }
        oldNotification.reminder = when;
        store.dispatch(setNotification(projectId, oldNotification));
        return oldNotification;
    })
}

/**
 * @param {string} projectId - id of the project
 * @param {string} notificationId - id of the notification
 * @param {eventFlag} flag - the flag the event is set to
 */
const updateFlag = (projectId, notification) => (dispatch) => {
    dispatch(setNotification(projectId, notification))
    return firebase.functions.httpsCallable(FUNCTIONS.NOTIFICATION_FLAG)({
        projectId: projectId,
        notificationId: notification.id,
        flag: notification.flag,
    })
}

const numberOfEvents = (projectId) => (dispatch) => {
    const uid = firebase.auth.currentUser.uid;

    return firebase.projectCollection.doc(projectId)
        .collection('members').doc(uid)
        .onSnapshot((doc) => {
            const notificationCounts = doc.data().notifications;
            dispatch(setNotificationCount(projectId, notificationCounts))
            return notificationCounts;
        })
}

export default {
    setEvents,
    setEvent,
    listenToEvents,
    listenToNotifications,
    seenNotification,
    seenAllNotifications,
    sendReminder,
    remindMe,
    updateFlag,
    numberOfEvents,
    fetchNotifications,
    clearState,
}

