import React, { useState, useEffect, useMemo } from "react";

// Styles
import styles from './ManageResponsibleModal.module.css'

// Store imports
import { useSelector, useDispatch} from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserActions from '~/store/user/actions';
import UserSelectors from '~/store/user/selectors';

// Core components
import TextField from "~/components/base/TextField";
import Icon from "~/components/base/Icon";
import Modal from '~/components/base/Modal'
import Tabs from '~/components/base/Tabs'

// Module components
import AssignUserItem from './AssignUserItem'

// Utils
import roleToText from '~/utils/text/roleToText';


const ManageResponsibleModal = (props) => {
    const dispatch = useDispatch();
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const sectionId = props.sectionId;

    // Memos
    const subCriterionIds = useMemo(() => Object.values(project.scope[sectionId]).reduce((prev, subCriterionIds) => ([...prev, ...subCriterionIds]), []), [sectionId]);
    const subCriterionsLabelValue = useMemo(() => {
        return (subCriterionIds || [])
            .map(subCriterionId => ({
                label: `Krav ${(subCriterionId || '').split("_").slice(-1)[0]}`,
                value: subCriterionId,
            }));
    }, [subCriterionIds]);

    const originalMembers = useSelector(UserSelectors.getAllMembersWithSectionAccess(project, sectionId));

    // State
    const [query, setQuery] = useState('');
    const [filterFunc, setFilterFunc] = useState({func: () => true});
    const [selectedSubCriterion, setSelectedSubCriterion] = useState(subCriterionIds.length > 0 ? subCriterionIds[0] : null);
    const [error, setError] = useState(null);

    useEffect(() => {
        UserActions.fetchAllMembersInProject(project.id)(dispatch);
    }, []);

    const onQuery = (event) => {
        if (event.key !== 'Enter') {
            return;
        };

        let keyword = query.toLowerCase();

        if (keyword === '') {
            setFilterFunc({
                func: () => true,
            });
        } else {  
            setFilterFunc({
                func: (member) => {
                    let memberRole = roleToText(project.roles[member.id]);
                    let fullName = `${member['firstName']} ${member['lastName']}`
                    return (
                        (member['firstName']).toLowerCase().startsWith(keyword) ||
                        (member['lastName']).toLowerCase().startsWith(keyword) ||
                        memberRole.toLowerCase().startsWith(keyword) ||
                        fullName.toLowerCase().startsWith(keyword)
                    );
                }
            });
        };
    };

    const toggleResponsibility = (member, subCriterionId) => {
        return UserActions.editUserResponsibility(project.id, [
            {
                id: member.id,
                criteriaResponsible: {
                    [subCriterionId]: !Boolean((member.criteriaResponsible || {})[subCriterionId]),
                }
            }
        ])(dispatch)
        .catch((error) => {
            setError(error.message);
        });
    };

    const isMemberResponsible = (member, subCriterionId) => {
        return ((member || {}).criteriaResponsible || {})[subCriterionId] === true;
    };

    const extractSubCriterionNumber = (subCriterionId) => {
        return subCriterionId ? subCriterionId.split("_").slice(-1)[0] : '';
    };

    return (
        <Modal
            show={props.show}
            onBackdropClick={props.onClose}
        >
            <div className={`${styles.root} bg-white rounded shadow-lg`}>
                <div className='border-r'>
                    <Tabs
                        className='h-full'
                        vertical
                        tabClassName='w-full border-b border-gray-500'
                        tabs={subCriterionsLabelValue}
                        value={selectedSubCriterion}
                        onChange={setSelectedSubCriterion}
                    />
                </div>
                <div className='p-16'>
                    <div className ="flex justify-around">
                        <div className="pb-4">
                        <h4>
                            Tildel overordnet ansvar til krav { extractSubCriterionNumber(selectedSubCriterion) }
                        </h4>
                        <TextField
                            margin={false}
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            onKeyPress={onQuery}
                            back={
                                <Icon 
                                    className='hover:text-primary cursor-pointer scale-110'
                                    icon='search'
                                    onClick={() => onQuery({ key: 'Enter' } )}
                                />    
                            }
                        />
                    </div>  
                    </div>
                    {
                        originalMembers
                        .filter(filterFunc.func)
                        .map((member) => (
                            <AssignUserItem 
                                key={member.id}
                                id={member.id}
                                firstName={member.firstName}
                                lastName={member.lastName}
                                isResponsible={isMemberResponsible(member, selectedSubCriterion)}
                                onClick={() => toggleResponsibility(member, selectedSubCriterion)}
                                disabled={false}
                            />
                        ))
                    }
                 { 
                    error ? 
                    <div className='bg-red-400 p-3 mb-3 shadow rounded'>
                        <p>{error}</p>
                    </div> : null
                }
                </div>
            </div>
        </Modal>
    )
};

export default ManageResponsibleModal;
