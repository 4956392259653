import React, { useState, useEffect } from "react";

// Components
import Modal from '~/components/base/Modal';
import { Button } from '~/components/base/Buttons';
import Spinner from '~/components/base/Spinner';
import Icon from '~/components/base/Icon';

import { useSelector } from 'react-redux';
import EventActions from '~/store/events/actions';
import ProjectSelectors from '~/store/project/selectors';

import SECTIONS from '~/data/sections.json';

const SendNotificationModal = (props) => {
    const project = useSelector(ProjectSelectors.getCurrentProject);

    // State
    const [isLoading, setIsLoading] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(0)

    const sendReminder = () => {
        setIsLoading(true);
        EventActions.sendReminder(project.id, props.id, props.sectionId)
        .then(() => {
            setNotificationStatus(1)
        })
        .catch(() => {
            setNotificationStatus(2)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if(props.show === true) setNotificationStatus(0);
    }, [props.show])

    return (
        <Modal
            show={props.show}
            onBackdropClick={props.onClose}
        >
            <div className={'paper'}>
                {
                    isLoading
                    ?
                    <Spinner />
                    :
                    <div>
                        {
                            notificationStatus !== 0
                            ?
                            <div>
                                {
                                    notificationStatus === 1
                                    ?
                                    <div className='text-center'>
                                        <h3>Suksess <Icon icon='check' color='var(--color-success)'/></h3>
                                        Purring ble sendt til {props.firstname} {props.lastname} for {SECTIONS[props.sectionId]}
                                    </div>
                                    :
                                    <div className='text-center'>
                                        <h3>Feil! <Icon icon='times-circle' color='var(--color-error)'/></h3>
                                        Noe gikk galt og det ble ikke sendt purring til {props.firstname} {props.lastname}
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <h6>Send purring for {SECTIONS[props.sectionId]}</h6>
                                <p>Er du sikker på at du vil purre på: {props.firstname} {props.lastname}</p>
                                <div className='flex'>
                                    <div className='flex-grow'/>
                                    <Button
                                        className='mr-4'
                                        onClick={sendReminder}
                                    >
                                        Ja
                                    </Button>
                                    <Button
                                        onClick={props.onClose}
                                        variant='outlined'
                                    >
                                        Nei
                                    </Button>
                                    <div className='flex-grow'/>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </Modal>
    )
}

export default SendNotificationModal
