// Config
import { deadlineStatuses } from '~/data/config';

// Utils
import yesterday from '~/utils/date/yesterday';

/**
 * @description Finds the weakest link for the deadline icon. 1. 'overdue' = weakest | 2. 'none' = second weakest | 3. 'active' = third weakest
 * @param {String<Date.toISOString>|undefined} deadline 
 * @returns {Object} 
 */
const calculateOneDeadline = (deadline) => {
    const deadlineObject = { deadline }

    if (!deadline) { deadlineObject.status = deadlineStatuses.none; }
    else {
        const deadlineDate = new Date(deadline);

        if (deadlineDate < yesterday) {
            deadlineObject.status = deadlineStatuses.overdue;
        }
        else {
            deadlineObject.status = deadlineStatuses.active;
        }
    }

    return deadlineObject;
};

export default calculateOneDeadline;