import {
	faBell,
	faFile,
	faFileAlt,
	faChevronDown,
	faChevronUp,
	faLock,
	faUser,
	faTrash,
	faMinusCircle,
	faCheckCircle,
	faCheck,
	faTimesCircle,
	faTimes,
	faHourglassHalf,
	faPaperPlane,
	faChevronRight,
	faUnlock,
	faFolderPlus,
	faUpload,
	faCog,
	faUserCircle,
	faUserFriends,
	faCommentAlt,
	faLightbulb,
	faPlus,
	faPlusCircle,
	faMinus,
	faSearch,
	faArrowLeft,
	faPen,
	faArrowRight,
	faEdit,
	faEye,
	faBook,
	faExpandAlt,
	faCompressAlt,
	faFileImage,
	faFilePdf,
	faFileCsv,
	faFileExcel,
	faFileArchive,
	faFilePowerpoint,
	faEllipsisH,
	faSmile,
	faClock,
	faCaretUp,
	faCaretDown,
	faClipboardList,
	faClipboard,
	faSquare,
	faUniversalAccess,
	faFilter,
	faSortAlphaUp,
	faSortAlphaDownAlt,
	faRedo,
	faBookmark,
	faBars,
	faTasks,
	faSave,
	faKey,
	faSignOutAlt,
	faExclamation,
	faHome,
	faArchive,
	faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFolder,
    faCommentAlt as farCommentAlt,
    faBookmark as farBookmark,
    faTimesCircle as farTimesCircle,
    faClock as farClock,
    faClipboard as farClipboard,
} from '@fortawesome/free-regular-svg-icons'

export default (library) => {
    library.add(faSortAlphaUp, faSortAlphaDownAlt);
    library.add(faFilter);

    library.add(faBell);
    library.add(faFile, faFileAlt);
    library.add(faArchive);
    library.add(faChevronDown, faChevronUp);
    library.add(faSquare);
    library.add(faSquare);

    library.add(faUnlock);
    library.add(faLock, faUser);
    library.add(faTrash);

    library.add(faMinusCircle, faCheckCircle, faTimesCircle);
    library.add(faCheck);
    library.add(faClipboard);
    library.add(faSave);
    library.add(faUndoAlt);

    library.add(faClipboardList);
    library.add(faHourglassHalf);
    library.add(faUniversalAccess);
    library.add(faClock);
    library.add(faCaretUp);
    library.add(faCaretDown);

    library.add(faPaperPlane);
    library.add(faChevronRight)
    library.add(faFolderPlus, faFolder);

    library.add(faUpload);
    library.add(faCog);
    library.add(faUserCircle);

    library.add(faUserFriends);
    library.add(faCommentAlt, farCommentAlt);
    library.add(faLightbulb);

    library.add(faPlus, faPlusCircle, faMinus);
    library.add(faSearch);
    library.add(faArrowLeft);
    library.add(faArrowRight);
    library.add(faEye);
    library.add(faBook);
    library.add(faExpandAlt);

    library.add(faCompressAlt);
    library.add(faFileImage);
    library.add(faFilePdf);

    library.add(faFileCsv);
    library.add(faFileExcel);
    library.add(faFileArchive);
    library.add(faFilePowerpoint);
    library.add(faArrowRight);

    library.add(faFilePowerpoint);
    library.add(faPen);

    library.add(faTimes);
    library.add(faEllipsisH);
    library.add(faSmile);

    library.add(faRedo);
    library.add(faBookmark, farBookmark);

    library.add(faBars);
    library.add(faEdit);
    library.add(farTimesCircle);
    library.add(farClock);
    library.add(faTasks);
    library.add(faKey);

    library.add(farClipboard);
    library.add(faSignOutAlt);
    library.add(faExclamation);
    library.add(faHome);
};