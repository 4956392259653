export const MUTATIONS = {
    SET_PROJECTS: 'PROJECTS_SET_PROJECTS',
    SET_ARCHIVED_PROJECTS: 'PROJECTS_SET_ARCHIVED_PROJECTS',
    SET_PROJECT: 'PROJECTS_SET_PROJECT',
    USE_PROJECT: 'PROJECTS_USE_PROJECT',

    SET_PROJECT_META: 'SET_PROJECT_META',

    SET_SECTION_METAS: 'PROJECTS_SET_SECTION_METAS',
    SET_SECTION_META: 'PROJECTS_SET_SECTION_META',
    SET_CUR_SECTION: 'PROJECTS_SET_CUR_SECTION',
    SET_CUR_CATEGORY: 'PROJECTS_SET_CUR_CATEGORY',

    SET_CRITERIA: 'PROJECTS_SET_CRITERIA',
    SET_CRITERION: 'PROJECTS_SET_CRITERION',

    SET_MANUAL: 'PROJECTS_SET_MANUAL',
    USE_MANUAL: 'PROJECTS_USE_MANUAL',

    SET_DOC_AGGREGATIONS: 'PROJECTS_SET_DOC_AGGREGATIONS',
    SET_CREDIT_AGGREGATIONS: 'PROJECTS_SET_CREDIT_AGGREGATIONS',

    CLEAR_STATE: 'PROJECTS_CLEAR_STATE',
};

export const SECTION_META_DATA_KEY = 'sectionMetaData';
export const CRITERIA_META_DATA_KEY = 'criteriaMetaData';

export const initialState = {
    projects: {}, // By id - dynamically adds "sectionMetaData" & "criteria"
    archivedProjects: {}, // By id - dynamically adds "sectionMetaData" & "criteria"
    sectionMetaData: {}, // By project id - contains the statuses and roles of the different sections of the project
    criteriaMetaData: {}, // By project id
    metrics: {}, // By project id - contains document aggregations { "projectId": {"docAggregations": {...} } }
    projectMeta: {}, // By project id - { "projectId": { "progressPlan": { ... }, ... } }

    manuals: {}, // By version, {"1.12": {...} }

    curManualVersion: null, // Version
    curProject: null, // Id
    curCategory: null, // Id, for example "man", "hea"...etc
    curSection: null, // Id

    listeners: {}, // By project id - contains unsubscribe functions -> { "projectId": { "docAggr": function, "warnings": function } }
};

const project = (state = initialState, action) => {
    const payload = action.payload;

    switch(action.type) {

        // ----- PROJECTS -----

        case MUTATIONS.SET_PROJECTS: {
            if(!payload || !(payload instanceof Array)) {
                return state;
            }

            const projects = {};
            payload.map(mapProject).forEach(p => projects[p.id] = p);

            return {
                ...state,
                projects: projects,
            }
        }
        case MUTATIONS.SET_ARCHIVED_PROJECTS: {
            if(!payload || !(payload instanceof Array)) {
                return state;
            }

            const projects = {};
            payload.map(mapProject).forEach(p => projects[p.id] = p);
            
            return {
                ...state,
                archivedProjects: projects,
            }
        }

        case MUTATIONS.SET_PROJECT: {
            if(!payload) {
                return state;
            }

            const projects = Object.assign({}, state.projects);
            const project = mapProject(payload);
            projects[project.id] = project;

            return {
                ...state,
                projects: projects,
            }
        }

        case MUTATIONS.SET_PROJECT_META: {
            const projectId = action.projectId;
            const key = action.key;

            const projectMetaData = Object.assign({}, (state.projectMeta || {})[projectId] || {}, {
                [key]: payload,
            });

            return {
                ...state,
                projectMeta: {
                    ...(state.projectMeta || {}),
                    [projectId]: projectMetaData, 
                }
            }
        }

        case MUTATIONS.USE_PROJECT: {

            const projectId = payload;

            // Shut off listeners for other project
            Object.values(state.listeners || {})
                .forEach(listeners => Object.values(listeners).forEach(unsub => unsub())) // Unsubscribe
            

            return {
                ...state,
                curProject: projectId,
                listeners: {[projectId]: action.unsubscriptions}, 
            }
        }

        // ----- SECTIONS ------
        case MUTATIONS.SET_SECTION_METAS: {
            const projectId = action.projectId;

            if(!(payload instanceof Array) || !state.projects[projectId]) {
                return state;
            }

            const sectionMetaData = 
                payload
                .map(meta => ({[meta.id]: mapSectionMeta(meta)}))
                .reduce((acc, meta) => ({...acc, ...meta}), (state[SECTION_META_DATA_KEY][projectId] || {}));

            return {
                ...state,
               [SECTION_META_DATA_KEY]: {
                   ...state[SECTION_META_DATA_KEY],
                   [projectId]: sectionMetaData,
               }
            }
        }

        case MUTATIONS.SET_SECTION_META: {
            const projectId = action.projectId;
            const sectionId = action.sectionId;

            
            if(!state.projects[projectId]) {
                return state;
            }


            const sectionMetaData = Object.assign(
                {}, 
                state[SECTION_META_DATA_KEY][projectId] || {},
                {[sectionId]: payload}   
            );

            return {
                ...state,
                [SECTION_META_DATA_KEY]: {
                    ...state[SECTION_META_DATA_KEY],
                    [projectId]: sectionMetaData,
                }
            }
        }

        case MUTATIONS.SET_CUR_SECTION: {
            return {
                ...state,
                curSection: payload,
            }
        }

        case MUTATIONS.SET_CUR_CATEGORY: {
            return {
                ...state,
                curCategory: payload,
            }
        }

        // ----- CRITERIAS ------
        case MUTATIONS.SET_CRITERIA: {
            const projectId = action.projectId;

            // Check if given project is available in the store
            if(
                !(payload instanceof Array)
                || !state.projects[projectId]  
            ) {
                return state;
            }

            const criteriaMetaData = payload
                .map(c => ({[c.id]: mapCriterionMeta(c)}))
                .reduce((acc, meta) => ({...acc, ...meta}), state[CRITERIA_META_DATA_KEY][projectId] || {});
      

            return {
                ...state,
                [CRITERIA_META_DATA_KEY]: {
                    ...state[CRITERIA_META_DATA_KEY],
                    [projectId]: criteriaMetaData,
                }
            }

        }

        case MUTATIONS.SET_CRITERION: {

            const projectId = action.projectId;
            
            // Check if given section is available in the store
            if(
                !state.projects[projectId] 
            ) {
                return state;
            }

            const criterion = mapCriterionMeta(payload);
            const criteriaMetaData = Object.assign(
                {}, 
                state[CRITERIA_META_DATA_KEY][projectId] || {},
                {[criterion.id]: criterion}    
            )

            return {
                ...state,
                [CRITERIA_META_DATA_KEY]: {
                    ...state[CRITERIA_META_DATA_KEY],
                    [projectId]: criteriaMetaData,
                }
            }
        }

        // ----- MANUALS -------

        case MUTATIONS.SET_MANUAL: {
            if(!payload) {
                return state;
            }

            const manuals = Object.assign(state.manuals);
            manuals[payload.version] = mapManual(payload);
            return {
                ...state,
                manuals: manuals,
            }
        }

        case MUTATIONS.USE_MANUAL: {
            return {
                ...state,
                curManualVersion: payload,
            }
        }

        // ------- METRICS --------

        case MUTATIONS.SET_DOC_AGGREGATIONS: {
            const projectId = action.projectId;
            if(!projectId) {
                return state;
            }

            const projectMetrics = Object.assign({}, state.metrics[projectId] || {});
            projectMetrics['docAggregations'] = mapDocAggregations(payload);
            
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    [projectId]: projectMetrics,
                }
            }
        }
        case MUTATIONS.SET_CREDIT_AGGREGATIONS: {
            const projectId = action.projectId;
            if(!projectId) {
                return state;
            }

            const projectMetrics = Object.assign({}, state.metrics[projectId] || {});
            projectMetrics['creditAggregations'] = mapDocAggregations(payload);
            
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    [projectId]: projectMetrics,
                }
            }
        }


        // ------ MISCELLANEOUS -----------

        case MUTATIONS.CLEAR_STATE: {
            // Shut off listeners for other project
            Object.values(state.listeners || {})
                .forEach(listeners => Object.values(listeners).forEach(unsub => unsub())) // Unsubscribe

            // Remove everything from the state except for the manuals
            const manuals = state.manuals;
            const newState = JSON.parse(JSON.stringify(initialState));
            return {
                ...newState,
                manuals: manuals,
            }
        }

        default:
            return state;
    }
};

export const mapProject = (project) => ({
    id: project.id,
    title: project.title,
    address: project.address,
    certificationType: project.certificationType,
    manualVersion: project.manualVersion,
    createdAt: project.createdAt,
    createdBy: project.createdBy,
    roles: project.roles,
    scope: project.scope || {},
    phases: project.phases || {},
    archived: project.archived || false,
    archivedBy: project.archivedBy || null,
});

export const mapSectionMeta = (sectionMeta) => ({
    id: sectionMeta.id,
    roles: sectionMeta.roles,
    note: sectionMeta.note,
    noteChangedAt: sectionMeta.noteChangedAt,
})

export const mapCriterionMeta = (criterionMeta) => ({
    id: criterionMeta.id || null,
    sectionId: criterionMeta.sectionId,
    locked: criterionMeta.locked || null,
    lockedBy: criterionMeta.lockedBy || null,
    status: criterionMeta.status || null,
    subCriteria: criterionMeta.subCriteria || {},
})

export const mapCriteriaDocument = (document) => ({
    documentId: document.documentId,
    locked: document.locked || null,
    lockedBy: document.lockedBy || null,
    status: document.status || null,
    statusNote: document.statusNote || null,
})

// ---- Manual mappers ------

export const mapManual = (manual) => ({
    lang: manual.lang,
    sections: manual.sections instanceof Array ? manual.sections.map(mapSection) : [],
    uploaded: manual.uploaded,
    version: manual.version,
    source: manual.source,
    pageNumbers: manual.pageNumbers || {},
});

export const mapSection = (section) => ({
    ...section,
    aim: section.aim,
    maxCredits: section.maxCredits,
    assessmentCriteria: section.assessmentCriteria ? section.assessmentCriteria.map(mapCriterion) : [],
});

export const mapCriterion = (criterion) => ({
    id: criterion.id,
    credits: criterion.credits,
    criteria: criterion.criterias.map(c => ({
        ...c,
        id: c.id,
        originalId: c['originalId'],
        criteriaDescription: c.criteriaDescription,
        subCriteria: c.subCriteria ? c.subCriteria.map(mapSubCriteria) : [],
    })).sort((a, b) => {
        const aId = a.originalId || '';
        const bId = b.originalId || '';
        if(aId.length > bId.length) {
            return 1;
        } else if (aId.length < bId.length) {
            return -1;
        } else {
            return aId.localeCompare(bId);
        }
    }),
    title: criterion.title,
    criterionIndex: criterion.criterionIndex,
});

export const mapSubCriteria = (subCriteria) => {
    // Extract the letter from the id - for example - "man_01__1__b" => "b"
    const internalId = (subCriteria.id || '__').split('__').pop();

    return {
        id: subCriteria.id,
        internalId: internalId,
        subCriteriaDescription: subCriteria.subCriteriaDescription,
    }
}

// ----- Document aggregation mappers -----

export const mapDocAggregations = (docAggr) => ({
    projectAggregations: docAggr.projectAggregations ? mapDocAggregation(docAggr.projectAggregations) : null,
    categoryAggregations: docAggr.categoryAggregations ? mapDocAggregationsWithKeys(docAggr.categoryAggregations) : null,
    sectionAggregations: docAggr.sectionAggregations ? mapDocAggregationsWithKeys(docAggr.sectionAggregations) : null,
    criteriaAggregations: docAggr.criteriaAggregations ? mapDocAggregationsWithKeys(docAggr.criteriaAggregations) : null,
});

export const mapDocAggregationsWithKeys = (docAggr) => (
    Object
        .entries(docAggr)
        .map(([key, aggs]) => ({[key]: mapDocAggregation(aggs)}))
        .reduce((acc, val) => ({...acc, ...val}), {})
)

export const mapDocAggregation = (docAggr) => {
    const aggrs = {
        approved: docAggr.approved || 0,
        disapproved: docAggr.disapproved || 0,
        pending: docAggr.pending || 0,
        missing: docAggr.missing || 0,
    };
    aggrs.total = Object.values(aggrs).reduce((acc, val) => acc + val, 0);
    return aggrs;
}


export default project;
