import React, { useState, useEffect } from 'react';
import CATEGORIES from '~/data/categories.json';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import ProjectActions from '~/store/project/actions.manual';
import Icon from '~/components/base/Icon';
import Collapse from '~/components/base/Collapse';
import sectionIdToText from '~/utils/text/sectionIdToText';
import Checkbox from '~/components/base/Checkbox';
import Spinner from '~/components/base/Spinner';



const ShowCriteria = ({ criteria, onChange }) => {

    // States
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [manual, setManual] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [newCriteria, setNewCriteria] = useState(criteria);

    // Constants

    useEffect(() => {
        fetchLatestManual();
        onChange(newCriteria, getCredits(criteria))
    }, [])

    // Functions
    const fetchLatestManual = () => {
        setIsLoading(true);
        ProjectActions.fetchLatestManual()
            .then((result) => {
                setManual(result);
            })
            .catch((error) => {
                console.error(error);
                console.log("Could not fetch manual");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    //toggles criterion and updates parent component
    const toggleCriterion = (value, criterionId) => {
        const tempNewCriteria = {
            ...newCriteria,
            [criterionId]: value,
        }
        setNewCriteria(tempNewCriteria)
        onChange(tempNewCriteria, getCredits(tempNewCriteria))
    }

    //finds the credits a specific criterion gives
    const getCreditsByCriterionId = (criterionId) => {
        if (manual) {
            const sectionId = criterionId.split("_").slice(0, 2).join("_");
            return (((manual.sections
                .find((section) => section.id === sectionId) || {}).assessmentCriteria || {})
                .find((crit) => crit.id === criterionId).credits
                || 0)
        }
        return 0;
    }

    // Calculates credits of tempCriteria object based on string filter
    const getCredits = (tempCriteria, filter) => {
        if (tempCriteria) {
            return Object.entries(tempCriteria)
                .reduce((prev, [critId, value]) => {
                    if (!value) {
                        return prev;
                    }
                    return prev + (filter ? critId.startsWith(filter) ? getCreditsByCriterionId(critId) : 0 : getCreditsByCriterionId(critId))
                }, 0)
        }
        return 0;
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
			<div>
				<div className="border-b p-2 grid-cols-2">
					<div className="flex items-center bg-white">
						<div className="p-1">
							<b>Poeng totalt</b>
						</div>
						<div className="flex-grow" />
						<div className="px-6">
							<b>{`${getCredits(newCriteria)}/50`}</b>
						</div>
					</div>
				</div>
				{CATEGORIES.map((category) => (
					<div key={category.key} className="border-b p-2 grid-cols-2">
						<div className="flex items-center bg-white">
							<div
								className="flex items-center w-full cursor-pointer"
								onClick={() =>
									setSelectedCategory(
										selectedCategory === category.key ? null : category.key
									)
								}
							>
								<div className="p-1">{category.name}</div>
								<div className="flex-grow" />
								<div className="px-6">
									{getCredits(newCriteria, category.key)}
								</div>
								<Icon
									icon={
										selectedCategory === category.key
											? 'chevron-up'
											: 'chevron-down'
									}
								/>
							</div>
						</div>
						<Collapse visible={selectedCategory === category.key}>
							<div className="px-4">
								{(manual.sections || []).reduce(
									(prev, section) =>
										section.id.startsWith(category.key)
											? [
													...prev,
													<div key={section.id} className="mb-4">
														<div className="flex items-center capitalize border-t">
															<div className="p-1">
																<b>{`${section.title || ''}`}</b>
															</div>
															<div className="flex-grow" />
															<div className="px-6">
																<b>{getCredits(newCriteria, section.id)}</b>
															</div>
														</div>
														{(section.assessmentCriteria || []).map(
															(criterion) => (
																<div
																	className="flex items-center capitalize border-b-1"
																	key={criterion.id}
																>
																	<Checkbox
																		value={criteria[criterion.id]}
																		onChange={(value) =>
																			toggleCriterion(value, criterion.id)
																		}
																	/>
																	<div>
																		{`Krav ${criterion.criterionIndex || 0}`}
																	</div>
																	<div className="flex-grow" />
																	<div className="px-6">
																		{criterion.credits}
																	</div>
																</div>
															)
														)}
													</div>,
											  ]
											: prev,
									[]
								)}
							</div>
						</Collapse>
					</div>
				))}
			</div>
		);
}

export default ShowCriteria;