import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { eventTypes as EVENT_TYPES, eventFlags as EVENT_FLAGS } from '~/data/config';
import { Link } from 'react-router-dom';

// State
import { useDispatch } from 'react-redux';
import EventActions from '~/store/events/actions';

// Core components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import DatePicker from '~/components/base/LiteDatePicker';
import ErrorModal from '../ErrorModal'
import NotificationIcon from '~/components/miscellaneous/NotificationIcon/NotificationIcon';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import notificationToText from '~/utils/notifications/notificationToText';
import notificationPath from '~/utils/notifications/notficationPath';
import timeAgo from '~/utils/date/timeAgo';
import isDateEqual from '~/utils/date/isDateEqual';


const NotificationStreamItem = ({
    projectId,
    item = {},
    disableActions = false,
}) => {
    const dispatch = useDispatch();

    const id = item.id;
    const eventAction = item.eventAction;
    const timestamp = item.timestamp;
    const payload = item.payload || {};
    const flag = item.flag;

    const [errorModal, setErrorModal] = useState({});
    const [flagState, setFlagState] = useState(flag);
    const [refreshKey, setRefreshKey]= useState(null);

    const updateReminder = (date) => {
        const isEqual = isDateEqual(item.reminder ? new Date(item.reminder) : null, date);
        if(isEqual) {
            return;
        }

        if(date < new Date()) {
            setErrorModal({show: true});
            return;
        };

        if(item.reminder === date.toISOString()) {
            return;
        }

        const offsetMilli = date.getTimezoneOffset()*60_000;

        // Make sure it is the right day in UTC time
        const iso = offsetMilli < 0 ?
            new Date(date.getTime() - offsetMilli).toISOString() :
            new Date(date.getTime() + offsetMilli).toISOString();


        // Update state
        item.reminder = iso;
        setRefreshKey(item.reminder);
        EventActions.remindMe(projectId, id, iso)
        .catch(() => {
            setErrorModal({show: true});

            // Update state
            item.reminder = null;
            setRefreshKey(null);
        });
    }

    const toggleBookmark = () => {
        if(flagState === EVENT_FLAGS.BOOKMARK) {
            setFlagState(EVENT_FLAGS.NONE)
            item.flag = EVENT_FLAGS.NONE
            EventActions.updateFlag(projectId, item)(dispatch)
                .catch(console.error);
        } else {
            setFlagState(EVENT_FLAGS.BOOKMARK)
            item.flag = EVENT_FLAGS.BOOKMARK
            EventActions.updateFlag(projectId, item)(dispatch)
                .catch(console.error);
        }
    }

    const toggleIgnore = () => {
        if(flagState === EVENT_FLAGS.IGNORE) {
            setFlagState(EVENT_FLAGS.NONE)
            item.flag = EVENT_FLAGS.NONE
            EventActions.updateFlag(projectId, item)(dispatch)
                .catch(console.error);
        } else {
            setFlagState(EVENT_FLAGS.IGNORE)
            item.flag = EVENT_FLAGS.IGNORE
            EventActions.updateFlag(projectId, item)(dispatch)
                .catch(console.error);
        }
    }

    return (
        <div className='grid grid-cols-4 mb-4'>
            <Link
                className='col-span-3 hover:underline'
                to={notificationPath(projectId, item)}
            >
                <div className='flex items-center'>
                    <NotificationIcon
                        notificationItem={item}
                    />
                    <div className='mx-2 text-lg font-semibold'>
                        { sectionIdToText(item.sectionId) }
                    </div>
                </div>
                <div className='font-light'>
                    {
                        notificationToText(item, projectId)
                    }
                </div>
                <div className='my-1 text-gray-600 text-sm'>
                    {
                        timestamp && timeAgo(new Date(timestamp))
                    }
                </div>
            </Link>
            {
                disableActions ?
                <div /> :
                <div className='flex items-center justify-end'>
                    <DatePicker
                        className='mx-1'
                        variant='icon'
                        color='black'
                        date={item.reminder ? new Date(item.reminder) : null}
                        onChange={(date) => {
                            updateReminder(date);
                        }}
                        lockDates={[[new Date(0), new Date()]]}
                    />
                    <Button
                        className='mx-1'
                        variant='icon'
                        color='black'
                        onClick={() => toggleBookmark()}
                    >
                        {
                            flagState === EVENT_FLAGS.BOOKMARK ?
                            <Icon icon='bookmark' /> :
                            <Icon icon={['far', 'bookmark']} />
                        }
                    </Button>
                    <Button
                        className='mx-1'
                        variant='icon'
                        color='black'
                        onClick={() => toggleIgnore()}
                    >
                        {
                            flagState === EVENT_FLAGS.IGNORE ?
                            <Icon icon='times-circle' /> :
                            <Icon icon={['far', 'times-circle']} />
                        }
                    </Button>
                </div>
            }
            <ErrorModal
                show={Boolean(errorModal.show)}
                onClose={() => setErrorModal({show: false})}
            />
        </div>
    )
};

NotificationStreamItem.propTypes = {
    projectId: PropTypes.string,
    item: PropTypes.shape({
        id: PropTypes.string,
        eventAction: PropTypes.string,
        sectionId: PropTypes.string,
        timestamp: PropTypes.string,
    }).isRequired,
    disableActions: PropTypes.bool,
}

export default NotificationStreamItem;