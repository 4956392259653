import * as firebase from './firebase';
import store from '../store';
import storeActions from '../store/actions';

export default class AuthService {

    static initialize() {

        // Listen to auth changed (log-in & log-out)
        firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                AuthService.onLoggedIn(user);
            } else {
                AuthService.onLoggedOut(user);
            }
        });
    }

    static onLoggedIn(user) {
        // Store user-info in store
        store.dispatch(storeActions.user.setUser(user));

        // Fetch projects
        storeActions.projects.getProjects(store.dispatch);
    }

    static onLoggedOut(user) {
        store.dispatch(storeActions.projects.clearState())
        store.dispatch(storeActions.documents.clearState())
        store.dispatch(storeActions.events.clearState())
        store.dispatch(storeActions.user.clearState())
        store.dispatch(storeActions.occurrences.clearState())
    }

    static currentUserId() {
        return firebase.auth.currentUser.uid;
    }
}