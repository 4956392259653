import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router";
import { roles as ROLES } from '~/data/config';
import AuthService from '~/services/authService';


// Styles
import styles from './ProjectNotificationHub.module.css'

// Store imports
import store from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import UserSelectors from '~/store/user/selectors';
import ProjectSelectors from '~/store/project/selectors';
import EventActions from '~/store/events/actions';

// React components
import { Link } from 'react-router-dom'

// Core components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import { Select } from "~/components/base/Select";
import Tabs from '~/components/base/Tabs';


// Page components
import ResponsibilityList from './components/ResponsibilityList';
import NotificationStream from './components/NotificationStream';
import NotificationSidebar from './components/NotificationSidebar';

// Utils
import { isObjEqual, groupBy } from '~/utils/type/object';
import { useMemo } from 'react';


// Typedefs
/**
 * @typedef {Object} NotificationFilter
 * @property {Number} limit
 * @property {String} from
 * @property {String} to
 * @property {String} flag
 * @property {String} eventAction
 * @property {String} targetUser
 * @property {String} sectionId
 * @property {String} subCriterionId
 * @property {Boolean} orderBySection
 * @property {String} fromSection
 * @property {String} toSection
 *
 */

// Constants
const TABS = [
    { value: null, label: 'Alle' },
    { value: 'BOOKMARK', label: 'Lagrede' },
    { value: 'IGNORE', label: 'Avviste' },
];

const SORTBY_FILTERS = [
    { value: null, label: 'Tid' },
    { value: true, label: 'Emne' },
]

const LIMIT = 20;

/**
 * @type {NotificationFilter}
 */
const DEFAULT_FILTERS = {
    limit: LIMIT,
    from: null,
    to: null,
    flag: null,
    eventAction: null,
    targetUser: null,
    sectionId: null,
    subCriterionId: null,
    orderBySection: null,
    fromSection: null,
    toSection: null,
};



const ProjectNotificationHub = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const projectId = params.id;

    // State
    const hasNextPageRef = useRef(true);
    const prevFilterRef = useRef({});

    /**
     * @type {[NotificationFilter, Function]}
     */
    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [showSidebar, setShowSidebar] = useState(false);
    const [hasFilterChanges, setHasFilterChanges] = useState(false);

    useEffect(() => {
        onFilter();
    }, [])

    useEffect(() => {
        fetchNotifications(filters);
    }, [filters.flag, filters.orderBySection]);

    useEffect(() => {
        setHasFilterChanges(!isObjEqual(filters, prevFilterRef.current));
    }, [filters,  prevFilterRef.current])

    const onFilter = () => {
        hasNextPageRef.current = true;
        fetchNotifications(filters);
    }

    /**
     * @param {String} key
     * @param {any} value
     */
    const onFilterChange = function() {
        const args = arguments;

        if(args[0] === null) {
            // Reset filters
            const newFilters = DEFAULT_FILTERS;
            setFilters(newFilters);
            setTimeout(() => fetchNotifications(newFilters), 200);
            return;
        }

        const newFilters = Object.assign({}, filters);
        for(let i = 0; i < args.length - 1; i += 2) {
            newFilters[args[i]] = args[i+1];
        }
        setFilters(newFilters);
    }


    const goToPreviousPage = () => history.goBack();

    const fetchNotifications = (filters, isFetchingNextPage) => {

        // Don't fetch if the filter has not changed
        if(!isFetchingNextPage && isObjEqual(prevFilterRef.current, filters)) {
            return;
        }

        // If one should fetch next page, modify the 'from' filter-property
        const filterObj =  Object.assign({}, filters || DEFAULT_FILTERS);
        if(isFetchingNextPage && notifications.length > 0 && hasNextPageRef.current) {
            const lastItem = notifications[notifications.length - 1];
            filterObj.from = lastItem.timestamp;
            filterObj.fromSection = !filterObj.orderBySection ? null : lastItem.sectionId;
        }

        !isFetchingNextPage && setIsLoading(true);
        isFetchingNextPage && setIsLoadingNextPage(true);

        EventActions.fetchNotifications(projectId, filterObj)(dispatch)
            .then((notfs) => {
                prevFilterRef.current = filters;
                hasNextPageRef.current = notfs.length >= LIMIT;

                if(isFetchingNextPage) {
                    const curScrollY = window.scrollY;
                    // Make sure there are no duplicates, group by key and then sort by time
                    const newNotf = Object.values(
                        groupBy([...notifications, ...notfs], (elem) => elem.id)
                    ).sort((a, b) => sortBy(a, b, Boolean(filterObj.orderBySection)));

                    setNotifications(newNotf);
                    window.scrollTo(0, curScrollY); // To maintain the same scroll height
                } else {
                    setNotifications(notfs);
                }
            })
            .catch(console.error)
            .finally(() => {
                !isFetchingNextPage && setIsLoading(false);
                isFetchingNextPage && setIsLoadingNextPage(false);
            })
    }

    const sortBy = (a, b, orderBySection = false) => {
        if(!orderBySection) {
            // Sort by time
            return -a.timestamp.localeCompare(b.timestamp);
        }

        // Sort by sectionId
        const sectionCompare = (a.sectionId || '').localeCompare(b.sectionId);
        if(sectionCompare !== 0) {
            return sectionCompare;
        }

        // SectionId is the same, sort by time
        return -a.timestamp.localeCompare(b.timestamp);
    }

    return (
        <div className={styles.root}>
            <NotificationSidebar
                show={showSidebar}
                onClose={() => setShowSidebar(false)}
                projectId={projectId}
                filters={filters}
                hasFilterChanges={hasFilterChanges}
                onChange={onFilterChange}
                onFilter={onFilter}
                disabled={isLoading}
            />
            <div>
                <header className='flex items-center bg-white shadow h-12 lg:h-16 w-full p-5'>
                    <div
                        className='block lg:hidden'
                    >
                        <Button
                            variant='icon'
                            onClick={() => setShowSidebar(!showSidebar)}
                        >
                            <Icon
                                icon='bars'
                            />
                        </Button>
                    </div>
                    <div className='flex-grow' />
                    <Icon icon='user' className='mx-3' size='lg' />
                </header>
                <div className={`p-5 pt-0 grid gap-6 mt-6 ${styles.content}`}>

                    <span
                        style={{gridArea: 'back'}}
                        onClick={goToPreviousPage}
                        to='#back'
                        className='cursor-pointer hover:underline my-auto'
                    >
                        <Icon icon='arrow-left' size='lg' className='mr-2' /> Tilbake
                    </span>

                    <div
                        style={{gridArea: 'filters'}}
                        className='grid grid-cols-1 lg:grid-cols-5 gap-4'
                    >
                        <Tabs
                            className='lg:col-span-4 shadow rounded bg-white'
                            tabClassName='rounded h-full'
                            tabs={TABS}
                            value={filters.flag}
                            onChange={(value) => onFilterChange('flag', value)}
                        />
                        <Select
                            className='w-25 mb-0'
                            value={filters.orderBySection}
                            onChange={(value) => onFilterChange('orderBySection', value)}
                            placeholder='Sorter etter'
                            margin={false}
                            data={SORTBY_FILTERS}
                        />
                    </div>
                    <div
                        style={{gridArea: 'todo'}}
                    >
                        <ResponsibilityList
                            projectId={projectId}
                            targetUser={prevFilterRef.current.targetUser}
                            isLoading={isLoading}
                            />
                    </div>
                    <div
                        style={{gridArea: 'stream'}}
                        >
                        <NotificationStream
                            projectId={projectId}
                            isLoading={isLoading}
                            notifications={notifications}
                            hasNextPage={hasNextPageRef.current}
                            onFetchNextPage={() => fetchNotifications(filters, true)}
                            isLoadingNextPage={isLoadingNextPage}
                            targetUser={prevFilterRef.current.targetUser}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectNotificationHub;