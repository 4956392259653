import React, { useState, useEffect } from "react";
import Modal from '~/components/base/Modal'
import AddUserItem from './AddUserItem'
import Icon from "~/components/base/Icon";
import TextField from "~/components/base/TextField";
import { useParams } from 'react-router-dom';

import { useSelector , useDispatch} from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';
import UserActions from '~/store/user/actions';

// Utils
import roleToText from '~/utils/text/roleToText';

const AddUserModal = (props) => {
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const params = useParams();
    const originalMembers = useSelector(UserSelectors.getAllMembersWithoutSectionAccess(project, props.sectionId));

    // State
    const [filterFunc, setFilterFunc] = useState({func: () => true})
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();

    const onQuery = (event) => {
        if (event.key !== 'Enter') {
            return;
        }
        
        let keyword = query.toLowerCase();

        if (keyword === '') {
            setFilterFunc({
                func: () => true,
            })
        } else {  
            setFilterFunc({
                func: (member) => {
                    let memberRole = roleToText(project.roles[member.id]);
                    let fullName = `${member['firstName']} ${member['lastName']}`
                    return (
                        (member['firstName']).toLowerCase().startsWith(keyword) ||
                        (member['lastName']).toLowerCase().startsWith(keyword) ||
                        memberRole.toLowerCase().startsWith(keyword) ||
                        fullName.toLowerCase().startsWith(keyword)
                    )
                }
            })
        }
    }

    useEffect(() => {
        UserActions.fetchAllMembersInProject(project.id)(dispatch);
    }, [])

    return (
        <Modal
            show={props.show}
            onBackdropClick={props.onClose}
        >
            <div className='paper'>
            <div className ="flex justify-around">
                <div className="pb-4">
                    <TextField
                        margin={false}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        onKeyPress={onQuery}
                        back={
                            <Icon 
                                className='hover:text-primary cursor-pointer scale-110'
                                icon='search'
                                onClick={() => onQuery({ key: 'Enter' } )}
                            />    
                        }
                    />
                </div>  
            </div>
            {
                originalMembers
                .filter(filterFunc.func)
                .map((member) => (
                    <AddUserItem 
                        key={member.id}
                        id={member.id}
                        firstname={member.firstName}
                        lastname={member.lastName}
                        projectId={project.id}
                        sectionId={params.sectionId}
                    />
                ))
            }
            </div>
        </Modal>
    )
}

export default AddUserModal
