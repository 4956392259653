import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '~/components/base/Icon';

// Styles
import style from './DatePicker.module.css'

// Utils
import typeOf from '~/utils/type/typeOf';

const DatePicker = ({ initValue, minDate, maxDate, handleDateValue, className }) => {
    /**
     * @param {DateObject} date.
     * @description Sets the clock of a date to 00:00:00 to be consistent.
     */
    const formatDateToInit = (date) => {
        if (!date || typeOf(date) !== 'date') return;
        date.setHours(0, 0, 0)
    }

    // Storing the todays date for practical reasons.
    const today = new Date();
    formatDateToInit(today);

    if (typeOf(initValue) !== 'date' && typeOf(initValue) !== 'null') {
        throw Error('Initial value of DatePicker has to be of object-type Date or null (none).')
    }


    // Core variables
    const date = (initValue && initValue > today) ? initValue : today;
    formatDateToInit(date);
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());

    // The calendars value should always be the current date.
    const [value, setValue] = useState((initValue || null));
    formatDateToInit(value);

    // Ranges for the calendar (optional).
    const min = minDate;
    const max = maxDate;

    // Checking that the value of the min/max property is valid.
    if ((!!min && typeOf(min) !== 'date') || (!!max && typeOf(max) !== 'date')) {
        throw Error('Error: Please only insert a new Date() object as a minDate and/or maxDate property in the DatePicker.')
    }

    formatDateToInit(min);
    formatDateToInit(max);

    // Hard-coded list of all days.
    const listOfAllDaysAsText = [
        'Mandag',
        'Tirsdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lørdag',
        'Søndag'
    ];

    // Hard-coded list of all months.
    const listOfAllMonthsAsText = [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    // Dynamic list of next 10 years.
    const nextTenYears = Array(11).fill().map((_, index) => today.getFullYear() + index);

    /**
     * @param {Number} The month number, 0 based.
     * @param {Number} The year, not zero based, required to account for leap years.
     * @return {Array<Date>} List with date objects for each day of the month.
     * @author Juan Mendes - 30th October 2012.
     */
    const getDaysInMonth = (month, year) => {
        if ((!month && month !== 0) || (!year && year !== 0)) { return };

        const date = new Date(year, month, 1);
        const days = [];

        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    const [arrayOfDays, setArrayOfDays] = useState(getDaysInMonth(month, year));

    // Updates the days of the calendar whenever the month and/or year changes.
    useEffect(() => {
        let _arrayOfDays = getDaysInMonth(month, year);
        let firstDayOfMonth = _arrayOfDays[0].getDay();

        // Converting Sunday (which is 0 when using getDay()) to 7 to make it easier to work with.
        firstDayOfMonth = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

        if (1 < firstDayOfMonth) {
            _arrayOfDays = Array(firstDayOfMonth - 1).fill(false, 0).concat(_arrayOfDays)
        }

        setArrayOfDays(_arrayOfDays);
    }, [month, year])


    const handlePreviousMonth = () => {
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
    }

    const handleNextMonth = () => {
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);

        } else {
            setMonth(month + 1);
        }
    }

    /**
     * @param {Date} date - date to check
     * @description Checks if the date passed is between the optional min/max values
     */
    const dateIsNotBetweenAllowedRange = (date) => {
        formatDateToInit(date);
        return (min || max) && (date.toISOString().split(':')[0] !== today.toISOString().split(':')[0] && (date < min || date > max));
    }

    /**
     * @param {Boolean} notAllowed - tells if the date that was clicked on is valid
     * @param {Date} date - the date-value that was clicked
     */
    const handleDateClick = (date) => {
        if (dateIsNotBetweenAllowedRange(date)) { return }
        setValue(date);
        handleDateValue(date);
    }

    const handleMonthOnChange = (event) => {
        const monthSelected = parseInt(event.target.value);
        setMonth(monthSelected)
    }

    const handleYearOnChange = (event) => {
        const yearSelected = parseInt(event.target.value);
        setYear(yearSelected)
    }

    return (
        <div id={style.calendarWrapper} className={className}>
            <header>
                {
                    <>
                        <select name="months" id="months" value={month} onChange={(event) => handleMonthOnChange(event)}>
                            {listOfAllMonthsAsText.map((_month, index) => <option key={index} value={index} >{_month}</option>)}
                        </select>
                        <select name="years" id="years" value={year} onChange={(event) => handleYearOnChange(event)}>
                            {nextTenYears.map((_year, index) => <option key={index} value={_year}>{_year}</option>)}
                        </select>
                    </>
                }
            </header>
            <div id={style.calendar}>
                <section id={style.calendarDays}>
                    {listOfAllDaysAsText.map((day, index) => <span key={index}>{day}</span>)}
                </section>
                <section id={style.calendarGrid}>
                    {
                        arrayOfDays.map((date, index) => {
                            const [Day, Month, Date, Year] = date.toString().split(' ');
                            const dateIsTheCurrentValue = value && value.toString() === date.toString();
                            return (
                                date ?
                                    <button
                                        onClick={() => handleDateClick(date)}
                                        key={index}
                                        disabled={dateIsNotBetweenAllowedRange(date)}
                                        className={dateIsTheCurrentValue ? style.selected : ''}
                                    >
                                        {Date}
                                    </button>
                                    :
                                    <span key={index}></span>
                            )
                        })
                    }
                </section>
                <section id={style.navigationWrapper}>
                    <button id={style.previousMonth} onClick={handlePreviousMonth}>
                        <Icon
                            icon={'caret-up'}
                            color='#202020'
                            size='lg'
                        />                    </button>
                    <button id={style.nextMonth} onClick={handleNextMonth}>
                        <Icon
                            icon={'caret-down'}
                            color='#202020'
                            size='lg'
                        />
                    </button>
                </section>
            </div>
        </div >

    )
}

DatePicker.propTypes = {
    initValue: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    handleDateValue: PropTypes.func,
    className: PropTypes.string
};

export default DatePicker;