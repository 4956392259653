import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { member as MEMBER } from '~/data/config';

// State imports
import { useDispatch, useSelector } from 'react-redux';
import UserActions from '~/store/user/actions';
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';


// Core Components
import Icon from '~/components/base/Icon';
import Spinner from '~/components/base/Spinner';


// Module Components
import CriterionItem from './components/CriterionItem';
import ManageResponsibleModal from './components/ManageResponsibleModal/ManageResponsibleModal'

const CriteriaModule = ({
    owner,
    className,
    sectionId,
    sectionCriteria,
    criteriaMetaData,
}) => {
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const members = useSelector(UserSelectors.getAllMembersWithResponsibility(project.id, sectionId))

    const [manageResponsibleModal, setManageResponsibleModal] = useState({});

    const manageResponsible = () => {
        setManageResponsibleModal({
            show: true,
        })
    }

    return (
        <div
            className={`${className} paper mt-4`}
        >
            <div className='flex items-center'>
                <h5 className='font-light'>
                    Ansvar
                </h5>
                <div className='flex-grow' />
                {
                    owner ?
                    <div 
                        className='flex items-center cursor-pointer'
                        onClick={() => {
                            manageResponsible();
                        }}    
                    >
                        <h6 className='font-light text-base underline mb-0 mr-2'>
                            Tildel overordnet ansvar
                        </h6>
                        <Icon icon='plus-circle' size='2x' />
                    </div>
                    : null
                }
            </div>
            <div>
                {
                    criteriaMetaData
                    .filter(c => c.sectionId === sectionId)
                    .map(c => 
                        <CriterionItem
                            owner={owner}
                            key={c.id}
                            id={c.id}
                            sectionCriteria={sectionCriteria}
                            criterionMetaData={c}
                            members={members}
                        />
                    )
                }
            </div>
            <ManageResponsibleModal
                show={Boolean(manageResponsibleModal.show)}
                onClose={() => setManageResponsibleModal({show: false})}
                members={members}
                criteriaMetaData={criteriaMetaData}
                sectionId={sectionId}
            />
        </div>
    )
};

CriteriaModule.propTypes = {
    className: PropTypes.string,
    sectionId: PropTypes.string.isRequired,
    sectionCriteria: PropTypes.array, // The criterias belonging to a section in the manual
    criteriaMetaData: PropTypes.array.isRequired, // The metadata belonging to the criteria
}

CriteriaModule.defaultTypes = {
    sectionCriteria: [],
}

export default CriteriaModule;
