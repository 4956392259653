import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { eventTypes as EVENT_ACTIONS } from '~/data/config';

// Store imports
import store from '~/store';
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserActions from '~/store/user/actions';
import UserSelectors from '~/store/user/selectors';

// Core components
import Sidebar from '~/components/navigation/Sidebar';
import { Select } from "~/components/base/Select";
import Collapse from '~/components/base/Collapse';
import Spinner from '~/components/base/Spinner';
import DatePicker from '~/components/base/DateRangePicker';
import KvistLogo from '~/components/miscellaneous/KvistLogo';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import Icon from '~/components/base/Icon';
import { Button } from '~/components/base/Buttons';

// Constants
const EVENT_ACTIONS_DATA = [
    { value: EVENT_ACTIONS.CRITERION_SENT_FOR_REVISION, label: 'Sendt for revisjon'},
    { value: EVENT_ACTIONS.CRITERION_STATUS_CHANGE, label: 'Vurdering'},
    { value: EVENT_ACTIONS.CRITERION_NEW_DOCUMENTATION, label: 'Ny dokumentasjon'},
]

const NotificationSidebar = ({
    filters = {},
    onChange,
    onFilter,
    disabled,
    onClose,
    hasFilterChanges,
    ...rest
}) => {
    const dispatch = useDispatch();

    // State
    const [isLoading, setIsLoading] = useState(true);

    const project = useSelector(ProjectSelectors.getCurrentProject);

    const sections = useMemo(() => (
        Object.keys(project.scope || {}).map((sectionId) => ({
            value: sectionId,
            label: sectionIdToText(sectionId),
        }))
    ), [project.id])

    const subCriteriaBySection = useMemo(() => (
        Object.entries(project.scope || {})
            .map(([sectionId, criteria]) => ({
                [sectionId]: Object.values(criteria || {})
                    .reduce((prev, subCriterionIds) => ([...prev, ...subCriterionIds]), [])
                    .map((subCriterionId) => ({
                        value: subCriterionId,
                        label: `Krav ${subCriterionId.split("_").slice(-1)[0]}`
                    }))
            }))
            .reduce((prev, sectionValue) => ({...prev, ...sectionValue}))
    ), [project.id]);

    const members = useMemo(() => (
        Object.values(UserSelectors.getAllMembersInProject(project.id)(store.getState()))
            .map((member) => ({
                value: member.id,
                label: `${member.firstName} ${member.lastName}`
            }))
    ), [project.id, isLoading])

    useEffect(() => {
        setIsLoading(true);
        UserActions.fetchAllMembersInProject(project.id)(dispatch)
            .finally(() => setIsLoading(false));
    }, [project.id])

    const onSectionChange = (sectionId) => {
        if(!onChange) {
            return;
        }

        onChange('subCriterionId', null, 'sectionId', sectionId);
    }

    const resetFilters = () => {
        onChange && onChange(null);
    }

    const onFilterClick = () => {
        onFilter();
        onClose && onClose();
    }

    return (
        <Sidebar
            onClose={onClose}
            {...rest}
        >
            <div className='flex items-center justify-between'>
                <Link to="/">
                    <KvistLogo
                        className='mb-2'
                    />
                </Link>
                <div className='block lg:hidden'>
                    <Button
                        variant='icon'
                        onClick={() => onClose && onClose()}
                    >
                        <Icon icon='bars' />
                    </Button>
                </div>
            </div>
            <div id="sidebar" className='pt-2'>
                <div className='flex items-center mb-4'>
                    <h5 className='font-bold mb-0'>Filtrering</h5>
                    <Button
                        variant='icon'
                        className='ml-2'
                        color='black'
                        onClick={resetFilters}
                    >
                        <Icon icon='redo' />
                    </Button>
                </div>
                {
                    isLoading ?
                    <div className='spinner-absolute'>
                        <Spinner />
                    </div> :
                    <>
                        <section id="select-section">
                            <p>Emne</p>
                            <Select
                                disabled={disabled}
                                value={filters.sectionId}
                                placeholder='Velg emne'
                                onChange={onSectionChange}
                                data={sections}
                            />
                        </section>
                        <Collapse
                            visible={Boolean(filters.sectionId)}
                        >
                            <section id="select-criteria">
                                <p>Krav</p>
                                <Select
                                    disabled={disabled}
                                    data={subCriteriaBySection[filters.sectionId] || []}
                                    value={filters.subCriterionId}
                                    onChange={(value) => onChange('subCriterionId', value)}
                                    placeholder='Velg krav'
                                />
                            </section>
                        </Collapse>
                        <section id="select-remark">
                            <p>Merknad</p>
                            <Select
                                disabled={disabled}
                                value={filters.eventAction}
                                onChange={(value) => onChange('eventAction', value)}
                                data={EVENT_ACTIONS_DATA}
                                placeholder='Velg merknad'
                            />
                        </section>
                        <section id="select-timespan">
                            <p>Periode</p>
                            <DatePicker
                                className='mb-6'
                                startDate={filters.to ? new Date(filters.to) : null}
                                endDate={filters.from ? new Date(filters.from) : null}
                                onChange={(start, end) => onChange(
                                    'to',
                                    start ? start.toISOString() : null,
                                    'from',
                                    end ? end.toISOString() : null,
                                )}
                                disabled={isLoading}
                            />
                        </section>
                        <section id="select-role">
                            <p>Ansvarlig</p>
                            <Select
                                disabled={disabled}
                                value={filters.targetUser}
                                onChange={(value) => onChange('targetUser', value)}
                                placeholder='Velg ansvarlig part'
                                data={members}
                            />
                        </section>
                        <Button
                            className='my-4 w-full'
                            disableElevation
                            onClick={onFilterClick}
                            loading={disabled}
                            disabled={disabled}
                        >
                            Filtrer
                            {
                                hasFilterChanges &&
                                <div className='bg-white rounded-full w-1 h-1 ml-4' />
                            }
                        </Button>
                    </>
                }

            </div>
        </Sidebar>
    )
}

NotificationSidebar.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    filters: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    hasFilterChanges: PropTypes.bool,
}

export default NotificationSidebar;