import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Litepicker from 'litepicker';

// Styles
import styles from './DateRangePicker.module.css';

// Utils
import { dateToDateString } from '~/utils/date';


const DatePicker = ({
    className = '',
    options,
    startDate,
    endDate,
    onChange,
    disabled,
}) => {
    const containerRef = useRef();
    const pickerRef = useRef();
    const stateRef = useRef({
        startDate: null,
        endDate: null,
        onChange,
    });

    // State
    const [display, setDisplay] = useState(<>
        Velg et tidsintervall
    </>);

    useEffect(() => {
        const picker = new Litepicker({ 
            ...options,
            element: containerRef.current,
            onSelect: onDateChange,
            singleMode: false,
        })
        pickerRef.current = picker;

    }, [containerRef]);

    useEffect(() => {
        if(!pickerRef.current) {
            return;
        }

        /**
         * @type {Litepicker}
         */
        const picker = pickerRef.current;

        const hasStartChanged = picker.getStartDate() !== startDate;
        const hasEndChanged = picker.getEndDate() !== endDate;
        if(hasStartChanged && hasEndChanged) {
            picker.setDateRange(startDate, endDate);
            stateRef.current.startDate = startDate;
            stateRef.current.endDate = endDate;
        } else if(hasStartChanged) {
            picker.setDate(startDate);
            stateRef.current.startDate = startDate;
        } else if(hasEndChanged) {
            picker.setEndDate(endDate);
            stateRef.current.endDate = endDate;
        }

        if(!startDate || !endDate) {
            picker.clearSelection();
        }

        updateDisplay();

    }, [startDate, endDate])

    useEffect(() => {
        stateRef.current.onChange = onChange;
    }, [onChange])


    const onDateChange = (start, end) => {
        // Get data from ref, as the onSelect-function is an event-listener
        const { startDate, endDate, onChange } = stateRef.current || {};

        if(+start === +startDate && +end === +endDate) {
            return;
        }

        if(onChange) {
            onChange(start, end);
        }
    }

    const updateDisplay = () => {
        const { startDate, endDate } = stateRef.current || {};

        if(!startDate || !endDate) {
            setDisplay(<>
                Velg et tidsintervall
            </>);
            return;
        }
        
        const dateToDisplay = <>
            { dateToDateString(startDate) }
            { ('- ' + dateToDateString(endDate)) }
        </>;
        setDisplay(dateToDisplay);
    }

    const disabledStyling = disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer';

    return (
        <div
            className={`
                ${styles.root} 
                w-full border border-gray-400 rounded text-gray-600 pl-3 pr-1 py-1 focus:border-primary 
                ${className}
                ${disabledStyling}
            `}
            ref={containerRef}
        >
            { display }
        </div>
    )
};

DatePicker.propTypes = {
    className: PropTypes.string,
    options: PropTypes.object,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default DatePicker;