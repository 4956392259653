import React from 'react';

const TableRow = ({children}) => {

    return (
        <tr>
            { children }
        </tr>
    )
};

export default TableRow;