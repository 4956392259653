
const getDocumentState = (state) => state.documents;

// --------- DOCUMENT SELECTORS ---------

const getDocumentById = (projectId, documentId) => (state) => (getDocumentState(state).documents[projectId] || {})[documentId];

const getDocumentsByKey = (projectId) => (state) => (getDocumentState(state).documents[projectId] || {});

const getDocumentsByIds = (projectId, documentIds) => (state) => Object.entries(getDocumentsByKey(projectId)(state))
    .filter(([key, doc]) => documentIds.some((docId) => docId === key))
    .map(([key, doc]) => doc);


// --------- DIRECTORY SELECTORS ----------

const getDirectoriesByKey = (projectId) => (state) => (getDocumentState(state).directories[projectId] || {});

const getDirectoryById = (projectId, directoryId) => (state) => getDirectoriesByKey(projectId)(state)[directoryId];

const getClosestPermanentDirParent = (projectId, fromDirectoryId) => (state) => {
    const directories = getDirectoriesByKey(projectId)(state);
    let curDirectory = directories[fromDirectoryId];
    while (Boolean(curDirectory)) {
        if (curDirectory.permanent) {
            break;
        }
        curDirectory = directories[curDirectory.parent];
    }
    return curDirectory;
}

// --------- DOCUMENT_META SELECTORS ------------

const getDocumentMetasByKey = (projectId) => (state) => (getDocumentState(state).documentMetas[projectId] || {});

const getDocumentMetaById = (projectId, documentMetaId) => (state) => getDocumentMetasByKey(projectId)(state)[documentMetaId];

const getDocumentMetaByIds = (projectId, documentMetaIds) => (state) => Object.entries(getDocumentMetasByKey(projectId)(state))
    .filter(([key, docMeta]) => documentMetaIds.some((metaId) => metaId === key))
    .map(([key, docMeta]) => docMeta)

const getDocumentsByIdsWithMetas = (projectId, documentIds) => (state) => {
    const wantedDocuments = documentIds.reduce((acc, id) => ({ ...acc, [id]: true }), {});
    const documentsByKey = Object.assign({}, getDocumentsByKey(projectId)(state));
    const documentMetasByKey = getDocumentMetasByKey(projectId)(state);

    Object.values(documentMetasByKey)
        .forEach((documentMeta) => {
            const documentId = documentMeta.document;
            if (wantedDocuments[documentId] && documentsByKey[documentId]) {
                const document = documentsByKey[documentId];
                document.metaData = document.metaData || [];
                document.metaData.push(documentMeta);
                documentsByKey[documentId] = document;
            }
        });

    return Object.values(documentsByKey);
}


// ----- ATTACHMENT CODES ------
const getAttachmentCodesByKey = (projectId) => (state) => getDocumentState(state).attachmentCodes[projectId] || {};


export default {
    getDocumentById,
    getDocumentsByIdsWithMetas,
    getDocumentsByIds,
    getDirectoryById,
    getClosestPermanentDirParent,

    getDocumentMetaById,
    getDocumentMetaByIds,

    getAttachmentCodesByKey,
}