import React from 'react';
import PropTypes from 'prop-types';

const ProjectItem = ({onClick, address,  title, certificationType}) => {

    return (
        <div 
            className='paper p-6 md:p-10 mb-4 cursor-pointer hover:shadow-lg' 
            onClick={onClick}
        >
            <h3 className='text-2xl md:text-4xl'>{title}</h3>
            <p className='text-sm md:text-lg'>
                Adresse: {address}
            </p>
            <p className='text-sm md:text-lg'>
                Valgt sertifiseringsnivå: { certificationType }
            </p>
        </div>
    )
}

ProjectItem.propTypes = {
    onClick: PropTypes  .func,
    address: PropTypes.string,
    title: PropTypes.string,
    certificationType:PropTypes.string,
}

export default ProjectItem;