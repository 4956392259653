import React from 'react';
import PropTypes from 'prop-types';
import ROUTES from '~/ROUTES';

// Styles
import styles from './ArchivedModule.module.css';

// Core components
import ProjectExportButton from '~/components/miscellaneous/ProjectExportButton/ProjectExportButton';
import ClipableLink from '~/components/miscellaneous/ClipableLink';

const ArchivedModule = ({
    projectId,
}) => {


    return (
        <div className='rounded-lg shadow-md lg:col-span-2'>
            <div className='bg-red-400 rounded-t-lg py-2 px-4'>
                <h6 className={`text-white ${styles.heading} mb-0`}>
                    Dette prosjektet er arkivert
                </h6>
            </div>
            <hr />
            <div className='bg-white p-6 py-4 rounded-b-lg grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                    <div className='font-light mb-2'>
                        Eksporter prosjektet:
                    </div>
                    <ProjectExportButton
                        projectId={projectId}
                    />
                </div>
                <div className='flex flex-col'>
                    <div className='font-light'>
                        Delelig link for å eksportere:
                    </div>
                    <div className='flex-grow' />
                    <ClipableLink
                        link={`${window.origin}${ROUTES.EXPORT}?p=${projectId}`}
                    />
                </div>
            </div>
        </div>
    )
}

ArchivedModule.propTypes = {
    projectId: PropTypes.string.isRequired,
}

export default ArchivedModule;