
import * as firebase from '~/services/firebase';
import { roles as ROLES } from '~/data/config';


const checkIfOwnerOrAuditor = (project) => {

    return (
        project.roles[firebase.auth.currentUser.uid] === ROLES.owner
        || project.roles[firebase.auth.currentUser.uid] === ROLES.auditor
    )
}

export default checkIfOwnerOrAuditor;