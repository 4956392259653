import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux';
// Store imports
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';

// Styles
import style from './ProgressPlan.module.css'

// Components
import CategoryItem from '~/components/miscellaneous/CategoryItem'
import ProgressBar from '../ProgressBar/ProgressBar.jsx'

// Data imports
import CATEGORIES from '~/data/categories.json';
import SECTIONS from '~/data/sections.json';

// Utils
import { checkIfAccessToCategory, checkIfAccessToSection } from '~/utils/access';

// Config
import { statusColors } from '../../data/config';

// Project config
import { statuses as STATUSES } from '~/data/config'


const ProgressPlan = ({ progressPlan, progressPhases, projectId, preview }) => {
    const [calcedPhaseCategories, setCalcedPhaseCategories] = useState({});
    const [curCategory, setCurCategory] = useState(null);


    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);
    const projectCriterionMeta = useSelector(ProjectSelectors.getCriterionMetaDataByProject(projectId));

    const progressData = progressPlan;

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        /**
         * Creating the phaseCategories objects.
         */
        const criteriaPhaseData = progressData || {};

        const phaseCategories = {};

        Object.keys(criteriaPhaseData).forEach(criteria => {
            const currenCriteria = criteriaPhaseData[criteria];


            // Filter out the data that is not criteria:
            if (typeof currenCriteria === 'string') { return; };

            const currentCriteriaKeys = Object.keys(currenCriteria);
            const categoryKey = currentCriteriaKeys[0].split('_')[0];

            if (!checkIfAccessToCategory(project, manual, userMemberData, categoryKey)) { return false; };

            // Getting the criteria meta-data for current criteria
            const criteriaMetaData = Object.values(projectCriterionMeta).find(section => section.sectionId === criteria);

            /**
             * The "main"-object that contains data about each section (man, ene, hea etc.).
             * We find the section with the categoryKey, and if it doesn't exist then we create the section-object here.
             */
            if (!phaseCategories[categoryKey]) {
                phaseCategories[categoryKey] = {
                    name: CATEGORIES.find(category => category.key === categoryKey).name,
                    key: criteria,
                    criteria: [],
                };
            };

            /**
             * The object that contains data about each criteria (man_01, man_02 etc.).
             */
            const criteriaObject = {
                name: SECTIONS[criteria],
                id: criteria,
                subCriteria: [],
            };

            currentCriteriaKeys
                .sort((subCriteriaA, subCriteriaB) => {
                    /**
                     * Sorting the subCriteria so that e.g. hea_01__04 will be before hea_01__10
                     */
                    const subCriteriaANumber = parseInt(subCriteriaA.split('__')[1]); // e.g: hea_01__04 -> 4
                    const subCriteriaBNumber = parseInt(subCriteriaB.split('__')[1]); // e.g: hea_01__10 -> 10
                    return subCriteriaANumber - subCriteriaBNumber;
                })
                .forEach(subCriteria => {
                    const currentSubCriteria = currenCriteria[subCriteria];
                    const subCriteriaId = subCriteria.split('_').slice(0, 2).join("_"); // "ene_01_01" => "ene_01"
                    const subCriteriaMeta = criteriaMetaData.subCriteria[subCriteria];
                    const isApproved = ((subCriteriaMeta || {}).status) === STATUSES.approved;

                    /**
                     * Creating an array with the same length as phases stored, so that we
                     * can set the phases for the criteria at the correct place no matter what.
                     */
                    const subCriteriaPhasesArray = Object.keys(progressPhases).filter(p => typeof p !== 'string').fill(false);

                    for (const phase in currentSubCriteria) {
                        const phaseNumber = parseInt(phase.split('_')[1]);
                        subCriteriaPhasesArray[phaseNumber - 1] = currentSubCriteria[phase];
                    }

                    if (project.scope[subCriteriaId] && checkIfAccessToSection(project, userMemberData, subCriteriaId)) {
                        criteriaObject.subCriteria.push({ id: subCriteria, phases: subCriteriaPhasesArray, isApproved: isApproved });
                    }
                });

            phaseCategories[categoryKey].criteria.push(criteriaObject);
        });

        setCalcedPhaseCategories(phaseCategories);
    }

    return (
        <div className={`p-3 ${curCategory ? 'pb-0' : ''}`}>
            <div className={`${preview ? '' : style.progressGrid}`}>
                <div className={`${style.filler} ${style.gridLeft}`}></div>
                <ProgressBar
                    progressPhases={progressPhases}
                    curCategory={curCategory}
                    showIndicator={true}
                />
                {
                    preview ?
                        null :
                        <div className={`${style.progressCategories} progress-sections`}>
                            {
                                Object.entries(calcedPhaseCategories)
                                    .map(([category, currentCategory]) => {

                                    return (
                                        <CategoryItem
                                            category={currentCategory.name}
                                            key={currentCategory.key}
                                            onClick={() => setCurCategory(currentCategory.key === curCategory ? null : currentCategory.key)}
                                            isOpen={currentCategory.key === curCategory}
                                            className={style.progressCategoryHeader}
                                        >
                                            {
                                                currentCategory.criteria.map(section => {
                                                    return (
                                                        <div className={`${style.progressGrid} ${style.sectionPart}`} key={section.id}>
                                                            <div className={`${style.gridLeft} ${style.categoryLeft}`}>
                                                                <div className='p-3 pt-0'>
                                                                    <span className={`${style.categoryLeftName}`}>
                                                                        {section.id.toUpperCase() + ' - ' + SECTIONS[section.id]}
                                                                    </span>
                                                                </div>
                                                                <div className={`${style.categoryLeftCriteria}`}>
                                                                    {
                                                                        section.subCriteria.map(subCriteria => {

                                                                            return (
                                                                                <div key={subCriteria.id} className={`${style.subCriteriaProgress} ${style.center}`}>
                                                                                    {subCriteria.id.split('__')[1]}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={`${style.gridRight}`}>
                                                                {
                                                                    section.subCriteria.map(subCriteria => {
                                                                        return (
                                                                            <div key={subCriteria.id} className={`${style.subCriteriaProgress} ${style.progressInnerGrid}`}>
                                                                                {
                                                                                    Object.keys(subCriteria.phases)
                                                                                        .map((phase, index) => {
                                                                                            const currentPhase = subCriteria.phases[phase];
                                                                                            const isFirst = index === 0;
                                                                                            return (
                                                                                                currentPhase ?
                                                                                                    <span key={phase} className={`${style.subCriteriaPhase}`}>
                                                                                                        {
                                                                                                            Object.keys(currentPhase.value)
                                                                                                                .sort((a, b) => a - b)
                                                                                                                .map((value) => {

                                                                                                                    return (
                                                                                                                        <span key={phase.concat(value)} className={`${style.innerSubPhase} bg-${subCriteria.isApproved ? 'success' : statusColors[currentPhase.value[value]]
                                                                                                                            }`}>
                                                                                                                            {
                                                                                                                                isFirst || subCriteria.isApproved ?
                                                                                                                                    null :
                                                                                                                                    <span className={`bg-gradient-to-l from-${statusColors[currentPhase.value[value]]} ${style.fadeOver}`}></span>
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                })
                                                                                                        }
                                                                                                    </span> :
                                                                                                    <span key={phase} className={`${style.center}`}></span>
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </CategoryItem>
                                    )
                                })
                            }
                        </div>}
            </div>
        </div >
    )
}

export default ProgressPlan; 
