/**
 * extractDocumentSubCriteria groups all subCriteria that is attached to the
 * documentMetas by sectionId
 * @param {Object} doc - A document with a "documentMetas" attribute
 * @returns {Object} A object with sectionIds filled with subCriteria numbers. (not ids)
 * {
 *       "<SECTION_ID>": [<SUB_CRITERION_NUMBERS>]
 * }
 */

export const extractDocumentSubCriteria = (doc) => {
    
    return (doc.documentMetas || [])
        .map((docMeta) => docMeta.metaData || {})
        .map((metaData) => metaData.subCriterionId)
        .filter(subCriterionId => subCriterionId)
        .reduce((acc, subCriterionId) => {
            const sectionId = subCriterionId.split('_').slice(0, 2).join(' ');
            const subCriterionNumber = subCriterionId.split('_').slice(-1)[0];

            return {
                ...acc,
                [sectionId]: [...(acc[sectionId] || []), subCriterionNumber].sort(),
            }
        }, {});
};

/**
 * extractAttachmentCodes extracts all attachmentCodes from a document
 * @param {Object} doc - A document with a "documentMetas" attribute
 * @returns {Array<String>} - A list of attachment codes
 */
export const extractAttachmentCodes = (doc) => {
    return Object.keys(
        (doc.documentMetas || [])
        .map((docMeta) => docMeta.metaData || {})
        .map((metaData) => metaData.attachmentCode)
        .filter(attachmentCode => attachmentCode)
        .reduce((acc, val) => ({...acc, [val]: true}), {})
    )
}