import {MUTATIONS, mapProject, mapManual } from './reducer';
import ProjectSelectors from './selectors';
import EventActions from '../events/actions';
import * as firebase from '../../services/firebase';
import {
    roles as ROLES, statuses as STATUSES,
    functions as FUNCTIONS,
    collections as COLLECTIONS,
} from '~/data/config';
import store from '../index';
import onCall from '~/services/onCall';

// Actions
import CriteriaActions from './actions.criteria';
import SectionActions from './actions.section';
import ManualActions from './actions.manual';
import MetricActions from './actions.metrics';
import UserActions from '../user/actions';

// ----- PROJECT RELATED ACTIONS -----
const setProjects = (projects) => ({
    type: MUTATIONS.SET_PROJECTS,
    payload: projects,
});

const setProject = (project) => ({
    type: MUTATIONS.SET_PROJECT,
    payload: project,
});

const setProjectProgressPlan = (projectId, progressPlan) => ({
    type: MUTATIONS.SET_PROJECT_META,
    key: 'progressPlan',
    projectId: projectId,
    payload: progressPlan,
})

const clearState = () => ({
    type: MUTATIONS.CLEAR_STATE,
});

const createProject = (dispatch, project) => {
	const postProject = firebase.functions.httpsCallable(
		FUNCTIONS.PROJECT_CREATE
	);
	return postProject(project)
		.then((result) => {
			// Store the new project in the store
			const data = result.data;
			dispatch(setProject(data));
			return data.id;
		})
		.catch((err) => {
			console.log(err);
		});
};

const archiveProject = (projectId) => (dispatch) => {
    const archiveProject = firebase.functions.httpsCallable(FUNCTIONS.PROJECT_ARCHIVE);
    return archiveProject({
        projectId: projectId
    })
        .then((result) => {
            dispatch(setProject({...result.data, id:projectId}));
            return result.data;
        })
}

/**
 *
 * @param {String} projectId
 * @param {Object} sections
 */
const addSectionAndCriteriaToProject = (projectId, data) => (dispatch) => {
    const postSectionsToProject = firebase.functions.httpsCallable(FUNCTIONS.PROJECT_EDIT);
    return postSectionsToProject({
        projectId: projectId,
        sections: data.sections || {},
        criteria: data.criteria || {}
    }).then((result) => {
        const res = result.data;

        // Insert updated project to store
        dispatch(setProject({...res, id:projectId}));

        // Add new criteria metadata to store if any
        if(data.criteria) {
                dispatch(CriteriaActions.setCriteriaMeta(projectId, [...Object.entries(data.criteria).reduce((prev, [id, value]) => {
                    if(!value) {
                        return prev;
                    }
                    return [...prev, {
                            id: id,
                            sectionId: id.split("_").slice(0,2).join("_")
                        }
                    ]
                }, [])]))
        }
        return res;
    })
}

const useProject = (projectId) => (dispatch) => {
    // Check if given projectId is already the project in use
    if(store.getState().projects.curProject === projectId) {
        return;
    }

    UserActions.fetchMemberById(projectId, firebase.auth.currentUser.uid)(dispatch);

    fetchProgressPlan(projectId)(dispatch);

    // Fetch project-section metadata
    const fetchSections =
        firebase.projectCollection
        .doc(projectId)
        .collection('sections')
        .get()
        .then((snapshot) => {
            const sectionMetaData = snapshot.docs.map(sectionMeta => ({id: sectionMeta.id, ...sectionMeta.data()}));
            dispatch(SectionActions.setSectionMetas(projectId, sectionMetaData));
        })
        .catch(console.error);

    // Download all criterias
    const fetchCriteria =
        firebase.projectCollection
        .doc(projectId)
        .collection('criteria')
        .get()
        .then((criteriaSnapshot) => {
            const criteriaMetaData = criteriaSnapshot.docs.map(criterionMeta => ({id: criterionMeta.id, ...criterionMeta.data()}));
            dispatch(CriteriaActions.setCriteriaMeta(projectId, criteriaMetaData));
        })
        .catch(err => {
            console.error("Criteria fetching error!", err);
        });

    // Start listening to metrics
    const unsubscribeToMetrics = firebase.projectCollection
        .doc(projectId)
        .collection('metrics')
        .doc('document_aggregations')
        .onSnapshot((docAggrRef) => {
            if(!docAggrRef.exists) {
                return;
            }
            dispatch(MetricActions.setDocAggregations(projectId, docAggrRef.data()));
        });
    const unsubscribeToCreditMetrics = firebase.projectCollection
            .doc(projectId)
            .collection('metrics')
            .doc('credit_aggregations')
            .onSnapshot((creditAggrRef) => {
                if(!creditAggrRef.exists) {
                    return;
                }
                dispatch(MetricActions.setCreditAggregations(projectId, creditAggrRef.data()));
            })
/*
    // Start listening to events
    const unsubscribeToEvents = EventActions
        .listenToEvents(projectId, 5)(dispatch); */

    // Start listening to
    const unsubscribeToNotifications = EventActions
        .listenToNotifications(projectId)(dispatch);

    // Start listening to unacknowledged notification counts
    const unsubscribeNotificationCounts = EventActions
        .numberOfEvents(projectId)(dispatch);

    return Promise
        .all([fetchSections, fetchCriteria])
        .then(() => {
            dispatch({
                type: MUTATIONS.USE_PROJECT,
                payload: projectId,
                unsubscriptions: {
                    docAggr: unsubscribeToMetrics,
                    creditAggr: unsubscribeToCreditMetrics,
                   // events: unsubscribeToEvents,
                    notifications: unsubscribeToNotifications,
                    notificationCount: unsubscribeNotificationCounts,
                },
            });
        });
};

const getProjects = (dispatch) => {
    return firebase.projectCollection
        .where(`roles.${firebase.auth.currentUser.uid}`, 'in', Object.keys(ROLES))
        .get()
        .then((data) => {
            const projects = [];
            data.forEach(doc => {
                projects.push({id: doc.id, ...doc.data()});
            });
            dispatch(setProjects(projects));
            return projects;
        });
};

const uploadPreanalysis = (preanalysisFile) => {
    return firebase.storage
    .ref('/preanalysis')
    .child(`${preanalysisFile.name}-${Date.now()}`)
    .put(preanalysisFile)
    .then((snapshot) => {
        return snapshot.ref.getDownloadURL().then((documentURL) => {
            return {
                documentURL:documentURL,
                id:snapshot.ref.name
            }
          });
    })
}

const parsePreanalysis = (document) => {
    const parsePreanalysis = firebase.functions.httpsCallable(FUNCTIONS.PROJECT_PREANALYSIS_PARSE);
    return parsePreanalysis(document)
        .then((result) => {
            return result.data;
        })
        .finally(() => {
            const documentRef = firebase.storage
            .ref('/preanalysis')
            .child(document.id);
            documentRef.delete()
        })

}

const fetchProjectById = (id) => (dispatch) => {
    const proj = ProjectSelectors.getProjectById(id)(store.getState());
    if(proj) {
        return Promise.resolve(proj);
    }

    return firebase.projectCollection
        .doc(id)
        .get()
        .then((projectRef) => {
            const project = {id: projectRef.id, ...projectRef.data()}
            dispatch(setProject(project));

            return mapProject(project);
        });
};

const fetchProgressPlan = (projectId) => (dispatch) => {
    return firebase.projectCollection
        .doc(projectId)
        .collection(COLLECTIONS.PROJECT_META)
        .doc('progress_plan')
        .get()
        .then((res) => {
            if(!res.exists) {
                return null;
            }

            const progressPlan = res.data();
            dispatch(setProjectProgressPlan(projectId, progressPlan));
            return progressPlan;
        })
}


/**
 *
 * @param {String} projectId
 * @param {Object.<string, string>} phaseDates
 * @param {String} endDate
 */
const createProgressPlan = (projectId, phaseDates, endDate = null) => (dispatch) => {
    return firebase.functions.httpsCallable(FUNCTIONS.PROJECT_PHASES_UPSERT)({
        projectId: projectId,
        phases: phaseDates,
        endDate: endDate,
    })
    .then(() => {
        return fetchProgressPlan(projectId)(dispatch);
    });
}


const exportAccessGrant = (projectId, userId) => {
    return firebase.functions.httpsCallable(FUNCTIONS.PROJECT_EXPORT_ACCESS_GRANT)({
        projectId: projectId,
        userId:userId,
    })
    .then((result) => {
        return result.data;
    })
}

/**
 *
 * @param {String} projectId
 */
const exportProject = (projectId) => {
    return onCall(FUNCTIONS.PROJECT_EXPORT, {
        projectId: projectId,
    })
    .then((result) => {
        return result.result; // The export URL
    });
}

const exportAccessRequest = (projectId) => {
    return firebase.functions.httpsCallable(FUNCTIONS.PROJECT_EXPORT_ACCESS_REQUEST)({
        projectId: projectId,
        url: `${window.origin}/export/access/${projectId}/${firebase.auth.currentUser.uid}`,
    });
}

export default {
    setProjects,
    setProject,
    useProject,
    exportProject,
    exportAccessRequest,
    createProject,
    archiveProject,
    addSectionAndCriteriaToProject,
    createProgressPlan,
    getProjects,
    uploadPreanalysis,
    parsePreanalysis,
    fetchProjectById,
    exportAccessGrant,
    clearState,
    ...CriteriaActions,
    ...SectionActions,
    ...ManualActions,
    ...MetricActions
}
