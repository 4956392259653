import React from 'react';
import styles from './Table.module.css';

// Table subcomponents
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';
import TableHeaderCell from './TableHeaderCell';

const Table = ({className = '',children, celled=true}) => {

    return (
        <table className={`${className} ${styles.table} ${celled ? styles.celled : ''}`}>
            { children }
        </table>
    )
};

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.HeaderCell = TableHeaderCell;

export default Table;