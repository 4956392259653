import { useState, useEffect } from 'react';

/**
 * @typedef {Object} WindowSize
 * @property {Number} height
 * @property {Number} width
 */

/**
 * @returns {WindowSize}
 */
function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      window.addEventListener("resize", handleResize);
      
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []); 

    return windowSize;
}

export default useWindowSize;