import React from "react";

// Components
import Icon from "~/components/base/Icon";
import Collapse from "~/components/base/Collapse";
import { Select, SelectV2 } from "~/components/base/Select";

// Utils
import roleToText from "~/utils/text/roleToText";
import { roles as ROLES } from "~/data/config";

const UserItem = ({ user, className, onClick, isOpen, role, onChange, data }) => {
    const firstName = user.firstName;
    const lastName = user.lastName;

    return (
        <>
            <div className={className} onClick={onClick}>
                <div className="font-medium break-words text-sm">
                    {firstName} {lastName}
                </div>
                <div className="font-medium text-sm">{roleToText(role)}</div>
                {(role === ROLES.auditor || role === ROLES.owner) && (
                    <div className="font-light text-sm">(Tilgang til alt)</div>
                )}
                <SelectV2
                    value={role}
                    label="rolle"
                    placeholder="Velg rolle"
                    className="mt-2"
                    data={data}
                    name="role"
                    onChange={onChange}
                />
                {
                    isOpen ?
                        <div className="flex-1 flex flex-col justify-center p-3 items-center">Trykk her for å minimere
                        <Icon className="mt-2" icon='compress-alt' size='sm' />
                        </div>
                        : null
                }
            </div>
        </>
    );
};

export default UserItem;
