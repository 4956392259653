import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '~/ROUTES';

// Components
import { Button } from '~/components/base/Buttons';
import TextField from '~/components/base/TextField';
import KvistLogo from '~/components/miscellaneous/KvistLogo';


const CreateUserForm = (props) => {
    const history = useHistory();
    return (
        <div>
            <h3 className="text-primary hidden md:block">
                Opprett ny konto
            </h3>

            <form className='paper' onSubmit={props.onClick}>
                <KvistLogo
                    className='m-auto mb-4'
                />

                <TextField
                    label='Email'
                    type="text"
                    icon = "user"
                    name="email"
                    //error={props.error}
                    placeholder="E-post adresse"
                    onChange={props.onChange}
                    required
                />

                <TextField
                    label='Fornavn'
                    type="text"
                    icon = "user"
                    name="firstname"
                    //error={props.error}
                    placeholder="Fornavn"
                    onChange={props.onChange}
                    required
                />

                <TextField
                    label='Etternavn'
                    type="text"
                    icon = "user"
                    name="lastname"
                    //error={props.error}
                    placeholder="Etternavn"
                    onChange={props.onChange}
                    required
                />

                <TextField
                    label='Passord'
                    type="password"
                    name="password"
                    icon = "lock"
                    error={props.error}
                    placeholder="Passord"
                    onChange={props.onChange}
                    required
                />

                <TextField
                    label='Gjenta password'
                    type="password"
                    name="password2"
                    icon = "lock"
                    error={props.error}
                    hint={props.error ? 'Passordene er ikke like' : null}
                    placeholder="Gjenta passord"
                    onChange={props.onChange}
                    required
                />

                <Button
                    className='inline-flex ml-none'
                    type='submit'
                    disabled={props.buttonDisabled}
                >
                    Opprett bruker
                </Button>

                <Button
                    className='inline-flex float-right'
                    type=''
                    variant='text'
                    onClick={() => {
                        history.push(ROUTES.LOGIN)
                    }}
                >
                    Avbryt
                </Button>

            </form>
        </div>
    )
}

export default CreateUserForm;