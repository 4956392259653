import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '~/ROUTES';

// Components
import { Button } from '~/components/base/Buttons';
import TextField from '~/components/base/TextField';
import KvistLogo from '~/components/miscellaneous/KvistLogo';


const ReAuthenticateForm = (props) => {
    return (
            <div>
                <div className='paper'>
                    <KvistLogo
                        className='m-auto mb-4'
                    />
                     <b className="text-primary">
                        {props.title}
                    </b>

                    <TextField
                        label='Passord'
                        type="password"
                        name="password"
                        icon = "lock"
                        error={props.error}
                        hint={props.error ? 'Feil passord' : null}
                        placeholder="Passord"
                        onChange={props.onChange}
                        required
                    />
                    <Button
                        className='inline-flex ml-none'
                        type='button'
                        onClick = {props.onClick}
                        loading = {props.isLoading}
                        disabled = {props.isLoading}
                    >
                        Autentiser
                    </Button>

                </div>
            </div>
    )
}

export default ReAuthenticateForm;