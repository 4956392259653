import React from 'react';
import PropTypes from 'prop-types';

// Components
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import LockIcon from '~/components/miscellaneous/LockIcon';
import Icon from '~/components/base/Icon';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import extractStatusText from '~/utils/text/extractStatusText';
import timeAgo from '~/utils/date/timeAgo';
import statusProperties from '~/utils/subCriteria/statusProperties';

const Preface = ({
    manualData,
    subCriterionMeta
}) => {
    const manualSubCriterion = manualData.subCriterion;

    const [statusColor, statusIcon] = statusProperties(subCriterionMeta.status);

    return (
        <div className='mb-2'>
            <div className='flex items-center mb-2'>
                <h4 className='mb-0 mr-2'>{ sectionIdToText(manualData.sectionId) } - Krav { manualSubCriterion.originalId }</h4>
                {
                    Boolean(subCriterionMeta.locked) && <LockIcon isLocked={subCriterionMeta.locked} size='lg' />
                }
            </div>
            <div className='flex items-center'>
                <div className={
                    `px-4 py-1 font-bold text-white text-sm rounded-full max-w-xs mr-2
                    bg-${subCriterionMeta.status ? statusColor : 'gray-600'}`
                }>
                    <StatusIcon className='mr-1' status={subCriterionMeta.status} color='white' />
                    { extractStatusText(subCriterionMeta.status) }
                </div>
                <div className='text-xs mr-1 text-gray-600'>
                    { 
                        subCriterionMeta.status && subCriterionMeta.statusChangedAt ? 
                        'Status ble sist endret ' + timeAgo(new Date(subCriterionMeta.statusChangedAt)).toLowerCase() :
                        subCriterionMeta.locked ? 
                        'Kravet ble sent til revisjon sist for ' + timeAgo(new Date(subCriterionMeta.locked)).toLowerCase() : ''
                    }
                </div>
            </div>
        </div>
    )
}

Preface.propTypes = {
    manualData: PropTypes.object.isRequired,
    subCriterionMeta: PropTypes.object.isRequired,
}

export default Preface;