/**
 * @description Finds the nodename of the element
 * @param {HTMLElement} HTMLElement
 * @returns {String} The nodename/tag of the element, e.q. "div", "select", "input"
 */

function elementType(HTMLElement) {
    return HTMLElement.nodeName.toLowerCase();
}

export default elementType;
