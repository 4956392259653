import React from 'react';

const TableHeading = ({children}) => {

    return (
        <th className='p-4 text-left'>
            { children }
        </th>
    )
};

export default TableHeading;