import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';

// State
import { useDispatch, useSelector } from 'react-redux';
import ProjectActions from '~/store/project/actions';
import DocumentActions from '~/store/documents/actions';
import ProjectSelectors from '~/store/project/selectors';
import DocumentSelectors from '~/store/documents/selectors';

// Core Components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import TextArea from '~/components/base/TextArea';
import Collapse from '~/components/base/Collapse';
import Spinner from '~/components/base/Spinner';

// Module components
import FileReviewItem from './components/FileReviewItem';
import store from '~/store';

const APPROVED = 'APPROVED';
const DISAPPROVED = 'DISAPPROVED';

const REVIEW_SUCCESS = 1;
const REVIEW_FAILED = 2;

/**
 * ReviewModule is a module for reviewing a subCriteria and its corresponding documents.
 *
 * TODO: Make all the request happen here and take in subCriteriaId as prop
 */
const ReviewModule =({
    projectId,
    subCriterionId = "man_01__1",
    refreshKey,
    onClose,
    onReviewComplete,
}) => {
    // State
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingReview, setIsLoadingReview] = useState(false);
    const [reviewStatus, setReviewStatus] = useState(null); // The status of the review request
    const [note, setNote] = useState('');
    const [status, setStatus] = useState(null);
    const [documentMetas, setDocumentMeta] = useState([]); // The ids of the fetch document metas to review

    // Store selectors
    const dispatch = useDispatch();
    const criterion = useSelector(ProjectSelectors.getManualCriterionBySubCriterionId(subCriterionId));


    useEffect(() => {
        // One needs a refresh key to get started
        if(!refreshKey) {
            return;
        }
        reset();

        setIsLoading(true);
        DocumentActions.fetchDocumentMetaBySubCriterion(projectId, subCriterionId)(dispatch)
        .then((docMetasData) => {
            // Extract the document meta from the store, as they are mapped into the correct format
            const docMetas = DocumentSelectors.getDocumentMetaByIds(projectId, docMetasData.map(docMeta => docMeta.id))(store.getState());
            setDocumentMeta(docMetas);
        })
        .finally(() => {
            setIsLoading(false);
        })

    }, [refreshKey])

    /**
     * Force reset by changing the refresh-key
     */
    useEffect(() => {
        reset();
    }, [refreshKey])

    const reset = () => {
        setReviewStatus(null);
        setNote('');
        setStatus(null);
    }

    const onDocumentMetaChanged = (docMetaId, changes = {}) => {
        const newDocMetas = Object.assign([], documentMetas);
        const docIndex = newDocMetas.findIndex(docMeta => docMeta.id === docMetaId);
        if(docIndex === -1) {
            return;
        }

        newDocMetas[docIndex] = {
            ...newDocMetas[docIndex],
            ...changes,
        }

        setDocumentMeta(newDocMetas);
    }

    const reviewSubCriterion = () => {

        // Check for changes in documentMetas
        const oldDocMetasById = DocumentSelectors
            .getDocumentMetaByIds(projectId, documentMetas.map(docMeta => docMeta.id))
                (store.getState())
            .reduce((acc, docMeta) => ({...acc, [docMeta.id]: docMeta}), {});

        // Extract the docMetas that changed
        const reviewedDocMeta = documentMetas
            .filter(docMeta => {
                // Only update if the docMeta has changed.
                const oldDocMeta = oldDocMetasById[docMeta.id];
                return !oldDocMeta || !(oldDocMeta.status === docMeta.status && oldDocMeta.statusNote === docMeta.statusNote);
            })
            .map((docMeta) => ({id: docMeta.id, status: docMeta.status, statusNote: docMeta.statusNote}))

        // Review subCriterion
        setIsLoadingReview(true);
        ProjectActions.reviewSubCriterion(projectId, subCriterionId, status, note, reviewedDocMeta)(dispatch)
            .then((updatedCriterionMeta) => {
                setReviewStatus(REVIEW_SUCCESS);
                if(onReviewComplete) {
                    onReviewComplete(updatedCriterionMeta);
                }
            })
            .catch((error) => {
                setReviewStatus(REVIEW_FAILED)
                console.error(error);
            })
            .finally(() => setIsLoadingReview(true))
    }

    const closeModule = () => {
        if(onClose) {
            onClose();
            setTimeout(() => {
                reset();
            }, 600)
        }
    }

    return (
        <div>
            <Collapse
                visible={isLoading}
            >
                <div className='h-48 relative'>
                    <div className='absolute-center'>
                        <Spinner />
                    </div>
                </div>
            </Collapse>
            <Collapse
                visible={!isLoading && reviewStatus === null}
            >
                <h6>Gi en vurdering av kravet:</h6>
                <div className='grid md:grid-cols-2 sm:grid-rows-2 gap-5 align-items'>
                    <Button
                        onClick={() => setStatus(APPROVED)}
                        color='primary'
                        variant={status === APPROVED ? 'contained' : 'outlined'}
                        >
                        <Icon className='mr-1' icon='check-circle' />
                        Godkjenn
                    </Button>
                    <Button
                        onClick={() => setStatus(DISAPPROVED)}
                        className='text-white'
                        color='error'
                        variant={status === DISAPPROVED ? 'contained' : 'outlined'}
                    >
                        <Icon className='mr-1' icon='times-circle' />
                        Ikke godkjenn
                    </Button>
                </div>

                <div
                    className='mb-2'
                >
                    <h6>Gi en begrunnelse av din vurdering:</h6>
                    <TextArea
                        rows={4}
                        maxLength={1000}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </div>

                <h6>Tilhørende dokumenter:</h6>
                <div className='mb-6'>
                    {
                        documentMetas
                        .sort((a, b) => -((a.metaData || {}).attachmentCode || '').localeCompare((b.metaData || {}).attachmentCode || ''))
                        .map((docMeta) => (
                            <FileReviewItem
                                key={docMeta.id}
                                projectId={projectId}
                                docMeta={docMeta}
                                onChange={onDocumentMetaChanged}
                            />
                        ))
                    }
                    {
                        documentMetas.length === 0 ?
                        <div className='text-center font-light text-gray-600'>
                            Dette kravet har ingen tilhørende dokumenter
                        </div>
                        : null
                    }
                </div>

                <div className='flex items-center flex-row-reverse'>
                    <Button
                        className='ml-2'
                        onClick={reviewSubCriterion}
                        loading={isLoadingReview}
                        disabled={isLoadingReview || status === null || (note || '').length === 0}
                        spinnerColor='primary'
                    >
                        { !isLoadingReview && <Icon icon='paper-plane' className='mr-2' />}
                        Send inn
                    </Button>
                    <Button
                        variant='text'
                        onClick={onClose}
                        disabled={isLoadingReview}
                        spinnerColor='primary'
                    >
                        Lukk
                    </Button>
                </div>
            </Collapse>
            <Collapse
                visible={reviewStatus}
            >
                <div className='flex flex-col items-center'>
                    <Icon
                        icon={reviewStatus === REVIEW_SUCCESS ? 'check-circle' : 'times-circle'}
                        color={reviewStatus === REVIEW_SUCCESS ? 'var(--color-success)' : 'var(--color-error)'}
                        size='5x'
                    />
                    <h6 className='text-center mb-10'>
                        {
                            reviewStatus === REVIEW_SUCCESS
                                ? 'Vurderingen er sendt inn'
                                : 'Det oppstod en feil under innsending'
                        }
                    </h6>
                    <Button
                        color='primary'
                        variant='text'
                        onClick={closeModule}
                    >
                        Ok
                    </Button>
                </div>
            </Collapse>
        </div>
    )
};

ReviewModule.propTypes = {
    projectId: PropTypes.string.isRequired,

    /**
     * The subCriteria to review
     */
    subCriterionId: PropTypes.string.isRequired,

    /**
     * A callback for when the module indicate that it wants to be closed.
     */
    onClose: PropTypes.func,

    /**
     * A callback for when a review was successfully completed
     */
    onReviewComplete: PropTypes.func,
};

export default ReviewModule;