import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import EventActions from '~/store/events/actions';
import EventSelectors from '~/store/events/selectors';

// Project components
import WarningModule from '~/components/modules/WarningModule';


const SectionWarningModule = ({
    projectId,
    sectionId,
}) => {
    const dispatch = useDispatch();

    const events = useSelector(EventSelectors.getProjectNotificationsBySection(projectId, sectionId))

    useEffect(() => {
        if(events.length > 0) {
            return;
        }
        
        EventActions.fetchNotifications(projectId, {
            limit: 5,
            sectionId: sectionId,
        })(dispatch);

    }, [events.length])

    return (
        <WarningModule 
            events={events}
            projectId={projectId}
        />
    )
};

SectionWarningModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
}


export default SectionWarningModule;