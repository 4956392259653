// Config
import { deadlineStatuses, statuses } from '~/data/config';

// Utils
import yesterday from '~/utils/date/yesterday';

/**
 * @description Finds the weakest link for the deadline icon. 1. 'overdue' = weakest | 2. 'none' = second weakest | 3. 'active' = third weakest
 * @param {Object} subCriteriaMetaData 
 * @returns {Object}
 */
const calculateMultipleDeadlines = (subCriteriaMetaData) => {
    const deadlinesObject = {
        status: deadlineStatuses.active,
        overviewDescription: 'Alle kriteriene har tidsfrister' // Can't decide if these texts should be in '~/data/config' or not...
    };

    for (const subCriterion in subCriteriaMetaData) {
        const currentSubCriteria = subCriteriaMetaData[subCriterion];

        if (!!currentSubCriteria.deadline) {
            const deadlineDate = new Date(currentSubCriteria.deadline);

            if (deadlineDate < yesterday) {
                deadlinesObject.status = deadlineStatuses.overdue;
                deadlinesObject.overviewDescription = 'Ett eller flere kriterier har utgått tidsfrist';
                break;
            }
        }

        if (currentSubCriteria.status !== statuses.approved && !currentSubCriteria.deadline && deadlinesObject.status !== deadlineStatuses.none) {
            deadlinesObject.status = deadlineStatuses.none;
            deadlinesObject.overviewDescription = 'Ett eller flere kriterier har ingen tidsfrist';
        }

    }

    return deadlinesObject;
};

export default calculateMultipleDeadlines;