import React, { useRef } from 'react';

// Core components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';

const FileUpload = ({value, onChange, accept, text,...rest}) => {
    const fileInputRef = useRef(null);

    const onFileChange = (event) => {
        const file = event.target.files[0];

        if(onChange) {
            onChange(file);
        }
    }

    const onBtnClicked = (e) => {
        if(!Boolean(value)) {
            fileInputRef.current.click();
            return;
        } else if(onChange) {
            onChange(null);
        }
    }

    return (
        <div className='d-flex align-items-center'>
            <input
                type='file'
                id='file-input'
                hidden
                ref={fileInputRef}
                onChange={onFileChange}
                accept={accept}
            />

            <Button
                color={Boolean(value) ? 'red' : 'primary'}
                onClick={onBtnClicked}
                variant='outlined'
                {...rest}
            >
                <Icon icon={!Boolean(value) ? 'file' : 'trash'} className='mr-2' />
                {
                    !Boolean(value) ? text ? text : 'Velg en fil' : value.name
                }
            </Button>
        </div>
    )
};

export default FileUpload;
