import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.css';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import Portal from '../Portal/Portal';

const Modal = ({show, children, center = true, transition, onBackdropClick}) => {
    const [hasOpenOnce, setHasOpenOnce] = useState(false);
    const [shouldShow, setShouldShow] = useState(false);
    const [curTimeout, setCurTimeout] = useState(null);

    useEffect(() => {
        if(show) {
            setHasOpenOnce(true);
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }

    }, [show])

    useEffect(() => {
        if(!hasOpenOnce && !show) {
            setHasOpenOnce(true);
            return;
        }

        if(curTimeout) {
            clearTimeout(curTimeout);
        }

        setCurTimeout(setTimeout(() => {
            setShouldShow(show);
            setCurTimeout(null);
        }, show ? 0 : 500));
    }, [show]);

    let curTransition = null;
    if (transition) {
        curTransition = `${transition}-${show ? 'enter' : 'leave'}`
    } else {
        curTransition = `${styles[`bounce-${show ? 'enter' : 'leave'}`]}`;
    }

    return (
        <Portal>
            {
                shouldShow &&
                <div className={`${styles.modal} fixed inset-0 w-full h-full overflow-y-scroll`}>
                    <div 
                        className={`fixed inset-0 w-full h-full bg-black ${styles[`opacity-${show ? 'enter' : 'leave'}`]} ${styles['opacity']}`} 
                        onClick={onBackdropClick}
                    />
                    <div className={`${styles['modal-container']} ${center ? 'flex items-center justify-center' : ''}`}>
                        <div className={curTransition}>
                            { children }
                        </div>
                    </div>
                </div>
            }
        </Portal>
    )

};

Modal.propTypes = {
    show: PropTypes.bool, 
    children: PropTypes.element,
    center: PropTypes.bool,
    transition: PropTypes.string,
    onBackdropClick: PropTypes.func,
}

export default Modal;