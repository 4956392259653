import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

// Store imports
import { useSelector } from 'react-redux';
import EventSelectors from '~/store/events/selectors';

// Project components
import WarningModule from '~/components/modules/WarningModule';

const ProjectWarningModule = ({
    projectId
}) => {
    const events = useSelector(EventSelectors.getProjectNotifications(projectId))

    return (
        <WarningModule
            className={`${styles.root}`}
            events={events}
            projectId={projectId}
        />
    )
};

ProjectWarningModule.propTypes = {
    projectId: PropTypes.string.isRequired,
}


export default ProjectWarningModule;