export default function scrollHandler(elementClassName, callback) {
    let lastScrollPosition = 0;
    let ticking = false;
    const element = document.querySelector(`.${elementClassName}`)

    element.addEventListener('scroll', function (e) {
        lastScrollPosition = element.scrollTop;

        if (!ticking) {
            window.requestAnimationFrame(() => {
                callback(lastScrollPosition)
                ticking = false;
            });

            ticking = true;
        }
    });

}