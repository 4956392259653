import { statuses as STATUSES } from '~/data/config';

export default (status) => {

    switch(status) {

        case STATUSES.approved: {
            return "Godkjent";
        }

        case STATUSES.disapproved: {
            return "Underkjent";
        }

        case STATUSES.pending: {
            return "Avventes";
        }
        
        default: {
            return "Ubehandlet"
        }
    }

}