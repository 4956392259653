import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Popup.module.css';

// Components
import Portal from '../Portal';

// Utils
import { calcPosition, posToTranslateStyle, getElemRect } from './utils';

const Popup = ({
    className = '',
    anchorEl,
    isOpen = false,
    placement = 'top-left',
    isFixed = false,
    isInner = false,
    onBackdropClick,
    onClickAway,
    children,
}) => {
    const containerEl = useRef(null);

    // State
    const [transformStyle, setTransformStyle] = useState({ transform: 'translateY(-1000px)' });

    /**
     * Translate the container to the correct position
     */
    useEffect(() => {
        let transformStyles = {};
        if (anchorEl && containerEl && containerEl.current) {
            transformStyles.transform = posToTranslateStyle(
                ...calcPosition(getElemRect(anchorEl), getElemRect(containerEl.current), placement, isInner, isFixed)
            );
            setTransformStyle(transformStyles);
        }
    }, [anchorEl, containerEl, placement, isInner, isFixed, isOpen, setTransformStyle])


    /**
     * Click away functionality. Call onClickAway() when the user click away from the
     * container element
     */
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (containerEl.current && !containerEl.current.contains(event.target)) {
                onClickAway && onClickAway();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerEl, onClickAway]);


    if ((!anchorEl || !containerEl || !isOpen)) {
        return null;
    }

    return (
        <Portal>
            {
                onBackdropClick &&
                <div
                    className={`fixed inset-0 w-full h-full`}
                    onClick={onBackdropClick}
                    tabIndex='0'
                />
            }
            <div
                ref={containerEl}
                className={`${isFixed ? 'fixed' : 'absolute'} top-0 left-0 right-auto ${styles.root} ${className}`}
                style={transformStyle}
            >
                {children}
            </div>
        </Portal>
    )
};

Popup.propTypes = {
    anchorEl: PropTypes.instanceOf(Element),

    isOpen: PropTypes.bool,

    placement: PropTypes.oneOf([
        'top-left',
        'top-right',
        'top',
        'bottom-left',
        'bottom-right',
        'bottom',
        'right-start',
        'right-end',
        'right',
        'left-start',
        'left-end',
        'left',
    ]),

    /**
     * Changes from position: aboslute to position: fixed
     */
    isFixed: PropTypes.bool,

    /**
     * Makes the children go inside of the anchorEl's box
     */
    isInner: PropTypes.bool,

    onBackdropClick: PropTypes.func,

    onClickAway: PropTypes.func,

    children: PropTypes.node,
};

export default Popup;