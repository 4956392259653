import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Components
import TopBar from "../projectView/components/TopBar";
import EditUserForm from "./components/EditUserForm";
import ManageButtons from "./components/ManageButtons";
import checkIfOwner from "~/utils/access/checkIfOwner";

const ProjectEditAccessView = () => {
    const history = useHistory();
    const project = useSelector(ProjectSelectors.getCurrentProject);

    // State
    const [state, setState] = useState({
        email: "",
        buttonDisabled: true,
        error: false,
        show: false,
    })

    useEffect(() => {
        if(!checkIfOwner(project)) {
            history.push("/");
        } else {
            setState((prev) => ({...prev, show: true}))
        }
    }, [project])

    function manageButton() {
        if (
            state.email !== ""
            ) {
            state.buttonDisabled = false;
        }
        else {
            state.buttonDisabled = true;
        }
    }
    manageButton();

    //callbacks
    function handleChange(event) {
        const value = event.target.value;

        setState({
            ...state,
            error: false,
            [event.target.name]: value
        })
        manageButton();
    }

    if(!state.show) {
        return null;
    }

    return (
			<div className="p-3 page">
				<TopBar />
				<EditUserForm
					buttonDisabled={state.buttonDisabled}
					onChange={handleChange}
				/>
			</div>
		);
}


export default ProjectEditAccessView;