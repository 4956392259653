import React, { useState, useMemo, useEffect, createRef } from 'react';
import { goToProjectAccess, goToAddUserToProject } from '~/ROUTES';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { roles as ROLES, member as MEMBER } from '~/data/config';

// Styles
import styles from './EditUserForm.module.css';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';
import UserActions from '~/store/user/actions';
import Spinner from '~/components/base/Spinner';

// Core Components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import Checkbox from '~/components/base/Checkbox';
import { UserItem } from './components';
import TextField from '~/components/base/TextField';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import sectionsToCategoryByKey from '~/utils/category/sectionsToCategoryByKey';
import roleToText from '~/utils/text/roleToText';
import elementType from '~/utils/dom/elementType';
import { deepFreeze, deepClone } from '~/utils/object';
import * as firebase from '~/services/firebase';


// Constants
const REVOKED = MEMBER.SECTIONS_VALUE.REVOKED;
const ACCESS = MEMBER.SECTIONS_VALUE.ACCESS;
const RESPONSIBILITY = MEMBER.SECTIONS_VALUE.RESPONSIBLE;

const EditUserForm = (props) => {
    /**
     * Selectors/React functions
     */
    const history = useHistory();
    const dispatch = useDispatch();
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const sections = useSelector(ProjectSelectors.getCurrentSectionMetas);
    const members = useSelector(UserSelectors.getAllMembersInProject(project.id));

    /**
     * States/Refs
     */
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [filteredMembers, setFilteredMembers] = useState(null);
    const [changesMade, setChangesMade] = useState([]);
    const [changedMembers, setChangedMembers] = useState({});
    const wrapperRef = createRef(null);

    /**
     * Constants
     */
    const oldMembers = deepFreeze(members.reduce((prev, cur) => (
        {
                ...prev,
                [cur.id]: {
                    role: project.roles[cur.id],
                    ...cur
                }
        }
    ), {}));

    const data = [
        { value: ROLES.owner, label: "Eier" },
        { value: ROLES.member, label: "Medlem" },
        { value: ROLES.auditor, label: "Revisor" }
    ];

    const ACCESS_STATES = {
        ALL: "all",
        SOME: "some",
        NONE: "none"
    };

    const COLORS = {
        [ACCESS_STATES.ALL]: "success",
        [ACCESS_STATES.SOME]: "pending",
        [ACCESS_STATES.NONE]: "error-dark",
    };

/**
 * useMemos/useEffects
 */

    const categoriesByKey = useMemo(() => {
        if (!project) { return {} }
        return sectionsToCategoryByKey(Object.keys(project.scope || {}));
    }, [project]);

    const categories = useMemo(() => {
        return Object.entries(categoriesByKey).map(
            ([categoryId, categoryName]) => ({
                value: categoryId,
                label: categoryName
            })
        );
    }, [categoriesByKey]);

    useEffect(() => {
        fetchMembers();
    }, []);

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.style.setProperty(
                "--cell-amount",
                Object.values(categoriesByKey).length + 1
            );
        }
    }, [wrapperRef]);

/**
 * Functions
 */
    const addUserToProject = () => {
        history.push(goToAddUserToProject(project.id));
    };

    const fetchMembers = () => {
        setIsLoading(true);
        UserActions.fetchAllMembersInProject(project.id)(dispatch).finally(
            () => {
                setIsLoading(false);
            }
        );
    };

    const userWantsToCancel = () => {
        return window.confirm("Trykk ok for å avbryte."); // Todo: better prompt?
    };

    const cancelChanges = () => {
        setUnsavedChanges(false);
        setChangesMade([]);
        setChangedMembers({});
    };

    const checkIfSaveToExit = () => unsavedChanges && userWantsToCancel() ? cancelChanges() : false;

    /**
     * @description Is used to expand the rows in the table/dashboard.
     * The table expands the row of the currently selectedMember - so if we clicked on the member
     * that is active then we set the value to null so the table is collapsed.
     * @param {Object} member - Info about the member that was clicked on
     */
    const handleOnClick = (event, member) => {
        // If the user clicks on the select list then don't expand row.
        if (elementType(event.target) === "select") { return }

        if (selectedMemberId === member.id && event.target.closest(`.${styles.userItem}`)) {
            setSelectedMemberId(null);
        } else {
            setSelectedMemberId(member.id);
        }
    };

    /**
     * @description Handles the onChange logic for the select list that decide the role of a user.
     * Note that the way to determine if a change is about the role of a user is by
     * checking if the change has the property "role". This is simply because a role
     * doesn't have a unique ID, and for every user there can only be one change object
     * about its role.
     * @param {String} role The value (role) selected in the select-list
     * @param {Object} member Data about the member altered with the select-list
     */
    const handleOnChange = (role, member) => {
        const memberId = member.id;
        setSelectedMemberId(memberId);

        const tempChangesMade = deepClone(changesMade);
        const changeFound = tempChangesMade.find((s) => s.hasOwnProperty("role") && s.userId === memberId);

        if (changeFound) {
            changeFound.newValue = role;
            changeFound.changed = changeFound.originalValue !== changeFound.newValue;
            setChangesMade(tempChangesMade);
        } else {
            setChangesMade([
                ...changesMade,
                {
                    userId: memberId,
                    role: true,
                    changed: true,
                    originalValue: oldMembers[memberId].role,
                    newValue: role
                }
            ]);
        }
    };

    /**
     * @description Reverts the changed property of a change done + sets the selected member to the one the change was done on.
     * @param {Object} change - An instance of a change-object, containing info about the change that is toggled
     */
    const toggleOneChange = (change) => {
        const isSectionChange = change.hasOwnProperty("sectionId")
        const isRoleChange = change.hasOwnProperty("role");
        let roleHasBeenChanged = false;

        setChangesMade(
            changesMade.map((curChange) => {
                if (change.userId === curChange.userId) {
                    const currentChangeIsRoleChange = curChange.hasOwnProperty("role");
                    const currentChangeIsSectionChange = curChange.hasOwnProperty("sectionId");

                    if ((isRoleChange && !currentChangeIsRoleChange) ||
                        (isRoleChange && roleHasBeenChanged) ||
                        (isSectionChange && !currentChangeIsSectionChange) ||
                        (isSectionChange && change.sectionId !== curChange.sectionId)) {
                        return curChange;
                    }

                    if (curChange.hasOwnProperty("role")) {
                        roleHasBeenChanged = true;
                    }

                    return {
                        ...curChange,
                        changed: !curChange.changed,
                        newValue: currentChangeIsRoleChange
                            ? curChange.originalValue // If it is the role that is reverted we want to set the newValue to the old again because we use this to compare in the handleOnChange function.
                            : curChange.newValue // If it is a section change we keep the newValue
                    };
                } else {
                    return curChange;
                }
            })
        );
        setSelectedMemberId(change.userId);
    };

    /**
     *
     * @param {Object} member - Info about the member that had a checkbox toggled
     * @param {String} sectionId - The sectionId where the checkbox was toggled
     * @param {Boolean} checkBoxValue - The current value of the checkbox
     */
    const toggleSectionValue = (member, sectionId, checkBoxValue) => {
        const memberId = member.id;

        // If user has access to everything then we don't want/need to toggle the value
        if (hasAccessToEverything(memberId)) {
            return;
        }

        const changeFound = changesMade.find((s) => s.sectionId === sectionId && s.userId === memberId);
        if (changeFound) {
            toggleOneChange(changeFound);
        } else {
            setChangesMade([
                ...changesMade,
                {
                    userId: memberId,
                    sectionId: sectionId,
                    changed: true,
                    originalValue: oldMembers[memberId].sections?.[sectionId],
                    newValue: checkBoxValue ? ACCESS : REVOKED
                }
            ]);
            setSelectedMemberId(memberId);
        }
    };

    /**
     * This is where most of the logic for setting a users access-value is.
     * The diff is automatically found and updates the list of changedMembers with the correct values.
     * The filter -> forEach part sets the correct values, which includes reverting to a sections
     * init value in case a user reverts a change.
     *
     * We have to deepClone from the oldMembers object because it's frozen and we want to easily edit the
     * temporary object below.
     *
     * Todo: Parts of the algorithm can be refactored and made more dynamic.
     * Todo: a changesMade object can have an attribute "typeOfChange" (instead of checking for hasOwnProperty)?
     */
    useEffect(() => {
        const tempMember =
            changedMembers[selectedMemberId] ||
            deepClone(oldMembers[selectedMemberId]);

        const tempChangedMembers = {
            ...changedMembers,
            [selectedMemberId]: { ...tempMember }
        };

        changesMade
            .filter((c) => c.userId === selectedMemberId)
            .forEach((change) => {
                const thisMemberPrev = tempChangedMembers[change.userId];
                if (!change.changed) {
                    const storedBeforeValue = change.originalValue;
                    if (storedBeforeValue) {
                        if (change.hasOwnProperty("role")) {
                            thisMemberPrev.role = storedBeforeValue;
                        } else {
                            thisMemberPrev.sections[change.sectionId] = storedBeforeValue;
                        }
                    } else {
                        delete thisMemberPrev.sections[change.sectionId];
                    }
                } else {
                    const newValue = change.newValue;
                    if (change.hasOwnProperty("role")) {
                        thisMemberPrev.role = newValue;
                    } else {
                        thisMemberPrev.sections[change.sectionId] = newValue;
                    }
                }
            });

        // No need to store the user if no changes has been done.
        const userHasNoChanges = changesMade.filter((c) => c.userId === selectedMemberId && c.changed === true).length === 0;
        if (userHasNoChanges) {
            delete tempChangedMembers[selectedMemberId];
        }

        setUnsavedChanges(changesMade.filter((c) => c.changed === true).length !== 0);
        setChangedMembers(tempChangedMembers);
    }, [changesMade]);


    /**
     * @description Calculates what state user has for category: full, some or none access.
     * @param {Object} member - Member to check the access amount on
     * @param {Object} category - Metadata about the category to check access amount for
     * @returns {String}
     */
    const getStateOfCategoryAccessForUser = (member, category) => {
        // We do this check asap, because if user is owner/auditor there's no need to calculate access below.
        if (hasAccessToEverything(member.id)) { return ACCESS_STATES.ALL }

        const memberSections = Object.keys(changedMembers[member.id]?.sections || oldMembers[member.id].sections);
        const categoryKey = category.value;
        const allSectionsForThisCategory = Object.keys(sections).filter(s => s.startsWith(categoryKey))
        const filteredMemberSections = memberSections.filter(s => s.startsWith(categoryKey));

        const hasAccessToWholeCategory = filteredMemberSections.length === allSectionsForThisCategory.length;
        if (hasAccessToWholeCategory) {
            return ACCESS_STATES.ALL
        }

        const hasSomeAccess = memberSections.some(s => s.startsWith(categoryKey));
        if (hasSomeAccess) {
            return ACCESS_STATES.SOME;
        }

        return ACCESS_STATES.NONE;
    };

    /**
     * @description Wrapper to get the color for the different states
     * @param {Object} member 
     * @param {Object} category 
     * @returns {String}
     */
    const getStateOfCategoryAccessForUserColor = (member, category) => COLORS[getStateOfCategoryAccessForUser(member, category)];

/**
 * @description Filters members by searching.
 * Todo: Enhance search-algorithm?
 * @param {Event} event
 */
    const onQuery = (event) => {
        if (event.key !== "Enter") {
            return;
        }

        let keyword = query.toLowerCase();

        if (keyword === "") {
            setFilteredMembers(null);
        } else {
            let filteredMembers = members.filter((member) => {
                let memberRole = roleToText(project.roles[member.id]);
                let fullName = `${member["firstName"]} ${member["lastName"]}`;
                return (
                    member["firstName"].toLowerCase().startsWith(keyword) ||
                    member["lastName"].toLowerCase().startsWith(keyword) ||
                    memberRole.toLowerCase().startsWith(keyword) ||
                    fullName.toLowerCase().startsWith(keyword)
                );
            });
            setFilteredMembers(filteredMembers);
        }
    };

    /**
     * @param {String} memberId
     * @returns {Boolean}
     */
    const hasAccessToEverything = (memberId) => {
        return (
            project.roles[memberId] === ROLES.owner ||
            project.roles[memberId] === ROLES.auditor
        );
    };

    const saveEdit = () => {
        /**
         * Converting the changedMembers object used to work with the changes
         * into an array only containing data about the members (which was stored at the ID's (keys) of the users in the 
         * changedMembers object).
         */
        const changedMembersOnlyObjectsArray = Object.entries(changedMembers).map(([key, value]) => value);

        setIsSaving(true);
        dispatch(
            UserActions.editUserAccess(
                project.id,
                changedMembersOnlyObjectsArray
            )
        )
            .then(() => {
                setChangesMade([]);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setIsSaving(false);
                setUnsavedChanges(false);
            });
    };

    if (error) {
        return <div>Noe gikk galt</div>;
    }

    if (isLoading) {
        return (
            <div className="absolute-center">
                <Spinner size="sm" />
            </div>
        );
    }

    return (
        <>
            <Prompt
                when={unsavedChanges}
                message="Du har ulagrede endringer, er du sikker på at du vil forlate siden?"
            />

            <div className="paper h-30 overflow-hidden">
                <div>
                    <div className="flex items-start justify-between h-20">
                        <div className="w-3/4">
                            <TextField
                                margin={false}
                                value={query}
                                onChange={(event) =>
                                    setQuery(event.target.value)
                                }
                                onKeyPress={onQuery}
                                placeholder="Skriv inn navn og trykk Enter for å søke etter bruker"
                                back={
                                    <Icon
                                        className="hover:text-primary cursor-pointer scale-110"
                                        icon="search"
                                        onClick={() =>
                                            onQuery({ key: "Enter" })
                                        }
                                    />
                                }
                            />
                        </div>
                        <Button
                            onClick={addUserToProject}
                            disabled={project.archived}
                            variant="text"
                        >
                            Legg til bruker
                            <Icon icon={"plus"} className="ml-2" />
                        </Button>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.wrapper} ref={wrapperRef}>
                            <div className={styles.row}>
                                {categories.map((category, i) => (
                                    <div key={category.value}>
                                        {category.label}
                                    </div>
                                ))}
                            </div>
                            {(filteredMembers || members).map((member) => {
                                return (
                                    <div
                                        className={`${styles.row} ${selectedMemberId === member.id ? styles.expanded : ""}`}
                                        key={member.id}
                                    >
                                        <UserItem
                                            user={changedMembers[member.id] || member}
                                            firstName={member.firstName}
                                            lastName={member.lastName}
                                            data={data}
                                            className={styles.userItem}
                                            role={
                                                changedMembers[member.id]?.role ||
                                                project.roles[member.id]
                                            }
                                            isOpen={
                                                selectedMemberId === member.id
                                            }
                                            onClick={(e) =>
                                                handleOnClick(e, member)
                                            }
                                            onChange={(data) =>
                                                handleOnChange(data, member)
                                            }
                                        />
                                        {categories.map((category, i) => {
                                            return (
                                                <div
                                                    onClick={(e) =>
                                                        handleOnClick(e, member)
                                                    }
                                                    key={`${category.value} ${member.id}`}
                                                >
                                                    {selectedMemberId ===
                                                        member.id ? (
                                                        Object.values(sections)
                                                                .filter((section) => section.id.split("_").slice(0, 1)[0] === category.value)
                                                            .map((section) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            section.id
                                                                        }
                                                                        className="border-b border-black p-2"
                                                                    >
                                                                        <div className="flex items-center hover:bg-gray-400 flex-grow">
                                                                            <Checkbox
                                                                                className={`${hasAccessToEverything(member.id)
                                                                                    ? ""
                                                                                    : "cursor-pointer"}`}
                                                                                labelText={sectionIdToText(section.id)}
                                                                                disabled={hasAccessToEverything(member.id)}
                                                                                value={hasAccessToEverything(member.id) ||
                                                                                    (changedMembers[member.id] || member).sections[section.id] === ACCESS ||
                                                                                    (changedMembers[member.id] || member).sections[section.id] === RESPONSIBILITY
                                                                                }
                                                                                onChange={(value) => {
                                                                                    toggleSectionValue(
                                                                                        member,
                                                                                        section.id,
                                                                                        value
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                    ) : (
                                                            <div className={`bg-${getStateOfCategoryAccessForUserColor(member, category)} ${styles.accessPreview}`}></div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className={`${styles.diffMenu} ${changesMade.filter((c) => c.changed).length >= 1 ? styles.showDiff : ""}`}
                    >
                        <h4>Endringer gjort:</h4>
                        <div className="w-full flex flex-col items-center overflow-y-auto pb-4">
                            {Object.entries(changedMembers).map(([memberId, memberData]) => {
                                const changesOnMember = changesMade.filter((c) => c.userId === memberId);

                                    return (
                                        <section
                                            key={memberId}
                                            className={styles.changeItemContainer}
                                        >
                                            <h5 className="self-start">
                                                {memberData.firstName}:
                                            </h5>
                                            {changesOnMember.map((change) => {
                                                if (change.changed) {
                                                    const checkedOn =
                                                        changedMembers[
                                                            change.userId
                                                        ]?.sections?.[
                                                        change.sectionId
                                                        ] === ACCESS;
                                                    return (
                                                        <div
                                                            key={`${change.userId}${change.sectionId}`}
                                                            className={`${change.role || checkedOn ? "text-primary" : "text-error"} flex justify-between items-center w-full`}
                                                        >
                                                            <span>
                                                                {change.role ? (
                                                                    <b>
                                                                        Rolle byttet til {data.find((i) => i.value === change.newValue)?.label}
                                                                    </b>
                                                                ) : (
                                                                        `${sectionIdToText(
                                                                            change.sectionId
                                                                        )} har blitt ${checkedOn
                                                                            ? "valgt"
                                                                            : "fjernet"
                                                                        }`
                                                                    )}
                                                            </span>
                                                            {!isSaving ? (
                                                                <Icon
                                                                    color="black"
                                                                    icon="undo-alt"
                                                                    size="sm"
                                                                    className="ml-2 cursor-pointer hover:opacity-50"
                                                                    onClick={() => toggleOneChange(change)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </section>
                                    );
                                }
                            )}
                        </div>
                        <div className="flex-grow" />
                        <div className="flex">
                            <Button
                                color="error"
                                onClick={() => checkIfSaveToExit()}
                                disabled={isSaving}
                            >
                                Avbryt
                            </Button>
                            <Button
                                className="ml-3"
                                onClick={() => saveEdit()}
                                disabled={isSaving}
                                loading={isSaving}
                                disableElevation
                            >
                                Lagre endringer
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditUserForm;
