import marked from 'marked'
import DOMPurify from 'dompurify';

// Options for the markdown (in most cases normal text) parser.
marked.setOptions({ breaks: true });

/**
 * @description Creates HTML using a markdown parser and then cleaning it afterwards.
 * @param {String} text The text that should be parsed to HTML
 * @returns {Object} An object that is ready to be used in the dangerouslySetInnerHTML prop.
 */

function cleanHTMLFrom(text) {
    /**
     * Line breaks over multiple lines are ignored because the
     * the "marked" library uses the same markdown parsing as GitHub.
     * However, it can be a desired feature to allow spaces over multiple lines
     * so therefore we do a extra check for empty line breaks and insert a &nbsp;
     */
    const textSplitIntoLines = (text || '').split(/\r\n|\r|\n/) // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp#Using_regular_expression_to_split_lines_with_different_line_endingsends_of_lineline_breaks

    const textWithCorrectSpaceBreaks = textSplitIntoLines.map(line => line.length === 0 ? '&nbsp;' : line).join('\n');

    const HTML = marked(textWithCorrectSpaceBreaks);

    const cleanHTML = DOMPurify.sanitize(HTML);

    return { __html: cleanHTML }
}

export default cleanHTMLFrom;