import { eventTypes as EVENT_TYPES } from '~/data/config';
import ROUTES, { goToProjectDocuments, goToProjectSection, goToProjectCriteria, goToProjectDashboard, goToProjectProgress } from '~/ROUTES';

/**
 * @param {import("~/store/events/mappers").Notification} notification
 */
export default (projectId, notification) => {

    const sectionId = notification.sectionId || '';
    const categoryId = notification.categoryId || '';
    const payload = notification.payload || {};

    switch(notification.eventAction) {

        case EVENT_TYPES.CRITERION_SENT_FOR_REVISION: {
            if(Array.isArray(payload.subCriterionId) && payload.subCriterionId.length > 1) {
                return goToProjectSection(projectId, categoryId, sectionId);
            } else {
                const [subCriterionNumber] = extractSubCriterionNumber(payload);
                return goToProjectCriteria(projectId, categoryId, sectionId, subCriterionNumber).concat('?event=', notification.id);
            }
        }

        case EVENT_TYPES.CRITERION_STATUS_CHANGE: {
            const [subCriterionNumber] = extractSubCriterionNumber(payload);
            return goToProjectCriteria(projectId, categoryId, sectionId, subCriterionNumber).concat('?event=', notification.id);
        }

        case EVENT_TYPES.CRITERION_DEADLINE_CHANGE: {
            const [subCriterionNumber] = extractSubCriterionNumber(payload);
            return goToProjectCriteria(projectId, categoryId, sectionId, subCriterionNumber);
        }

        case EVENT_TYPES.DOCUMENT_CREATED: {
            const [subCriterionNumber] = extractSubCriterionNumber(payload);
            return goToProjectCriteria(projectId, categoryId, sectionId, subCriterionNumber).concat('?event=', notification.id);   
        }

        case EVENT_TYPES.REMINDER: {
            return goToProjectSection(projectId, categoryId, sectionId);
        }

        case EVENT_TYPES.MEMBER_ADD: {
            return goToProjectDashboard(projectId);
        }

        case EVENT_TYPES.ACCESS_REVOKED: {
            return goToProjectDashboard(projectId);
        }

        case EVENT_TYPES.RESPONSIBILITY_REVOKED: {
            return goToProjectDashboard(projectId);
        }

        case EVENT_TYPES.ACCESS_GIVEN: {
            return goToProjectSection(projectId, categoryId, sectionId);
        }

        case EVENT_TYPES.RESPONSIBILITY_GIVEN: {
            return goToProjectSection(projectId, categoryId, sectionId);
        }

        case EVENT_TYPES.PHASE_REMINDER: {
            return goToProjectProgress(projectId);
        }

        default: {
            return '';
        }

    }
}

const extractSubCriterionNumber = (payload) => {
    let subCriterionNumber = '';
    const subCriterionId = payload.subCriterionId && payload.subCriterionId.length > 0 ? 
        Array.isArray(payload.subCriterionId) ? payload.subCriterionId[0] : 
        payload.subCriterionId : null;
        
    if(subCriterionId) {
        subCriterionNumber = subCriterionId.split("_").slice(-1)[0];
    }
    return [subCriterionNumber, subCriterionId];
}