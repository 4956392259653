import React, { useState } from 'react';

import { Link, useParams, useHistory } from 'react-router-dom'
import { goToProjectProgress } from '~/ROUTES';

// Styles
import styles from './index.module.css';

// State imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import PhasePlannerModal from './components/PhasePlannerModal/PhasePlannerModal';
import ProgressPlan from './components/ProgressPlan/ProgressPlan.jsx'


const ProjectSummary = ({ preview }) => {
    const params = useParams();
    const projectId = params.id;
    const history = useHistory();
    const progressPlan = useSelector(ProjectSelectors.getProjectProgressPlan(projectId));
    const archived = useSelector(ProjectSelectors.getCurrentArchived);
    const progressPhases = useSelector(ProjectSelectors.getProjectPhases);

    // State
    const [showPlanner, setShowPlanner] = useState(false);

    const goToPreviousPage = () => history.goBack();

    return (
        <div className={`${styles.root} flex flex-col paper lg:col-span-2 ${preview ? '' : 'm-4'}`} >
            <PhasePlannerModal
                show={showPlanner}
                projectId={projectId}
                onClose={() => setShowPlanner(false)}
            />
            {
                preview ?
                    null :
                    <span
                        style={{ gridArea: 'back' }}
                        onClick={goToPreviousPage}
                        className='cursor-pointer hover:underline my-auto'
                    >
                        <Icon icon='arrow-left' size='lg' className='mr-2' /> Tilbake
                    </span>
            }
            <header className={`${preview ? '' : 'mt-4'}`}>
                <h4>Fremdriftsplan </h4>
            </header>
            {
                progressPlan ?
                    <ProgressPlan progressPlan={progressPlan} progressPhases={progressPhases} projectId={projectId} preview={preview} />
                    :
                    <div>
                        <Button
                            disabled = {Boolean(archived)}
                            disableElevation
                            onClick={() => setShowPlanner(true)}
                        >
                            Sett opp fremdriftsplan
                        </Button>
                    </div>
            }
            {preview && progressPlan ?
                <Link className={`text-xl mt-3 py-3 px-8 hover:bg-gray-250 rounded-md font-semibold self-center ${styles.linkHover}`} to={goToProjectProgress(projectId)}>Se hele fremdriftsplanen<Icon icon={'arrow-right'} /></Link>
                : null}
        </div>
    )
};

export default ProjectSummary;
