import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ROUTES from '~/ROUTES';
import { useParams } from 'react-router-dom';

// Store selectors
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import { Button } from '~/components/base/Buttons';
import ProjectExportButton from '../../components/miscellaneous/ProjectExportButton';
import ClipableLink from '~/components/miscellaneous/ClipableLink';

// Module components
import ArchiveModal from './components/ArchiveModal';

// Utils
import { dateToDateString } from '~/utils/date';
import checkIfOwner from '~/utils/access/checkIfOwner';


const ProjectSettings = () => {
    const params = useParams();
    const projectId = params.id;

    const project = useSelector(ProjectSelectors.getProjectById(projectId));

    // State
    const [showArchive, setShowArchive] = useState(false);

    return (
        <div className='page p-3 mb-4'>
            <ArchiveModal
                show={showArchive}
                onClose={() => setShowArchive(false)}
                project={project}
            />

            <div className='mb-4'>
                <h5 className='my-0 mb-1 mr-4'>
                    Prosjekt innstillinger
                </h5>
            </div>

            <div className='paper'>
                <div className='mb-4'>
                    <h6>Info</h6>
                    <hr />
                </div>
                <section className='mb-5'>
                    <div className='font-light mb-2 text-gray-700'>
                        Projekt navn: { project.title }
                    </div>
                    <div className='font-light mb-2 text-gray-700'>
                        Opprettet: { dateToDateString(new Date(project.createdAt || null)) }
                    </div>
                </section>

                <div className='mb-4'>
                    <h6>Diverse</h6>
                    <hr />
                </div>
                <section className='mb-5'>
                    <div className='font-light mb-2'>
                        Eksporter hele prosjektet til en fil:
                    </div>
                    <ProjectExportButton
                        projectId={projectId}
                    />
                    <div className='mt-6'>
                        <div className='font-light'>
                            Delelig link for å eksportere:
                        </div>
                        <ClipableLink
                            link={`${window.origin}${ROUTES.EXPORT}?p=${projectId}`}
                        />
                    </div>
                </section>
                {
                    checkIfOwner(project) &&
                    <section className='mb-5'>
                        <div className='font-light mb-2'>
                            Avslutt prosjektet:
                        </div>
                        <Button
                            variant='outlined'
                            color='error'
                            disabled={Boolean(project.archived)}
                            onClick={() => setShowArchive(true)}
                        >
                            Arkiver prosjekt
                        </Button>
                    </section>
                }
            </div>

        </div>
    )
};

ProjectSettings.propTypes = {

}

export default ProjectSettings;
