import { db, sectionCollection, auth } from '~/services/firebase';
import { MUTATIONS } from './reducer';
import ProjectSelectors from './selectors';
import store from '..';

const setSectionMeta = (projectId, sectionId, metaData) => ({
    type: MUTATIONS.SET_SECTION_META,
    projectId: projectId,
    sectionId: sectionId,
    payload: metaData,
});

const setSectionMetas = (projectId, sectionMetaDatas) => ({
    type: MUTATIONS.SET_SECTION_METAS,
    projectId: projectId,
    payload: sectionMetaDatas,
});

const setCurSection = (sectionId) => ({
    type: MUTATIONS.SET_CUR_SECTION,
    payload: sectionId,
});

const setCurCategory = (categoryId) => ({
    type: MUTATIONS.SET_CUR_CATEGORY,
    payload: categoryId,
})


const editSectionNote = (projectId, sectionId, note) => (dispatch) => {
    const payload = {
        note: note,
        noteChangedAt: new Date().toISOString(),
        noteChangedBy: auth.currentUser.uid,
    };

    return sectionCollection(projectId)
        .doc(sectionId)
        .update(payload)
        .then(() => {
            const oldSectionMeta = ProjectSelectors.getSectionById(projectId, sectionId)(store.getState()) || {};
            dispatch(setSectionMeta(projectId, sectionId, {
                ...oldSectionMeta,
                ...payload,
            }))
        });
}

export default {
    setSectionMeta,
    setSectionMetas,
    setCurSection,
    setCurCategory,
    editSectionNote,
}