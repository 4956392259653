import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { roles as ROLES } from '~/data/config';


// State imports
import { useDispatch, useSelector } from 'react-redux';
import DocumentActions from '../../store/documents/actions';
import DocumentSelectors from '../../store/documents/selectors';
import ProjectSelectors from '../../store/project/selectors';

// Project Components
import TopBar from '~/views/projectView/components/TopBar';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import TextField from '~/components/base/TextField';
import Spinner from '~/components/base/Spinner';

// Page documents
import DocumentTable from './components/DocumentTable';
import DocumentUpload from '~/components/modules/DocumentUpload';

const LIMIT = 25;

const ProjectDocumentView = () => {

    // State
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [hasNextPage, setHasNextPage] = useState(false);

    const params = useParams();
    const projectId = params.id;
    const dispatch = useDispatch();
    const userRole = useSelector(ProjectSelectors.getProjectRoleById(projectId))
    const archived = useSelector(ProjectSelectors.getCurrentArchived);


    useEffect(() => {
        fetchDocuments(page, LIMIT);
    }, [])

    const fetchDocuments = (page, limit, query) => {
        setIsLoading(true);
        DocumentActions.fetchDocuments(projectId, page, limit, query)(dispatch)
        .then((docs) => {
            let newDocuments = null;
            if(page === 1) {
                newDocuments = docs;
            } else {
                newDocuments = [...documents, ...docs];
            }
            setDocuments(newDocuments);
            setHasNextPage(docs.length > 0 && newDocuments.length%limit === 0);

        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }

    const onQuery = (event) => {
        if(event.key !== 'Enter') {
            return;
        }

        setPage(1);
        setDocuments([]);
        fetchDocuments(1, LIMIT, query);
    }

    const getNextPage = () => {
        setPage(page + 1);
        fetchDocuments(page + 1, LIMIT, query);
    }

    const toggleUpload = () => {
        setIsUpload(!isUpload);
    };

    const HAS_WRITE_ACCESS = (userRole === ROLES.owner || userRole === ROLES.member) && !archived;

    return (
        <div className='p-3 page'>
            <TopBar />
            <div className='paper'>
                <div className='mb-4 flex items-center'>
                    {
                        !isUpload &&
                        <TextField
                            margin={false}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyPress={onQuery}
                            back={<Icon icon='search' />}
                        />
                    }
                    <div className='flex-grow' style={{minWidth: 24}} />
                    {
                        HAS_WRITE_ACCESS &&
                        <Button
                            className='h-fit-content w-fit-content'
                            style={{minWidth: 160}}
                            variant='text'
                            onClick={toggleUpload}
                        >
                            { isUpload ? 'Tilbake' : 'Last opp'}
                            <Icon icon={isUpload ? 'arrow-left' : 'plus'} className='ml-2' />
                        </Button>
                    }
                </div>
                {
                    isUpload &&
                    <DocumentUpload
                        newDocOnMount={true}
                        projectId={projectId}
                        onDocumentUpload={toggleUpload}
                    />
                }
                {
                    !isUpload &&
                    <DocumentTable
                        projectId={projectId}
                        documents={documents}
                        isLoading={isLoading && !hasNextPage}
                    />
                }
                {
                    isLoading &&
                    <div className='mt-5'>
                        <Spinner />
                    </div>
                }
                {
                    (!isUpload && !isLoading && hasNextPage) &&
                    <div className='mt-4'>
                        <Button
                            className='block m-auto'
                            variant='text'
                            onClick={getNextPage}
                        >
                            Neste side
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
};

export default ProjectDocumentView;
