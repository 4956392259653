import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TextField.module.css';

import Icon from '../Icon';

const TextField = ({
    className = '', 
    label, 
    children, 
    color = 'primary', 
    error, 
    hint,
    front,
    back,
    margin = true,
    ...rest
}) => {
    const [hasFocus, setHasFocus] = useState(false);

    let borderColor = 'gray-400';
    if(error) {
        borderColor = 'red-600'
    }

    const onFocus = () => {
        setHasFocus(true);
    };  
    
    const onBlur = () => {
        setHasFocus(false);
    };


    return (
        <div className={`${margin ? 'mb-6 mt-4' : '' } w-full`}>
            {
                label && <p className='subtitle1 font-semibold mb-1'> { label }</p>
            }
            <div className={`rounded border border-solid border-${hasFocus ? color : borderColor} flex items-center`}>
                {
                    front && 
                    <div className='pl-4'>
                        { front }
                    </div>
                }
                <input
                    className={`${className} ${styles.input} px-4 py-2 border-none rounded focus:outline-none w-full`}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...rest}
                >
                    { children }
                </input>
                {
                    back &&
                    <div className='pr-4'>
                        { back }
                    </div>
                }
            </div>
            {
                hint &&
                <p className={error ? 'text-red-600' : ''}>
                    { hint }
                </p>
            }
        </div>
    )
};

TextField.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    error: PropTypes.bool,
    front: PropTypes.element,
    back: PropTypes.element,
    margin: PropTypes.bool,
}

export default TextField;