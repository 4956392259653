import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../../FileSystem.module.css';

// State imports
import { useDispatch, useSelector } from 'react-redux';
import DocumentActions from '~/store/documents/actions';
import DocumentSelectors from '~/store/documents/selectors';
import { FileSystemContext } from '../../FileSystem';

// Core components
import Icon from '~/components/base/Icon';
import Collapse from '~/components/base/Collapse';
import Spinner from '~/components/base/Spinner';

// Component components
import FileItem from '../FileItem';

const DirectoryItem = ({
    className = '',
    projectId,
    directoryId,
    depth = 0,
    isRoot = false,
    onReview,
    directoriesToExclude = [],
    editMode = false,
}) => {
    const editModeInput = useRef();

    // State
    const [isOpen, setIsOpen] = useState(isRoot);
    const [isLoading, setIsLoading] = useState(false);
    const [isInlineLoading, setInlineLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const { systemState, setSystemState } = useContext(FileSystemContext);


    // Store
    const dispatch = useDispatch();
    const directory = useSelector(DocumentSelectors.getDirectoryById(projectId, directoryId));
    const files = useSelector(DocumentSelectors.getDocumentMetaByIds(projectId, Object.keys((directory || {}).documents || {})))
    let subDirectories = (directory || {}).directories || [];

    if(directoriesToExclude.length > 0) {
        subDirectories = subDirectories.filter(dirId => !directoriesToExclude.some(filterId => filterId === dirId));
    }

    // Indent
    const indent = Math.min(depth, 7);

    useEffect(() => {
        const hasContent = ((directory || {}).directories || []).length > 0 || files.length > 0;

        // Fetch the directory data only if it is not available in the store
        if(!editMode && directoryId && !hasContent && !isLoading && !hasFetched && isOpen) {
            setIsLoading(true);
            DocumentActions.fetchDirectory(projectId, directoryId)(dispatch)
            .catch(console.error)
            .finally(() => {
                setHasFetched(true);
                setIsLoading(false);
            });
        }
    }, [directory, isLoading, hasFetched, isOpen, editMode]);

    useEffect(() => {
        if(editMode && editModeInput) {
            editModeInput.current.focus();
        }
    }, [editModeInput])

    useEffect(() => {
        reset();
    }, [directoryId])

    const onDirectoryClicked = () => {
        if(editMode) {
            return;
        }

        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        setSystemState({
            ...systemState,
            selectedDirectory: newIsOpen ? directoryId : null,
            createNewDirectory: false,
        });
    };

    const onNewDirNameChanged = (event) => {
        setSystemState({
            ...systemState,
            newDirectoryName: event.target.value,
        })
    }

    const onNewDirNameKeyPress = (event) => {
        if(event.key === 'Enter') {
            saveNewDirectory();
        }
    }

    const saveNewDirectory = () => {
        setInlineLoading(true);
        DocumentActions.createNewDirectory(projectId, systemState.selectedDirectory, systemState.newDirectoryName)(dispatch)
            .then(() => {
                setSystemState({
                    ...systemState,
                    createNewDirectory: false,
                    newDirectoryName: '',
                })
            })
            .catch(() => {
                setInlineLoading(false);
            })
    }

    const reset = () => {
        setIsOpen(isRoot);
        setInlineLoading(false);
        setHasFetched(false);
    }

    const isSelectedDirectory = systemState.selectedDirectory === directoryId;
    const bgColor = isSelectedDirectory ? 'bg-gray-300' : 'bg-gray-250';

    return (
        <div>
            {
                !isRoot &&
                <div className={`border-b border-gray-300 ${bgColor}`}>
                    <div 
                        className={`${className} ${styles[`indent-${indent}`]} flex items-center py-1 px-2 cursor-pointer`}
                        onClick={onDirectoryClicked}    
                    >
                        <Icon icon={['far', 'folder']} size='sm' className='mr-2' />
                        {
                            editMode ? 
                            <input
                                ref={editModeInput}
                                className='px-2 bg-gray-200 w-full'
                                value={systemState.newDirectoryName || ''} 
                                onChange={onNewDirNameChanged}
                                onKeyPress={onNewDirNameKeyPress} />
                            :
                            <>
                                <div className='font-semibold text-base mr-16'>
                                    { (directory || {}).name || '' }
                                </div>
                                <Icon icon={`chevron-${isOpen ? 'up' : 'down'}`} size='sm' className='mr-2' />
                            </>
                        }
                        {
                            isInlineLoading &&
                            <Spinner size='xs' />
                        }
                    </div>
                </div>
            }
            {
                isLoading &&
                <div className='mt-2'>
                    <Spinner size='sm' />
                </div>
            }
            <Collapse visible={isOpen}>
                <div>
                    {
                        (isSelectedDirectory && systemState.createNewDirectory) &&
                        <DirectoryItem
                            directoryId={''}
                            editMode={true}
                            projectId={projectId}
                            depth={depth + 1}
                        />
                    }
                    {
                        subDirectories.map(dirId => (
                            <DirectoryItem
                                key={dirId}
                                projectId={projectId}
                                directoryId={dirId}
                                depth={depth + 1}
                                onReview={onReview}
                            />
                        ))
                    }
                    {
                        files && files.map(file => (
                            <FileItem 
                                key={file.id} 
                                projectId={projectId}
                                depth={depth} 
                                item={file}
                                onReview={onReview}
                            />

                        ))
                    }

                    {
                        (!isLoading && subDirectories.length === 0 && (files || []).length === 0) &&
                        <div className='flex items-center w-full my-2'>
                            <p className='text-center w-full font-light'>Ingen filer å vise</p>
                        </div>
                    }
                </div>
            </Collapse>
        </div>
    )
};

DirectoryItem.propTypes = {
    className: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    directoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    depth: PropTypes.number,
    isRoot: PropTypes.bool,
    onReview: PropTypes.func,
    directoryFilters: PropTypes.array, // List of directoryIds that is only allowed to be showed as a sub-directory, does not work for subsub-directories

    editMode: PropTypes.bool, // If the directory is in editMode or not
}

export default DirectoryItem;