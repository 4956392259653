import React from 'react';
import styles from './index.module.css';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Project components
import Icon from '~/components/base/Icon';
import DocAggrPieChart from '~/components/graphs/DocAggrPieChart';

const DocumentationModule = () => {
    const docAggregations = useSelector(ProjectSelectors.getCurDocAggregations);
    const creditAggregations = useSelector(ProjectSelectors.getCurCreditAggregations);

    const docProjectAggregations = (docAggregations || {}).projectAggregations;
    const creditProjectAggregations = (creditAggregations || {}).projectAggregations;

    return (
        <div className={`${styles.root} paper`}>
            <h4>Progresjon</h4>
            <div className = "grid grid-cols-1 xl:grid-cols-2 gap-6">
                <div>
                    <div className='flex items-center mb-4'>
                        <Icon icon='file' size='1x' className='mr-2' />
                        <h6 className='mb-1'>Dokumentasjon</h6>
                    </div>
                    <DocAggrPieChart
                        data={docProjectAggregations}
                    />
                </div>
                <div>
                    <div className='flex items-center mb-4'>
                        <Icon icon='check' size='1x' className='mr-2' />
                        <h6 className='mb-1'>Poeng</h6>
                    </div>
                    <DocAggrPieChart
                        data={creditProjectAggregations}
                    />
                </div>
           </div>
        </div>
    )
};

export default DocumentationModule;