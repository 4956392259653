import React from 'react';
import { Link, useLocation } from 'react-router-dom'

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import Icon from '~/components/base/Icon';

// Components
import TopBarIcon from './components/TopBarIcon'

// Data
import CATEGORIES from '~/data/categories.json';
import SECTIONS from '~/data/sections.json';

// Routes
import { goToProjectDashboard, goToProjectCategory, goToProjectSection, goToProjectSectionChat, goToProjectSectionEdit, goToProjectManual } from '~/ROUTES';

const SectionTopBar = ({ projectId }) => {
	const categoryId = useSelector(ProjectSelectors.getCurCategory);
	const sectionId = useSelector(ProjectSelectors.getCurSection);
	const project = useSelector(ProjectSelectors.getProjectById(projectId));
	const location = useLocation();

	return (
		<div className={`flex items-center mb-4`}>
			<div className="flex items-center h-10">
				{project && (
					<Link
						className="hover:underline text-2xl"
						to={goToProjectDashboard(projectId)}
					>
						{project.title}
					</Link>
				)}
			</div>
			<div className="flex items-center h-10">
				<Icon icon="chevron-right" className="mx-4" color="gray" />
				{categoryId && (
					<Link
						className="hover:underline text-xl"
						to={goToProjectCategory(projectId, categoryId)}
					>
						{(CATEGORIES.find((c) => c.key === categoryId) || {}).name || ''}
					</Link>
				)}
			</div>

			{sectionId && SECTIONS[sectionId] ? (
				<div className="flex items-center h-10">
					<Icon icon="chevron-right" className="mx-4" color="gray" />
					<p className="font-light text-lg mb-0">{SECTIONS[sectionId]}</p>
				</div>
			) : (
				''
			)}

			<div className="flex-grow" />
			<div className="grid grid-cols-4 gap-5">
				<TopBarIcon
					to={goToProjectSection(projectId, categoryId, sectionId)}
					className="bg-white shadow px-2 py-2 rounded-full flex items-center justify-center"
					tabIndex={0}
					activeClassName="bg-primary text-white"
					hoverText="Kravside"
					icon="tasks"
				/>
				<TopBarIcon
					to={goToProjectManual(projectId, categoryId, sectionId)}
					className="bg-white shadow px-2 py-2 rounded-full flex items-center justify-center"
					tabIndex={0}
					activeClassName="bg-primary text-white"
					hoverText="BREEAM Manual"
					icon="book"
				/>
				<TopBarIcon
					to={() => {
						return location.pathname.split('/').slice(-1).join() === 'edit'
							? goToProjectSection(projectId, categoryId, sectionId)
							: goToProjectSectionEdit(projectId, categoryId, sectionId);
					}}
					isActive={() =>
						location.pathname.split('/').slice(-1).join() === 'edit'
					}
					className="bg-white shadow px-2 py-2 rounded-full flex items-center justify-center"
					tabIndex={0}
					activeClassName="bg-primary text-white"
					hoverText="Behandle tilganger"
					icon="user-friends"
				/>
				<TopBarIcon
					to={goToProjectSectionChat(projectId, categoryId, sectionId)}
					className="bg-white shadow px-2 py-2 rounded-full flex items-center justify-center"
					tabIndex={0}
					activeClassName="bg-primary text-white"
					hoverText="Emne-chat"
					icon="comment-alt"
				/>
			</div>
		</div>
	);
};

export default SectionTopBar;