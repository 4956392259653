import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CriterionItem.module.css';

import Icon from '~/components/base/Icon';
import AssignResponsibleModal from '../AssignResponsibleModal/AssignResponsibleModal';

const CriterionItem = ({
    owner,
    id,
    sectionCriteria,
    members,
}) => {
    const criteriaInfo = sectionCriteria.find(sc => sc.id === id);

    const [assignResponsibleModal, setAssignResponsibleModal] = useState({})
    const [subCritId, setSubCritId] = useState('')
    const [subCritOrgId, setSubCritOrgId] = useState('')

    const addUser = (subCriterionId, subCritirionOriginalId) => {
        setSubCritId(subCriterionId);
        setSubCritOrgId(subCritirionOriginalId);
        setAssignResponsibleModal({
            show: true,
        })

    }
    
    return (
        <div className='mb-4'>
                                
            <div className='flex items-center mb-8'>
                <h6 className='font-bold text-base mb-0 mr-8'>
                    { criteriaInfo.credits } poeng
                </h6>
            </div>
            {
                criteriaInfo.criteria &&
                criteriaInfo.criteria
                .map((subCriterion, index) => (
                    <div key={subCriterion.id}
                        className='mb-10'
                    >
                        <div 
                            className={`${styles.content} mb-8`}
                        >
                            <p>
                                <strong>{ subCriterion.originalId || 0 }.</strong>
                            </p>
                            
                            <div>
                                <div className='mr-20'>
                                    {
                                        subCriterion.criteriaDescription &&
                                        <p>
                                            { subCriterion.criteriaDescription }
                                        </p>
                                    }
                                    {
                                        subCriterion.subCriteria &&
                                        <ul className='ml-4'>
                                            {
                                                subCriterion.subCriteria.map(sub => (
                                                    <p key={sub.id}>
                                                        { sub.internalId }
                                                        {". " + sub.subCriteriaDescription}
                                                    </p>
                                                ))
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    members[subCriterion.originalId] ?
                                    members[subCriterion.originalId].map((member) => (
                                        <ul key={member.id}>{member.firstName} {member.lastName}</ul>
                                    ))
                                    : null
                                }
                                {
                                    owner ?
                                    <div 
                                        className='flex items-center cursor-pointer'
                                        onClick={() => {
                                            addUser(subCriterion.id, subCriterion.originalId)
                                        }}
                                    >
                                        <h6 className='font-light text-base underline mb-0 mr-2'>
                                            Legg til
                                        </h6>
                                        <Icon icon='plus-circle' size='sm' />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        {
                            index < criteriaInfo.criteria.length - 1 ?
                            <hr /> : null
                        }
                    </div>
                ))
            }
            <AssignResponsibleModal
                show={Boolean(assignResponsibleModal.show)}
                onClose={() => setAssignResponsibleModal({show: false})}
                members={members}
                subCriterionId={subCritId}
                subCriterionOriginalId={subCritOrgId}
            />
        </div>
    )
}

CriterionItem.propTypes = {
    sectionCriteria: PropTypes.array.isRequired,
    criterionMetaData: PropTypes.object.isRequired, // The metadata for the criterion
}

export default CriterionItem;