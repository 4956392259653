import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { goToDashboard } from '~/ROUTES';
import qs from 'query-string';

// Styles
import styles from './ExportView.module.css';

// Store import
import { useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';

// Components
import KvistLogo from '~/components/miscellaneous/KvistLogo';
import Spinner from '~/components/base/Spinner';
import { Button } from '~/components/base/Buttons';
import Collapse from '~/components/base/Collapse';

// Assets
import LoadingSVG from '~/assets/img/loading.svg';
import AuthenticationSVG from '~/assets/img/authentication.svg';
import EmailSVG from '~/assets/img/email.svg';
import WarningSVG from '~/assets/img/warning.svg';

// Constants
const STATUS_ERROR = -1;
const STATUS_DOWNLOAD = 0;
const STATUS_UNAUTHORIZED = 1;
const STATUS_ACCESS_REQUEST_SENT = 2;

const ExportView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = useMemo(() => qs.parse(location.search) || {}, [location.search]);
    const projectId = queryParams.p;

    // State
    const [status, setStatus]= useState(STATUS_DOWNLOAD);
    const [exportURL, setExportURL] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const downloadExportURL = () => {
        ProjectActions.exportProject(projectId)
            .then((url) => {
                setExportURL(url);

                window.open(url, '_blank');
            })
            .catch((err) => {
                setStatus(STATUS_UNAUTHORIZED);
            })
    }

    const requestAccess = () => {
        setIsLoading(true);
        ProjectActions.exportAccessRequest(projectId)
            .then(() => {
                setStatus(STATUS_ACCESS_REQUEST_SENT);
            })
            .catch((error) => {
                console.error(error.details);
                setStatus(STATUS_ERROR);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const retryDownload = () => {
        setStatus(STATUS_DOWNLOAD);
        if(projectId) {
            downloadExportURL();
        }
    }

    useEffect(() => {
        if(projectId) {
            downloadExportURL();
        } else {
            history.push(goToDashboard());
        }
    }, [projectId])


    return (
        <div>

            <div className={`paper max-w-screen-md m-auto md:mt-10 rounded-none md:rounded-md ${styles.root}`}>
                <KvistLogo  className='m-auto mb-10' />
                <Collapse
                    visible={status === STATUS_DOWNLOAD}
                >
                    <div className='flex flex-col'>
                        <h4 className='text-center'>Laster ned prosjekt</h4>
                        <img
                            className='m-auto'
                            src={LoadingSVG}
                            width={200}
                            height={200}
                        />
                        <div className='relative h-12'>
                            {
                                !exportURL &&
                                <div
                                    className='p-4 shadow-md rounded-md m-auto w-12 h-12 absolute bg-white left-0 right-0'
                                    style={{top: -20}}
                                >
                                    <Spinner size='xs'/>
                                </div>
                            }
                        </div>
                        {
                            !exportURL ?
                                <div className='text-center font-light mt-2'>
                                    Exporterer prosjektet - dette kan ta litt tid.
                                </div>
                            :
                            <a href={exportURL} className='m-auto text-center text-primary underline'>
                                Klikk her for å laste ned
                            </a>
                        }
                    </div>
                </Collapse>
                <Collapse
                    visible={status === STATUS_UNAUTHORIZED}
                >
                    <div className='mt-6'>
                        <div className='flex flex-col-reverse md:flex-row gap-4 justify-center'>
                            <div>
                                <h6 className='text-center md:text-right'>Du har ikke tilgang</h6>
                                <div className='font-light mt-2 text-center md:text-right'>
                                    Du har ikke tilgang til å laste ned denne filen
                                </div>
                                <Button
                                    className='mx-auto md:ml-auto md:mr-0 mt-6'
                                    disableElevation
                                    onClick={requestAccess}
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    Be om tilgang
                                </Button>
                            </div>
                            <div>
                                <img
                                    className='mx-auto md:ml-auto md:mr-0'
                                    src={AuthenticationSVG}
                                    width={200}
                                    height={200}
                                />
                            </div>
                        </div>

                    </div>
                </Collapse>
                <Collapse
                    visible={status === STATUS_ACCESS_REQUEST_SENT}
                >
                    <h4 className='text-center'>Forespørsel om tilgang er sendt!</h4>
                    <img
                        className='m-auto'
                        src={EmailSVG}
                        width={200}
                        height={200}
                    />
                </Collapse>
                <Collapse
                    visible={status === STATUS_ERROR}
                >
                    <h4 className='text-center'>Æsj, det oppstod en feil!</h4>
                    <img
                        className='m-auto'
                        src={WarningSVG}
                        width={200}
                        height={200}
                    />
                    <Button
                        className='mx-auto mt-6'
                        disableElevation
                        onClick={retryDownload}
                    >
                        Prøv igjen
                    </Button>
                </Collapse>
            </div>
        </div>
    )
}

export default ExportView;