import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

import Spinner from '../../Spinner/Spinner';

const Button = React.forwardRef(
	(
		{
			className = '',
			color = 'primary',
			variant = 'contained',
			disabled,
			disableElevation,
			loading,
			spinnerColor,
			children,
			onClick,
			size = 'md',
			...rest
		},
		ref
	) => {
		const btnClick = () => {
			if (!disabled && onClick) {
				onClick();
			}
		};

		let baseStyles = 'font-bold px-4 py-2 rounded';
		let btnStyles = '';
		if (disabled) {
			btnStyles +=
				variant !== 'icon'
					? `${baseStyles} cursor-not-allowed opacity-50 ${
							variant !== 'text' ? 'bg-gray-400' : ''
					  } `
					: 'cursor-not-allowed opacity-50 p-2';
		} else {
			switch (variant) {
				case 'contained': {
					btnStyles += `${baseStyles} bg-${color} hover:bg-${color}-dark text-${color}-text ${
						disableElevation ? '' : 'shadow-md'
					} ${styles['btn-contained']}`;
					break;
				}

				case 'outlined': {
					btnStyles += `${baseStyles} border-solid border-${color} border text-${color} hover:bg-gray-100 active:bg-${color} active:text-${color}-text`;
					break;
				}

				case 'text': {
					btnStyles += `${baseStyles} bg-transparent text-${color} hover:bg-gray-100 ${styles['btn-contained']}`;
					break;
				}

				case 'icon': {
					btnStyles += `bg-transparent text-${color} rounded-full p-2 hover:bg-gray-100 ${styles['btn-contained']}`;
					break;
				}

				default:
			}
		}

		const colorOfSpinner =
			disabled && !spinnerColor ? 'secondary' : spinnerColor || 'gray-400';

		return (
			<button
				className={`${styles.btnFocus} ${btnStyles} ${className} flex items-center justify-center`}
				onClick={btnClick}
				ref={ref}
				{...rest}
			>
				{loading && (
					<Spinner className="ml-0 mr-3" size="xs" color={colorOfSpinner} />
				)}
				{children}
			</button>
		);
	}
);

Button.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default Button;
