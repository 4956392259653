import React from 'react';

const TableCell = ({children}) => {

    return (
        <td className='p-3'>
            { children }
        </td>
    )
};

export default TableCell;