
export const mapDocument = (document) => ({
    id: document.id,
    fileName: document.fileName,
    source: document.source,
    bucket: document.bucket,
    createdAt: document.createdAt,
    createdBy: document.createdBy,
});

export const mapDocumentMeta = (meta) => ({
    id: meta.id,
    createdAt: meta.createdAt,
    createdBy: meta.createdBy,
    document: meta.document,
    fileName: meta.fileName,

    status: meta.status,
    statusNote: meta.statusNote,
    statusChangedBy: meta.statusChangedBy,
    locked: meta.locked,
    lockedBy: meta.lockedBy,

    metaData: meta.metaData || {},
});

export const mapDirectory = (directory) => ({
    id: directory.id,
    name: directory.name,
    permanent: directory.permanent || false,
    parent: directory.parent,
    documents: directory.documents,
    directories: directory.directories || [],
    metaData: directory.metaData,
});