import React from "react";

// Components
import Modal from '~/components/base/Modal';
import Icon from '~/components/base/Icon';

const RemoveUserModal = (props) => {
    return (
        <Modal
            show={props.show}
            onBackdropClick={() => {
                props.onClose()
            }}
        >
        <div className='text-center paper'>
            <h3>Feil! <Icon icon='times-circle' color='var(--color-error)'/></h3>
            Noe gikk galt, valgte du en dato senere enn i dag?
        </div>
        </Modal>
    )
}

export default RemoveUserModal
