import { mapDirectory, mapDocument, mapDocumentMeta } from './mappers';

export const MUTATIONS = {
    SET_DOCUMENT:       'DOCUMENTS_SET_DOCUMENT',
    SET_DOCUMENT_META:  'DOCUMENTS_SET_DOCUMENT_META',
    SET_DIRECTORY:      'DOCUMENTS_SET_DIRECTORY',
    SET_DIRECTORY_DATA: 'DOCUMENTS_SET_DIRECTORY_DATA',

    SET_ATTACHMENT_CODE: 'DOCUMENTS_SET_ATTACHMENT_CODE',
    DELETE_ATTACHMENT_CODE: 'DOCUMENTS_DELETE_ATTACHMENT_CODE',

    CLEAR_STATE: 'DOCUMENTS_CLEAR_STATE',
};

const initialState = {
    documents: {}, // By projectId { [projectId]: { [docID]: doc } }
    directories: {}, // By projectId { [projectId]: { [dirID]: dir } }
    documentMetas: {}, // By projectId { [projectId]: { [docMetaId]: docMeta } }

    /**
     * Is used to keep track of reservered attachment codes, making them available to be freed up when not using them.
     */
    attachmentCodes: {}, // By project { [projectId]: { [attachmentCode]: {...} } }
};

const reducer = (state=initialState, action) => {
    const payload = action.payload;

    switch(action.type) {

        // Appends a document to "documents"
        case MUTATIONS.SET_DOCUMENT: {
            const projectId = action.projectId;
            const projectDocuments = Object.assign({}, state.documents[projectId] || {});

            if(action.merge) {
                projectDocuments[payload.id] = Object.assign({}, projectDocuments[payload.id] || {}, mapDocument(payload));
            } else {
                projectDocuments[payload.id] = mapDocument(payload);
            }


            return {
                ...state,
                documents: {
                    ...state.documents,
                    [projectId]: projectDocuments,
                }
            }
        }

        // Appends a documentMeta to "documentMetas"
        case MUTATIONS.SET_DOCUMENT_META: {
            const projectId = action.projectId;
            const projectDocumentMetas = Object.assign({}, state.documentMetas[projectId] || {});
            projectDocumentMetas[payload.id] = mapDocumentMeta(payload);

            return {
                ...state,
                documentMetas: {
                    ...state.documentMetas,
                    [projectId]: projectDocumentMetas,
                }
            }
        }

        // Appends a directory to "directories"
        case MUTATIONS.SET_DIRECTORY: {
            const projectId = action.projectId;
            const projectDirectories = Object.assign({}, state.directories[projectId] || {});
            
            if(action.merge) {
                const oldDirectory = projectDirectories[payload.id] || {};
                const newDirectory = mapDirectory(payload);
                projectDirectories[payload.id] = {
                    ...(oldDirectory),
                    ...newDirectory,

                    // Make sure "directories" will be preserved on merge
                    directories: oldDirectory.directories && oldDirectory.directories.length > 0 && newDirectory.directories.length === 0 ? 
                        oldDirectory.directories : 
                        newDirectory.directories, 
                }
            } else {
                projectDirectories[payload.id] = mapDirectory(payload);
            }

            return {
                ...state,
                directories: {
                    ...state.directories,
                    [projectId]: projectDirectories,
                }
            }

        }

        // Appends a set of directory data into the "directories" and "metadata"
        /*
            {
                ...rootDirectoryData,
                "subDirectories": [...otherDirectories],
                "files": [...documentMetas]
            }
        */
        case MUTATIONS.SET_DIRECTORY_DATA: {
            const projectId = action.projectId;

            // Map and prepare directories
            const rootDirectory = mapDirectory(action.payload);
            const subDirectories = (action.payload.subDirectories || []).map(dir => mapDirectory(dir));
            let directoriesByKey = [rootDirectory, ...subDirectories]
                .map(dir => ({[dir.id]: dir}))
                .reduce((acc, val) => ({...acc, ...val}), {});
            directoriesByKey = Object.assign({}, (state.directories[projectId] || {}), directoriesByKey); // Merge with existing state

            // Map and prepare document metas
            let docMetasByKey = (action.payload.files || [])
                .map(meta => mapDocumentMeta(meta))
                .map(meta => ({[meta.id]: meta}))
                .reduce((acc, val) => ({...acc, ...val}), {});
            docMetasByKey = Object.assign({}, (state.documentMetas[projectId] || {}), docMetasByKey); // Merge with existing data

            return {
                ...state,
                directories: {
                    ...state.directories,
                    [projectId]: directoriesByKey,
                },
                documentMetas: {
                    ...state.documentMetas,
                    [projectId]: docMetasByKey,
                }
            }
        }

        case MUTATIONS.SET_ATTACHMENT_CODE: {
            const projectId = action.projectId;
            const attachmentCode = action.attachmentCode;
            const reservation = payload;
            const projectAttachmentCodes = Object.assign({}, state.attachmentCodes[projectId] || {}, {[attachmentCode]: reservation})

            return {
                ...state,
                attachmentCodes: {
                    ...state.attachmentCodes,
                    [projectId]: projectAttachmentCodes,
                }
            }
        }

        case MUTATIONS.DELETE_ATTACHMENT_CODE: {
            const projectId = action.projectId;
            const attachmentCode = payload;
            const projectAttachmentCodes = Object.assign({}, state.attachmentCodes[projectId] || {});
            if(projectAttachmentCodes[attachmentCode]) {
                delete projectAttachmentCodes[attachmentCode];
            }

            return {
                ...state,
                attachmentCodes: {
                    ...state.attachmentCodes,
                    [projectId]: projectAttachmentCodes,
                }
            }
        }

        case MUTATIONS.CLEAR_STATE: {
            return initialState;
        }

        default: {
            return state;
        }

    }
};



export default reducer;