import React, { useState } from 'react';

// State imports
import ProjectActions from '~/store/project/actions';

// Components
import FileInput from '~/components/base/FileInput';
import { Button } from '~/components/base/Buttons';
import ShowCriteria from './components/ShowCriteria';


const Step02 = ({onChange, isLoading}) => {

    // States
    const [document, setDocument] = useState(null);

    const [criteria, setCriteria] =  useState({});

    const [credits, setCredits] = useState(0);

    const [childIsLoading, setChildIsLoading] = useState(false);

    const [showCriteria, setShowCriteria] = useState(false);

    const [error, setError] = useState(null);


    const onFileChange = (file)  => {
        setDocument(file);
        if(file){
            upload(file);
        } else {
            setCriteria({});
            setShowCriteria(false);
        }
    }

    const handleCriteriaChange = (criteria, credits) => {
        setCriteria(criteria);
        setCredits(credits);
    }

    const upload = (file) => {
        setChildIsLoading(true);
        ProjectActions.uploadPreanalysis(file)
        .then((result) => {
            ProjectActions.parsePreanalysis(result)
            .then((res) => {
                setError(null);
                setCriteria(res);
                setChildIsLoading(false);
                setShowCriteria(true);
            })
            .catch((error) => {
                setError(error);
                setChildIsLoading(false);
            })
        })
        .catch(error => {
            setError(error);
            setChildIsLoading(false);
        })
    }

    const createProject = () => {
        onChange(criteria);
    }

    return (
			<div
				className="grid-cols-1 justify-center max-w-4xl m-auto mb-12"
				//style={{ height: '200vh' }}
			>
				<div className={`${showCriteria ? 'mb-32': ''} paper`}>
					<h5>Valg av krav</h5>
					<div className="flex justify-center my-2">
						<FileInput
							onChange={(value) => onFileChange(value)}
							value={document}
							disabled={childIsLoading}
							loading={childIsLoading}
							text="Last opp preanalyse"
						/>
					</div>
					{error && !childIsLoading ? (
						<div className="bg-red-400 p-3 mb-3 shadow rounded">
							<p>Kunne ikke oversette filen</p>
						</div>
					) : null}
					{showCriteria && (
						<>
							<ShowCriteria
								criteria={criteria}
								onChange={(criteria, credits) =>
									handleCriteriaChange(criteria, credits)
								}
							/>
							<div className="flex items-center fixed left-0 bottom-0 w-full bg-white p-4 flex-row-reverse p-1 mt-6">
								<Button
									color="primary"
									type="submit"
									disabled={isLoading}
									loading={isLoading}
									onClick={createProject}
                                    >
									Opprett prosjekt
								</Button>
							</div>
						</>
					)}
				</div>
			</div>
		);
}

export default Step02;