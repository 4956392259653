import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './PhasePlanner.module.css';

// Store
import { useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';

// Core components
import LiteDatePicker from '~/components/base/LiteDatePicker';
import { dateToDateString } from '~/utils/date';
import { Button } from '~/components/base/Buttons';

const MIN_DATE = new Date(-8640000000000000);
const MAX_DATE = new Date(8640000000000000);
const END_DATE_INDEX = 7;

const PhasePlanner = ({
    projectId,
    onSuccess,
    onError,
}) => {
    const dispatch = useDispatch();

    /**
     * @type {[Object.<string, Date>, Function]}
     */
    const [phaseDates, setPhaseDates] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const setPhaseDate = (phaseId, date) => {
        setPhaseDates({
            ...phaseDates,
            [phaseId]: date,
        })
    }

    const createProgressPlan = () => {
        const createDate = (date) => new Date(date.getTime() - date.getTimezoneOffset()*60000).toISOString();

        // Format state
        const formattedPhaseObj = Object.entries(phaseDates)
            .filter(([phaseNumber, _]) => phaseNumber !== END_DATE_INDEX)
            .map(([phaseNumber, date]) => {
                const phaseStart = createDate(date);
                return {
                    [`phase_0${phaseNumber}`]: phaseStart,
                }
            })
            .reduce((prev, phaseObj) => ({...prev, ...phaseObj}), {});

        const endDate = createDate(phaseDates[`${END_DATE_INDEX}`]);

        setIsLoading(true);
        ProjectActions.createProgressPlan(projectId, formattedPhaseObj, endDate)(dispatch)
            .then(() => {
                setIsLoading(false);
                onSuccess && onSuccess();
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                onError && onError(err);
            })
    }

    return (
        <div>
            <h4 className='mb-1'>
                Opprettelse av fremdriftsplan
            </h4>
            <div className='text-sm text-gray-600 mb-4'>
                Velg oppstartsdato for hver fase
            </div>
            {
                [1, 2, 3, 4, 5, 6, END_DATE_INDEX]
                .map((phaseNumber) => (
                    <div
                        key={phaseNumber}
                    >
                        <div className={`flex items-center flex-col md:flex-row relative py-1`}>
                            <div className='flex items-center'>
                                <div className={`w-4 h-4 rounded-full bg-gray-200 mr-4 ${styles.content}`} />
                                <div className='font-semibold text-lg'>
                                    {
                                        phaseNumber !== END_DATE_INDEX ?
                                        'Fase '.concat(phaseNumber) :
                                        'Sluttdato'
                                    }
                                </div>
                            </div>
                            <div className='flex-grow' />
                            <div>
                                <LiteDatePicker
                                    variant='text'
                                    date={phaseDates[phaseNumber]}
                                    onChange={(date) => setPhaseDate(phaseNumber, date)}
                                    disabled={phaseNumber !== 1 && !phaseDates[phaseNumber - 1]}
                                    lockDates={
                                        [
                                            [MIN_DATE, phaseDates[phaseNumber - 1] ? new Date(phaseDates[phaseNumber - 1]) : MIN_DATE],
                                            [phaseDates[phaseNumber + 1] ? new Date(phaseDates[phaseNumber+1]) : MAX_DATE, MAX_DATE],
                                        ]
                                    }
                                >
                                    <div
                                        className='ml-2 font-light pointer-events-none text-black'
                                    >
                                        {
                                            phaseDates[phaseNumber] ?
                                                dateToDateString(phaseDates[phaseNumber]) :
                                                'Velg oppstartsdato'
                                        }
                                    </div>
                                </LiteDatePicker>
                            </div>
                        </div>
                        <hr />
                    </div>
                ))
            }

            <div className='mt-6'>
                <Button
                    className='md:ml-auto'
                    disabled={Object.keys(phaseDates).length < 6 || isLoading}
                    loading={isLoading}
                    onClick={createProgressPlan}
                >
                    Lag fremdriftsplan
                </Button>
            </div>
        </div>
    )
}

PhasePlanner.propTypes = {
    projectId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
}

export default PhasePlanner;