import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../FileSystem.module.css';

// State imports
import { useDispatch } from 'react-redux';
import DocumentActions from '~/store/documents/actions';

// Core components
import StatusIcon from '~/components/miscellaneous/StatusIcon';

const FilteItem = ({
    className = '',
    projectId,
    item,
    depth,
}) => {
    const dispatch = useDispatch();

    const downloadFile = () => {
        DocumentActions.downloadFile(projectId, item.document)(dispatch);
    }

    const indent = Math.min(depth, 4);

    return (
        <div className={`${className}  grid grid-cols-3 gap-4 py-1 px-2`}>
            <div className={`${styles[`indent-${indent}`]} font-normal text-sm my-auto uppercase`}>
                { item.metaData && item.metaData.attachmentCode ? 
                    item.metaData.attachmentCode.split("_").join(" ")
                    : ''
                }
            </div>
            <div 
                className='font-normal text-sm my-auto truncate max-w-xs underline cursor-pointer'
                onClick={downloadFile}
            >
                { item.fileName }
            </div>
            <div className='grid justify-end'>
                <StatusIcon 
                    className={`ml-auto ${styles['status-icon']}`} 
                    status={item.status} 
                /> 
            </div>
        </div>
    )
};

FilteItem.propTypes = {
    className: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
}

export default FilteItem;