import {MUTATIONS} from './reducer';
import * as firebase from '../../services/firebase';
import UserSelectors from './selectors';
import ProjectActions from '../project/actions';
import ProjectSelectors from '../project/selectors';
import store from '~/store';
import { member, functions as FUNCTIONS, roles, firebaseStorage } from '~/data/config';
import { auth } from '~/services/firebase';

const setUser = (user) => ({
    type: MUTATIONS.SET_USER,
    payload: user,
});

const setCurrentUserInfo = (user) => ({
    type: MUTATIONS.SET_CURRENT_USER_INFO,
    payload: user,
})

const clearState = (user) => ({
    type: MUTATIONS.CLEAR_STATE,
})

const setMembers = (projectId, members) => ({
    type: MUTATIONS.SET_MEMBERS,
    payload: members,
    projectId: projectId,
});

const signOut = () => {
    firebase.auth.signOut();
}

const createUser = (email, firstName, lastName, password) => {
    return firebase.auth.createUserWithEmailAndPassword(email, password)
        .then(() => {
            const user = {
                firstName: firstName,
                lastName: lastName,
                email: email
            }
            return firebase.functions.httpsCallable(FUNCTIONS.USER_CREATE)({user})
                .catch ( err => {
                    console.log(`error: ${err}`)
                })
        })
}

const editUser = (user) => (dispatch) => {
    return firebase.functions.httpsCallable(FUNCTIONS.USER_EDIT)(user)
        .then((result) => {
            dispatch(setCurrentUserInfo(result.data));
        })
}

const updateFirebaseEmail = (email) => {
    const user = auth.currentUser;
    return user.updateEmail(email)
        .then(() => {
            return user.sendEmailVerification();
        })
}

const updatePassword = () => {
    return auth.sendPasswordResetEmail(auth.currentUser.email);
}

/**
 *
 * @param {String} projectId
 * @param {Object} member
 * @param {Object} member.sections
 */
const editUserAccess = (projectId, members) => (dispatch) => {
    if(members.length === 0) {
        console.error("No members passed")
        return;
    }
    const payload = {
        projectId: projectId,
        members: members.reduce((prev, member)=>(
            {...prev, [member.id]: {
                sections: member.sections,
                role:member.role,
            }}
            ), {})
    };
    const project = ProjectSelectors.getProjectById(projectId)(store.getState());

    return firebase.functions.httpsCallable(FUNCTIONS.USER_ACCESS_EDIT)(payload)
    .then((result) => {
        members.forEach((member) => {
            const oldMember = UserSelectors.getMemberById(member.id, projectId)(store.getState());
            const newMember = {...oldMember, ...member}

            dispatch(setMembers(projectId, [newMember]));
            dispatch(ProjectActions.setProject({
                ...project,
                roles:{
                    ...project.roles,
                    ...member.role?{[member.id]: member.role}:{}
                }
            }));
        })

    })
}

/**
 *
 * @param {String} projectId
 * @param {Object} member
 * @param {Object} member.criteriaResponsible
 */
const editUserResponsibility = (projectId, members) => (dispatch) => {
    const payload = {
        projectId: projectId,
        members: members.reduce((prev, member)=>(
            {...prev, [member.id]: {
                criteriaResponsible: member.criteriaResponsible,
            }}
            ), {})
    };

    return firebase.functions.httpsCallable(FUNCTIONS.USER_ACCESS_EDIT)(payload)
    .then((result) => {
        members.forEach((member) => {
            const oldMember = UserSelectors.getMemberById(member.id, projectId)(store.getState());
            const newMember = {
                ...oldMember,
                ...member,
                criteriaResponsible: {
                    ...(oldMember.criteriaResponsible || {}),
                    ...(member.criteriaResponsible || {}),
                }
            }

            dispatch(setMembers(projectId, [newMember]))
        })

    })
}

const fetchUserInfo = (dispatch) => {
    return firebase.db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then((result) => {
            const user = result.data();
            dispatch(setCurrentUserInfo(user));
            return user;
        })
}

const fetchUserInfoById = (userId) => {
    return firebase.db
        .collection('users')
        .doc(userId)
        .get()
        .then((result) => {
            return result.data();
        })
}

const fetchAllMembersInProject = (projectId) => (dispatch) => {
    return firebase.db
        .collection("projects")
        .doc(projectId)
        .collection("members")
        .get()
        .then((result) =>{
            const members = result.docs.map((memberRef) => ({id: memberRef.id, ...memberRef.data()}));

            dispatch(setMembers(projectId, members))

            return members;
        })
}

const fetchMemberById = (projectId, userId) => (dispatch) => {
    return firebase.db
        .collection("projects")
        .doc(projectId)
        .collection("members")
        .doc(userId)
        .get()
        .then((result) => {
            const member = result.data() || {};
            member.id = userId;
            dispatch(setMembers(projectId, [member]));
            return result;
        })
}

const memberAdd = (projectId, email, role = null, sections = null) => {
    return firebase.functions.httpsCallable('user-memberAdd')({
        projectId: projectId,
        email: email,
        role: role,
        sections: sections
    });
}

const updateProfilePicture = (profilePictureFile) => {
    return firebase.storage
    .ref(`/avatars/${auth.currentUser.uid}`)
    .child('avatar.png')
    .put(profilePictureFile)
    .then((snapshot) => {
        return;
    })
}


export default {
    setUser,
    signOut,
    fetchAllMembersInProject,
    fetchMemberById,
    fetchUserInfo,
    fetchUserInfoById,
    editUser,
    updateFirebaseEmail,
    updatePassword,
    editUserAccess,
    editUserResponsibility,
    createUser,
    clearState,
    memberAdd,
    updateProfilePicture,
}
