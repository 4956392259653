import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import ProjectActions from '~/store/project/actions';

// Page components
import SectionTopBar from '~/components/navigation/SectionTopBar';
import ChatField from './components/ChatField';

const ProjectSectionChatView = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const sectionId = params.sectionId;
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const criteriaMetas = useSelector(ProjectSelectors.getCurCriteriaMetaDataBySectionAsArray(sectionId));
    const docAggregations = useSelector(ProjectSelectors.getCurDocAggregations);
    const userRole = useSelector(ProjectSelectors.getCurProjectRole);

    useEffect(() => {
        dispatch(ProjectActions.setCurCategory(params.categoryId));
        dispatch(ProjectActions.setCurSection(params.sectionId));

        return function cleanup() {
            dispatch(ProjectActions.setCurCategory(null));
            dispatch(ProjectActions.setCurSection(null));
        }
    }, [dispatch, params])

    return (
        <div className='p-3 page'>
            <SectionTopBar projectId={project.id} />
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                <ChatField 
                    projectId={project.id}
                    sectionId={sectionId}
                />
            </div>
        </div>
    )
};

ProjectSectionChatView.propTypes = {

}

export default ProjectSectionChatView;