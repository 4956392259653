import React from 'react';
import PropTypes from 'prop-types';
import { eventTypes as EVENT_TYPES } from '~/data/config';
import timeAgo from '~/utils/date/timeAgo';
import styles from './NotificationItem.module.css';
import { useHistory } from 'react-router';

// State imports
import { useDispatch } from 'react-redux';
import EventAction from '~/store/events/actions';
import UserSelectors from '~/store/user/selectors';

// Components
import Icon from '~/components/base/Icon';
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import NotificationIcon from '~/components/miscellaneous/NotificationIcon';

// Utiity
import notificationPath from '../../../../../../utils/notifications/notficationPath';
import notificationToText from '~/utils/notifications/notificationToText';

/**
 * NotificationItem renders a notification.
 * @param {Object} props
 * @param {import('~/docs').Notification} props.notificationItem 
 */
const NotificationItem = ({
    projectId,
    notificationItem = {},
    onClose,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onClick = () => {
        history.push(notificationPath(projectId, notificationItem))

        EventAction.seenNotification(projectId, notificationItem.id)(dispatch);

        if(onClose) {
            onClose();
        }
    }

    const payload = notificationItem.payload || {};
    const timestamp = new Date(notificationItem.timestamp);
    let notificationText = notificationToText(notificationItem, projectId);


    return (
        <div 
            className={`border-b cursor-pointer hover:bg-gray-200 ${styles.root}`}
            onClick={onClick}
        >
            <div className='py-3 pl-3 pr-1'>
                <div className='flex items-center'>
                    <div className='mr-2'>
                        <NotificationIcon
                            notificationItem={notificationItem}
                        />
                    </div>
                    <div className='text-sm'>
                        { notificationText }
                    </div>
                </div>
                <div className='text-xs font-normal text-gray-600'>
                    {
                        timeAgo(timestamp)
                    }
                </div>
            </div>
            <div className='flex items-center justify-content'>
                {
                    // TODO: Add seen marker here
                    !Boolean(notificationItem.seen) && (
                        <div className='bg-red-500 rounded-full w-2 h-2' />
                    ) 
                }
            </div>
        </div>
    )
};

NotificationItem.propTypes = {

    projectId: PropTypes.string.isRequired,

    /**
     * @type {import('~/store/events/mappers').Notification}
     */
    notificationItem: PropTypes.object.isRequired,

    onClose: PropTypes.func,
}

export default NotificationItem;
