import { statuses as STATUSES } from '~/data/config';

/**
 * @param {String} status
 * @description
 * StatusProperties extracts the corresponding color and icon for a given status
 */
export default (status) => {
    let statusIcon = ""
    let statusColor = ""

    switch(status) {
        case STATUSES.disapproved:
            statusIcon = "times-circle"
            statusColor = "error"
            break; 
        case STATUSES.approved:
            statusIcon = "check-circle"
            statusColor = "success"
            break;
        case STATUSES.pending:
            statusIcon = "hourglass-half"
            statusColor = "pending"
            break;
        default:
            statusIcon = "minus-circle"
            statusColor = "#718096"
    }

    return [statusColor, statusIcon];
}