// Config
import { dateStyles } from '~/data/config';

/**
 * @param {Date|Date<String>} date Date that should be converted to string.
 * @param {String} dateStyle The style of the string.
 * @description Intl.DateTimeFormat is a nice Object that makes it very easy to format Date/Times.
 * @returns Date in string format
 */
const dateToDateString = (date, dateStyle = dateStyles.LONG) => new Intl.DateTimeFormat("no", { dateStyle }).format(new Date(date));


export default dateToDateString;