import React, { useRef, useState, useEffect } from 'react';
import styles from './Collapse.module.css';

const getElementHeight = (el) => {
    if (!el.current) {
        return 'auto';
    }
    return el.current.scrollHeight;
}

const Collapse = ({ visible, children }) => {
    const rootRef = useRef();
    const [heightTimeout, setHeightTimeout] = useState(null);

    useEffect(() => {
        if (visible && rootRef.current) {

            rootRef.current.style.minHeight = `${getElementHeight(rootRef)}px`; // MinHeight is used the content can grow larger if necessary
            rootRef.current.style.display = 'block';

            // To allow nested collapses or height changes, height needs to be set to 'auto', sometime after minHeight is set
            setHeightTimeout(setTimeout(() => {
                if(!rootRef.current) {
                    return;
                }
                rootRef.current.style.height = 'auto';
                rootRef.current.style.overflow = 'visible';
            }, 300))
        } else {
            rootRef.current.style.minHeight = 0;
            rootRef.current.style.height = 0; // height to hide content
            rootRef.current.style.overflow = 'hidden';

            if (heightTimeout) {
                clearTimeout(heightTimeout);
            }
        }

    }, [visible, rootRef])

    return (
        <div
            className={styles.root}
            style={{height: 0, minHeight: 0}}
            ref={rootRef}
        >
            {children}
        </div>
    )
};

export default Collapse;