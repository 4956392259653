/**
 * timeLeft converts time to text in the format of '{X} {time}'.
 * @param {Date} date
 */
export default (date) => {
    const seconds = Math.floor((date - new Date()) / 1000);
    let interval = Math.floor(seconds / 31536000); // One year
  
    if (interval >= 1) {
      return ''.concat(interval + " år");
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return ''.concat(interval + " måneder");
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return ''.concat(interval + " dager");
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return ''.concat(interval + " timer");
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return ''.concat(interval + " minutter");
    }
    return 'mindre enn et minutt';

}