import typeOf from "../type/typeOf"

function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  
  for (const name of propNames) {
    const value = object[name];

    if (value && typeOf(value) === "object") { 
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

export default deepFreeze;