import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ROUTES from '../../ROUTES';
import { checkIfAccessToSection } from '~/utils/access';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import ProjectActions from '~/store/project/actions';
import UserSelectors from '~/store/user/selectors';

// Page components
import SectionTopBar from '~/components/navigation/SectionTopBar';
import CriteriaModule from './components/CriteriaModule';
import DocAggrModule from './components/DocAggrModule';
import SectionWarningModule from './components/SectionWarningModule';
import SectionNoteModule from './components/SectionNoteModule';

const ProjectSectionView = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const sectionId = params.sectionId;

    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const sectionMeta = useSelector(ProjectSelectors.getSectionById(project.id, sectionId)) || {};
    const criteriaMetas = useSelector(ProjectSelectors.getProjectCriteriaMetaDataBySection(sectionId));
    const docAggregations = useSelector(ProjectSelectors.getCurDocAggregations);
    const creditAggregations = useSelector(ProjectSelectors.getCurCreditAggregations);
    const userRole = useSelector(ProjectSelectors.getCurProjectRole);
    const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);


    useEffect(() => {
        if (!checkIfAccessToSection(project, userMemberData, sectionId)) {
            history.push(ROUTES.DASHBOARD);

        } else {
            dispatch(ProjectActions.setCurCategory(params.categoryId));
            dispatch(ProjectActions.setCurSection(params.sectionId));
        }

        return function cleanup() {
            dispatch(ProjectActions.setCurCategory(null));
            dispatch(ProjectActions.setCurSection(null));
        }
    }, [dispatch, params])

    if (!project || !manual) {
        return null;
    }

    const docSectionAggregations = ((docAggregations || {}).sectionAggregations || {})[sectionId];
    const creditSectionAggregations = ((creditAggregations || {}).sectionAggregations || {})[sectionId];

    const manualCriteriaInSection = (manual.sections
        .find(section => section.id === sectionId).assessmentCriteria || []);

    return (
        <div className='p-3 page'>
            <SectionTopBar projectId={project.id} />
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                <DocAggrModule
                    docData={docSectionAggregations}
                    creditData={creditSectionAggregations}
                />
                <SectionWarningModule
                    projectId={project.id}
                    sectionId={sectionId}
                />

                <SectionNoteModule
                    className='col-span-1 lg:col-span-2'
                    sectionId={sectionId}
                    note={sectionMeta.note}
                    noteChangedAt={sectionMeta.noteChangedAt}
                />

                <CriteriaModule
                    className='col-span-1 lg:col-span-2'
                    projectId={project.id}
                    sectionId={sectionId}
                    manualCriteriaInSection={manualCriteriaInSection}
                    criteriaMetaData={criteriaMetas}
                    userRole={userRole}
                    archived={project.archived}
                />
            </div>
        </div>
    )
};

export default ProjectSectionView;