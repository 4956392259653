import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory, Route, Switch } from 'react-router-dom';
import ROUTES from '../../ROUTES';
import styles from './index.module.css';

// Store imports
import { useDispatch, useSelector } from 'react-redux';
import ProjectActions from '../../store/project/actions';
import ProjectSelectors from '../../store/project/selectors';
import UserActions from '~/store/user/actions';

// Components
import Spinner from '~/components/base/Spinner';

// Page components
import ProjectSidebar from './components/ProjectSidebar';
import TopBar from './components/TopBar';
import ProgressModule from './components/ProgressModule';
import DocumentationModule from './components/DocumentationModule';
import WarningModule from './components/WarningModule';
import ArchivedModule from './components/ArchivedModule';

// Sub routes
import ProjectCategoryView from '../projectCategoryView';
import ProjectSectionView from '../projectSectionView';
import ProjectSectionChatView from '../projectSectionChatView';
import ProjectDocumentView from '../projectDocumentView';
import ProjectCriteriaView from '../projectCriteriaView';
import ProjectAddUserView from '../projectAddUserView';
import ProjectEditAccessView from '../projectEditAccessView';
import ProjectSectionEditView from '../projectSectionEditView';
import ProjectNotificationHub from '../projectNotificationHub';
import ProjectManualView from '../projectManualView';
import ProjectSettings from '../projectSettings';
import EditUser from '../editUser';

// Constants
const SIDEBAR_BLACKLIST = {
    [ROUTES.NOTIFICATION_HUB]: true,
}

const ProjectView = ({
    match
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const projectId = params.id;
    const project = useSelector(ProjectSelectors.getProjectById(projectId));
    const manual = useSelector(ProjectSelectors.getCurrentManual);

    // State
    const [isLoading, setIsLoading] = useState(true);

    // Memos
    const currentSubPage = useMemo(() => location.pathname.replace(`/projects/${projectId}`, ""), [location.pathname]);

    // ComponentDidMount
    useEffect(() => {
        ProjectActions.fetchProjectById(projectId)(dispatch)
            .then((project) => {
                return ProjectActions.fetchManualByVersion(dispatch, project.manualVersion);
            })
            .catch(err => {
                // TODO: Fix better UX - unauthorized/404
                console.log("Error:", err);
                console.log("Was not able to fetch project");
                history.replace(ROUTES.DASHBOARD);
            })
            .then((manualRef) => {
                dispatch(ProjectActions.useManual(manualRef.version));

                // Set project as the current project and fetch all necessary meta data
                return ProjectActions.useProject(projectId)(dispatch);
            })
            .then(() => {
                return UserActions.fetchUserInfo(dispatch);
            })
            .catch(() => {
                console.log("error getting userInfo");
            })
            .then(() => {
                // All necessary project data was successfully loaded
                setIsLoading(false);
            })
            .catch(err => {
                // TODO: Fix better UX - was not able to find manual
                console.log(err);
                console.log("Was not able to fetch manual")
                history.replace(ROUTES.DASHBOARD);
            })

    }, [dispatch, history, projectId]);

    if (isLoading) {
        return (
            <div className='absolute-center'>
                <Spinner size='sm' />
            </div>
        )
    }

    return (
        <div className={!(SIDEBAR_BLACKLIST[currentSubPage]) ? styles.root : null} >
            {
                !(SIDEBAR_BLACKLIST[currentSubPage]) &&
                <ProjectSidebar
                    project={project}
                    isLoading={isLoading}
                />
            }
            {
                project && manual &&
                <div className={styles.content}>
                    <div className={styles.main}>
                        <Switch>
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.PROGRESS)} component={ProgressModule} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.DOCUMENTS)} component={ProjectDocumentView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.NOTIFICATION_HUB)} component={ProjectNotificationHub} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.SETTINGS)} component={ProjectSettings} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.MANUAL)} component={ProjectManualView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', ROUTES.MANUAL, '/:sectionId')} component={ProjectManualView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/access')} component={ProjectEditAccessView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/access', '/add')} component={ProjectAddUserView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/:categoryId')} component={ProjectCategoryView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/:categoryId', '/:sectionId')} component={ProjectSectionView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/:categoryId', '/:sectionId', '/edit')} component={ProjectSectionEditView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/:categoryId', '/:sectionId', '/chat')} component={ProjectSectionChatView} />
                            <Route exact path={ROUTES.PROJECT.concat('/:id', '/:categoryId', '/:sectionId', '/:criteriaNumber')} component={ProjectCriteriaView} />
                            <Route render={() => (
                                <div className='p-3 page'>
                                    <TopBar project={project} />
                                    <div className={styles.overview}>
                                        {
                                            project.archived &&
                                            <ArchivedModule
                                                projectId={project.id}
                                            />
                                        }
                                        <ProgressModule preview={true} />
                                        <DocumentationModule />
                                        <WarningModule
                                            projectId={projectId}
                                        />
                                    </div>
                                </div>
                            )} />
                        </Switch>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectView;