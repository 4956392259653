import React from 'react';
import PropTypes from 'prop-types';
import { eventTypes as EVENT_TYPES } from '~/data/config';

// Module components
import CommentContainer from './CommentContainer';
import TimeLineEventContainer from './TimeLineEventContainer';

const TimelineItem = ({
    item
}) => {

    /**
     * @type {Occurrence}
     */
    const occurrence = item || {};
    const eventAction = occurrence.eventAction;
    const mainUser = (occurrence.users || {})[occurrence.createdBy] || {};

    switch (eventAction) {

        case EVENT_TYPES.DOCUMENT_CREATED: {
            return <TimeLineEventContainer
                occurrence={occurrence}
            />
        }

        case EVENT_TYPES.CRITERION_STATUS_CHANGE: {
            return <TimeLineEventContainer
                occurrence={occurrence}
            />
        }

        case EVENT_TYPES.CRITERION_SENT_FOR_REVISION: {
            return <TimeLineEventContainer
                occurrence={occurrence}
            />
        }

        default: {
            return null;
        }
    }
}

TimelineItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default TimelineItem;