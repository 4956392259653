import React from 'react';
import styles from './Spinner.module.css';

const Spinner = ({className, size = 'md', color = 'primary'}) => {

    return (
        <div className={`${className || ''} ${styles.spinner} ${styles[size]} border-${color}`}>
           
        </div>
    )
};

export default Spinner;