import React, { useState, useEffect } from 'react';
import store from './store';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router-dom'

// Services
import AuthService from './services/authService';
import * as firebase from './services/firebase';

// Store imports
import { useSelector } from 'react-redux';
import UserSelectors from './store/user/selectors';

// Project components
import Spinner from '~/components/base/Spinner';

// Routes
import ROUTES from './ROUTES';
import ProjectCreate from './views/projectCreate';
import ProjectView from './views/projectView';
import Dashboard from './views/dashboard';
import Login from './views/login';
import CreateUser from './views/createUser';
import EditUser from './views/editUser';
import ExportView from './views/exportView';
import ProjectExportGiveAccessView from './views/projectExportGiveAccessView';
import NotFound from './views/notFound';


AuthService.initialize();

const PrivateRoute = ({ component, ...rest }) => {
    const isAuthenticated = useSelector(UserSelectors.isLoggedIn);
    const location = useLocation();

    if (isAuthenticated) {
        return <Route {...rest} component={component} />
    } else {
        return <Redirect to={`${ROUTES.LOGIN}?continue=${location.pathname + (location.search || '')}`} />
    }
}

const App = () => {
    const [isReady, setIsReady] = useState(false);

    
    useEffect(() => {
			firebase.auth.onAuthStateChanged((user) => {
				setIsReady(true);
			});
			//console.log('%cHeisann 👋 %cHvis du driver å leker rundt kan det hende at https://www.kvst.no/stillinger er noe for deg 😉', 'color: #319795; font-weight: bold; font-size: 1.5em;', 'font-size: 1.2em; color: #319795;')
			//console.log('%c- kvist devteam 🌱', 'font-size: 1.2em; color: #319795;')
    }, []);

    return (
        <Provider store={store}>
            {
                isReady ?
                    <Router>
                        <Switch>
                            <Route exact path={ROUTES.LOGIN} component={Login} />
                            <Route exact path={ROUTES.CREATEUSER} component={CreateUser} />

                            <PrivateRoute exact path={ROUTES.EXPORT} component={ExportView} />
                            <PrivateRoute exact path={ROUTES.EXPORT.concat(ROUTES.ACCESS, '/:projectId', '/:userId')} component={ProjectExportGiveAccessView} />
                            <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
                            <PrivateRoute exact path={ROUTES.PROJECT_CREATE} component={ProjectCreate} />
                            <PrivateRoute path={ROUTES.PROJECT.concat('/:id')} component={ProjectView} />
                            <PrivateRoute path={ROUTES.USER} component={EditUser} />

                            <Route path="*" component={NotFound} />
                        </Switch>
                    </Router>
                    :
                    <div className='absolute-center'>
                        <Spinner size='sm' />
                    </div>
            }
        </Provider>
    )
}

export default App;
