import React from 'react'
import { eventTypes as EVENT_TYPES } from '~/data/config'

// Components
import Icon from '~/components/base/Icon';
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import Avatar from '~/components/base/Avatar';
import CommentContainer from './CommentContainer';

// Styles
import style from './TimeLineEvent.module.css'

// Utils
import extractStatusText from '~/utils/text/extractStatusText';
import timeAgo from '~/utils/date/timeAgo';


/**
 * This TimeLineEvent is used in:
 * - TimeLineEventContainer
 * @param {Object} props
 * @param {Occurrence} props.occurrence
 */
const TimeLineEvent = ({ occurrence }) => {

    const eventAction = occurrence.eventAction;

    /**
     * @type {OccurenceUser}
     */
    const user = (occurrence.users || {})[occurrence.createdBy] || {};



    /**
     * There are 3 different types of "events" that occur in the timeline:
     * - DOCUMENT_CREATED: User has uploaded files to the auditor
     * - CRITERION_SENT_FOR_REVISION: User has asked auditor for revision
     * - CRITERION_STATUS_CHANGE: Auditor has given feedback to the users documents
     */
    let iconName = '';
    let staticText = '';

    switch (eventAction) {
        case EVENT_TYPES.DOCUMENT_CREATED:
            iconName = 'file-alt'
            staticText = <div><b>{user.firstName}</b> har lastet opp {(occurrence.items || []).length} dokumenter</div>
            break;

        case EVENT_TYPES.CRITERION_SENT_FOR_REVISION:
            iconName = 'eye'
            staticText = <div><b>{user.firstName}</b> har bedt revisor om revidering.</div>

            break;

        case EVENT_TYPES.CRITERION_STATUS_CHANGE:
            iconName = 'book';
            staticText = <div><b>{user.firstName || 'Revisor'}</b> har {extractStatusText(occurrence.status).toLowerCase()} kravet.</div>
            break;
    }


    return (
        <div>
            <div className={`point ${style.line} rounded-md bg-primary-opacity min-h-12 flex flex-col justify-center`}>
                <Avatar 
                    size='md' 
                    className='absolute -ml-12 top-0 mt-1' 
                    userId={occurrence.createdBy} 
                    firstName={user.firstName} 
                    lastName={user.lastName}
                    initials={user.initials}
                    >
                </Avatar>
                <div className="rounded-md flex items-center ml-4 m-1" >
                    <div className="comment-content_text p-2">{staticText}</div>
                    <div className='flex-grow' />

                    <div className="icon-container mr-2 p-1">
                        {
                            eventAction !== EVENT_TYPES.CRITERION_STATUS_CHANGE ?
                                null :
                                <StatusIcon status={occurrence.status} size='lg' />
                        }
                    </div>
                    <div className='mr-2 text-gray-500'>
                        {timeAgo(new Date(occurrence.timestamp || '')).toLowerCase()}
                    </div>
                </div>
                {
                    eventAction === EVENT_TYPES.CRITERION_STATUS_CHANGE && (occurrence.statusNote || '').length > 0 ?
                        <>

                            <div className="icon-container rounded-md m-4 p-4 mt-0 pt-0 pb-0">
                                <CommentContainer occurrence={occurrence} />
                            </div>

                        </>
                        : null
                }
            </div >
        </div>
    )
}

export default TimeLineEvent
