import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import UserSelectors from '~/store/user/selectors';
import { useSelector, useDispatch } from 'react-redux';


// Components
import Icon from '~/components/base/Icon';
import Avatar from '~/components/base/Avatar';
import Spinner from '~/components/base/Spinner';

// Styles
import style from './CommentInputBox.module.css'
import { auth } from '~/services/firebase';


const CommentInputBox = ({
    placeholder = 'Trykk her for å skrive inn meldingen din',
    errorMessage = 'Vi fikk ikke videresendt meldingen din. Vennligst prøv på nytt.',
    avatarSrc,
    onSubmit,
    rows = 6,
    main,
    color = 'light-blue',
    borderWidth = '2',
    disabled,
}) => {
    const [input, setInput] = useState('')
    const [currentlySubmitting, setCurrentlySubmitting] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [buttonOpacity, setButtonOpacity] = useState(false)
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('none');

    const user = useSelector(UserSelectors.getUserInfo);

    
    if(disabled){
        return null;
    }

    /**
     * @author Mathias Picker
     * @description onSubmitPromise is a wrapper that gives the "onSubmit"-function passed as a prop
     * some "super-powers". Without having to create a promise, you can just use the
     * "resolve" and "reject" callback-functions passed down from the promise-wrapping.
     */
    const onSubmitPromise = () => {
        return new Promise((resolve, reject) => {
            try {
                onSubmit({ input, resolve: (data) => resolve(data), reject: (error) => reject(error) })
            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * @param {Event} e - the event from the form
     * @author Mathias Picker
     * @description submitWrapper is a wrapper that handles all the boring stuff when doing submission-stuff on
     * a form. Combined with the onSubmitPromise wrapper, it becomes very easy to pass down a custom onSubmit from
     * the parent of the CommentInputBux.
     */
    const submitWrapper = (e) => {
        e.preventDefault();
        setErrorMessageDisplay('none')

        if (input.length === 0) { return }

        setCurrentlySubmitting(true);

        onSubmitPromise()
            .then(data => {
                setInput('')
            })
            .catch(error => {
                setErrorMessageDisplay('block')
                console.log(error)
            })
            .finally(() => {
                resetInputComponents()
            })
    }


    /**
     * Helper function for resetting the form
     */
    const resetInputComponents = () => {
        setCurrentlySubmitting(false)
        setButtonOpacity(false)
    }

    /**
     * @param {Event} e - the event from the onChange-handler for the <textaera>
     * @description Sets the state of the input
     */
    const handleInputChange = e => {
        setInput(e.target.value)
    }

    /**
     * @description Helper function for expanding the comment-input
     */
    const expandInput = () => {
        setExpanded(true)
    }

    /**
     * @description Function for minimizing the comment-input if the user clicks the "Avbryt"-button.
     * Gives a little prompt asking if you really wanted to cancel your comment if user has written something.
     */
    const cancelInput = () => {
        if (input.length > 0 && !window.confirm("Er du sikker på at du vil avbryte det du har skrevet?")) {
            return
        }
        setInput('')
        setErrorMessageDisplay('none')
        setExpanded(false)
    }

    return (
        <div className={`w-full ${style.commentInputContainer} ${main ? 'mt-20' : ''}`}>
            <Avatar size={main ? 'lg' : 'smd'} className='mr-4' userId =  {auth.currentUser.uid} firstName={user.firstName} lastName={user.lastName} initials={user.initials}>
            </Avatar>
            {
                expanded ?
                    <form onSubmit={(e) => { submitWrapper(e) }} className={`${style.formContainer}`}>
                        <textarea value={input} autoFocus onChange={handleInputChange} rows={rows} className={`${style.formContainerTextaera} border-${color} border-${borderWidth} rounded-md p-2`} placeholder={placeholder} disabled={currentlySubmitting}></textarea>
                        <div className={`${style.buttonContainer}`}>
                            <div style={{ alignSelf: 'center', color: 'red', display: errorMessageDisplay }}>{errorMessage}</div>
                            <button type="button" onClick={cancelInput} className={`${style.buttonCancel} rounded-md ${buttonOpacity && !currentlySubmitting ? 'opacity-50' : ''}`} disabled={currentlySubmitting}>
                                {
                                    currentlySubmitting ?
                                        <Spinner size="xs" /> :
                                        <>Avbryt</>
                                }
                            </button>
                            <button type="submit" onMouseOver={() => { setButtonOpacity(true) }} onMouseLeave={() => { setButtonOpacity(false) }} className={`${style.buttonSubmit} rounded-md`} disabled={currentlySubmitting}>
                                {
                                    currentlySubmitting ?
                                        <Spinner size="xs" /> :
                                        <>Publiser</>
                                }
                            </button>
                        </div>
                    </form >
                    :
                    <div onClick={expandInput} onKeyDown={expandInput} className="dummyInputContainer w-full flex items-center">
                        <input type="text" className={`rounded-md px-2 pt-1 pb-2 h-10 ${style.dummyInput} border-${color} border-${borderWidth}`} placeholder={placeholder} />
                    </div>
            }
        </div >
    )
}

CommentInputBox.propTypes = {
    placeholder: PropTypes.string,
    avatarSrc: PropTypes.string,
    onSubmit: PropTypes.func,
    collumns: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    color: PropTypes.string,
    borderWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default CommentInputBox
