import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../ROUTES';
import styles from './index.module.css';

// Config
import { roles as ROLES } from '~/data/config';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '../../store/project/selectors';
import UserSelectors from '../../store/user/selectors';
import ProjectActions from '../../store/project/actions';

// Project components
import { Button } from '~/components/base/Buttons';

// Page components
import DashboardSidebar from './components/DashboardSidebar';
import project from '~/store/project/reducer';
import Tabs from '~/components/base/Tabs';
import ProjectItem from './components/projectItems/ProjectItem';
import ArchivedProjectItem from './components/projectItems/ArchivedProjectItem';
import ArchivedList from './components/projectItems/ArchivedList';
import { dateToDateString } from '~/utils/date';

const TAB_VALUES = [{value: 0, label: 'Aktive prosjekter'}, {value: 1, label:'Arkiverte prosjekter'}];
const COLUMNS = ['Prosjekt', 'Adresse', 'Dato'];

const Dashboard = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const projects = useSelector(ProjectSelectors.getProjects);
	const archivedProjects = useSelector(ProjectSelectors.getArchivedProjects);
	const isLoggedIn = useSelector(UserSelectors.isLoggedIn);
	const userRole = useSelector(ProjectSelectors.getCurProjectRole);

	console.log(projects);

	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		if (isLoggedIn) {
			ProjectActions.getProjects(dispatch);
		}
	}, [isLoggedIn, dispatch]);

	const createNewProject = () => {
		history.push(ROUTES.PROJECT_CREATE);
	};

	const setTab = (value) => {
		setTabValue(value);
	};

	const goToProject = (id) => {
		history.push(ROUTES.PROJECT.concat('/', id));
	};

	return (
		<div className={styles.root}>
			<div>
				<div className={styles.content}>
					<h2 className="text-2xl md:text-5xl">Dine prosjekter</h2>
					<div className="flex p-3">
						<Tabs
							className="w-full md:w-1/2"
							tabs={TAB_VALUES}
							value={tabValue}
							onChange={setTab}
						/>
					</div>
					{tabValue == 0 ? (
						projects.length > 0 ? (
							projects.map((p) => (
								<ProjectItem
									key={p.id}
									onClick={() => goToProject(p.id)}
									title={p.title}
									certificationType={p.certificationType}
									address={p.address}
								/>
							))
						) : (
							<div className="p-6">
								<h4>Du har ingen prosjekter</h4>
								<h6>Trykk på "Nytt prosjekt"-knappen for å komme igang!</h6>
							</div>
						)
					) : (
						<>
							<ArchivedList className="paper mb-4" columns={COLUMNS}>
								{archivedProjects.map((p) => (
									<ArchivedProjectItem
										key={p.id}
										onClick={() => goToProject(p.id)}
										title={p.title}
										date={dateToDateString(new Date(p.archived || ''))}
										columns={COLUMNS}
										address={p.address}
									/>
								))}
							</ArchivedList>
						</>
					)}
					{
						//userRole === ROLES.owner &&
						tabValue === 0 && (
							<div className="flex items-center">
								<div className="flex-grow" />
								<Button className="w-full md:w-auto" onClick={createNewProject}>
									Nytt prosjekt
								</Button>
							</div>
						)
					}
				</div>
			</div>
			<DashboardSidebar projects={projects} />
		</div>
	);
};

export default Dashboard;
