import typeOf from '~/utils/type/typeOf';


const getOccurrenceState = (state) => state.occurrences;

const getOccurrencesByProject = (projectId) => (state) => getOccurrenceState(state).occurrences[projectId] || {};

const getOccurrencesByStorageLocation = (projectId, storageLocation, order) => (state) => {
    let correctMultiplier = order === 'ascending' ? -1 : 1;

    if (typeOf(order) !== 'string') { correctMultiplier = -1; }


    const projectOccurrences = getOccurrencesByProject(projectId)(state);
    const storageLocationOccurrenceIds = (getOccurrenceState(state).occurrenceIds[projectId] || {})[storageLocation] || {};
    return Object.entries(storageLocationOccurrenceIds)
        .sort(([_, a], [__, b]) => correctMultiplier * ((typeOf(a) === 'string' ? a : '')).localeCompare(b)) // Sort by time
        .map(([id, _]) => projectOccurrences[id]);
}



const getOccurenceById = (projectId, occurrenceId) => (state) => {
    const projectOccurrences = getOccurrencesByProject(projectId)(state);
    return projectOccurrences[occurrenceId]
}

const getOccurrenceByChildId = (projectId, childOccurrenceId) => (state) => {
    const projectOccurrences = getOccurrencesByProject(projectId)(state);
    return Object.values(projectOccurrences).find(occ => (occ.children || {})[childOccurrenceId] !== undefined);
}


export default {
    getOccurrencesByProject,
    getOccurrencesByStorageLocation,
    getOccurenceById,
    getOccurrenceByChildId,
}