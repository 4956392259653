import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ROUTES, { goToProjectSection } from '../../ROUTES';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '../../store/project/selectors';
import ProjectActions from '../../store/project/actions';
import UserSelectors from '~/store/user/selectors';

// Page components
import TopBar from '../projectView/components/TopBar';
import Summary from './components/Summary';
import DocAggrModule from '../projectSectionView/components/DocAggrModule';
import CategoryWarningModule from './components/CategoryWarningModule';
import { checkIfAccessToCategory } from '~/utils/access';

const ProjectCategoryView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const categoryId = params.categoryId;
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const docAggregations = useSelector(ProjectSelectors.getCurDocAggregations);
    const creditAggregations = useSelector(ProjectSelectors.getCurCreditAggregations);
    const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);

    useEffect(() => {
        if(!checkIfAccessToCategory(project, manual, userMemberData, categoryId,)) {
            history.push(ROUTES.DASHBOARD);
        }
        dispatch(ProjectActions.setCurCategory(categoryId))
        dispatch(ProjectActions.setCurSection(null))
        
        return () => {
            dispatch(ProjectActions.setCurCategory(null))
        }

    }, [dispatch, categoryId])

    const goToSection = (sectionId) => {
        history.push(goToProjectSection(project.id, categoryId, sectionId));
    }

    const docCategoryAggregations = ((docAggregations || {}).categoryAggregations || {})[categoryId];
    const creditCategoryAggregations = ((creditAggregations||{}).categoryAggregations || {})[categoryId];

    return (
        <div className='p-3 page'>
            <TopBar />
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                <DocAggrModule
                    creditData={creditCategoryAggregations}
                    docData={docCategoryAggregations}
                />
                <CategoryWarningModule
                    projectId={project.id}
                    categoryId={categoryId}
                />
                <Summary
                    className='col-span-1 lg:col-span-2 overflow-x-scroll' 
                    sections={manual.sections.filter(section => section.category === categoryId && project.scope[section.id])} 
                    onSectionClick={goToSection}
                />
            </div>
        </div>
    )
}

export default ProjectCategoryView;