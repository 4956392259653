import React, { useEffect, useState } from 'react';

// Components
import SectionTopBar from '~/components/navigation/SectionTopBar';
import Manual from './components/Manual';
import TopBar from '../projectView/components/TopBar';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';
import ProjectSelectors from '~/store/project/selectors';

const ProjectManualView = () => {
    const dispatch = useDispatch();
		const project = useSelector(ProjectSelectors.getCurrentProject);
		const [query, setQuery] = useState({});

		useEffect(() => {
			const currentURL = new URL(window.location)
			const query = new URLSearchParams(currentURL.search)

			if(query.get("section")){
					const sectionId = query.get('section');
					const categoryId = sectionId.split('_').slice(0, 1).join();

					dispatch(ProjectActions.setCurSection(sectionId));
					dispatch(ProjectActions.setCurCategory(categoryId));

					setQuery({
						sectionId: sectionId,
						categoryId: categoryId
					})
				}

        return function cleanup() {
            dispatch(ProjectActions.setCurCategory(null));
            dispatch(ProjectActions.setCurSection(null));
        };
    }, [dispatch]);

    return (
			<div className="p-3 page">
				{Object.keys(query).length !== 0 ? (
					query.sectionId && query.categoryId ? (
						<SectionTopBar projectId={project.id} />
					) : null
				) : (
					<TopBar/>
				)}
				<Manual />
			</div>
		);
}

export default ProjectManualView