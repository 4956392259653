import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styles from './DocumentUpload.module.css';
import { v4 as uuidv4 } from 'uuid';

// Store imports
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';
import DocumentActions from '~/store/documents/actions';
import { useSelector, useDispatch } from 'react-redux';

// Core components
import FileInput from '~/components/base/FileInput';
import { Select } from "~/components/base/Select";
import TextArea from '~/components/base/TextArea';
import Checkbox from '~/components/base/Checkbox';
import Spinner from '~/components/base/Spinner';

// Utils
import { Button } from '~/components/base/Buttons';
import { checkIfAccessToSection } from '~/utils/access';


/**
 * DocumentUpload is a module for uploading documents into subCriterias.
 * One can also configure which sections or criteria to filter on using the "uploadConfig":
 *
 * "uploadConfig" = {
 *   sectionId = string,
 *   criterionId? = string,
 *   subCriteria? = object
 * }
 *
 */
const DocumentUpload = forwardRef(({
    className = '',
    projectId,
    uploadConfig = {},
    onClose,
    onDocumentUpload,
    onUploadError,
    newDocOnMount = false,
    disableSelection = false,
}, ref) => {
    const dispatch = useDispatch();
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const criteriaMetaData = useSelector(ProjectSelectors.getCurCriteriaMetaData);
    const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);

    // State
    const [documents, setDocuments] = useState([]);
    const [documentsIsLoading, setDocumentsIsLoading] = useState({}); // { [docId]: Boolean }
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        // On component deconstruction
        return () => {
            // Cancel all unused and reserved attachment-code
            DocumentActions.cancelAllAttachmentCodes(projectId)(dispatch)
            .catch(console.error)
        }
    }, [])

    /**
     * Clears the existing documents and creates a single new
     * document when "newDocOnMount" changes to true.
     */
    useEffect(() => {
        if(newDocOnMount) {
            resetDocuments();
        }
    }, [newDocOnMount])

    /**
     * Clears the existing documents and creates a new document with the given config
     * @param {Object} newDocConfig - A config for prefilling sectionId, criterionId and/or subCriteria-ids for that given document.
     */
    const resetDocuments = (newDocConfig = {}) => {
        addNewDocumentFromCfg([], newDocConfig);
    }

    /**
     * Adds a new document to the documentList with the document-config from props.
     */
    const addNewDocument = () => {
        addNewDocumentFromCfg(documents, uploadConfig);
    }

    /**
     * Adds a new document into a given array of existing documents and applies
     * document-configuration values into that new document.
     * @param {Array<Object>} docs - The existing array of documents to mutate.
     * @param {Object} config - The document-configuration containing sectionId, criterionId, and/or subCriteria-ids as an object.
     */
    const addNewDocumentFromCfg = (docs, config) => {
        const newDocuments = Object.assign([], docs);
        newDocuments.push({
            id: uuidv4(),
            sectionId: config.sectionId || null,
            criterionId: config.criterionId || Object.keys(criteriaMetaData)[0],
            subCriteria: config.subCriteria || {},
        });

        // If there is no SectionId, generate a sectionId based on the criterionId
        if(!newDocuments[0].sectionId && newDocuments[0].criterionId) {
            newDocuments[0].sectionId = newDocuments[0].criterionId.split("_").slice(0, 2).join("_"); // "man_01__1_RANDOMID" => "man_01"
        }

        setDocuments(newDocuments);
    }

    /**
     * onFileChange-handler for a given document by index.
     * @param {File} file - The file to apply to the document - can be null/undefined
     * @param {Number} index - The index of the document in the "documents" state-array
     */
    const onFileChange = (file, index) => {
        const newDocuments = Object.assign([], documents);
        newDocuments[index] = {
            ...newDocuments[index],
            file: file,
        }
        setDocuments(newDocuments);


        setTimeout(() => addAttachmentCodeToDocs(newDocuments[index]), 100);
    }
    /**
     * setCriterionId assigns a criterion to the document
     * @param {String} docCriterionId - the criterionId to be applied to the document
     * @param {Number} index - The index of the document in the "documents" state-array
     */
    const setCriterionId = (docCriterionId, index) => {
        const newDocuments = Object.assign([], documents);

        const oldSectionId = newDocuments[index].sectionId;
        const newSectionId = docCriterionId.split("_").slice(0, 2).join("_"); // "ene_01_1_RANDOM_ID" => "ene_01";

        newDocuments[index] = {
            ...newDocuments[index],
            criterionId: docCriterionId,
            sectionId: newSectionId,
        }

        // If the selected sectionId changed - make sure to reset the selected subCriterias.
        if(newSectionId !== oldSectionId) {
            newDocuments[index].subCriteria = {};
        }
        setDocuments(newDocuments);

        // Has the sectionId changed and the document has already an attachmentCode assigned?
        // Get a new attachmentCode
        if(oldSectionId !== newSectionId && newDocuments[index].attachmentCode) {
            delete newDocuments[index].attachmentCode;
            addAttachmentCodeToDocs(newDocuments[index]);
        }
    }

    /**
     * toggleSubCriterion assigns or removes a subCriterion from the document
     * @param {String} subCriterionId - The subCriterionId to apply or remove from the document
     * @param {Boolean} value - A boolean indicating if the subCriterionId should be applied or removed
     * from the document.
     * @param {Number} index - The index of the document to mutate.
     */
    const toggleSubCriterion = (subCriterionId, value, index) => {
        const newDocuments = Object.assign([], documents);
        newDocuments[index] = {
            ...newDocuments[index],
            subCriteria: {
                ...newDocuments[index].subCriteria,
                [subCriterionId]: true,
            }
        }
        if(!value) {
            delete newDocuments[index].subCriteria[subCriterionId];
        }
        setDocuments(newDocuments);
    }

    /**
     * onNoteChange changes the status note of the document
     * @param {String} note - The note of the document
     * @param {Number} index - The index of the document to mutate.
     */
    const onNoteChange = (note, index) => {
        const newDocuments = Object.assign([], documents);
        newDocuments[index] = {
            ...newDocuments[index],
            note: note || null,
        }
        setDocuments(newDocuments);
    }

    /**
     * addAttachmentCodeToDocs checks if some documents has been assigned a file and an sectionId, but
     * not an attachment-code - and if so, reserves an attachmentCode for those documents.
     */
    const addAttachmentCodeToDocs = (doc) => {
        const docPromises = [];

        // If the document does not have a file or a sectionId - ignore that document.
        if(!doc.file || doc.attachmentCode || !doc.sectionId || documentsIsLoading[doc.id]) {
            return;
        }

        // Set that the document is loading
        const newDocsIsLoading = Object.assign({}, documentsIsLoading, {
            [doc.id]: true,
        });
        setDocumentsIsLoading(newDocsIsLoading);

        DocumentActions.reserveAttachmentCode(projectId, doc.sectionId)(dispatch)
            .then((data) => {
                // Mutate the document with the new attachment-code
                const newDocuments = Object.assign([], documents);
                const index = newDocuments.findIndex(d => d.id === doc.id);
                if(index === -1) {
                    // The document does not exist anymore - just return
                    return doc.id;
                }
                newDocuments[index] = {
                    ...doc,
                    attachmentCode: data.attachmentCode,
                }
                setDocuments(newDocuments);
                return doc.id;
            })
            .finally(() => {
                setDocumentsIsLoading({
                    ...documentsIsLoading,
                    [doc.id]: false,
                });
            })
    }

    /**
     * isDocumentsValid checks if the documents are valid and can be uploaded. They are valid if
     * all the documents as a file, has selected at least one subCriteria and has a note written to them.
     * @returns {Boolean} - A boolean indicating if all documents can be uploaded or not.
     */
    const isDocumentsValid = () => {
        return documents.every(doc => doc.file && Object.keys(doc.subCriteria).length > 0 && doc.note && !documentsIsLoading[doc.id] && doc.attachmentCode);
    }

    /**
     * uploadDocuments upload the documents in the "documents" state-array.
     */
    const uploadDocuments = () => {
        const promises = [];

        const documentsToUpload = documents.filter(doc => doc.file);

        for(let doc of documentsToUpload) {
            promises.push(uploadDocument(doc));
        }

        setIsLoading(true);
        Promise.all(promises)
            .then((result) => {
                if(onDocumentUpload) {
                    onDocumentUpload(result);
                }
            })
            .catch((err) => {
                if(onUploadError) {
                    onUploadError(err);
                }
                console.error(err);
            })
            .finally(() => setIsLoading(false));
    }

    /**
     * uploadDocument uploads a signle document, configures which directory the document should be
     * placed in...etc.
     * @param {Object} doc - The document to upload.
     */
    const uploadDocument = (doc) => {
        // The config for uploading the document inside the correct directory
        let directoryCfgs = [{
            directoryId: uploadConfig.directoryId || (doc.sectionId || -1), // -1 refers to the root directory that technically does not exists
            metaData: {
                sectionId: doc.sectionId,
                criterionId: doc.criterionId || null,
            }
        }];

        // If the document is assigned to at least one subCriteria, push the document
        // to every directory corresponding to the provided subCriteria.
        const subCriterionIds = Object.keys(doc.subCriteria);
        if(subCriterionIds.length > 0) {
            directoryCfgs = [];
            for(let subCriterionId of subCriterionIds) {
                let directoryId = subCriterionId;

                // If a directoryId and a subCriterionId is provided in Props, use that directory for the given subCriterionId;
                if(uploadConfig.directoryId && uploadConfig.subCriterionId === subCriterionId) {
                    directoryId = uploadConfig.directoryId;
                }


                directoryCfgs.push({
                    directoryId: directoryId,
                    metaData: {
                        sectionId: doc.sectionId,
                        criterionId: doc.criterionId || null,
                        subCriterionId: subCriterionId,
                        attachmentCode: doc.attachmentCode,
                    }
                })
            }
        }

        return DocumentActions.uploadDocument(projectId, doc.file, doc.note, directoryCfgs)(dispatch);
    }

    useImperativeHandle(ref, () => ({
        resetDocuments,
    }));

    if(!manual) {
        return null;
    }

    // TODO: Make this fetch from meta data - we have to be dynamic, not fetch everything from the manual
    const criteriaData = manual.sections
        .filter(s => (uploadConfig.sectionId ? uploadConfig.sectionId === s.id : true) && checkIfAccessToSection(project, userMemberData, s.id))
        .map(s => s.assessmentCriteria || [])
        .reduce((acc, val) => ([...acc, ...val]), [])
        .filter(criterion => uploadConfig.criterionId ? uploadConfig.criterionId === criterion.id : true);

    const subCriteriaTitles = {};
    const criteriaSelectData = criteriaData
        .map(s => {
            const title = (s.id || '').split('_') // "man_01_1_criterionId" => "Man 01 - 1"
                .slice(0, 3)
                .map((part, i) => i >= 2 ? `- ${part}` : part)
                .join(" ")
                .toUpperCase();
            subCriteriaTitles[s.id] = title;
            return {value: s.id, label: title}
        });

    return (
        <div className={className}>
            <div style={{minHeight: 280}}>
                {
                    documents.map((doc, index) => (
                        <div className={styles['doc-form']} key={doc.id}>
                            <div className='uppercase mt-2'>
                                {
                                    documentsIsLoading[doc.id] ?
                                    <Spinner size='xs' /> :
                                    (doc.attachmentCode || '').split("_").join(" ")
                                }
                            </div>
                            <div>

                                <div className='flex items-center mb-4'>
                                    <FileInput
                                        onChange={(value) => onFileChange(value, index)}
                                        value={doc.file}
                                        disabled={isLoading}
                                    />

                                </div>
                                {
                                    manual &&
                                    <div>
                                        {
                                            !Boolean(uploadConfig.criterionId) &&
                                            <Select
                                                disabled={documentsIsLoading[doc.id]}
                                                value={doc.criterionId}
                                                onChange={(value) => setCriterionId(value, index)}
                                                data={criteriaSelectData}
                                            />
                                        }
                                        <div className='grid grid-flow-col grid-cols-6 items-center'>
                                            {
                                                ((!disableSelection && doc.criterionId &&
                                                criteriaData.find(c => c.id === doc.criterionId) || {})
                                                .criteria || [])
                                                .map((subCriteria) => (
                                                    <div className='flex align-items-center mx-3' key={subCriteria.id}>
                                                        <Checkbox
                                                            disabled={Boolean((((criteriaMetaData[doc.criterionId] || {}).subCriteria || {})[subCriteria.id] || {}).locked)}
                                                            value={Boolean(doc.subCriteria[subCriteria.id])}
                                                            onChange={(value) => toggleSubCriterion(subCriteria.id, value, index)}
                                                        />
                                                        <div className='font-light ml-2 my-auto'>
                                                            { subCriteria.originalId  }
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                }

                                <div className='font-semibold text-base'>
                                    Samsvarsnotat
                                </div>
                                <TextArea
                                    onChange={(event) => onNoteChange(event.target.value, index)}
                                    disabled={isLoading}
                                />
                            </div>

                        </div>

                    ))
                }
            </div>

            <div className='flex items-center justify-end mt-4'>
                {
                    onClose &&
                    <Button
                        className='mr-2'
                        variant='text'
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Lukk
                    </Button>
                }
                <Button
                    onClick={uploadDocuments}
                    loading={isLoading}
                    disabled={isLoading || !isDocumentsValid()}
                >
                    Last opp
                </Button>
            </div>

        </div>
    )
});

DocumentUpload.propTypes = {
    projectId: PropTypes.string.isRequired,
    uploadConfig: PropTypes.object,
    onClose: PropTypes.func,
    onDocumentUpload: PropTypes.func, // A listener for when the documents has been uploaded
    onUploadError: PropTypes.func,
    newDocOnMount: PropTypes.bool,
    disableSelection: PropTypes.bool,
}

export default DocumentUpload;