import React from 'react';
import styles from './index.module.css';

// Project components
import Table from '~/components/base/Table';

// Data
import SECTIONS_NAMES from '../../../../data/sections.json';

const Summary = ({className = '', sections, onSectionClick}) => {

    const goToSection = (e, sectionId) => {
        e.preventDefault();

        if(onSectionClick) {
            onSectionClick(sectionId);
        }
    }

    return (
        <div className={`${className} ${styles.root} paper`}>
            <h4> Sammendrag </h4>
            <div>
                <p className='body2'>
                    Denne kategorien oppfordrer til spesifikasjon og prosjektering av energieffektive løsninger, systemer og utstyr for
                    bygg som legger til rette for bærekraftig bruk av energi i bygget og bærekraftig ledelse i driften av bygget. Målet
                    med emnene i denne kategorien er å vurdere tiltak for å bedre byggets energieffektivitet, oppfordre til å redusere
                    CO2-utslipp og sikre effektiv ledelse gjennom byggets driftsfase.
                </p>
            </div>
            <div>
                <p>
                    Sammendragstabell for kategorien
                </p>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Emne</Table.HeaderCell>
                            <Table.HeaderCell>Poeng</Table.HeaderCell>
                            <Table.HeaderCell>Poengsammendrag</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            sections &&
                            sections.map(section => (
                                <Table.Row key={section.id}>
                                    <Table.Cell>
                                        <p
                                            className='underline text-primary cursor-pointer' 
                                            onClick={(e) => goToSection(e, section.id)}
                                        >
                                            { section.id.toUpperCase() + ' ' + SECTIONS_NAMES[section.id] }
                                        </p>
                                    </Table.Cell>
                                    <Table.Cell>
                                        { section.maxCredits || 'Ukjent' }
                                    </Table.Cell>
                                    <Table.Cell>
                                        { section.aim }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
};

export default Summary;