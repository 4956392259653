import React from 'react';
import PropTypes from 'prop-types';

// Store imports
import { useSelector } from 'react-redux';
import EventSelectors from '~/store/events/selectors';

// Project components
import WarningModule from '~/components/modules/WarningModule';


const CategoryWarningModule = ({
    projectId,
    categoryId,
}) => {
    const events = useSelector(EventSelectors.getProjectNotifications(projectId))

    return (
        <WarningModule 
            events={events.filter(event => event.categoryId === categoryId)} 
            projectId={projectId}
        />
    )
};

CategoryWarningModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
}


export default CategoryWarningModule;