import React, { useContext } from 'react'
import { eventTypes as EVENT_ACTIONS, roles as ROLES } from '~/data/config';
import { TimelineContext } from '../TimelineModule';

// Styles
import style from './TimeLineEventPoint.module.css';

// Store imports
import DocumentActions from '~/store/documents/actions';
import { useDispatch } from 'react-redux';

//Components
import Icon from '~/components/base/Icon';
import Avatar from '~/components/base/Avatar';

// Module components
import CommentContainer from './CommentContainer';
import RevisionFeedback from './RevisionFeedback';

// Utils
import timeAgo from '~/utils/date/timeAgo';
import DOCUMENT_ICON from './DOCUMENT_ICON_TYPES'


/**
 * This TimeLineEventPoint is used in:
 * - TimeLineEventPointContainer
 * @param {Object} props
 * @param {Occurrence} props.occurrence
 */
const TimeLineEventPoint = ({ occurrence }) => {
    const dispatch = useDispatch();
    const { state } = useContext(TimelineContext);

    /**
     * @type {OccurenceUser}
     */
    const user = (occurrence.users || {})[occurrence.createdBy] || {};
    const userRole = user.role || '';
    const timestamp = occurrence.timestamp;
    const fileName = occurrence.fileName || '';
    const fileNameSplitArray = fileName.split('.') || [];
    const fileNameExtension = fileNameSplitArray[fileNameSplitArray.length - 1] || '';
    const fileIcon = DOCUMENT_ICON(fileNameExtension);
    const attachmentCode = (occurrence.attachmentCode || '').split("_").join(" ").toUpperCase();

    const downloadDocument = (documentId) => {
        if(!documentId) {
            return;
        }

        DocumentActions.downloadFile(state.projectId, documentId)(dispatch);
    }


    return (
        <div className={`point ${style.line}`} id={occurrence.id}>
            <div className={`comment-content rounded-md flex items-center ${userRole !== ROLES.auditor ? 'ml-4' : ''}`} >

                <div className='ml-10'>
                    <Avatar 
                        size='sm' 
                        userId = {occurrence.createdBy}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        initials={user.initials}
                        >
                    </Avatar>
                </div>
                <div 
                    className="documentWrapper ml-2 hover:underline hover:cursor-pointer flex items-center flex-grow"
                    onClick={() => downloadDocument(occurrence.document)}    
                >
                    <Icon icon={fileIcon} size='lg' />
                    <div 
                        className="comment-content_text p-2 text-sm opacity-75"
                    >
                        {attachmentCode ? attachmentCode + ' - ' : ''}  {fileName}
                    </div>
                </div>
                <div className="comment-content_text p-2 ml-6 text-sm opacity-75">{timeAgo(new Date(timestamp))}</div>
            </div>
        </div>
    )
}

export default TimeLineEventPoint
