import React from 'react';
import PropTypes from 'prop-types';

// Core component
import Modal from '~/components/base/Modal';

// Modal components
import UserAccessEdit from '~/components/modules/UserAccessEdit';
import Spinner from '~/components/base/Spinner';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import LogInForm from '~/views/login/components/LogInForm';
import ReAuthenticateForm from '~/components/modules/ReAuthenticateForm/ReAuthenticateForm';

const ReauthenticateModal = ({
    title,
    show,
    onClose,
    onClick,
    onChange,
    isLoading,
    error,
    ...rest
}) => {
    return (
        <Modal
            show={show}
            onBackdropClick={onClose}
            {...rest}
        >
            <>
                <div className='bg-white rounded-lg shadow-md relative'>
                    <ReAuthenticateForm
                        onChange = {onChange}
                        onClick = {onClick}
                        title = {title}
                        error = {error}
                        isLoading = {isLoading}
                    />
                </div>
                <Button
                    className='absolute top-0 right-0 mr-2 mt-2'
                    variant='icon'
                    onClick={onClose}
                >
                    <Icon icon='times' color='gray' />
                </Button>
            </>
        </Modal>
    )
}

export default ReauthenticateModal;