import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ROUTES, { goToProjectSubCriteria } from '~/ROUTES';
import { useHistory } from 'react-router-dom';

// Store imports
import { useDispatch } from 'react-redux';
import OccurrenceActions from '~/store/occurrence/actions';

// Core components
import Collapse from '~/components/base/Collapse';
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import OccurrenceMessageItem from '~/components/miscellaneous/OccurrenceMessageItem';
import CommentInputBox from '~/components/miscellaneous/CommentInputBox/CommentInputBox';

// Utils
import extractStatusText from '~/utils/text/extractStatusText';
import subCriterionIdToText from '~/utils/text/subCriterionIdToText';
import { Button } from '~/components/base/Buttons';
import timeAgo from '~/utils/date/timeAgo';

const RevisionItem = ({
    className,
    projectId,
    sectionId,
    occurrence
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showComments, setShowComments] = useState(false);
    const [numOfMessageToShow, setNumOfMessagesToShow] = useState(3);

    const sectionName = (occurrence.subCriterionId || '').split("_").slice(0, 2).join(" ").toUpperCase();
    const subCriterionNumber = (occurrence.subCriterionId || '').split("_").slice(-1)[0];

    const userBank = occurrence.users || {};
    const messageBank = occurrence.messages || {};
    const messages = (occurrence.messageIds || [])
        .map(messageId => ({ id: messageId, ...messageBank[messageId] }))
        .filter(m => m.message);

    const goToTimeline = () => {
        history.push(goToProjectSubCriteria(
            projectId,
            sectionId.split("_")[0],
            sectionId,
            occurrence.subCriterionId,
        ))
    }

    const submitComment = ({ input, resolve, reject }) => {
        const message = input;
        OccurrenceActions.addCommentOnRevision(
            projectId,
            sectionId,
            occurrence.id,
            message
        )(dispatch)
            .then((data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
        setTimeout(() => setNumOfMessagesToShow(numOfMessageToShow + 1), 100);
    }

    return (
        <div className={`bg-gray-100 rounded border ${className}`}>
            <Collapse
                visible={showComments}
            >
                <div className='p-2'>
                    {
                        messages.length === 0 &&
                        <div className='my-4 font-light text-center'>
                            Ingen kommentarer å vise
                        </div>
                    }
                    {
                        messages
                            .slice(-numOfMessageToShow)
                            .map(message => (
                                <OccurrenceMessageItem
                                    key={message.id}
                                    projectId={projectId}
                                    sectionId={sectionId}
                                    user={{ id: message.userId, ...(userBank[message.userId] || {}) }}
                                    messageObject={message}
                                    usesMessageBank={true}
                                />
                            ))
                    }
                    <div className='mt-4'>
                        <CommentInputBox
                            onSubmit={submitComment}
                            rows='2'
                            color='gray-400'
                            borderWidth='2'
                        />
                    </div>
                </div>
            </Collapse>
            <div className='flex items-center'>
                <Button
                    className='w-full border-r hover:underline'
                    style={{ borderRadius: 0 }}
                    variant='text'
                    tabIndex={0}
                    onClick={() => setShowComments(!showComments)}
                >
                    {showComments ? 'Skjul kommentarer' : `Vis kommentarer (${messages.length})`}
                </Button>
                <Button
                    className='w-48 hover:underline'
                    style={{ borderRadius: 0 }}
                    variant='text'
                    tabIndex={0}
                    color='secondary'
                    onClick={goToTimeline}
                >
                    Gå til tidslinje
                </Button>
            </div>
            <hr />
            <div className='flex items-center p-2'>
                <StatusIcon
                    className='mr-2'
                    status={occurrence.status}
                />
                <div>
                    {
                        'Revisor har ' + extractStatusText(occurrence.status).toLowerCase() + ' '
                    }
                    <strong>
                        {sectionName + ' - Krav ' + subCriterionNumber}
                    </strong>
                </div>
                <div className='flex-grow' />
                <div className='text-gray-400'>
                    {
                        timeAgo(new Date(occurrence.timestamp || '')).toLowerCase()
                    }
                </div>
            </div>
        </div>
    )
};

RevisionItem.propTypes = {
    projectId: PropTypes.string,
    sectionId: PropTypes.string,
    occurrence: PropTypes.object.isRequired,
}

RevisionItem.defaultProps = {
    occurrence: {
        timestamp: new Date(),
        status: 'APPROVED',
        statusNote: 'Ikke bra! ',
        subCriterionId: 'man_01__1'
    }
}

export default RevisionItem;