import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './MemberModule.module.css';

// State imports
import { useSelector, useDispatch } from 'react-redux';
import UserSelectors from '~/store/user/selectors';
import UserActions from '~/store/user/actions';

// Core components
import Popup from '~/components/base/Popup';
import { goToUserEdit, goToUser } from '~/ROUTES';
import { useHistory } from 'react-router';

/**
 * MemberModule displayes the name of the logged in user, will be updated with links to user settings
 */
const MemberModule = ({
    projectId,
    anchorEl,
    isOpen,
    onClose
}) => {
    const history = useHistory();

    const dispatch = useDispatch();

    // Selectors
    const userMemberData = useSelector(UserSelectors.test(projectId));

    // State
    const [isPopUpOpen, setIsPopUpOpen] = useState(isOpen);
    const [openTimeout, setOpenTimeout] = useState(null);

    /**
     * To make a fade-out effect when one closes the module.
     */
    useEffect(() => {
        if (openTimeout) {
            clearTimeout(openTimeout);
        };

        if (isOpen) {
            setIsPopUpOpen(true);
        } else {
            setOpenTimeout(
                setTimeout(() => setIsPopUpOpen(isOpen), 500)
            );
        };
    }, [isOpen]);

    useEffect(() => {
        UserActions.fetchAllMembersInProject(projectId)(dispatch);
    }, [])

    const animClasses = isOpen ? 'opacity-100' : 'opacity-0';

    return (
        <Popup
            className={styles.popup}
            anchorEl={anchorEl}
            isOpen={isPopUpOpen}
            onClickAway={onClose}
            isFixed
            placement='bottom-left'
        >
            <div
                id='member-module-content'
                className={`bg-white shadow-2xl rounded-md transition-opacity duration-500 ease-in-out ${animClasses} ${styles.root}`}
            >
                <div className="p-4 text-center">
                    {userMemberData.firstName} {userMemberData.lastName}
                </div>
                <div className="p-4 text-center cursor-pointer" onClick={() => history.push(goToUserEdit())}>
                    Innstillinger
                </div>
            </div>
        </Popup>
    )
}

MemberModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    anchorEl: PropTypes.instanceOf(Element).isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default MemberModule;