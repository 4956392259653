import ProjectSelectors from '~/store/project/selectors';
import { auth } from '~/services/firebase';
import { roles as ROLES, member as MEMBER } from '~/data/config';
import sortByName from '~/utils/text/sortByName';


const getUserState = (state) => state.user;

const getUserInfo = (state) => {
    return state.user.userInfo;
}

const getMembersByKey = (state) => getUserState(state).members || {};

const getAllMembersInProject = (projectId) => (state) => Object.values(getMembersByKey(state)[projectId] || {});

const getAllMembersWithSectionAccess = (project, sectionId) => (state) => {
    return sortByName(Object.values(
        getAllMembersInProject(project.id)(state)
    )
    .reduce((membs, member) => {
        // Extracts the member that has access to the section
        if([ROLES.auditor, ROLES.owner].includes(project.roles[member.id])) membs.push(member);
        else if(member.sections && [MEMBER.SECTIONS_VALUE.ACCESS, MEMBER.SECTIONS_VALUE.RESPONSIBLE].includes(member.sections[sectionId])) {
            membs.push(member);
        }
        return membs;
    }, []));
}

const getAllMembersWithoutSectionAccess = (project, sectionId) => (state) => {
    return sortByName(Object.values(
        getAllMembersInProject(project.id)(state)
    )
    .reduce((membs, member) => {
        if([ROLES.auditor, ROLES.owner].includes(project.roles[member.id])) return membs;
        else if(!member.sections || !member.sections[sectionId] || member.sections[sectionId] === MEMBER.SECTIONS_VALUE.REVOKED) {
            membs.push(member);
        }
        return membs;
    }, []));
}

const getAllMembersWithSectionResponsibility = (project, sectionId) => (state) => {
    return sortByName(Object.values(
        getAllMembersInProject(project.id)(state)
    ).reduce((membs, member) => {
        for (const [crit, responsible] of Object.entries(member.criteriaResponsible)) {
            if(crit.match(sectionId) && responsible) {
                membs.push(member);
                return membs;
            }
        }
        return membs;
    }, []));
}

const getAllMembersWithResponsibility = (projectId, sectionId) => (state) => {
    const members = Object.values(getAllMembersInProject(projectId)(state));
    let hasResponsibility = {};
    for (const member of members) {
        for (const [crit, responsible] of Object.entries(member.criteriaResponsible)) {
            if(crit.match(sectionId) && responsible) {
                const id = crit.split('_').pop();
                hasResponsibility[id] ? hasResponsibility[id].push(member) : hasResponsibility[id] = [member]
            };
        };
    };
    for (let [key, arr] of Object.entries(hasResponsibility)) {
        hasResponsibility[key] = sortByName(arr);
    };
    return hasResponsibility;
}

const getMemberById = (memberId, projectId) => (state) => (getMembersByKey(state)[projectId] || {})[memberId] || null;

const isLoggedIn = state => state.user.isLoggedIn;

const getCurrentUserMemberData = state => getMemberById(auth.currentUser.uid, (ProjectSelectors.getCurrentProject(state) || {}).id)(state);

const getProfilePictureById = (userId) => (state) => (state.user || {}).profilePictures ? state.user.profilePictures[userId] : null;

const getCurrentProfilePicture = (state) => state.user.profilePicture || null;

const test = (projectId) => getMemberById(auth.currentUser.uid, projectId);

export default {
    getUserInfo,
    isLoggedIn,
    getMembersByKey,
    getMemberById,
    getAllMembersInProject,
    getAllMembersWithSectionAccess,
    getAllMembersWithoutSectionAccess,
    getAllMembersWithSectionResponsibility,
    getAllMembersWithResponsibility,
    getCurrentUserMemberData,
    getProfilePictureById,
    getCurrentProfilePicture,
    test,
};