import React, { useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import UserActions from '~/store/user/actions';
import ProjectSelectors from '~/store/project/selectors';

// Components
import Modal from '~/components/base/Modal';
import Spinner from '~/components/base/Spinner';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';

import SECTIONS from '~/data/sections';
import { member as MEMBER } from '~/data/config'

const RemoveUserModal = (props) => {
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const dispatch = useDispatch();

    // State
    const [isLoading, setIsLoading] = useState(false);
    const [removeStatus, setRemoveStatus] = useState(0);

    const removeUser = () => {
        setIsLoading(true);
        dispatch(UserActions.editUserAccess(project.id, [
            {
                id: props.member.id,
                sections: {
                    [props.sectionId]: MEMBER.SECTIONS_VALUE.REVOKED
                }
            }
        ]))
        .then(() => {
            setRemoveStatus(1)
        })
        .catch(() => {
            setRemoveStatus(2)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <Modal
            show={props.show}
            onBackdropClick={() => {
                props.onClose()
                setRemoveStatus(0)
            }}
        >
            {
                props.member
                ?
                <div className={'paper'}>
                {
                    isLoading
                    ?
                    <Spinner />
                    :
                    <div>
                        {
                            removeStatus !== 0
                            ?
                            <div>
                                {
                                    removeStatus === 1
                                    ?
                                    <div className='text-center'>
                                        <h3>Suksess <Icon icon='check' color='var(--color-success)'/></h3>
                                        {props.member.firstName} {props.member.lastName} sin tilgang til {SECTIONS[props.sectionId]} ble fjernet
                                    </div>
                                    :
                                    <div className='text-center'>
                                        <h3>Feil! <Icon icon='times-circle' color='var(--color-error)'/></h3>
                                        Noe gikk galt og {props.member.firstName} {props.member.lastName} ble ikke fjernet
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <h6 className='text-center'>Fjern bruker fra {SECTIONS[props.sectionId]}</h6>
                                <p className='text-center'>Er du sikker på at du vil fjerne: {props.member.firstName} {props.member.lastName}</p>
                                <div className='flex mt-6'>
                                    <div className='flex-grow'/>
                                    <Button
                                        className='mr-4'
                                        onClick={removeUser}
                                        color='error'
                                    >
                                        Ja
                                    </Button>
                                    <Button
                                        onClick={props.onClose}
                                        variant='outlined'
                                    >
                                        Nei
                                    </Button>
                                    <div className='flex-grow'/>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
                :
                null
            }

        </Modal>
    )
}

export default RemoveUserModal
