import React from 'react';
import PropTypes from 'prop-types';
import { eventTypes as EVENT_TYPES } from '~/data/config';

// Core components
import Icon from '~/components/base/Icon';
import StatusIcon from '../StatusIcon';

const NotificationIcon = ({
    notificationItem
}) => {
    const payload = notificationItem.payload || {};

    switch(notificationItem.eventAction) {

        case EVENT_TYPES.CRITERION_SENT_FOR_REVISION: {
            return <Icon icon='paper-plane' />
        }

        case EVENT_TYPES.CRITERION_STATUS_CHANGE: {
            return <StatusIcon status={payload.status} />
        }

        case EVENT_TYPES.DOCUMENT_CREATED: {
            return <Icon icon='file-alt' />;
        }
        
        case EVENT_TYPES.CRITERION_DEADLINE_CHANGE: {
            return <Icon icon='clock' />;
        }

        default: {
            return <Icon icon='bell' />;
        }
    }

}

NotificationIcon.propTypes = {
    notificationItem: PropTypes.object.isRequired,
}

export default NotificationIcon;