import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ReviewModal.module.css';

// Components
import Modal from '~/components/base/Modal';
import ReviewModule from '~/components/modules/ReviewModule';

/**
 * ReviewModal is a modal that wraps the ReviewModule.
 */
const ReviewModal = ({projectId, subCriterionId, show, onClose, ...rest}) => {
  
    const [key, setKey] = useState('');

    /**
     * Refresh the ReviewModule every time show becomes true.
     */
    useEffect(() => {
        if(show) {
            setKey(new Date().toTimeString()); // For refreshing the review module
        }
    }, [show])

    return (
        <Modal
            {...rest}
            show={show}
        >
            <div className={`${styles.root} paper`}>
                <h4 className='mb-2'>Vurdering av krav</h4>
                <ReviewModule
                    projectId={projectId}
                    refreshKey={key}
                    subCriterionId={subCriterionId}
                    onClose={onClose}
                    {...rest}
                />
            </div>
        </Modal>
    )
};

ReviewModal.propTypes = {
    show: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string,
    onClose: PropTypes.func,
};

export default ReviewModal;