import React, { useState, useEffect } from 'react';
import firebase from 'firebase';


// Components
import { Button } from '~/components/base/Buttons';
import TextField from "~/components/base/TextField";
import Icon from '~/components/base/Icon';

// Store
import { useDispatch, useSelector } from 'react-redux';
import UserActions from '~/store/user/actions';
import UserSelectors from '~/store/user/selectors';
import Spinner from '~/components/base/Spinner';
import SuccessModal, { REQUEST_FAILED, REQUEST_SUCCESS } from '~/views/projectAddUserView/components/SuccessModal';
import ReauthenticateModal from '~/components/modals/ReAuthenticaModal';
import { auth } from '~/services/firebase';

// Utils
import typeOf from '~/utils/type/typeOf';

const errorMessages = {
    InputTooLong: "Det du skrev inn er for langt",
    emailAlreadyInUse: 'Emailen er allerede i bruk',
    invalidEmail: "Skriv inn en gyldig email",
    loginRequired: "Passord kreves for å kunne bytte E-post adresse"
};

const successMessages = {
    verificationEmailSent: "Vi har sendt en verifikasjons E-post til den nye E-post adressen din"
}
const EditUserForm = () => {
    const dispatch = useDispatch();
    const user = useSelector(UserSelectors.getUserInfo);

    const [editedUser, setEditedUser] = useState({ ...user, email: auth.currentUser.email });
    const [userToUpload, setUserToUpload] = useState({});
    const [error, setError] = useState({});
    const [uploadError, setUploadError] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [login, setLogin] = useState({
        email: user.email,
        password: "",
        error: false,
        loggedIn: false,
        loading: false,
    });


    // Constants


    // Functions
    const handleChange = (event) => {
        let value = event.target.value;

        if (event.target.name === "initials") {
            value = value.toUpperCase();
        }

        setEditedUser({
            ...editedUser,
            [event.target.name]: value
        })
    }

    // Signs in the user, updates firebase user if correctly authenticated, as well as calling action for updating user-info in db
    const signIn = () => {
        setLogin({
            ...login,
            loading: true
        })
        firebase.auth()
            .signInWithEmailAndPassword(auth.currentUser.email, login.password)
            .then(() => {
                setLogin({
                    ...login,
                    loading: false
                })
                setShowLoginModal(false);
                setShowSuccessModal(true);
                setUploading(true);
                UserActions.updateFirebaseEmail(userToUpload.email)
                    .then(() => {
                        updateUser(userToUpload);

                        setRequestStatus(REQUEST_SUCCESS);
                    })
                    .catch((error) => {
                        console.log(error.code);
                        // email update fails due to invalid email
                        const errorMessage = error.code === 'auth/email-already-in-use' ?
                            errorMessages.emailAlreadyInUse : errorMessages.invalidEmail;
                        setUploadError(errorMessage);
                        setRequestStatus(REQUEST_FAILED);
                        setUploading(false);
                        setShowLoginModal(false);
                    })
            })
            .catch(err => {
                setLogin({
                    ...login,
                    error: true
                })
            })
    }

    // Handles login input for reauthentication modal
    const handleLogin = (event) => {
        const value = event.target.value;
        setLogin({
            ...login,
            error: false,
            [event.target.name]: value
        })
    }


    const fetchUser = () => {
        setIsLoading(true);
        UserActions.fetchUserInfo(dispatch)
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    // fetch user if not in store
    useEffect(() => {
        if (!user) {
            fetchUser();
        }
    }, [user])

    // Checks if any value is empty or if editeduser is equal to user in db
    const disableSaveButton = () => {
        return (Object.values(editedUser).some((value) => {
            return (value === '');
        })
            || (Object.entries(editedUser)
                .every(([field, value]) => {
                    return user[field] === value
                }))
        )
    }

    const saveChanges = () => {

        setUploadError(null);
        if (!editedUser || (typeOf(editedUser) === 'object' && Object.keys(editedUser).length === 0)) {
            return;
        }
        setError({});

        // Prepare object and check for errors in input
        let tempError = null;
        const userToUpload = Object.entries(editedUser)
            .reduce((prev, [field, value]) => {
                if (value.length > 100) {
                    ;
                    tempError = {
                        ...tempError,
                        [field]: errorMessages.InputTooLong
                    }
                }

                return value.length > 0 && value !== user[field] ? {
                    ...prev,
                    [field]: value
                }
                    : prev
            }, {})
        if (userToUpload.email && !userToUpload.email.includes('@')) {
            tempError = {
                ...tempError,
                email: errorMessages.invalidEmail
            };
        }
        if (tempError) {
            setError(tempError);
            return;
        }

        //sets the object to upload, (only includes actually updated fields)
        setUserToUpload(userToUpload);
        if (userToUpload.email) {
            setShowLoginModal(true);
        } else {
            updateUser(userToUpload);
        }
    }

    // update user in db
    const updateUser = (userToUpload) => {
        setUploading(true);
        UserActions.editUser(userToUpload)(dispatch)
            .catch((error) => {
                setUploadError(error);
            })
            .finally(() => {
                setUploading(false);
            })
    }

    if (isLoading || !user) {
        return (
            <div className='absolute-center'>
                <Spinner size='sm' />
            </div>
        )
    }

    return (
        <div className=''>
            <form>
                <TextField
                    label='Fornavn'
                    type='text'
                    icon='user'
                    error={Boolean(error.firstName)}
                    hint={error.firstName}
                    name='firstName'
                    onChange={handleChange}
                    value={editedUser.firstName}
                    placeholder='Navn'
                    required
                />
                <TextField
                    label='Etternavn'
                    type='text'
                    icon='user'
                    name='lastName'
                    error={Boolean(error['lastName'])}
                    hint={error.lastName}
                    onChange={handleChange}
                    value={editedUser.lastName}
                    placeholder='Navnesen'
                    required
                />

                <TextField
                    label='Email'
                    type="email"
                    icon="user"
                    name="email"
                    error={Boolean(error['email'])}
                    hint={error.email}
                    onChange={handleChange}
                    value={editedUser.email}
                    placeholder='E-post adresse'
                    required
                />

                <TextField
                    label='Initialer'
                    type="text"
                    icon="user"
                    name="initials"
                    error={Boolean(error['initials'])}
                    hint={error['initials']}
                    value={editedUser.initials}
                    placeholder='NN'
                    onChange={handleChange}
                    maxLength={2}
                    required
                />

                <TextField
                    label='Telefonnummer'
                    type="number"
                    icon="user"
                    name="number"
                    error={Boolean(error['number'])}
                    hint={error['number']}
                    value={editedUser.number}
                    placeholder='00000000'
                    onChange={handleChange}
                    required
                />
                {
                    uploadError && !uploading ?
                        <div className='bg-red-400 p-3 mb-3 shadow rounded'>
                            <p>Kunne ikke lagre endringene</p>
                        </div> : null
                }
                <div className='p-2'>
                    <Button
                        loading={uploading}
                        disabled={uploading || disableSaveButton()}
                        onClick={saveChanges}
                        type='button'
                    >
                        <div className='p-1'>
                            <Icon icon='save' />
                        </div>
                        Lagre endringer
                    </Button>
                </div>
            </form>
            <SuccessModal
                show={showSuccessModal}
                status={requestStatus}
                onClose={() => setShowSuccessModal(false)}
                isLoading={uploading}
                errorMessage={uploadError}
                successMessage={successMessages.verificationEmailSent}
            />
            <ReauthenticateModal
                show={showLoginModal}
                title={errorMessages.loginRequired}
                onChange={handleLogin}
                isLoading={login.loading}
                onClick={signIn}
                error={login.error}
                onClose={() => setShowLoginModal(false)}
            />
        </div>
    )
}

export default EditUserForm;