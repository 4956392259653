// Config
import { deadlineStatuses } from '~/data/config';

function setIconColor(deadlineStatus) {
    switch (deadlineStatus.status) {
        case deadlineStatuses.active:
            return 'orange';
        case deadlineStatuses.overdue:
            return '#E53E3E'; // Color is 'error', but for some reason using the custom tailwind color doesn't work :/
        default:
            return 'gray';
    }
}

export default setIconColor;