import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.css';

/**
 * Select is a normal dropdown component
 */
const Select = ({
    className = '', 
    color = 'primary', 
    value, 
    onChange, 
    placeholder, 
    data = [],
    disabled = false,
    margin = true,
}) => {
    const rootRef = useRef(null);
    const optionRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isInFocus, setIsInFocus] = useState(false);
    const [curElem, setCurElem] = useState(null);

    const openSelect = (shouldOpen) => {
        if(disabled) {
            return;
        }
        setIsOpen(shouldOpen)
    }

    const handleChange = (value) => {
        onChange && onChange(value);
        setIsOpen(false);
    }

    const onFocus = () => {
        setIsInFocus(true);
    }
    
    const onBlur = () => {
        setIsInFocus(false);
    }

    useEffect(() => {
        const elem = (data || []).find(e => e.value === value);
        setCurElem(elem);
    }, [value, data])

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (optionRef.current && !optionRef.current.contains(event.target)) {
                if(!rootRef.current || (rootRef.current && !rootRef.current.contains(event.target))) {
                    setIsOpen(false);
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [optionRef]);

    useEffect(() => {
        if(disabled) {
            setIsOpen(false);
        }
    }, [disabled])

    const focusStyling = isInFocus && !disabled ? `focus:border-${color}` : '';
    const isOpenStyling = `border-${isOpen ? color : 'gray-400'}`
    const disabledStyling = disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer';

    return (
        <div className={`relative w-full ${margin ? 'mb-6' : ''} ${className} `}>

            <div
                ref={rootRef}
                className={`${styles.select} w-full pl-3 pr-1 py-1 bg-white rounded border border-solid ${isOpenStyling} focus:outline-none ${focusStyling}  flex items-center ${disabledStyling}`}
                onClick={() => openSelect(!isOpen)}
                value={value || ''}
                tabIndex={0}
                onFocus={onFocus}
                onBlur={onBlur}
            >
                <div className={curElem ? '':  `text-gray-600`}>
                    { curElem ? curElem.label : placeholder || ''}
                </div>
                <div className='flex-grow' />
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            </div>
            {
                isOpen && data && 
                <div className={`${styles.content} absolute w-full bg-white border border-solid border-gray-400`} ref={optionRef}>
                    {
                        data.map(d => (
                            <div
                                key={d.value}
                                onClick={() => handleChange(d.value)}
                                className={`px-3 py-3 hover:bg-gray-200 cursor-pointer`}
                            >
                                {d.label}
                                
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
};

Select.propTypes = {
    // The value of the selected item
    value: PropTypes.any,

    // Data is an array of label-value data - { label: ..., value: ... }
    data: PropTypes.array,

    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    margin: PropTypes.bool,
}

export default Select;