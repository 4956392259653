import React, { useState, useEffect, useMemo} from 'react';

// Store imports
import {useSelector, useStore, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import Spinner from '~/components/base/Spinner';
import Checkbox from '~/components/base/Checkbox';
import Icon from '~/components/base/Icon';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import sectionsToCategoryByKey from '~/utils/category/sectionsToCategoryByKey';

// Data
import {member as MEMBER} from '~/data/config';

// Constants
const REVOKED = MEMBER.SECTIONS_VALUE.REVOKED;
const ACCESS = MEMBER.SECTIONS_VALUE.ACCESS;
const RESPONSIBILITY = MEMBER.SECTIONS_VALUE.RESPONSIBLE;

const UserCategoryAccessEdit = ({
    member,
    categoryId,
    onChange,
})=>{
	const project = useSelector(ProjectSelectors.getCurrentProject);
	const sections = useSelector(ProjectSelectors.getCurrentSectionMetas);
	const manual = useSelector(ProjectSelectors.getCurrentManual);

	//States
	const [oldMember, setOldMember] = useState(null);
	const [openSectionId, setOpenSectionId] = useState(null);

	useEffect(() => {
		setOldMember(member);
	}, [member.id]);

	const categoriesByKey = useMemo(() => {
		if (!project) {
			return {};
		}

		return sectionsToCategoryByKey(Object.keys(project.scope || {}));
	}, [project]);

	const categoriesLabelValue = useMemo(() => {
		return Object.entries(categoriesByKey).map(
			([categoryId, categoryName]) => ({
				value: categoryId,
				label: categoryName,
			})
		);
	}, [categoriesByKey]);

	const checkAllSectionsToggled = () => {
		return Object.values(sections)
			.filter((section) => section.id.split('_').slice(0, 1)[0] === categoryId)
			.every(
				(section) =>
					member.sections[section.id] === ACCESS ||
					member.sections[section.id] === RESPONSIBILITY
			);
	};

	const toggleCategoryValue = (value) => {
		const tempMember = {
			...member,
			sections: {
				...member.sections,
				...Object.values(sections)
					.filter(
						(section) => section.id.split('_').slice(0, 1)[0] === categoryId
					)
					.reduce((prev, section) => {
						return { ...prev, [section.id]: value ? ACCESS : REVOKED };
					}, {}),
			},
		};
		onChange(tempMember);
	};

	/**
	 *
	 * @param {String} sectionId
	 * @param {boolean} accessValue
	 */
	const toggleSectionValue = (sectionId, accessValue) => {
		const tempMember = {
			...member,
			sections: {
				...member.sections,
				[sectionId]: accessValue ? ACCESS : REVOKED,
			},
		};

		onChange(tempMember);
	};

	if (!member || !oldMember || !categoryId) {
		return null;
	}

	return (
		<div className="mb-6">
			<Icon className="mr-2" icon="universal-access" />
			<b>Tilgang</b>
			<div className="border-b-2 border-black p-2">
				<div className="flex items-center hover:bg-gray-400 flex-grow">
					<Checkbox
						className="cursor-pointer"
						value={checkAllSectionsToggled()}
						onChange={(value) => toggleCategoryValue(value)}
					/>
					<div>
						<b>Alle emner</b>
					</div>
				</div>
			</div>
			{Object.values(sections)
				.filter(
					(section) => section.id.split('_').slice(0, 1)[0] === categoryId
				)
				.map((section) => (
					<div key={section.id} className="border-b border-black p-2">
						<div className="flex items-center hover:bg-gray-400 flex-grow">
							<Checkbox
								className="cursor-pointer"
								value={
									(member.sections || {})[section.id] === ACCESS ||
									(member.sections || {})[section.id] === RESPONSIBILITY
								}
								onChange={(value) => toggleSectionValue(section.id, value)}
							/>
							<div
								onClick={
									(member.sections || {})[section.id] === ACCESS ||
									(member.sections || {})[section.id] === RESPONSIBILITY
										? () =>
												setOpenSectionId(
													openSectionId === section.id ? null : section.id
												)
										: null
								}
							>
								{sectionIdToText(section.id)}
							</div>
							{(oldMember.sections || {})[section.id] !==
								(member.sections || {})[section.id] && (
								<div className="bg-red-400 w-2 h-2 rounded-full ml-2" />
							)}
						</div>
					</div>
				))}
		</div>
	);
}

export default UserCategoryAccessEdit;
