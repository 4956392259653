const ROUTES = {
    DASHBOARD: '/',
    CREATEUSER: '/sign-up',
    LOGIN: '/sign-in',

    USER: '/user',

    SETTINGS: '/settings',

    EDIT: '/edit',

    PROJECT: '/projects',

    PROJECT_CREATE: '/projects/create',

    PROGRESS: '/progress',

    DOCUMENTS: '/documents',

    ACCESS: '/access',

    ADDTOPROJECT: '/add',
    NOTIFICATION_HUB: '/notifications',

    EDITACCESS: '/edit',

    MANUAL: '/manual',

    EXPORT: '/export',
};
export default ROUTES;

export const goToDashboard = () => ROUTES.DASHBOARD;
export const goToProjectDashboard = (projectId) => ROUTES.PROJECT.concat(`/${projectId}`)
export const goToProjectCategory = (projectId, category) => ROUTES.PROJECT.concat(`/${projectId}/${category}`);
export const goToProjectSection = (projectId, category, section) => ROUTES.PROJECT.concat(`/${projectId}/${category}/${section}`);
export const goToProjectSectionEdit = (projectId, category, section) => ROUTES.PROJECT.concat(`/${projectId}/${category}/${section}${ROUTES.EDITACCESS}`);
export const goToProjectDocuments = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.DOCUMENTS}`);
export const goToProjectCriteria = (projectId, category, section, criterionNumber) =>
    ROUTES.PROJECT.concat(`/${projectId}/${category}/${section}/${criterionNumber}`);
export const goToProjectSectionChat = (projectId, category, section) => goToProjectSection(projectId, category, section).concat('/chat');
export const goToProjectSubCriteria = (projectId, category, section, subCriterionId) =>
    goToProjectSection(projectId, category, section).concat(`/${subCriterionId.split("_").slice(-1)[0]}`);
export const goToProjectAccess = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.ACCESS}`);
export const goToAddUserToProject = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.ACCESS}${ROUTES.ADDTOPROJECT}`);
export const goToProjectEditAccess = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.ACCESS}${ROUTES.EDITACCESS}`);
export const goToProjectManual = (projectId, category, section) => section ? ROUTES.PROJECT.concat(`/${projectId}${ROUTES.MANUAL}?section=${section}`) : ROUTES.PROJECT.concat(`/${projectId}${ROUTES.MANUAL}`);
export const goToProjectNotificationHub = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.NOTIFICATION_HUB}`);
export const goToProjectProgress = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.PROGRESS}`);
export const goToUser = () => ROUTES.USER;
export const goToUserEdit = () => ROUTES.USER.concat(`${ROUTES.EDIT}`);
export const goToUserSettings = () => ROUTES.USER.concat(`${ROUTES.SETTINGS}`);
export const goToProjectSettings = (projectId) => ROUTES.PROJECT.concat(`/${projectId}${ROUTES.SETTINGS}`);