import React from 'react';
import styles from '../../index.module.css';
import PropTypes from 'prop-types';

const ArchivedProjectItem = ({onClick, address, title, date , columns = [], className =''}) => {

    return (
        <div
            onClick={onClick} 
            className={`${className} ${styles.hoverContent} py-3 cursor-pointer border-b grid grid-cols-${columns.length}`} 
        >
            <div>
                {title}
            </div>
            <div>
                {address}
            </div>
            <div>
                {date}
            </div>
        </div>
    )
}

ArchivedProjectItem.propTypes = {
    onClick: PropTypes.func,
    addres: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    columns: PropTypes.array.isRequired,
    className: PropTypes.string,
}

export default ArchivedProjectItem;