import React, { useState, useRef } from 'react';
import { auth } from '~/services/firebase';
import PropTypes from 'prop-types';
import { reactions as REACTIONS, roles as ROLES } from '~/data/config';

// Styles
import styles from './MessageItem.module.css';

// Core components
import Avatar from '~/components/base/Avatar';
import Icon from '~/components/base/Icon';
import { Button } from '~/components/base/Buttons';
import TextArea from '~/components/base/TextArea';
import ReactionTooltip from '~/components/miscellaneous/ReactionTooltip';

// Utils
import timeAgo from '~/utils/date/timeAgo';
import roleToText from '~/utils/text/roleToText';
import cleanHTMLFrom from '~/utils/html/cleanHTML';

const MessageItem = ({
    message,
    timestamp,
    user = {},
    reactions = {},
    edited = false,
    onEdit,
    onReaction,
    isLoading = false,
    isError = false,
}) => {
    const reactionBtn = useRef(null);

    const [editCfg, setEditCfg] = useState({
        isEdit: false,
        message: null,
        isLoading: false,
    });
    const [showReactions, setShowReactions] = useState(false);
    const [reactionIsLoading, setReactionIsLoading] = useState({});

    const toggleEditMode = () => {
        setEditCfg({
            isEdit: !editCfg.isEdit,
            message: editCfg.isEdit ? null : message,
            isLoading: false,
        })
    }

    const onEditMessageChange = (event) => {
        setEditCfg({
            ...editCfg,
            message: event.target.value,
            isLoading: false,
        })
    }

    const onMessageEdit = () => {
        if (onEdit) {
            setEditCfg({
                ...editCfg,
                isLoading: true,
            })
            Promise.all([onEdit(editCfg.message)])
                .finally(() => {
                    toggleEditMode();
                })
        }
    }

    const onReactionEdit = (reaction) => {
        if (onReaction) {
            setReactionIsLoading((prev) => ({
                ...prev,
                [reaction]: true,
            }))
            Promise.all([onReaction(reaction)])
                .finally(() => {
                    setReactionIsLoading((prev) => ({
                        ...prev,
                        [reaction]: false,
                    }))
                });
        }
        setShowReactions(false);
    }

    const toggleReactionLoading = (reaction) => {
        setReactionIsLoading({
            ...reactionIsLoading,
            [reaction]: !Boolean(reactionIsLoading[reaction]),
        })
    }

    return (
        <div
            className={`${styles.root} ${isLoading ? styles.opacity : ''}`}

        >
            <ReactionTooltip
                anchorEl={reactionBtn.current}
                isOpen={showReactions}
                onClose={() => setShowReactions(false)}
                onSelect={onReactionEdit}
                placement='top-right'
            />

            <div>
                <Avatar
                    className='m-auto mt-1'
                    size='sm'
                    userId={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    initials={user.initials}
                />
            </div>
            <div>
                <div className='flex'>
                    <div className='font-bold mr-4'>
                        {user.firstName}
                    </div>
                    {
                        (user.role === ROLES.owner || user.role === ROLES.auditor) &&
                        <div className='px-1 border rounded mr-4 text-sm h-min-content text-gray-600'>
                            {roleToText(user.role)}
                        </div>
                    }
                    <div className='text-gray-600'>
                        {timeAgo(new Date(timestamp || '')).toLowerCase()}
                    </div>
                    {
                        edited &&
                        <span className='ml-2 text-gray-400'>
                            (redigert)
                        </span>
                    }
                    <div className='flex-grow' />
                    <Button
                        variant='icon'
                        color='gray-800'
                        ref={reactionBtn}
                        onClick={() => setShowReactions(!showReactions)}
                        disabled={editCfg.isLoading}
                    >
                        <Icon
                            icon='smile'
                        />
                    </Button>
                    {
                        auth.currentUser.uid === user.id &&
                        <Button
                            variant='icon'
                            color='gray-800'
                            onClick={toggleEditMode}
                            disabled={editCfg.isLoading}
                        >
                            <Icon
                                icon='edit'
                            />
                        </Button>
                    }
                </div>
                <div className='py-2 pr-2 mb-2'>
                    {
                        !editCfg.isEdit ?
                            <div>
                                <div dangerouslySetInnerHTML={cleanHTMLFrom(message)} className={styles.messageItem} />

                                <div className={`mt-2 ${styles.reactions}`}>
                                    {
                                        Object.entries(reactions || {})
                                            .filter(([reactionType, metaData]) => metaData.count > 0)
                                            .sort((a, b) => -a[0].localeCompare(b[0]))
                                            .map(([reactionType, metaData = {}]) => (
                                                <Button
                                                    className={`
                                                    p-2 w-14 h-8
                                                    ${Object.keys(metaData.users || {}).some(userId => userId === auth.currentUser.uid) ?
                                                            'border border-blue-200' :
                                                            ''
                                                        }
                                                `}
                                                    variant='text'
                                                    key={reactionType}
                                                    onClick={() => onReactionEdit(reactionType)}
                                                    disabled={reactionIsLoading[reactionType]}
                                                >
                                                    {
                                                        REACTIONS[reactionType] || ''
                                                    }
                                                    <div className='ml-2 text-black font-light text-sm'>
                                                        {
                                                            metaData.count || 0
                                                        }
                                                    </div>
                                                </Button>
                                            ))
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <TextArea
                                    value={editCfg.message}
                                    onChange={onEditMessageChange}
                                />
                                <div className='flex flex-row-reverse'>
                                    <Button
                                        disableElevation
                                        disabled={editCfg.message.length === 0 || editCfg.isLoading}
                                        loading={editCfg.isLoading}
                                        onClick={onMessageEdit}
                                    >
                                        Oppdater
                                    </Button>
                                    <Button
                                        className='mr-4'
                                        variant='text'
                                        color='secondary'
                                        disabled={editCfg.isLoading}
                                        onClick={toggleEditMode}
                                    >
                                        Avbryt
                                    </Button>
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                isLoading &&
                <div className='absolute inset-0 w-full' />
            }
        </div>
    )
};

MessageItem.propTypes = {
    message: PropTypes.string,
    timestamp: PropTypes.string,
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    onEdit: PropTypes.func,
    onReaction: PropTypes.func,
    isLoading: PropTypes.bool,
}

MessageItem.defaultProps = {
    message: '',
    timestamp: new Date().toISOString(),
    user: {},
    reactions: {}
}

export default MessageItem;