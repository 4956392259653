import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Project components
import Icon from '~/components/base/Icon';
import EventList from '~/components/modules/WarningModule/components/EventList';

// Routes
import { goToProjectNotificationHub } from '~/ROUTES';



const WarningModule = ({
    className = '',
    events,
    dense = false,
    projectId,
}) => {

    return (
			<div className={`${className} ${dense ? '' : 'paper'}`}>
				{!dense && (
					<div className="flex items-center">
						<Link
							to={goToProjectNotificationHub(projectId)}
							className="hover:underline"
						>
							<h5>
								<Icon icon="bell" size="1x" className="mr-3" />
								Varsler
							</h5>
						</Link>
					</div>
				)}
				{events.length > 0 ? (
					<EventList events={events} projectId={projectId} />
				) : (
					<div
						className="w-full h-full grid items-center justify-center"
						style={{ maxHeight: 200 }}
					>
						<h6 className="text-center">Ingen varsler enda</h6>
					</div>
				)}
			</div>
		);
};

WarningModule.propTypes = {
    events: PropTypes.array.isRequired,
    dense: PropTypes.bool,
}

export default WarningModule;