/**
 * @typedef {Object} Notification 
 * @property {String} id
 * @property {String} timestamp
 * @property {String} seen
 * 
 * @property {String} categoryId
 * @property {String} eventAction
 * @property {String} eventFamily
 * @property {Object} payload
 * @property {String} sectionId
 * @property {String} user 
 */

 /**
  * @param {Object} notification - The notification 
  * @returns {Notification} 
  */
export const mapNotification = (notification) => {
    return {
        id: notification.id,
        timestamp: notification.timestamp,
        seen: notification.seen,

        categoryId: notification.categoryId,
        eventAction: notification.eventAction,
        eventFamily: notification.eventFamily,
        payload: notification.payload || {},
        sectionId: notification.sectionId,
        user: notification.user,
    }
};

export const mapNotificationCount = (notificationCounts) => ({
    total: notificationCounts.TOTAL,
    documents: notificationCounts.DOCUMENTS,
    criterion: notificationCounts.CRITERION,
})