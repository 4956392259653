export const statusColors = {
    '0': 'white',
    '1': 'white-yellow',
    '2': 'yellow',
    '3': 'yellow-orange',
    '4': 'orange',
    '5': 'orange-red',
    '6': 'red',
    '7': 'red-black',
    '8': 'black'
}
