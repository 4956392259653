import React from 'react';

// External components
import { ResponsivePie } from '@nivo/pie';

const colors = {"Uten vurdering": "#F6F6F6", "Underkjent": "var(--color-error)", "I revisjon": "var(--color-pending)",  "Godkjent": "#6D9B97"};
const getColor = (elem) => colors[elem.id];

const legendItem =  {
    anchor: "left",
    direction: "column",
    justify: false,
    translateX: -100,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    itemTextColor: "#000000",
    symbolShape: 'circle',
    symbolSize: 14,
    effects: [
        {
            on: "hover",
            style: {
                itemOpacity: 1
            }
        }
    ]
};

const mapDocAggregations = (data) => {

    const total = ["missing", "pending", "disapproved", "approved"]
        .map((key) => data[key] || 0)
        .reduce((acc, val) => acc + val, 0);

    const keyPairs = [
        ["Uten vurdering", "missing"],
        ["I revisjon", "pending"],
        ["Underkjent", "disapproved"],
        ["Godkjent", "approved"],
    ]

    const legends = [
        {
            ...legendItem,
            dataFrom: "data",
            data: keyPairs.map(([id, attribute]) => ({
                    id,
                    color: getColor({id}),
                    label: `${id} a ${((data[attribute] || 0)/total).toFixed(1)}`, // <--- Why is this not working???
                }
            )),
        }
    ];

    const mappedData = keyPairs.map((keyPair) => ({
        id: keyPair[0],
        label: keyPair[0],
        value: data[keyPair[1]] || 0,
    }))

    return [mappedData, legends];
};

const DogAggrPieChart = ({data, height=200}) => {

    const calculatedData = data ? mapDocAggregations(data) : null;
    let mappedData = null;
    let legends = null;
    if(calculatedData) {
        mappedData = calculatedData[0];
        legends = calculatedData[1];
    }

    return (
        <div style={{maxHeight: height, height: height}}>
            {
            mappedData ?
                <ResponsivePie
                    data={mappedData}
                    colors={getColor}
                    margin={{ top: 0, right: 0, bottom: 0, left: 100 }}
                    startAngle={-180}
                    innerRadius={0.7}
                    enableRadialLabels={false}
                    enableSlicesLabels={true}
                    slicesLabelsSkipAngle={10}
                    isInteractive={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={legends}
                /> :
            <div className='w-full h-full grid items-center justify-center'>
                <h6 className='text-center'>Ingen dokumenter enda</h6>
            </div>
            }
        </div>
    )

}

export default DogAggrPieChart;