import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Sidebar.module.css';
import Portal from '~/components/base/Portal';

// Utils
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import useWindowSize from '~/utils/hooks/useWindowSize';

const Sidebar = ({
    show = false,
    onClose,
    children,
}) => {
    const windowSize = useWindowSize();

    const [shouldShow, setShouldShow] = useState(false);
    const [curTimeout, setCurTimeout] = useState(null);
    const [drawerClass, setDrawerClass] = useState('');

    useEffect(() => {
        setDrawerClass(
            show ? styles.open : styles.close
        );
        if(show) {
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }
    }, [show])

    useEffect(() => {
        if(curTimeout) {
            clearTimeout(curTimeout);
        }
        setCurTimeout(setTimeout(() => {
            setShouldShow(show);
            setCurTimeout(null);
        }, show ? 0 : 500));
    }, [show]);

    useEffect(() => {
        if(show && windowSize.width >= 1024) {
            onClose && onClose();
        }
    }, [windowSize.width])

    return (
			<>
				<div className={`hidden lg:block ${styles.root} shadow`}>
					{children}
				</div>
				<Portal>
					{shouldShow && (
						<div className="block lg:hidden">
							<div
								className="bg-black absolute inset-0 opacity-25"
								onClick={() => onClose && onClose()}
							/>

							<div
								className={`${styles.root} ${styles.drawer} ${drawerClass} shadow`}
							>
								{children}
							</div>
						</div>
					)}
				</Portal>
			</>
		);
}

Sidebar.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default Sidebar;