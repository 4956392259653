import React from 'react';
import { eventTypes as EVENT_TYPES } from '~/data/config';
import { useHistory } from 'react-router';

// Core components
import NotificationIcon from '~/components/miscellaneous/NotificationIcon/NotificationIcon';

// Utils
import extractStatusText from '~/utils/text/extractStatusText';
import sectionIdToText from '~/utils/text/sectionIdToText';
import { timeAgo } from '~/utils/date';
import notificationToText from '~/utils/notifications/notificationToText';
import notificationPath from '~/utils/notifications/notficationPath';

const EventItem = ({
    projectId,
    event,
}) => {
    const history = useHistory();

    const timestamp = new Date(event.timestamp);
    const notificationText = notificationToText(event, projectId);
    
    const goTo = () => {
        history.push(notificationPath(projectId, event));
    }

    return (
        <div
            onClick={goTo}
        >
            <div className='py-3 pl-3 pr-1'>
                <div className='flex items-center hover:underline cursor-pointer'>
                    <div className='mr-2'>
                        <NotificationIcon
                            notificationItem={event}
                        />
                    </div>
                    <div className='text-sm'>
                        { notificationText }
                    </div>
                </div>
                <div className='text-xs font-normal text-gray-600'>
                    {
                        timeAgo(timestamp)
                    }
                </div>
            </div>
        </div>
    )
}

export default EventItem;