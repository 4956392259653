import React, { useState, useEffect, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { goToUserEdit, goToProjectSettings } from '../../../../ROUTES';

// Store imports
import { useSelector } from 'react-redux';
import EventSelectors from '~/store/events/selectors';

// Project components
import KvistLogo from '~/components/miscellaneous/KvistLogo';
import { Button, ButtonHover } from '~/components/base/Buttons';
import Badge from '~/components/base/Badge';
import Icon from '~/components/base/Icon';
import Sidebar from '~/components/navigation/Sidebar';

// Page components
import CategoryList from '../CategoryList/CategoryList';
import NotificationModule from '../NotificationModule';
import MemberModule from '../MemberModule';

/**
 * The Sidebar in the ProjectView - showing notifications and CategoryList :D.
 * @param {Object} project - the current project
 */
const ProjectSidebar = ({ project = {} }) => {
    const history = useHistory();
    const notificationModule = createRef();
    const memberModule = createRef();

    // State
    const [openNotification, setOpenNotification] = useState(false);
    const [canOpenNotifications, setCanOpenNotifications] = useState(null);
    const [openMember, setOpenMember] = useState(false);
		const [appendNotificationModule, setAppendNotificationModule] = useState(false);
		const [notificationAnchor, setNotificationAnchor] = useState(null);

    const unackedNotificationCounts = useSelector(EventSelectors.getProjectNotificationCounts(project.id));

    /**
     * The ClickAway functionality in the NotificaitonModule can trigger the "toggleNotifications"
     * method when one clicks on the button that ALSO triggers "toggleNotifications". To prevent
     * those calls to cancel-out each other, this makes sure one can only open the NotificationModule
     * 100 ms after it was closed.
     */
    useEffect(() => {
        if (!openNotification) {
            setCanOpenNotifications(setTimeout(() => setCanOpenNotifications(null), 100));
        }

		}, [openNotification])

		useEffect(() => {
			if(notificationModule.current) {
				setAppendNotificationModule(true);
				setNotificationAnchor(notificationModule.current);
			}
		},[notificationModule])

    const goToDashboard = () => {
        history.push(ROUTES.DASHBOARD);
        // <h3 className='cursor-pointer text-primary font-bold' onClick={goToDashboard}>KViST</h3>
    }

    const goToProject = () => {
        history.push(ROUTES.PROJECT.concat('/', project.id))
    }

    const toggleNotifications = () => {
        if (canOpenNotifications !== null) {
            return;
				}
        setOpenNotification(!openNotification)
    }

    return (
					<Sidebar>
						{appendNotificationModule && (
							<NotificationModule
								projectId={project.id}
								anchorEl={notificationAnchor}
								isOpen={openNotification}
								onClose={() => setOpenNotification(false)}
							/>
						)}
						{memberModule && memberModule.current && (
							<MemberModule
								projectId={project.id}
								anchorEl={memberModule.current}
								isOpen={openMember}
								onClose={() => setOpenMember(false)}
							/>
						)}

						<div className="flex items-center p-4 pb-1 pr-2">
							<KvistLogo className="pb-4 cursor-pointer" onClick={goToDashboard} />

							<div className="flex-grow" />
							<ButtonHover
								ref={memberModule}
								variant="icon"
								color="black"
								onClick={() => history.push(goToUserEdit())}
								hoverText="Din profil"
							>
								<Icon icon="user-circle" size="lg" />
							</ButtonHover>
							<ButtonHover
								ref={notificationModule}
								variant="icon"
								color="black"
								onClick={toggleNotifications}
								hoverText="Se varslinger"
							>
								<Icon icon="bell" size="lg" />
								{unackedNotificationCounts.total > 0 && (
									<Badge content={unackedNotificationCounts.total} />
								)}
							</ButtonHover>

							<ButtonHover
                variant="icon"
								color="black"
								onClick={() => history.push(goToProjectSettings(project.id))}
								hoverText="Innstillinger"
								>
								<Icon icon="cog" size="lg" />
							</ButtonHover>
						</div>
						<div
							className="py-3 px-3 text-lg flex items-center justify-start my-2 cursor-pointer hover:bg-gray-250"
							onClick={goToProject}
						>
							<Icon size="lg" icon="home" />
							<span className="font-semibold mb-0 ml-4" size="huge">
								{project.title}
							</span>
						</div>
						<div>
							<CategoryList />
						</div>
					</Sidebar>
				);
};

export default ProjectSidebar;