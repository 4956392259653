import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './PhasePlannerModal.module.css';

// Core components
import Modal from '~/components/base/Modal';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';

// Subcomponents
import PhasePlanner from '../PhasePlanner/PhasePlanner';

const PhasePlannerModal = ({
    show,
    onClose,
    projectId,
    onError
}) => {

    return (
        <Modal
            show={show}
        >
            <div className={`paper relative ${styles.root}`}>
                <PhasePlanner
                    projectId={projectId}
                    onSuccess={onClose}
                    onError={onError}
                />
                <Button
                    className='absolute top-0 right-0 mt-1 mr-1'
                    variant='icon'
                    onClick={onClose}
                >
                    <Icon icon='times' size='sm' />
                </Button>
            </div>
        </Modal>
    )
}

PhasePlannerModal.propTypes = {
    show: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onError: PropTypes.func,
}

export default PhasePlannerModal;