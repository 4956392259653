import React, { useState, useContext } from 'react'
import { TimelineContext } from '../TimelineModule';

// Styles
import style from './RevisionFeedback.module.css'

// Store imports
import { useDispatch } from 'react-redux';
import DocumentActions from '~/store/documents/actions';

// Base components
import Icon from '~/components/base/Icon';
import Collapse from '~/components/base/Collapse';
import Tabs from '~/components/base/Tabs';
import Avatar from '~/components/base/Avatar';
import StatusIcon from '~/components/miscellaneous/StatusIcon';

// Module components
import ReplyItem from './ReplyItem';

// Utils
import DOCUMENT_ICON from './DOCUMENT_ICON_TYPES';
import extractStatusText from '~/utils/text/extractStatusText';
import timeAgo from '~/utils/date/timeAgo';


// Constants
const TABS = [
    { value: 0, label: 'Kommentar' },
    { value: 1, label: 'Samsvarsnotat' },
]

/**
 * This RevisionFeedback is used in:
 * - TimeLineEventoint
 * @param {Object} props
 * @param {Object} props.documentMeta
 * @param {Object} props.messageBank
 * @param {Object} props.userBank
 */
const RevisionFeedback = ({ documentMeta, messageBank, userBank, includeTitle }) => {
    const { state, setState} = useContext(TimelineContext);
    const dispatch = useDispatch();

    const [visibility, setVisibility] = useState(false);
    const [tab, setTab]= useState(0);

    /**
     * @author Mathias Picker
     * @description - toggleVisibility toggles the visibility state
     */
    const toggleVisibility = () => setVisibility(!visibility)

    /**
     * @description - click-handler for toggleVisibility
     */
    const toggleVisibilityClick = () => toggleVisibility();


    /**
     * @param {Event} e - the event used for the onKeyDown handler for toggleVisibility
     * @descritpion - keydown-handler for the toggleVisibility. 
     * Used for A11y; toggles the expansion when Enter-key is pressed
     */
    const toggleVisibilityKeyPress = (e) => {
        if (e.key === 'Enter') {
            toggleVisibility();
        }
    }


    const downloadDocument = (documentId) => {
        DocumentActions.downloadFile(state.projectId, documentId)(dispatch);
    }

    const fileName = documentMeta.fileName || '';
    const fileNameSplitArray = fileName.split('.') || [];
    const fileNameExtension = fileNameSplitArray[fileNameSplitArray.length - 1] || '';
    const fileIcon = DOCUMENT_ICON(fileNameExtension);
    const attachmentCode = (documentMeta.attachmentCode || '').split("_").join(" ").toUpperCase();

    const user = (userBank || {})[documentMeta.createdBy] || {};

    return (
        <div className={`mb-4 flex rounded-md bg-gray-100 border ml-4 ${style.revisionInner} ${style.line}`}>
            <div className="w-full">
                <div

                    className="flex w-full justify-between align-center p-2"
                >

                    <div tabIndex="0"
                        className="cursor-pointer hover:underline p-2"
                        onClick={() => downloadDocument(documentMeta.document)}    
                    >
                        <Icon icon={fileIcon} size='lg' className='mr-2' />
                        {attachmentCode ? attachmentCode + ' - ' : ''} {fileName}
                        <StatusIcon status={documentMeta.status} className='mx-4' />
                    </div>
                    {
                        documentMeta.statusNote &&
                        <div tabIndex="0"
                            className="cursor-pointer hover:underline p-2"
                            onClick={toggleVisibilityClick}
                            onKeyDown={toggleVisibilityKeyPress}
                        >
                            {visibility ?
                                <>
                                    Lukk <Icon icon='compress-alt' size='lg' className='ml-4' />
                                </>
                                :
                                <>
                                    Vis tilbakemelding <Icon icon='expand-alt' size='lg' className='ml-4' />
                                </>
                            }
                        </div>
                    }
                </div>
                {
                    <Collapse
                        visible={visibility}
                    >
                        <div className="w-full p-5 pt-0 bg-white-05">
                            <Tabs
                                className='pb-4'
                                tabs={TABS}
                                value={tab}
                                onChange={setTab}
                            />

                            {
                                tab === 0 &&
                                <>
                                    <div className='flex items-center '>
                                        <Avatar className='mt-1 mr-2' size='sm' userId = {documentMeta.createdBy} >
                                        </Avatar>
                                        <div>
                                            <strong>
                                                {
                                                    (user.firstName || 'Revisor').concat(' ')
                                                }
                                            </strong>
                                            { 
                                                ''.concat(extractStatusText(documentMeta.status).toLowerCase(), 'e dokumentet og kommenterte:')
                                            }
                                        </div>
                                        <div className='flex-grow' />
                                        <div className='ml-2 text-gray-400'>
                                            {
                                                timeAgo(new Date(documentMeta.createdAt || '')).toLowerCase()
                                            }
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        {
                                            documentMeta.statusNote
                                        }
                                    </div>
                                </>
                            }
                            {
                                tab === 1 &&
                                <div className='flex items-center'>
                                    {
                                        documentMeta.note
                                    }
                                </div>
                            }
                        </div>
                    </Collapse>
                }
            </div>
        </div>
    )
}



export default RevisionFeedback;