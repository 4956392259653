import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CriterionItemAdd.module.css';

// Config
import Icon from '~/components/base/Icon';


const CriterionItemAdd = ({
    id,
    manualCriteriaInSection,
    onChange,
    inProject,
}) => {
    const criteriaInfo = manualCriteriaInSection.find(sc => sc.id === id);

    const [add, setAdd] = useState(false);
    


    return (
        <div 
            className={`mb-4 ${!inProject ? styles.overcontent : ''}`}
            onClick = {() => {
                if(!inProject){
                    setAdd(!add);
                    onChange(id, !add);
                }
            }}
        >
            <div className='flex items-center mb-8 '>
                {
                    !inProject &&
                    <div className = 'p-3'> 
                        <Icon 
                            icon = {!add ? 'plus' : 'minus'} 
                            size = 'lg'
                            color = {!add ?'#2C7A': 'red'}
                        />
                    </div>
                }
                <h6 className='font-bold text-base mb-0 mr-8'>
                    {criteriaInfo.credits} poeng
                </h6>
            </div>
            <div className = {`${!add && !inProject ? 'text-gray-500' : '' }`}>
                {
                    criteriaInfo.criteria &&
                    criteriaInfo.criteria
                        .map((subCriterion, index) => (
                            <div key={subCriterion.id}
                                className='mb-10'
                            >
                                <div
                                    className={`${styles.content} mb-8`}
                                >
                                    <div 
                                        className={styles.undercontent} >
                                        <p>
                                            <strong>{subCriterion.originalId || 0}.</strong>
                                        </p>

                                        <div>
                                            <div className={`mr-20`}>
                                                {
                                                    subCriterion.criteriaDescription &&
                                                    <p>
                                                        {subCriterion.criteriaDescription}
                                                    </p>
                                                }
                                                {
                                                    subCriterion.subCriteria &&
                                                    <ul className='ml-4'>
                                                        {
                                                            subCriterion.subCriteria.map(sub => (
                                                                <p key={sub.id}>
                                                                    {sub.internalId}
                                                                    {". " + sub.subCriteriaDescription}
                                                                </p>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    index < criteriaInfo.criteria.length - 1 ?
                                        <hr /> : null
                                }
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

CriterionItemAdd.propTypes = {
    id: PropTypes.string.isRequired,
    manualCriteriaInSection: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    inProject: PropTypes.bool.isRequired,
}


export default CriterionItemAdd;