
/**
 * getElemRect gets the an element's position relative to 0, 0, in the coordinate system.
 * @param {*} anchor 
 * @returns {Array<Number>} [x, y]
 */
export const getElemRect = (element) => {
    if(!element || !element.getBoundingClientRect) {
        return {
            bottom: 0,
            height: 0,
            right: 0,
            left: 0,
            top: 0,
            width: 0,
            x: 0,
            y: 0,
        };
    }

    return element.getBoundingClientRect();
}

export const posToTranslateStyle = (x, y, z = 0) => {
    return `translate3d(${x}px, ${y}px, ${z}px)`;
}

/**
 * calcPosition calculates the x and y position based on the anchorRect
 * @returns {Array<Number>}- [x, y]
 */
export const calcPosition = (anchorElRect, containerElRect, placement, isInner = false, isFixed = false) => {
    const aEl = anchorElRect;
    const cEl = containerElRect;

    let position = null;
    switch(placement) {
        case 'top-left': {
            position = [aEl.x, aEl.y - cEl.height];
            break;
        }

        case 'top-right': {
            position = [aEl.x + aEl.width - cEl.width, aEl.y - cEl.height]
            break;
        }

        case 'top': {
            position = [aEl.x + aEl.width/2 - cEl.width/2, aEl.y - cEl.height];
            break;
        }

        case 'bottom-left': {
            position = [aEl.x, aEl.bottom]
            break;
        }   

        case 'bottom-right': {
            position = [aEl.x + aEl.width - cEl.width, aEl.bottom]
            break;
        }

        case 'bottom': {
            position = [aEl.x + aEl.width/2 - cEl.width/2, aEl.bottom];
            break;
        }

        case 'right': {
            position = [aEl.x + aEl.width, aEl.y + aEl.height/2 - cEl.height/2]
            break;
        }

        case 'right-start': {
            position = [aEl.x + aEl.width, aEl.y]
            break;
        }
        
        case 'right-end': {
            position = [aEl.x + aEl.width, aEl.bottom - cEl.height]
            break;
        }

        case 'left': {
            position = [aEl.x - cEl.width, aEl.y + aEl.height/2 - cEl.height/2]
            break;
        }

        case 'left-start': {
            position = [aEl.x - cEl.width, aEl.y]
            break;
        }
        
        case 'left-end': {
            position = [aEl.x - cEl.width, aEl.bottom - cEl.height]
            break;
        }

        default: {
            position = [0, 0];
        } 
    }

    if(isInner) {
        if(placement.startsWith('top')) {
            position[1] += cEl.height;
        }
        else if(placement.startsWith('bottom')) {
            position[1] -= cEl.height;
        }
        else if(placement.startsWith('right')) {
            position[0] -= cEl.width;
        }
        else if(placement.startsWith('left')) {
            position[0] += cEl.width;
        }
    }

    if(!isFixed) {
        position[1] += window.scrollY;
    }

    return position;
}