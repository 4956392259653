import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import store from '~/store';

// ROUTES
import { goToProjectCriteria } from '~/ROUTES';

// Styles
import style from './SideBar.module.css'


const SideBar = () => {
    /**
     * Extracting the needed values from URL-parameters.
     * Because so many unique values are needed it is better
     * to get them from the URL instead of passing them down as props.
     */
    const { id, categoryId, sectionId, criteriaNumber } = useParams();

    /**
     * Getting the manual from a section (E.G: "hea_01").
     * Used to map through all the criteria on the sidebar.
     */
    const manualSection = useSelector(ProjectSelectors.getManualSectionById(sectionId));

    return (
			<div className="h-full w-24 py-3 pl-2">
				{
					/**
					 * It's easy to divide all the critera into their groups by
					 * looping through every assesment-criteta (E.G: id:"hea_01_03_b5d32b1666194cb1d710").
					 */
					manualSection.assessmentCriteria.map((assessmentCriteriaItem) => {
						/**
						 * Here every "group" is created by having a div with a flex-display.
						 * What is rendered:
						 * On the left side all the criterion are listed by their index,
						 * and on the right side the points gives for the assessment.
						 */
						return (
							<section
								className="flex flex-col rounded-l-md bg-white items-center pt-3 mb-8"
								key={assessmentCriteriaItem.criterionIndex}
							>
								<h6 className="assesmentCriterionSection--credits mb-2 text-center font-semibold text-base">
									{assessmentCriteriaItem.credits || 'Ingen'} poeng
								</h6>
								<section className="flex flex-col text-center">
									{assessmentCriteriaItem.criteria.map((criteria) => {
										/**
										 * The index used to render each criteria is already in the assessmentCriteriaItem.criteria array.
										 * However, it's also very useful to be able to preview the status of each
										 * criterion in the sidebar. That's why the getSubCriterionById is called for
										 * each criterion.
										 */
										const criteriaOriginalId = criteria.originalId;
										const criteriaId = criteria.id;
										const criterionMeta =
											ProjectSelectors.getSubCriterionById(criteriaId)(
												store.getState()
											) || {};

										// A Link-component is created for every criterion-index, so that the user easily can navigate through the manual-section.
										return (
											<Link
												key={criteria.id}
												className={`${
													criteriaOriginalId === criteriaNumber
														? 'bg-primary-dark text-white'
														: 'hover:bg-primary-opacity'
												} ${
													criterionMeta.hasOwnProperty('status')
														? style[criterionMeta.status.toLowerCase()]
														: ''
												} p-2 my-1 rounded-md`}
												to={goToProjectCriteria(
													id,
													categoryId,
													sectionId,
													criteriaOriginalId
												)}
											>
												{criteriaOriginalId}
											</Link>
										);
									})}
								</section>
							</section>
						);
					})
				}
			</div>
		);
};

export default SideBar;
