import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import '~/docs/occurrence.typedef';

// Store imports
import store from '~/store';
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelector from '~/store/project/selectors';
import OccurrenceActions from '~/store/occurrence/actions';
import OccurrenceSelectors from '~/store/occurrence/selectors';

// Core components
import Spinner from '~/components/base/Spinner';

// Module components
import Preface from './components/Preface';
import TimelineItem from './components/TimelineItem';
import project from '~/store/project/reducer';

export const TimelineContext = React.createContext({
    projectId: null,
    subCriterionId: null,
})

/**
 * TimelineModule is a module for displaying the history for a given subCriterion in form of a timeline.
 * @author Mathias Picker, Anders Iversen
 * @param {String} projectId 
 * @param {String} subCriterionId 
 * @param {String} goTo 
 */
const TimelineModule = ({
    projectId,
    subCriterionId,
    goTo = null,
}) => {
    const dispatch = useDispatch();
    const manualData = useMemo(() => ProjectSelector.getAllManualDataBySubCriterionId(subCriterionId)(store.getState()), [subCriterionId]) || {};
    const criterionMeta = useSelector(ProjectSelector.getCriterionById(manualData.criterionId)) || {};
    const subCriterionMeta = (criterionMeta.subCriteria || {})[subCriterionId] || {};


    // State
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({ projectId, subCriterionId });

    /**
     * @type {Array<Occurrence>}
     */
    const occurrences = useSelector(OccurrenceSelectors.getOccurrencesByStorageLocation(projectId, subCriterionId, 'ascending'))

    useEffect(() => {
        setState({ projectId, subCriterionId });

        if(!projectId || !subCriterionId) {
            return;
        }

        
        let unsubFuncPromise = null;
        if (!occurrences || occurrences.length === 0) {
            unsubFuncPromise = fetchOccurrences();
        } else {
            unsubFuncPromise = Promise.resolve(listenToChanges(occurrences[0].timestamp));
        }

        return function cleanup() {
            if (unsubFuncPromise) {
                unsubFuncPromise.then((unsubFuncs) => {
                    if (Array.isArray(unsubFuncs)) {
                        unsubFuncs.forEach((f) => f());
                    } else {
                        unsubFuncs && unsubFuncs();
                    }
                })

            }
        }
    }, [projectId, subCriterionId]);


    useEffect(() => {
        if(!isLoading && goTo) {
            const elem = document.getElementById(goTo);
            if(!elem) {
                return;
            }

            elem.scrollIntoView();
        }

    }, [isLoading, goTo])

    const fetchOccurrences = () => {
        setIsLoading(true);
        return OccurrenceActions.fetchOccurrences(projectId, { subCriterionId })(dispatch)
            .then((data) => {
                return listenToChanges();
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setIsLoading(false));
    }

    /**
     * @param {String} fromTime - timestamp 
     */
    const listenToChanges = (fromTime) => {
        return OccurrenceActions.listenToOccurrences(projectId, {
            subCriterionId: subCriterionId,
            timestamp: fromTime,
        })(dispatch);
    }

    if (isLoading) {
        return (
            <div className='spinner-absolute'>
                <Spinner />
            </div>
        )
    }

    return (
        <TimelineContext.Provider value={{ state, setState }}>
            <Preface
                manualData={manualData}
                subCriterionMeta={subCriterionMeta}
            />
            {
                occurrences && occurrences.length > 0 ? occurrences
                    .filter(occ => occ)
                    .map((occ) => (
                        <TimelineItem
                            key={occ.id}
                            item={occ}
                        />
                    ))
                    :
                    <div>
                        <h6 className='text-center font-light my-6'>Ingen aktivitet på dette kravet enda</h6>
                    </div>
            }
        </TimelineContext.Provider>
    )
}

TimelineModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string.isRequired,
    goTo: PropTypes.string, // The id of an occurrence to look for.
}

export default TimelineModule;