import React, { useState } from "react";
import Icon from '~/components/base/Icon';
import RemoveUserModal from './RemoveUserModal';
import SendNotificationModal from './SendNotificationModal';

// Data
import { roles as ROLES } from '~/data/config';

// Utils
import roleToText from '~/utils/text/roleToText';

const UserItem = (props) => {
    const [removeUserModal, setRemoveUserModal] = useState({});
    const [sendNotificationModal, setSendNotificationModal] = useState({});

    const showRemove = () => {
        if(props.owner) {
            return props.role === roleToText(ROLES.member);
        } else return false;
    } 
    return (
        <div className="border-t flex items-center">
            <div className="p-4 w-3/12">
                {props.firstname} {props.lastname}
            </div>
            <div className="p-4 w-3/12">
                {props.role}
            </div>
            <div className="p-4 w-2/12">
                {props.subCriteria}
            </div>
            <div className="flex p-4 w-4/12">
                {
                    showRemove()
                    ?
                    <div className='flex-grow'>
                        <div className='flex items-center'>
                            {
                                !props.archived &&                            
                                <div className='flex w-1/2 items-center cursor-pointer'
                                onClick={() => {
                                    setSendNotificationModal({show: true})
                                }}
                            >
                                <div className='flex-grow'/>
                                <h6 className='font-light text-base underline mb-0 mr-2'>
                                    Send purring
                                </h6>
                                <Icon icon='bell' size='lg' />
                            </div>
                            }
                            <div className='flex w-1/2 items-center cursor-pointer'
                                onClick={() => {
                                    props.removeUser()
                                }}
                            >
                                <h6 className='font-light text-base underline mb-0 ml-2 mr-2'>
                                    Fjern tilgang
                                </h6>
                                <Icon icon='times-circle' size='lg' />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex-grow'>
                    {!props.archived &&
                    <div className='flex items-center cursor-pointer'
                            onClick={() => setSendNotificationModal({show: true})}
                        >
                            <div className='flex-grow'></div>
                            <h6 className='font-light text-base underline mb-0 mr-2'>
                                Send purring
                            </h6>
                            <Icon icon='bell' size='lg' />
                            <div className='flex-grow'></div>
                    </div>
                    }
                    </div>
                }
            </div>

            <RemoveUserModal 
                show={Boolean(removeUserModal.show)} 
                onClose={() => setRemoveUserModal({show: false})}
                {...props}
            />
            <SendNotificationModal
                show={Boolean(sendNotificationModal.show)}
                onClose={() => setSendNotificationModal({show: false})}
                {...props}
            />
        </div>
    )
}

export default UserItem;
