import React from 'react';
import { eventTypes as EVENT_ACTIONS } from '~/data/config'

// Components
import TimeLineEvent from './TimeLineEvent';
import TimeLineEventPoint from './TimeLineEventPoint';
import RevisionFeedback from './RevisionFeedback';



/**
 * This TimeLineEventContainer is used in:
 * - TimeLine (index.js)
 * @param {Object} props
 * @param {Occurrence} props.occurrence
 */
const TimeLineEventContainer = ({ occurrence }) => {

    let items = occurrence.items;
    let documents = Object.values(occurrence.documents || {}) || [];

    return (
        <div className='ml-12' id={occurrence.id}>
            <TimeLineEvent occurrence={occurrence} />
            {(items && items.length > 0) && items.map(item => <TimeLineEventPoint key={item.id} occurrence={item} />)}
            {
                (occurrence.eventAction === EVENT_ACTIONS.CRITERION_STATUS_CHANGE && documents.length > 0) &&
                documents.map((doc) => (
                    doc &&
                    <RevisionFeedback
                        key={doc.id}
                        documentMeta={doc}
                        messageBank={occurrence.messages}
                        userBank={occurrence.users}
                    />
                ))
            }
        </div>
    )
}

export default TimeLineEventContainer
