import React from 'react';
import PropTypes from 'prop-types';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import OccurrenceActions from '~/store/occurrence/actions';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import MessageItem from '~/components/miscellaneous/MessageItem';

// Utils
import roleToText from '~/utils/text/roleToText';

const OccurrenceMessageItem = ({
    projectId,
    sectionId,
    messageObject = {},
    usesMessageBank = false,
    user,
    ...rest
}) => {
    const dispatch = useDispatch();
    const project = useSelector(ProjectSelectors.getProjectById(projectId));

    const onMessageEdit = (newMessage) => {
        return OccurrenceActions.editComment(
            projectId,
            sectionId,
            usesMessageBank ? messageObject.messageBank : messageObject.id,
            newMessage,
            usesMessageBank ? messageObject.id : null,
        )(dispatch)
            .catch(console.error);
    }

    const onReactionToggle = (reaction) => {
        return OccurrenceActions.editComment(
            projectId,
            sectionId,
            usesMessageBank ? messageObject.messageBank : messageObject.id,
            null,
            usesMessageBank ? messageObject.id : null,
            reaction,
        )(dispatch)
            .catch(console.error);
    }

    const userObject = user && user.id ? {
        ...user,
        role: project.roles[user.id] || null,
    } : null;

    return (
        <MessageItem
            onEdit={onMessageEdit}
            onReaction={onReactionToggle}
            message={messageObject.message}
            timestamp={messageObject.timestamp}
            reactions={messageObject.reactions}
            edited={messageObject.edited}
            user={userObject}
            isLoading={messageObject.isLoading}
            {...rest}
        />
    )
}

export default OccurrenceMessageItem;