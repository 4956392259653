import React, { useState, useEffect } from "react";
import UserActions from '~/store/user/actions';
import { member as MEMBER } from '~/data/config';
import { useHistory } from 'react-router';
import ROUTES, { goToProjectAccess } from '~/ROUTES';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Components
import TopBar from "../projectView/components/TopBar";
import AddUserForm from "./components/AddUserForm";
import SuccessModal, { REQUEST_SUCCESS, REQUEST_FAILED } from './components/SuccessModal';
import convertErrorMessage from './components/convertErrorMessage';
import { checkIfOwnerOrAuditor } from "~/utils/access";
import checkIfOwner from "~/utils/access/checkIfOwner";

const AddUserToProjectView = () => {
    const history = useHistory();

    // State
    const [state, setState] = useState({
        email: '',
        role: '',
        buttonDisabled: true,
        error: false
    })
    const [isLoading, setIsLoading] = useState(false);
    const [modalConfig, setModalConfig] = useState({});
    const [requestStatus, setRequestStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const project = useSelector(ProjectSelectors.getCurrentProject);

    useEffect(() => {
        if(!checkIfOwner(project)) {
            history.push(ROUTES.DASHBOARD);
        }
    }, [])

    const openModal = () => {
        setModalConfig({
            show: true,
        })
    }

    const closeModal = () => {
        setModalConfig({
            show: false,
        })

        if(requestStatus === REQUEST_SUCCESS) {
            history.push(goToProjectAccess(project.id))
        } else {
            setRequestStatus(null);
        }
    }

    function manageButton() {
        if (
            state.email !== ""
            ) {
            state.buttonDisabled = false;
        }
        else {
            state.buttonDisabled = true;
        }
    }
    manageButton();

    //callbacks
    function handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        setState({
            ...state,
            error: false,
            [name]: value
        })
        manageButton();
    }

    const addMember = (sections) => {
        setIsLoading(true);
        openModal()

        const sectionObjToSend = Object
            .keys(sections)
            .reduce((prev, sectionId) => (sections[sectionId] === true ? {...prev, [sectionId]: MEMBER.SECTIONS_VALUE.ACCESS } : {...prev}), {}); 

        UserActions.memberAdd(project.id, state.email, state.role, sectionObjToSend)
        .then((err) => {
            setRequestStatus(REQUEST_SUCCESS);
        })
        .catch((err) => {
            
            console.log(`error: ${err} ${err.data} ${err.message} ${err.details}`)
            setRequestStatus(REQUEST_FAILED);
            setErrorMessage(convertErrorMessage(err.message))
        })
        .finally(() => {
            setIsLoading(false);
        }) 
    }

    return (
        <div className='p-3 page'>
            <TopBar />
            <AddUserForm submit={addMember} buttonDisabled={state.buttonDisabled} onChange={handleChange} />
            <SuccessModal 
                show={Boolean(modalConfig.show)} 
                onClose={closeModal}
                status={requestStatus}
                isLoading={isLoading}
                errorMessage={errorMessage}
                successMessage='Brukeren ble lagt til'
            />
        </div>
    )
}


export default AddUserToProjectView;
