import React from 'react';
import { Link } from 'react-router-dom'

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import Icon from '~/components/base/Icon';

// Data
import CATEGORIES from '~/data/categories.json';
import SECTIONS from '~/data/sections.json';

import { goToProjectCategory, goToProjectSection } from '~/ROUTES';


const TopBar = ({
    criteriaNumber,
    projectId,
    sectionId,
    categoryId
}) => {

    const userRole = useSelector(ProjectSelectors.getCurProjectRole);

    return (
        <div className={`flex items-center mb-4`}>
            <h5 className='my-0 mb-1 mr-4'>
                {

                    categoryId && (
                        <Link className='hover:underline' to={goToProjectCategory(projectId, categoryId)}>{(CATEGORIES.find(c => c.key === categoryId) || {}).name || ''}</Link>
                    )
                }
            </h5>

            {

                sectionId && SECTIONS[sectionId] ? (
                    <div className='flex items-center'>
                        <Icon icon='chevron-right' className='mr-4' color='gray' />
                        <Link className='hover:underline font-light text-lg mb-0 mr-4' to={goToProjectSection(projectId, categoryId, sectionId)}>
                            {SECTIONS[sectionId]}
                        </Link>
                    </div>
                ) : ''
            }

            <Icon icon='chevron-right' className='mr-4' color='gray' />
            {`Krav ${criteriaNumber}`}
        </div>
    )
};

export default TopBar;