import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router';

// Styles
import styles from './index.module.css';

// Store imports
import store from '~/store';
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import UserSelectors from '~/store/user/selectors';

//data
import ROUTES from '../../ROUTES';

// Page components
import TopBar from './components/TopBar'
import SubCriterionModule from './components/SubCriterionModule';
import FileSystemModule from './components/FileSystemModule';
import SideBar from './components/SideBar';
import { checkIfAccessToSection } from '~/utils/access';

const ProjectCriteriaView = () => {
  const params = useParams();
  const history = useHistory();
  const projectId = params.id;
  const sectionId = params.sectionId;
  const categoryId = params.categoryId;
  const criteriaNumber = params.criteriaNumber
  const subCriterionId = sectionId.concat('__', params.criteriaNumber);
  const manual = useSelector(ProjectSelectors.getCurrentManual);
  const project = useSelector(ProjectSelectors.getCurrentProject);
  const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);

  const allManualData = useMemo(() => ProjectSelectors.getAllManualDataBySubCriterionId(subCriterionId)(store.getState()), [subCriterionId]);
  const userRole = useSelector(ProjectSelectors.getProjectRoleById(projectId));
  const criterionMeta = useSelector(ProjectSelectors.getCriterionById(allManualData.criterionId)) || {};
  const subCriterionMeta = (criterionMeta.subCriteria || {})[subCriterionId] || {};

  useEffect(() => {
    if(!checkIfAccessToSection(project, userMemberData, sectionId)) {
        history.push(ROUTES.DASHBOARD);
    }

}, [])

  return (
    <div className={`min-h-screen ${styles.content}`}>

      <div className='p-3 mb-32'>
        <div className='page'>
          <TopBar criteriaNumber={criteriaNumber} projectId={projectId} sectionId={sectionId} categoryId={categoryId} />  
        </div>
        <div className="grid grid-cols-1 gap-6 page">
          

          <SubCriterionModule
            projectId={projectId}
            subCriterionId={subCriterionId}
            subCriterionMeta={subCriterionMeta}
            manualData={allManualData}
            userRole={userRole}
          />

          <FileSystemModule
            projectId={projectId}
            subCriterionId={subCriterionId}
            subCriterionMeta={subCriterionMeta}
            manualData={allManualData}
            userRole={userRole}
          />
        </div>
      </div>
      <div>
        <SideBar sectionId={sectionId} subCriterionId={subCriterionId}  />
      </div>
    </div>
  );
};

ProjectCriteriaView.propTypes = {};

export default ProjectCriteriaView;
