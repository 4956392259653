import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { auth } from '~/services/firebase';
import { roles as ROLES } from '~/data/config';

// Store imports
import { usDispatch, useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import ProjectActions from '~/store/project/actions';

// Core components
import TextArea from '~/components/base/TextArea';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import timeAgo from '~/utils/date/timeAgo';

// Style
import style from './sectionNoteModule.module.css'

// Utils
import cleanHTMLFrom from '~/utils/html/cleanHTML';

const SectionNoteModule = ({
    className = '',
    sectionId,
    note,
    noteChangedAt,
}) => {
    const dispatch = useDispatch();
    const project = useSelector(ProjectSelectors.getCurrentProject);

    const [editCfg, setEditCfg] = useState({
        isEdit: !Boolean(note),
        isLoading: false,
        note: '',
    });

    useEffect(() => {
        setEditCfg((prev) => ({ ...prev, isEdit: Boolean(!note), note: '' }))
    }, [sectionId, note])

    const toggleEditMode = () => {
        setEditCfg({
            ...editCfg,
            isEdit: !editCfg.isEdit,
            note: note || '',
        })
    }

    const onNoteChange = (e) => {
        setEditCfg({
            ...editCfg,
            note: e.target.value,
        })
    }

    const editNote = () => {
        const note = (editCfg.note || '').trim();
        if (!note) {
            return;
        }

        setEditCfg((prev) => ({ ...prev, isLoading: true }));
        ProjectActions.editSectionNote(project.id, sectionId, note.trim())(dispatch)
            .finally(() => {
                setEditCfg((prev) => ({ ...prev, isLoading: false, isEdit: false }));
            })
    }

    const HAS_WRITE_ACCESS = project.roles[auth.currentUser.uid] === ROLES.owner && !project.archived;
    // If there is no note and the user has no authority to create one, don't show anything.
    if (!note && !HAS_WRITE_ACCESS) {
        return null;
    }

    return (
        <div className={`paper ${className}`}>
            <h6>Samsvarsnotat</h6>
            {
                editCfg.isEdit ?
                    <div>
                        <TextArea
                            value={editCfg.note}
                            onChange={onNoteChange}
                            placeholder='Skriv et samsvarsnotat for dette emnet'
                            rows={3}
                        />
                        <div className='flex items-center flex-row-reverse'>
                            <Button
                                disableElevation
                                onClick={editNote}
                                loading={editCfg.isLoading}
                                disabled={editCfg.isLoading}
                            >
                                Lagre
                        </Button>
                            {
                                note &&
                                <Button
                                    className='mr-2'
                                    variant='text'
                                    color='secondary'
                                    onClick={toggleEditMode}
                                    disabled={editCfg.isLoading}
                                >
                                    Lukk
                            </Button>
                            }

                        </div>
                    </div>
                    :
                    <div>
                        <div className={`my-2 font-light ${style.sectionNoteModule}`} dangerouslySetInnerHTML={cleanHTMLFrom(note)} />
                        {
                            noteChangedAt &&
                            <div className='text-sm text-gray-600'>
                                {'Sist redigert: ' + timeAgo(new Date(noteChangedAt || '')).toLowerCase()}
                            </div>
                        }
                        {
                            HAS_WRITE_ACCESS &&
                            <div>
                                <Button
                                    className='ml-auto'
                                    disableElevation
                                    onClick={toggleEditMode}
                                >
                                    <Icon icon='edit' className='mr-2' />
                                Rediger
                            </Button>
                            </div>
                        }
                    </div>
            }

        </div>
    )
}

SectionNoteModule.propTypes = {
    sectionId: PropTypes.string.isRequired,
    note: PropTypes.string
}

SectionNoteModule.defaultProps = {
    sectionId: 'man_01',
    note: null,
}

export default SectionNoteModule;