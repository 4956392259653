import project, { MUTATIONS, mapProject, mapManual, mapCriterionMeta } from './reducer';
import ProjectSelectors from './selectors';
import * as firebase from '../../services/firebase';
import { statuses as STATUSES, functions as FUNCTIONS } from '../../data/config';
import store from '../index';

const setCriteriaMeta = (projectId, metaData) => ({
    type: MUTATIONS.SET_CRITERIA,
    projectId: projectId,
    payload: metaData,
})

const setCriterionMeta = (projectId, metaData) => ({
    type: MUTATIONS.SET_CRITERION,
    projectId: projectId,
    payload: metaData,
})

/**
 * reviewSubCriteria reviews a subCriteria by id - changing it's status in addition to the status of given related documents.
 * @param {String} projectId 
 * @param {String} subCriterionId 
 * @param {String} status 
 * @param {String} note 
 * @param {Array<DocumentMetaReview>} documentMetaReviews
 * 
 * @typedef {Object} DocumentMetaReview
 * @property {String} id - The id of the documentMeta.
 * @property {String} status - the status of the document
 * @property {String} statusNote - the text-based evaluation of the document
 */
const reviewSubCriterion = (projectId, subCriterionId, status, note, documentMetaReviews) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.CRITERIA_SUB_CRITERION_REVIEW)({
        projectId: projectId,
        subCriterionId: subCriterionId,
        status: status,
        statusNote: note,
        documentMetaReviews: documentMetaReviews.map(docMetaReview => ({
            id: docMetaReview.id,
            status: docMetaReview.status,
            statusNote: docMetaReview.statusNote || null
        }))
    })
        .then((res) => {
            const newCriterion = res.data;
            dispatch(setCriterionMeta(projectId, newCriterion))
            return newCriterion;
        });
};


/**
 * @description Function for locking a criterion.
 * @param {String} projectId 
 * @param {String} criterionId 
 * @returns Object
 */

const lockCriterion = (projectId, criterionId) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.CRITERIA_LOCK)({ projectId, criterionId })
        .then((result) => {
            const newCriterion = { ...(result.data || {}), id: criterionId };
            dispatch(setCriterionMeta(projectId, newCriterion))
            return mapCriterionMeta(newCriterion)
        })
}

/**
 * @description Function for locking a sub-criterion.
 * @param {String} projectId 
 * @param {String} criterionId 
 * @returns Object
 */

const lockSubCriterion = (projectId, criterionId, subCriterionId) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.CRITERIA_LOCK)({ projectId, subCriterionId })
        .then((result) => {
            const newCriterion = { ...(result.data || {}), id: criterionId };
            dispatch(setCriterionMeta(projectId, newCriterion))
            return mapCriterionMeta(newCriterion)
        })
        .catch(error => console.error(error))
}

/**
 * @description Generic function for configuring deadline on sub-criterias.
 * @param {String} projectId 
 * @param {String} criterionId 
 * @param {String|undefined} subCriterionId
 * @param {String<Date.toISOString()>} deadline 
 * @returns Object
 */

const configureDeadlineOnCriteria = (projectId, criterionId, subCriterionId, deadline) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.CRITERIA_DEADLINE_SET)({ projectId, criterionId, subCriterionId, deadline })
        .then((result) => {
            const newCriterion = { ...(result.data || {}), id: criterionId };
            dispatch(setCriterionMeta(projectId, newCriterion))
            return mapCriterionMeta(newCriterion)
        });
}

/**
 * @description The action for calling the back-end function for setting deadline on a criteria.
 * @param {String} projectId 
 * @param {String} criterionId 
 * @param {String|undefined} subCriterionId
 * @param {String<Date.toISOString()>} deadline 
 * @returns Object
 */

const setDeadlineOnCriteria = (projectId, criterionId, subCriterionId, deadline) => (dispatch) => {
    return configureDeadlineOnCriteria(projectId, criterionId, subCriterionId, deadline)(dispatch)
}

/**
 * @description The action for calling the back-end function for resetting deadline on a criteria.
 * @param {String} projectId 
 * @param {String} criterionId 
 * @param {String|undefined} subCriterionId
 * @returns Object
 */

const removeDeadlineOnCriteria = (projectId, criterionId, subCriterionId) => (dispatch) => {
    /*
    * Removing a deadline is equal to setting it to null. 
    * Therefore we can simply reuse the same function but hard-code the deadline to be null.
    */
    const deadline = null;
    return configureDeadlineOnCriteria(projectId, criterionId, subCriterionId, deadline)(dispatch)
}

export default {
    setCriteriaMeta,
    setCriterionMeta,
    reviewSubCriterion,
    lockCriterion,
    lockSubCriterion,
    setDeadlineOnCriteria,
    removeDeadlineOnCriteria
}
