import React, { useRef } from 'react'

// Utils
import { dateToDateString, calculatePercentageBetweenTwoDates } from '~/utils/date';


// Styles
import style from './ProgressBar.module.css'

// Config
import { dateStyles } from '~/data/config';

const ProgressBar = ({ progressPhases, curCategory, showIndicator = false }) => {
    const progressRef = useRef();
    let currentProgress;
    let isCurrent = false;

    const progressPhasesArray = Object.keys(progressPhases)
        .filter(phase => typeof progressPhases[phase] !== 'string')
        .sort((phaseA, phaseB) => {
            const phaseANumber = parseInt(phaseA.slice(-2)); // e.g: phase01 -> 1
            const phaseBNumber = parseInt(phaseB.slice(-2)); // e.g: phase02 -> 2
            return phaseANumber - phaseBNumber;
        })
        .map((phase, index, array) => {
            const currentProgresPhase = progressPhases[phase];

            if (index === array.length - 1) {
                currentProgresPhase.endDate = progressPhases.endDate;
            }
            else {
                const today = new Date().toISOString();

                const tempArr = phase.split('_');
                tempArr[1] = `0${parseInt(tempArr[1]) + 1}`

                const nextPhase = progressPhases[tempArr.join('_')];
                currentProgresPhase.hasBeen = nextPhase.start < today;
            }

            return currentProgresPhase;
        });

    return (
        <header className={`${style.gridRight} ${style.progressHeader}`}>
            <section className={`${style.progressInnerGrid}`}>
                {
                    progressPhasesArray.map(phase => {
                        const phaseStart = new Date(phase.start);
                        const dateString = dateToDateString(phaseStart, dateStyles.MEDIUM)
                        return (
                            <div key={phase.phaseNumber} className='flex flex-col text-center text-sm'>
                                <h6>Fase {phase.phaseNumber}</h6>
                                {dateString}
                            </div>
                        )
                    })
                }
            </section>

            <section className={`${style.progressTimelineBar} ${style.progressInnerGrid}`} ref={progressRef}>
                {
                    progressPhasesArray.map((phase, i) => {
                        isCurrent = false;

                        if (!phase.hasBeen && !currentProgress) {
                            isCurrent = true; // Will only be true on this iteration
                            currentProgress = phase; // Automatically store "active" phase

                            const dateOfCurrent = new Date(currentProgress.start);
                            const dateOfNext = progressPhasesArray[i + 1].start ? new Date(progressPhasesArray[i + 1].start) : progressPhases.endDate;

                            const percentage = calculatePercentageBetweenTwoDates(dateOfCurrent, dateOfNext);

                            if (progressRef.current) { progressRef.current.style.setProperty('--percentage', `${percentage}%`); };
                        };

                        return (
                            <span
                                key={phase.start}
                                className={`${style.progressbarField} ${phase.hasBeen ? `bg-primary` : isCurrent ? `${curCategory && showIndicator ? style.progressIndicator : ''} ${style.currentField}` : ''}`}>
                            </span>
                        )
                    })
                }
            </section>
        </header>
    )
}


export default ProgressBar;

