// Imports

// Style
import styles from './index.module.css';
import React, {useState, useEffect, useMemo, useRef} from 'react';

import {useHistory, Switch, Route} from 'react-router-dom';
import ROUTES, { goToProjectDashboard, goToDashboard, goToUser, goToUserSettings, goToUserEdit } from '../../ROUTES';

// Store
import UserSelectors from '~/store/user/selectors';
import UserActions from '~/store/user/actions';
import ProjectSelectors from '~/store/project/selectors'

// Components
import EditUserForm from './components/EditUserForm';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '~/components/base/Spinner';
import Avatar from '~/components/base/Avatar';
import Icon from '~/components/base/Icon';
import KvistLogo from '~/components/miscellaneous/KvistLogo/KvistLogo';
import { Button } from '~/components/base/Buttons';
import Tabs from '~/components/base/Tabs';
import UserSettings from './components/UserSettings';
import { auth } from '~/services/firebase';
import { firebaseStorage as STORAGE } from '~/data/config'
import { getProfilePictureURL } from '~/utils/text/getProfilePictureURL';


const TAB_VALUES = [{value: 0, label: 'Profil'}, {value: 1, label: 'Innstillinger'}];



const EditUser = () => {
    // States
    const [isLoading, setIsLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [update, setUpdate] = useState(false);

    // Constants

    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(UserSelectors.getUserInfo);
    const project = useSelector(ProjectSelectors.getCurrentProject);


    const onFileChange = (event) => {
        setImageLoading(true);
        UserActions.updateProfilePicture(event.target.files[0])
        .then((result) => {
            setUpdate(!update);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setImageLoading(false);
        })
    }

    const onBtnClicked = (e) => {
        fileInputRef.current.click();
    }


    useEffect (() => {
        setTab(0);
        if(!user) {
            fetchUser();
        }
    }, [user])

    const fetchUser = () => {
        setIsLoading(true);
        UserActions.fetchUserInfo(dispatch)
            .catch((error) => {
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    // Functions

    const setTab = (value) => {
        setTabValue(value);
        if(value == 0) {
            history.push(goToUserEdit());
        } else {
            history.push(goToUserSettings());
        }
    }
    if(isLoading || !user) {
        return (
            <div className='absolute-center'>
                <Spinner size='sm' />
            </div>
        )
    }
    const goBack = () => {
        if(project) {
            history.push(goToProjectDashboard(project.id))
        } else {
            history.push(goToDashboard());
        }
    }


    return (
            <>
            <div className = 'p-3'>
            <KvistLogo
                    className="pb-4 cursor-pointer"
                    onClick={() => history.push(goToDashboard())}
                />
            </div>
                <div className = 'page p-3 '>
                    <div
                        style={{ gridArea: 'back' }}
                        onClick={goBack}
                        className='cursor-pointer hover:underline my-auto mb-4'
                    >
                        <Icon icon='arrow-left' size='lg' className='mr-2' /> Tilbake
                    </div>
                    <div className = 'flex flex-wrap -mb-4 gap-6'>
                        <div className = 'paper text-center break-words items-center mb-4 w-full lg:w-1/3 sm:w-full h-min-content'>
                            <div className='m-auto'>
                            <input
                                ref={fileInputRef}
                                type='file'
                                id='file-input'
                                hidden
                                onChange={onFileChange}
                                accept="image/*"
                            />
                            <div
                                onClick={onBtnClicked}
                                className = 'cursor-pointer flex justify-center'>
                                <Avatar
                                    userId={auth.currentUser.uid}
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    initials={user.initials}
                                    size= 'xl'
                                    update={update}
                                    isLoading={imageLoading}
                                />
                            </div>
                            </div>
                            <div className = 'p-4'>
                                <b>
                                    {`${user.firstName} ${user.lastName}`}
                                </b>
                            </div>
                            <div className = 'p-4'>
                                {user.initials}
                            </div>
                            <div className = 'p-4'>
                                {auth.currentUser.email}
                            </div>
                            <div className = 'p-4'>
                                {user.number}
                            </div>
                        </div>
                        <div className = 'mb-4 w-full lg:flex-1 sm:w-full'>
                            <div className = 'bg-white rounded-t-lg shadow-md'>
                                <Tabs
                                    tabs={TAB_VALUES}
                                    value={tabValue}
                                    onChange={setTab}
                                />
                            </div>
                            <hr />
                            <div className = 'paper rounded-t-none'>
                                <Switch>
                                <Route exact path={ROUTES.USER.concat(ROUTES.EDIT)} component={EditUserForm} />
                                <Route exact path={ROUTES.USER.concat(ROUTES.SETTINGS)} component={UserSettings} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default EditUser;