import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from '~/ROUTES';

// Components
import { Button } from '~/components/base/Buttons';
import TextField from '~/components/base/TextField';
import KvistLogo from '~/components/miscellaneous/KvistLogo';


const LogInForm = (props) => {
    const history = useHistory();
    return (
            <div>
                {
                    props.isEmailSignInLink
                    ?
                    <h3 className="text-primary">
                        Gjenta emailen din og bestem ett passord
                    </h3>
                    :
                    <h3 className="text-primary hidden md:block">
                        Logg inn på din konto
                    </h3>
                }




                <form className='paper' onSubmit={props.onClick}>
                    <KvistLogo
                        className='m-auto mb-4'
                    />
                    <TextField
                        label='Email'
                        type="text"
                        icon = "user"
                        name="email"
                        error={props.error}
                        placeholder="E-post adresse"
                        onChange={props.onChange}
                        required
                    />


                    <TextField
                        label='Passord'
                        type="password"
                        name="password"
                        icon = "lock"
                        error={props.error}
                        hint={props.error ? 'Feil email eller passord' : null}
                        placeholder="Passord"
                        onChange={props.onChange}
                        required
                    />



                    <Button
                        className='inline-flex ml-none'
                        type='submit'
                    >
                        Logg inn
                    </Button>

                    <Button
                        className='inline-flex float-right'
                        variant='text'
                        onClick={() => {
                            history.push(ROUTES.CREATEUSER)
                        }}
                    >
                        Ny konto
                    </Button>

                </form>
            </div>
    )
}

export default LogInForm;