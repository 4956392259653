import React, { useState, useEffect, useContext } from 'react'
import { TimelineContext } from '../TimelineModule';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import OccurrenceActions from '~/store/occurrence/actions';
import store from '~/store';

// Components
import Avatar from '~/components/base/Avatar';
import Collapse from '~/components/base/Collapse';
import OccurrenceMessageItem from '~/components/miscellaneous/OccurrenceMessageItem';

// Styles
import style from './CommentContainer.module.css'

// Module components
import ReplyItem from './ReplyItem';
import CommentInputBox from '../../../miscellaneous/CommentInputBox/CommentInputBox';
import { Button } from '~/components/base/Buttons';

/**
 * This CommentContainer is used in:
 * - TimeLineEvent
 */
const CommentContainer = ({ occurrence, disabled}) => {
    const dispatch = useDispatch();
    const { state, setState } = useContext(TimelineContext);
    const [showComments, setShowComments] = useState(false);

    const projectId = state.projectId;
    const subCriterionId = state.subCriterionId;
    const sectionId = !subCriterionId ? state.sectionId : subCriterionId.split("_").slice(0, 2).join("_"); // "ene_01__1" => "ene_01"
    const occurrenceId = (occurrence || {}).id;

    const userBank = occurrence.users || {};
    const messageBank = occurrence.messages || {};
    const messages = (occurrence.messageIds || [])
        .map(messageId => ({ id: messageId, ...messageBank[messageId] }))
        .filter(m => m.message);

    const submitComment = ({ input, resolve, reject }) => {
        const message = input;

        OccurrenceActions.addCommentOnRevision(
            projectId,
            sectionId,
            occurrenceId,
            message
        )(dispatch)
            .then((data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    }

    return (
        <div>
            <div className='text-gray-600'>
                Revisor har kommentert:
            </div>
            <section className="bg-white w-full rounded-md p-2 mt-2 pl-0 relative">
                {
                    occurrence.statusNote &&
                    <div className='ml-4 mt-3 mb-3'>
                        {occurrence.statusNote}
                    </div>
                }
            </section>

            <div className='bg-white w-full rounded-md mt-4'>
                <Button
                    className='w-full'
                    variant='text'
                    onClick={() => setShowComments(!showComments)}
                >
                    {showComments ? `Skjul kommentarer` : 'Vis kommentarer'} {`(${messages.length})`}
                </Button>
                <Collapse
                    visible={showComments}
                >
                    <hr />
                    <div className='mb-5 mt-2'>
                        {
                            messages.map((message) => (
                                <OccurrenceMessageItem
                                    key={message.id}
                                    projectId={projectId}
                                    sectionId={sectionId}
                                    user={{ id: message.userId, ...(userBank[message.userId] || {}) }}
                                    messageObject={message}
                                    usesMessageBank={true}
                                />
                            ))
                        }
                        {
                            messages.length === 0 &&
                            <div className='my-4'>
                                <div className='font-light text-center'>
                                    Ingen kommentarer å vise
                                </div>
                            </div>
                        }
                    </div>
                    <hr />
                    {!disabled &&
                        <div className='p-4'>
                            <CommentInputBox
                                onSubmit={submitComment}
                                color='light-blue'
                                placeholder='Trykk her for å svare...'
                                errorMessage='Vi fikk ikke videresendt meldingen din. Vennligst prøv på nytt.'
                            />
                        </div>
                    }
                </Collapse>
            </div>
        </div>
    )
}

export default CommentContainer
