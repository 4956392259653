import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { goToDashboard } from '~/ROUTES';

// Styles
import styles from './ArchiveModal.module.css';

// Store import
import { useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';

// Core components
import Modal from '~/components/base/Modal';
import TextField from '~/components/base/TextField';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';

const STATUS_NONE = 0;
const STATUS_SUCCESS = 1;

const ArchiveModal = ({
    project,
    show,
    onClose,
    ...rest
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(STATUS_NONE);
    const CAN_ARCHIVE = input === project.title;

    useEffect(() => {
        if(show) {
            setInput('');
            setIsLoading(false);
            setStatus(STATUS_NONE);
        }

    }, [show])

    const archiveProject = () => {
        setIsLoading(true);
        ProjectActions.archiveProject(project.id)(dispatch)
            .then(() => {
                setStatus(STATUS_SUCCESS);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const toDashboard = () => {
        history.push(goToDashboard());
    }

    return (
        <Modal
            show={show}
            onBackdropClick={() => !isLoading && onClose()}
            {...rest}
        >
            <div className={`paper ${styles.root}`}>
                {
                    status === STATUS_NONE ?
                    <>
                        <h4 className='text-center'>
                            Arkivering av prosjekt
                        </h4>
                        <div className='my-2 text-center text-light'>
                            Er du sikker på at du vil arkivere dette prosjektet?
                        </div>
                        <div className='mt-4 font-semibold'>
                            Skriv inn navnet på prosjektet { project.title } for å arkivere:
                        </div>
                        <TextField
                            placeholder='Prosjekt navn'
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                        <div className='flex flex-row-reverse'>
                            <Button
                                variant='contained'
                                color='error'
                                disableElevation
                                disabled={isLoading || !CAN_ARCHIVE}
                                loading={isLoading}
                                onClick={archiveProject}
                            >
                                Arkiver
                            </Button>
                            <Button
                                variant='text'
                                disabled={isLoading}
                                onClick={onClose}
                            >
                                Lukk
                            </Button>
                        </div>
                    </>
                    :
                    <div className='flex justify-center items-center flex-col'>
                        <Icon
                            icon='check-circle'
                            color='var(--color-success)'
                            size='6x'
                        />
                        <div className='mt-2 text-center font-semibold'>
                            Prosjektet er nå arkivert
                        </div>
                        <Button
                            className='mt-6'
                            onClick={toDashboard}
                        >
                            Ok
                        </Button>
                    </div>
                }
            </div>
        </Modal>
    )

};

ArchiveModal.propTypes = {
    project: PropTypes.object.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default ArchiveModal;