import React, { useState } from "react";
import { Button } from '~/components/base/Buttons';

const AssignUserItem = ({
    firstName,
    lastName,
    onClick,
    isResponsible = false,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onClickHandler = () => {
        if(!onClick) {
            return;
        }

        setIsLoading(true);
        const promise = onClick();
        Promise.all([promise])
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <div className="border-t flex items-center">
            <div className="flex-grow p-4 pl-0 w-3/12">
                {firstName} {lastName}
            </div>
            <Button
                onClick={onClickHandler}
                disableElevation
                color={isResponsible ? 'secondary' : 'primary'}
                {...rest}
                loading={isLoading}
                disabled={isLoading}
            >
                {
                    isResponsible ? 'Fjern ansvar' : 'Gi ansvar'
                }
            </Button>
        </div>
    )
}

export default AssignUserItem;
