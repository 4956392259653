import React from 'react';


// Components
import DocAggrPieChart from '~/components/graphs/DocAggrPieChart';
import Icon from '~/components/base/Icon';


const DocAggrModule = ({creditData, docData}) => {

  return (
      <div
          className='paper grid grid-cols-1 xl:grid-cols-2 gap-6'
      > 
        <div>
          <div className='flex items-center mb-4'>
            <Icon icon='file' size='1x' className='mr-2' />
            <h6 className='mb-1'>Dokumentasjon</h6>
          </div>
          <DocAggrPieChart
            data={docData}
          />
        </div>

        <div>
          <div className='flex items-center mb-4'>
            <Icon icon='check' size='1x' className='mr-2' />
            <h6 className='mb-1'>Poeng</h6>
          </div>
          <DocAggrPieChart
            data={creditData}
          />
        </div>
      </div>
  )
};

export default DocAggrModule;