import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { roles as ROLES } from '~/data/config';
import store from '~/store';

// State imports
import { useDispatch, useSelector } from 'react-redux';
import ProjectActions from '~/store/project/actions';
import ProjectSelectors from '~/store/project/selectors';

// Core Components
import Icon from '~/components/base/Icon';

// Components
import ReviewModal from '~/components/modals/ReviewModal';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import FileSystemModal from '~/components/modals/FileSystemModal';
import TimelineModal from '~/components/modals/TimelineModal';
import DatePickerModal from '~/components/modals/DatePickerModal';

// Style
import style from './CriteriaModule.module.css'

// Data
import SECTIONS from '~/data/sections.json';

// Module Components
import CriterionItem from './components/CriterionItem';
import { DOCUMENT_MODE_SECTION, DOCUMENT_MODE_CRITERIA } from '~/components/modals/FileSystemModal/FileSystemModal';
import { Button } from '~/components/base/Buttons';
import { checkIfAccessToCategory } from '~/utils/access';
import checkIfOwner from '~/utils/access/checkIfOwner';
import CriterionItemAdd from './components/CriterionItemAdd/CriterionItemAdd';

// Types
const REVIEW_SUBCRITERIA = 'REVIEW_SUBCRITERIA';
const REVIEW_CRITERIA = 'REVIEW_CRITERIA';

const createReviewConfig = (type, criterionId = null, subCriterionId = null) => ({
    type: type,
    criterionId: criterionId,
    subCriterionId: subCriterionId,
});

const createClosedConfirmationConfig = (config) => {
    return {
        ...config,
        loading: false,
        show: false,
        onConfirm: () => { },
        onClose: () => { },
    }
}

const CriteriaModule = ({
    className,
    projectId,
    sectionId,
    manualCriteriaInSection,
    criteriaMetaData,
    userRole,
    archived,
}) => {
    const dispatch = useDispatch();
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);

    // State
    const [currentlyShowingTitle, setCurrentlyShowingTitle] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [showDatePickerModal, setShowDatePickerModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showTimelineModule, setShowTimelineModule] = useState(false);
    const [selectedCriterionId, setSelectedCriterionId] = useState(null);
    const [selectedSubCriterionId, setSelectedSubCriterionId] = useState(null);
    const [fileSystemConfig, setFileSystemConfig] = useState({});
    const [confirmationConfig, setConfirmationConfig] = useState({});
    const [newCriteria, setNewCriteria] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const criteriaView = useRef(null);
    const criteriaItemsRefs = useRef([]);


    useEffect(() => {
        let timeout;

        // Detects if CriterionItem is in viewport (10px from top)
        const handleScroll = () => {
            if (timeout) { window.cancelAnimationFrame(timeout); }
            if (!criteriaView.current || !criteriaItemsRefs.current || criteriaItemsRefs.current.length === 0) { return; }

            timeout = window.requestAnimationFrame(() => {
							// If we have scrolled
							const headerPosition = criteriaView.current.getBoundingClientRect().top;

							if (headerPosition > 20) {
								setCurrentlyShowingTitle(null);
							}

							// Finding which element is currently in the viewport
							criteriaItemsRefs.current.forEach((ref) => {
								if (!ref.element.current) return;
								const pos = ref.element.current.getBoundingClientRect().top;
								const title = ref.title;
								if (pos < 20 && pos > -ref.element.current.scrollHeight) {
									return setCurrentlyShowingTitle(title);
								}
							});
						})
        };

        // Init on load
        handleScroll();

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            timeout = undefined;
            window.removeEventListener('scroll', handleScroll);
        };
    }, [criteriaView]);

    const closeReviewAndTimelineModal = () => {
        setShowReviewModal(false);
        setShowTimelineModule(false);
        setTimeout(() => setSelectedSubCriterionId(null), 500);
    }

    const closeDatePickerModal = () => {
        setShowDatePickerModal(false);
        setTimeout(() => {
            setSelectedSubCriterionId(null);
            setSelectedSubCriterionId(null)
        }, 500);
    }

    const openDatePickerModal = (criterionId, subCriterionId) => {
        if (subCriterionId) {
            setSelectedSubCriterionId(subCriterionId);
        } else {
            setSelectedSubCriterionId(null)
        }
        setSelectedCriterionId(criterionId);
        setShowDatePickerModal(true);
    }

    const startReviewSubCriterion = (subCriterionId) => {
        setSelectedSubCriterionId(subCriterionId);

        if (userRole === ROLES.auditor) {
            setShowReviewModal(true);
        } else {
            setShowTimelineModule(true);
        }

    }

    const lockCriterion = (criterionId, allSubCriteriaMetaData) => {
        const lockFunc = () => {
            setConfirmationConfig((prev) => ({ ...prev, loading: true }))
            return ProjectActions.lockCriterion(projectId, criterionId, allSubCriteriaMetaData)(dispatch)
                .catch(console.error)
                .finally(() => closeConfirmationModal())
        };

        const title = 'Send inn til revisjon?';
        const description = 'Har du lyst til å låse alle tilhørende subkrav og sende inn til revisjon?';
        setConfirmationConfig({
            title: title,
            description: description,
            show: true,
            onConfirm: lockFunc,
            onClose: () => closeConfirmationModal(title, description),
        })
    }

    const lockSubCriterion = (criterionId, subCriterionId) => {
        const lockFunc = () => {
            setConfirmationConfig((prev) => ({ ...prev, loading: true }))
            return ProjectActions.lockSubCriterion(projectId, criterionId, subCriterionId)(dispatch)
                .catch(console.error)
                .finally(() => closeConfirmationModal())
        }

        const title = 'Send inn til revisjon?';
        const description = 'Har du lyst til å sende subkravet til revisjon?';
        setConfirmationConfig({
            title: title,
            description: description,
            show: true,
            onConfirm: lockFunc,
            onClose: () => closeConfirmationModal(title, description),
        });
    }

    const hasAllCriteria = () => {
        return manual.sections ?
            manual.sections.find((section) => section.id == sectionId).assessmentCriteria
                .every((criterion) => {
                    return project.scope[sectionId][criterion.id];
                })
            : false;
    }

    const closeConfirmationModal = (title, description) => {
        setConfirmationConfig({
            ...createClosedConfirmationConfig({}),
            title: title,
            description: description
        });
    }

    const showFileSystemModal = (directoryId, config = {}) => {

        // Check if one is able to create files or not
        const fileSystemConfig = {
            show: true,
            directoryId: directoryId,
            ...config,
        };

        setFileSystemConfig(fileSystemConfig)
    }

    const closeFileSystemModal = () => {
        setFileSystemConfig({
            show: false,
        });
    }

    const toggleCriteria = (critId, value) => {
        setNewCriteria({
            ...newCriteria,
            [critId]: value,
        })
    }

    const closeEditMode = () => {
        setEditMode(false);
        setNewCriteria({});
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setNewCriteria({});
    }

    const openConfirmationModal = () => {
        const criteriaToAdd = Object.entries(newCriteria)
            .reduce((prev, [id, value]) => {
                if (value === false) {
                    return prev;
                }
                return {
                    ...prev,
                    [id]: value,
                }
            }, {})
        if (Object.keys(criteriaToAdd).length <= 0) {
            closeEditMode();
            return;
        }
        setNewCriteria(criteriaToAdd);
        setShowConfirmationModal(true);
    }

    // Sends new criteria to action
    const saveNewCriteria = () => {
        setIsLoading(true);
        ProjectActions.addSectionAndCriteriaToProject(project.id, { criteria: newCriteria })(dispatch)
            .then((result) => {
                setShowConfirmationModal(false);
                closeEditMode();
            })
            .catch(error => {
                setError(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const CAN_LOCK = userRole === ROLES.owner;
    const CAN_SET_DEADLINE = userRole === ROLES.owner;

    return (
        <>
            <div className={`${className}`}>
                <DatePickerModal
                    projectId={projectId}
                    sectionId={sectionId}
                    criteriaMetaData={criteriaMetaData}
                    criterionId={selectedCriterionId || ''}
                    subCriterionId={selectedSubCriterionId || ''}
                    show={showDatePickerModal}
                    onClose={closeDatePickerModal}
                    canSetDeadline={CAN_SET_DEADLINE}
                />

                <ReviewModal
                    projectId={projectId}
                    subCriterionId={selectedSubCriterionId || ''}
                    show={showReviewModal}
                    onClose={closeReviewAndTimelineModal}
                />

                <TimelineModal
                    projectId={projectId}
                    subCriterionId={selectedSubCriterionId || ''}
                    show={showTimelineModule}
                    onClose={closeReviewAndTimelineModal}
                />

                <ConfirmationModal
                    title={confirmationConfig.title}
                    description={confirmationConfig.description}
                    show={confirmationConfig.show}
                    loading={confirmationConfig.loading}
                    onConfirm={confirmationConfig.onConfirm || closeConfirmationModal}
                    onClose={confirmationConfig.onClose || closeConfirmationModal}
                />

                <FileSystemModal
                    show={Boolean(fileSystemConfig.show)}
                    onClose={closeFileSystemModal}
                    projectId={projectId}
                    canWrite={fileSystemConfig.canWrite}
                    directoryId={fileSystemConfig.directoryId || ''}
                    directoriesToExclude={fileSystemConfig.directoriesToExclude}
                    config={{
                        sectionId: sectionId,
                        criterionId: fileSystemConfig.criterionId || '',
                    }}
                />

                <div className={`p-3 pt-1 pb-6 rounded-md  ${editMode ? 'relative bg-transparent' : `${style.criteriaHeader} ${currentlyShowingTitle ? style.darken : 'bg-white'}`}`}>
                    <div className='flex justify-center'>
                        <h5 className={`font-light ${currentlyShowingTitle ? '' : style.transform} ${style.header}`}>
                            Krav - {sectionId && SECTIONS[sectionId]}
                        </h5>
                        <span className={`${currentlyShowingTitle ? '' : style.initExtraHeader} ${style.criteriaHeaderExtra}`}>
                            {currentlyShowingTitle}
                        </span>

                        {
                            CAN_LOCK && (
                                <>
                                    {/* <h6 className='font-light text-base underline cursor-pointer mb-0 mr-2'>
                                    Send alt til revisjon
                                </h6>
                                <Icon icon='lock' size='lg' /> */}
                                </>
                            )
                        }
                    </div>
                </div>


                <div ref={criteriaView}>
                    {
                        !editMode ?
                            criteriaMetaData
                                .map(c =>
                                    <CriterionItem
                                        key={c.id}
                                        id={c.id}
                                        manualCriteriaInSection={manualCriteriaInSection}
                                        criterionMetaData={c}
                                        onReviewSubCriterion={startReviewSubCriterion}
                                        onLockCriterion={lockCriterion}
                                        onLockSubCriterion={lockSubCriterion}
                                        onFileSystemOpen={showFileSystemModal}
                                        onShowDatePicker={openDatePickerModal}
                                        criteriaItemsRefs={criteriaItemsRefs}
                                        userRole={userRole}
                                        archived={archived}
                                    />
                                )
                            :
                            <div className=''>
                                {(manual.sections
                                    .find((s) => s.id === sectionId)
                                    .assessmentCriteria || [])
                                    .reduce((prev, c) => {
                                        if (project.scope[sectionId][c.id]) {
                                            return [...prev,
                                            <CriterionItemAdd
                                                key={c.id}
                                                id={c.id}
                                                manualCriteriaInSection={manualCriteriaInSection}
                                                inProject={project.scope[sectionId][c.id] ? true : false}
                                                onChange={(critId, value) => toggleCriteria(critId, value)}
                                            />]
                                        }
                                        return prev
                                    }, [])
                                }
                            </div>
                    }

                </div>

                <ConfirmationModal
                    show={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    onConfirm={saveNewCriteria}
                    title={`Er du sikker?`}
                    loading={isLoading}
                    description={!error ? `Du vil legge til ${Object.values(newCriteria)
                        .reduce((prev, value) => {
                            return prev + (value ? 1 : 0)
                        }, 0)} krav` : `ERROR: ${error}, Prøv igjen? `}
                >

                </ConfirmationModal>
            </div>
            {editMode &&
                <div className={`${className} paper`}>
                    {(manual.sections
                        .find((s) => s.id === sectionId)
                        .assessmentCriteria || [])
                        .reduce((prev, c) => {
                            if (!project.scope[sectionId][c.id]) {
                                return [...prev,
                                <CriterionItemAdd
                                    key={c.id}
                                    id={c.id}
                                    manualCriteriaInSection={manualCriteriaInSection}
                                    inProject={project.scope[sectionId][c.id] ? true : false}
                                    onChange={(critId, value) => toggleCriteria(critId, value)}
                                />]
                            }
                            return prev
                        }, [])
                    }
                </div>
            }
            {
                checkIfOwner(project) && !hasAllCriteria() && !project.archived &&
                <div className='relative'>
                    <div className='flex items-center'>
                        <div className='p-1'>
                            <Button
                                onClick={toggleEditMode}
                            >

                                <div className=''>
                                    <Icon icon={editMode ? 'arrow-left' : 'plus'} />
                                </div>
                            </Button>
                        </div>
                        {editMode &&
                            <div className='p-1'>
                                <Button
                                    onClick={openConfirmationModal}
                                >
                                    <div className=''>
                                        <Icon icon='save' />
                                    </div>
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
};

CriteriaModule.propTypes = {
    className: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    manualCriteriaInSection: PropTypes.array, // The criterias belonging to a section in the manual
    criteriaMetaData: PropTypes.array.isRequired, // The metadata belonging to the criteria
}

CriteriaModule.defaultTypes = {
    manualCriteriaInSection: [],
}

export default CriteriaModule;
