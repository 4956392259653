import React from 'react';
import PropTypes from 'prop-types';

// Project components
import EventItem from './components/EventItem';

const EventList = ({
    events,
    projectId,
}) => {

    return (
        <div>
            {
                events
                .sort((eventA, eventB) => -eventA.timestamp.localeCompare(eventB.timestamp))
                .slice(0, 4)
                .map((event) => (
                    <EventItem 
                        key={event.id} 
                        event={event}
                        projectId={projectId}
                    />
                ))
            }
        </div>
    )
}

EventList.propTypes = {
    events: PropTypes.array.isRequired,
}

export default EventList;