import React from 'react';

// Project components
import Icon from '~/components/base/Icon';
import Collapse from '~/components/base/Collapse';

const CategoryItem = ({ category, onClick, isOpen, children, className, childrenClassName, weak }) => {
    return (
        <>
            <div className={`${className} px-2 hover:bg-gray-250 py-2 rounded-sm shadow-none flex items-center mb-1 cursor-pointer`} onClick={onClick}>
                <p className={`mb-0 font-semibold ${weak ? 'text-gray-500' : ''}`}>{category}</p>
                <div className='flex-grow' />
                <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
            </div>

            <Collapse
                visible={isOpen}
            >
                <div className={childrenClassName}>
                    {children}
                </div>
            </Collapse>

        </>
    )
};

export default CategoryItem;