import React from 'react';

const TextArea = ({className = '', children, color = 'primary', value, onChange, maxLength, ...rest}) => {

    return (
        <div>
            <textarea
                className={`${className} border border-solid border-gray-400 w-full p-4 rounded focus:border-${color} focus:outline-none disabled:bg-gray-200 disabled:cursor-not-allowed`}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                {...rest}
            />
            {
                maxLength &&
                <p className='caption text-right'>{ (value || '').length }/{maxLength}</p>
            }
        </div>
    )
};

export default TextArea;