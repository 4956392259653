import React from 'react';
import PropTypes from 'prop-types';

const ArchivedList = ({className = '', children, columns = []}) => {
    
    return (
        <div className = {className}>
            <div className = {` grid grid-cols-${columns.length} overflow-y-auto`}>
                {
                    columns.map((c) => (
                        <div key={c}>
                            <b>{c}</b>
                        </div>
                    ))
                }
            </div>
            {children}
        </div>
    )
}

ArchivedList.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array.isRequired,
}

export default ArchivedList;
