import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { statuses as STATUSES } from '~/data/config';

// Store imports
import { useDispatch } from 'react-redux';
import DocumentActions from '~/store/documents/actions';

// Core components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import TextArea from '~/components/base/TextArea';
import Collapse from '~/components/base/Collapse';
import Tabs from '~/components/base/Tabs';
import timeAgo from '~/utils/date/timeAgo';

const DEFAULT_COLOR = 'gray-600';

const TAB_VALUES = [{value: 0, label: 'Tilbakemelding'}, {value: 1, label: 'Samsvarsnotat'}]
/**
 * FileReviewItem is a component that is used for reviewing a documentMeta - indirectly reviewing a document
 */
const FileReviewItem = ({
    projectId,
    docMeta = {},
    onChange,
}) => {
    const dispatch = useDispatch();

    const [showTextArea, setShowTextArea] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const onDocMetaChange = (key, newValue) => {
        if(onChange) {
            onChange(docMeta.id, {
                ...docMeta,
                [key]: newValue
            })
        }
    }

    const onStatusChange = (statusValue) => {
        if(docMeta.status === statusValue) {
            onDocMetaChange('status', null);
        } else {
            onDocMetaChange('status', statusValue);

            setShowTextArea(true);
        }
    }

    const downloadFile = () => {
        DocumentActions.downloadFile(projectId, docMeta.document)(dispatch);
    }

    const metaData = docMeta.metaData || {};

    return (
        <div className='border-b'>
            <div className='grid grid-cols-3 md:grid-cols-4 items-center gap-4 py-2'>
                <div className='font-bold capitalize'>
                    {
                        metaData.attachmentCode ?
                        metaData.attachmentCode.split("_").join(" ") : ''
                    }
                </div>
                <div
                    className='font-light hover:underline cursor-pointer col-span-2 truncate flex items-center'
                    onClick={downloadFile}
                >
                    <div>
                        {
                            docMeta.fileName
                        }
                    </div>
                    {
                        docMeta.createdAt &&
                        <div className='text-gray-600 ml-2'>
                            {
                               timeAgo(new Date(docMeta.createdAt || '')).toLowerCase()
                            }
                        </div>
                    }
                </div>
                <div className='flex sm:justify-end'>
                    <Button
                        color={DEFAULT_COLOR}
                        variant='icon'
                        disabled={!showTextArea && !Boolean(docMeta.status)}
                        onClick={() => setShowTextArea(!showTextArea)}
                    >
                        <Icon icon={showTextArea ? 'times' : 'edit'} />
                    </Button>
                    <Button
                        color={docMeta.status === STATUSES.disapproved ? 'error' : DEFAULT_COLOR}
                        variant='icon'
                        onClick={() => onStatusChange(STATUSES.disapproved)}
                    >
                        <Icon icon='times-circle' />
                    </Button>
                    <Button
                        color={docMeta.status === STATUSES.approved ? 'success' : DEFAULT_COLOR}
                        variant='icon'
                        onClick={() => onStatusChange(STATUSES.approved)}
                    >
                        <Icon icon='check-circle' />
                    </Button>
                </div>
            </div>
            <Collapse
                visible={showTextArea}
            >
                <div className='mt-2'>
                    <Tabs
                        tabs={TAB_VALUES}
                        value={tabValue}
                        onChange={setTabValue}
                    />
                    <Collapse
                        visible={tabValue === 0}
                    >
                        <TextArea
                            className='mt-2'
                            rows={4}
                            value={docMeta.statusNote || ''}
                            onChange={(e) => onDocMetaChange('statusNote', e.target.value)}
                            placeholder='Skriv en tilbakemelding'
                        />
                    </Collapse>
                    <Collapse
                        visible={tabValue === 1}
                    >
                        <div
                            className={`font-light mt-2 ${!docMeta.note || docMeta.note.length === 0 ? 'text-gray-600' : ''}`}
                        >
                            {
                                (docMeta && docMeta.note && docMeta.note.length > 0 ? docMeta.note : 'Ingen samsvarsnotat å vise')
                            }
                        </div>
                    </Collapse>
                </div>
            </Collapse>
        </div>

    )
}

FileReviewItem.propTypes = {

    projectId: PropTypes.string.isRequired,

    /**
     * A documentMeta item
     */
    docMeta: PropTypes.object.isRequired,

    onChange: PropTypes.func.isRequired,
}

export default FileReviewItem;