import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from '~/components/base/Buttons';

// Actions
import UserActions from '~/store/user/actions';

// Data
import {member as MEMBER} from '~/data/config';


const AddUserItem = (props) => {
    const dispatch = useDispatch();

    // State
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState(null);

    const addUser = () => {
        setIsloading(true);
        setError(null);

        UserActions.editUserAccess(props.projectId, [{
            id: props.id,
            sections: {
                [props.sectionId]: MEMBER.SECTIONS_VALUE.ACCESS
            }
        }])(dispatch)
        .catch((error) => {
            setError(error.message);
        })
    }

    return (
        <div className="border-t flex items-center">
            <div className="flex-grow p-4 pl-0 w-3/12">
                {props.firstname} {props.lastname}
            </div>
            <Button
                onClick={addUser}
                loading={isLoading}
                disabled={isLoading}
            >
                Legg til
            </Button>
            {
                    error && !isLoading ?
                    <div className='bg-red-400 p-3 mb-3 shadow rounded'>
                        <p>{error}</p>
                    </div> : null
                }
        </div>
    )
}

export default AddUserItem;
