import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.css';

const Checkbox = ({
    className = '',
    value,
    labelText,
    onChange,
    disabled,
    color = 'primary'
}) => {

    const onValueChange = (event) => {
        if(onChange) {
            onChange(event.target.checked);
        }
    }

    const borderColor = disabled ? 'gray-600' : color;
    const checkedStyles = value ? `border-${borderColor} bg-${borderColor}` : '';
    const disabledStyles = 'bg-gray-300';

    return (
        <span className={`${className} ${styles.root} ${disabled ? 'cursor-not-allowed' : styles.hover}`} >
            <div className={`${styles.box} ${`border-${borderColor}`} border-solid border-2 ${checkedStyles} ${disabled ? disabledStyles : ''}`}>
                { value && <span className={`${styles.checkmark} ${disabled ? 'border-gray-400' : ''}`} />}
            </div>
            <input type='checkbox' checked={value} onChange={onValueChange} disabled={disabled} className={`${styles['checkbox-input']} ${disabled ? 'cursor-not-allowed' : ''}` } />
            {
                labelText ?
                    <span className="ml-2">{labelText}</span>
                    : null
            }
        </span>
    )
}

Checkbox.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Checkbox;