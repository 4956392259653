import React from 'react'

import { useHistory, Link } from 'react-router-dom'

// Styles
import styles from './NotFound.module.css';

// Components
import KvistLogo from '~/components/miscellaneous/KvistLogo';
import ErrorImage from '~/components/miscellaneous/Illustrations/Error-404';

const NotFound = () => {
    const history = useHistory();
    const goToStart = () => history.push('/');
    return (
        <>
            <KvistLogo className='ml-5 mt-5 cursor-pointer absolute' width='10rem' onClick={goToStart} />
            <div className='grid content-center justify-center h-screen'>
                <div className='relative p-4 flex flex-col'>
                    <div className="relative flex flex-col">
                        <ErrorImage width="30rem" className={`self-end ${styles.svg}`}>
                            <span className={styles.text1}>Kjenner du til noen sider med linken <br></br>{window.location.href}?</span>
                            <span className={styles.text2}>Næh, tror noen har gått seg vill...</span>
                        </ErrorImage>
                    </div>
                    <h3>
                        Beklager, her er det ingenting spennende å finne...
                    </h3>
                    <Link to='/' className='hover:underline text-base md:text-lg self-start'>Trykk her for å komme til forsiden</Link>
                </div>

            </div>
        </>
    )
}

export default NotFound
