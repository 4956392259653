import React from 'react';
import { statuses as STATUSES } from '~/data/config';

// Components
import Icon from '~/components/base/Icon';

// Utils
import statusProperties from '~/utils/subCriteria/statusProperties';

const StatusIcon = ({className, status, ...rest}) => {

    const [_, statusIcon] = statusProperties(status);
    let statusColor = null;
    switch(status) {
        case STATUSES.disapproved:
            statusColor = "var(--color-error)"
            break; 
        case STATUSES.approved:
            statusColor = "var(--color-success)"
            break;
        case STATUSES.pending:
            statusColor = "var(--color-pending)"
            break;
        default:
            statusColor = "#718096"
    }

    return <Icon 
        className={className}
        icon={statusIcon}
        color={statusColor}
        {...rest}
    /> 
};

export default StatusIcon;