import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Selectv2.module.css";

/**
 * Select is a normal dropdown component
 */
const Select = ({
    className = "",
    color = "primary",
    value,
    onChange,
    placeholder = "Trykk for å velge",
    data = [],
    disabled = false,
    margin = true
}) => {
    const [selectValue, setSelectValue] = useState(value || null);
    const [optionChosen, setOptionChosen] = useState(false);
    const handleChange = (event) => {
        if (!optionChosen) {
            setOptionChosen(true);
        }
        setSelectValue(event.target.value);
        onChange && onChange(event.target.value);
    };

    useEffect(() => {
        if (selectValue) {
            setOptionChosen(true);
        }
    }, []);

    useEffect(() => {
        setSelectValue(value)
    },[value])

    return (
        <select
            className={`${styles.selectList} ${className}`}
            aria-label="Default select example"
            value={selectValue}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
        >
            {!optionChosen ? <option value={null}>{placeholder}</option> : null}
            {data.map((d) => (
                <option key={d.value} value={d.value}>
                    {d.label}
                </option>
            ))}
        </select>
    );
};

Select.propTypes = {
    // The value of the selected item
    value: PropTypes.any,

    // Data is an array of label-value data - { label: ..., value: ... }
    data: PropTypes.array,

    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    margin: PropTypes.bool
};

export default Select;
