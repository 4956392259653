import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './DatePickerModal.module.css';

// Store imports
import { useSelector, useDispatch } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';
import ProjectActions from '~/store/project/actions';

// Components
import { Button } from '~/components/base/Buttons';
import Modal from '~/components/base/Modal';
import DatePicker from '~/components/base/DatePicker';

// Data
import CATEGORIES from '~/data/categories.json';
import SECTIONS from '~/data/sections.json';

// Utils
import typeOf from '~/utils/type/typeOf';

/**
 * TimePickerModal is a modal that displays a datepicker
 */
const TimePickerModal = ({
    projectId,
    sectionId,
    criterionId,
    criteriaMetaData = [],
    subCriterionId,
    subCriteriaIds,
    show,
    onClose,
    canSetDeadline,
    ...rest }) => {
    const [currentValue, setCurrentValue] = useState(null);
    const [chosenDate, setChosenDate] = useState(null);
    const [foundDeadline, setFoundDeadline] = useState(null);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [date, setDate] = useState('');
    const [year, setYear] = useState('');
    const [currentlySubmitting, setCurrentlySubmitting] = useState(false)
    const todaysDate = new Date();

    const [alreadyHasDeadline, setAlreadyHasDeadline] = useState(false)

    const dispatch = useDispatch();

    const criteriaInfo = criteriaMetaData.find(criteria => criteria.id === criterionId) || {};

    const listOfAllDaysAsText = [
        'Søndag',
        'Mandag',
        'Tirsdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lørdag',
    ];

    const listOfAllMonthsAsText = [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    const categoryId = useSelector(ProjectSelectors.getCurCategory);

    //if (!Array.isArray(subCriterions)) { throw Error('List of subcriterions should be an array.') }

    /**
     * This has to be put into a useEffect or something else that improves performance
     */
    if (subCriterionId && !!criteriaInfo.subCriteria) {
        const singleSubCriteriaDeadline = ((criteriaInfo.subCriteria || {})[subCriterionId] || {}).deadline;

        if (!!singleSubCriteriaDeadline && typeOf(chosenDate) !== 'date') {
            setFoundDeadline(new Date(singleSubCriteriaDeadline));

            handleDateChange(foundDeadline);
            setAlreadyHasDeadline(true)
        }
    }


    const orderedListOfSubCriteria = Object.keys((criteriaInfo.subCriteria || {})).map(criteria => parseInt(criteria.split('__')[1])).sort((a, b) => a - b);

    // Formating title: if there's one criteria: display number | if there's more than one: display "<first> - <last>" | if there's none: be empty
    const criteriaTitle = subCriterionId ?
        subCriterionId.split('__')[1] :
        orderedListOfSubCriteria.length === 1 ?
            orderedListOfSubCriteria[0] :
            orderedListOfSubCriteria.length !== 0 ?
                `${orderedListOfSubCriteria[0]} - ${orderedListOfSubCriteria[orderedListOfSubCriteria.length - 1]}` :
                '';


    useEffect(() => {
        if (show) {
            // Resetting the chosen date
            setChosenDate(null);
            setAlreadyHasDeadline(false);
            setCurrentValue(null);
            setFoundDeadline(null);
        }
    }, [show])


    function handleDateChange(date) {
        if (!date) { return };

        const [Day, Month, Date, Year] = date.toString().split(' ');
        setDay(date.getDay());
        setMonth(date.getMonth());
        setDate(Date);
        setYear(Year);
        setChosenDate(date);

        if (foundDeadline && !currentValue) {
            date.setHours(0, 0, 0);
            setCurrentValue(date);
        }
    }

    const setDeadline = () => {
        const deadline = chosenDate;
        // Formatting date to ISO with correct timezone-difference (the ISO deadline should have hours 00:00:00 in the database)
        const timezoneOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const offsetDeadline = (new Date(deadline - timezoneOffset))
        const formatedDeadline = offsetDeadline.toISOString();

        setCurrentlySubmitting(true)

        ProjectActions.setDeadlineOnCriteria(projectId, criterionId, subCriterionId, formatedDeadline)(dispatch)
            .then(data => {
                // Do something cool here?
                setCurrentValue(chosenDate);
            })
            .catch((error) => { console.error(error) })
            .finally(() => { setCurrentlySubmitting(false) })
    }

    const resetDeadline = () => {
        setCurrentlySubmitting(true)

        ProjectActions.removeDeadlineOnCriteria(projectId, criterionId, subCriterionId)(dispatch)
            .then(data => {
                // Do something cool here?
            })
            .catch((error) => { console.error(error) })
            .finally(() => {
                setCurrentlySubmitting(false);
                onClose();
            })
    }

    const deadlineBeforeToday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        return chosenDate < yesterday
    }

    const changeDeadline = () => {
        setAlreadyHasDeadline(false);
        // if (deadlineBeforeToday()) { setChosenDate(null) } // This should work but doesn't
    }

    return (
        <Modal
            {...rest}
            show={show}
            onBackdropClick={onClose}
        >
            <div className={`paper pt-6 pb-4 ${styles.modal}`}>
                <span className="opacity-75 text-sm">{categoryId && ((CATEGORIES.find(c => c.key === categoryId) || {}).name || '')} - {((SECTIONS || {})[sectionId]) || ''}</span>
                <h3 className="mt-2">
                    {alreadyHasDeadline ? 'Tidsfrist' : 'Sett tidsfrist'} på {criteriaTitle && orderedListOfSubCriteria.length > 1 ? `kriterier ${criteriaTitle}` : criteriaTitle ? `kriterie ${criteriaTitle}` : 'kriteriene under'}
                </h3>

                {alreadyHasDeadline ?
                    <div className='p-2 text-lg flex justify-between items-start'>
                        <b>
                            {deadlineBeforeToday() ? 'Utgått tidsfrist:' : 'Dato valgt:'} {`${listOfAllDaysAsText[day]} ${date}. ${listOfAllMonthsAsText[month]} ${year}`}
                        </b>
                        <div className="flex flex-col justify-around h-24">
                            <Button
                                disableElevation
                                className='rounded-md py-2 px-3'
                                onClick={changeDeadline}
                            >
                                {deadlineBeforeToday() ? 'Sett ny tidsfrist' : 'Endre tidsfrist'}
                            </Button>

                            <Button
                                disableElevation
                                className='rounded-md py-2 px-3'
                                color='error'
                                disabled={currentlySubmitting}
                                onClick={resetDeadline}
                                loading={currentlySubmitting}
                            >
                                Fjern tidsfrist
                            </Button>
                        </div>
                    </div>
                    :
                    <>
                        <DatePicker initValue={chosenDate} className='mt-4 p-1' minDate={todaysDate} handleDateValue={(value) => handleDateChange(value)} />
                        <div className='p-2 text-lg flex justify-between h-24 items-center'>
                            {
                                (!deadlineBeforeToday() && typeOf(chosenDate) === 'date') ?
                                    <>
                                        <b>Dato valgt: {`${listOfAllDaysAsText[day]} ${date}. ${listOfAllMonthsAsText[month]} ${year}`}</b>
                                        {((typeOf(currentValue) === 'null' && typeOf(chosenDate) === 'date') || (currentValue.toString() !== chosenDate.toString()))
                                            ?
                                            <Button
                                                disableElevation
                                                disabled={currentlySubmitting}
                                                onClick={setDeadline}
                                                loading={currentlySubmitting}
                                            >
                                                Bekreft tidsfrist
                                            </Button>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    <>
                                        Velg dato for å sette tidsfrist.
                                    </>
                            }
                        </div>
                    </>
                }
            </div>
        </Modal >
    )
};

TimePickerModal.propTypes = {
    projectId: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    criterionId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string.isRequired,
    criteriaMetaData: PropTypes.array,
    subCriteriaIds: PropTypes.array,
    subCriterionId: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    canSetDeadline: PropTypes.bool,
};
export default TimePickerModal;