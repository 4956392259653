import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '~/components/base/Icon';

const LockIcon = ({
    className, 
    isLocked = false, 
    ...rest
}) => {


    return <Icon 
        className={className}
        icon={isLocked ? 'lock' : 'unlock'}
        color={isLocked ? 'black' : '#718096'} 
        {...rest}
    /> 
};

LockIcon.propTypes = {
    isLocked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

export default LockIcon;