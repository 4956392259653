/**
 * @author Mathias Picker
 * @param {*} item item to typecheck
 * @description An enhanced version of typeof, because JavaScript is confusing.
 */

export default function typeOf(item) {
    if (Number.isNaN(item)) { return 'NaN' }

    return Object.prototype.toString.call(item).slice(8, -1).toLowerCase();

    /** The best way to check the true type of something is by using the 
     * Object.prototype.toString.call() function, and it always returns 
     * a string formated like '[object Type]'. Therefore by slicing 
     * the part we want and making it lowercase we get the same result as 
     * doing something like this:
    switch (Object.prototype.toString.call(item)) {
        case '[object Array]':
            return 'array';
        case '[object Error]':
            return 'error';
        case '[object Float32Array]':
            return 'float32array'
            ...
    }
    */
}