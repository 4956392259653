import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { roles as ROLES, roles } from '~/data/config';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

// Styles
import styles from './SubCriterionModule.module.css';

// Store
import { useSelector, useDispatch } from 'react-redux';
import ProjectActions from '~/store/project/actions';
import ProjectSelectors from '~/store/project/selectors';

// Components
import Tabs from '~/components/base/Tabs';
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import Collapse from '~/components/base/Collapse';
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import LockIcon from '~/components/miscellaneous/LockIcon';
import { DeadlineIcon } from '~/components/miscellaneous/DeadlineIcon';

// Module components
import ReviewModule from '~/components/modules/ReviewModule';
import TimeLineModule from '~/components/modules/TimelineModule';
import ConfirmationModal from '~/components/modals/ConfirmationModal';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import { calculateOneDeadline, deadlineIconWithPlacement } from '~/utils/subCriteria';

// Config
import { deadlineIconPlacements } from '~/components/miscellaneous/DeadlineIcon/data/config';
import { statuses as STATUSES } from '~/data/config';

// Constants
const TAB_INFO = 0;
const TAB_TIMELINE = 1;
const TAB_REVIEW = 2;
const TABS = [
    { value: TAB_INFO, label: 'Info' },
    { value: TAB_TIMELINE, label: 'Tidslinje' },
    { value: TAB_REVIEW, label: 'Gi vurdering' },
];

const SubCriterionModule = ({
    projectId,
    subCriterionId,
    subCriterionMeta,
    manualData
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const queryParams = useMemo(() => qs.parse(location.search) || {}, [location.search]);
    const event = queryParams.event;

    const archived = useSelector(ProjectSelectors.getCurrentArchived);

    // State
    const [tab, setTab] = useState(event ? TAB_TIMELINE : TAB_INFO);
    const [mountedSubCriterionId, setMountedSubCriterionId] = useState(null);
    const [mountedReview, setMountedReview] = useState(null);
    const [reviewModuleRefreshKey, setReviewModuleRefreshKey] = useState('DEFAULT');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isLoadingLock, setIsLoadingLock] = useState(false);

    const sectionId = manualData.sectionId;
    const manualSubCriterion = manualData.subCriterion || {};

    // Selectors
    const userRole = useSelector(ProjectSelectors.getCurProjectRole);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setTab(event ? TAB_TIMELINE : TAB_INFO);
        setMountedSubCriterionId(event ? subCriterionId : null);
    }, [subCriterionId])

    useEffect(() => {
        if (tab === TAB_TIMELINE) {
            setMountedSubCriterionId(subCriterionId);
        } else if (tab === TAB_REVIEW) {
            setMountedReview(subCriterionId);
        }
    }, [tab])

    const lockCriterion = () => {
        setIsLoadingLock(true);
        ProjectActions.lockSubCriterion(projectId, manualData.criterionId, manualData.subCriterionId)(dispatch)
            .finally(() => {
                setIsLoadingLock(false);
                setShowConfirmModal(false);
            });
    }

    const onReviewModuleClose = () => {
        setTab(TAB_TIMELINE);
        setReviewModuleRefreshKey(new Date().toISOString());
    }

    const authorizedTabs = userRole === roles.auditor && Boolean(subCriterionMeta.locked) ? TABS : TABS.slice(0, 2);

    return (
        <div className='bg-white rounded-lg shadow-md'>
            <ConfirmationModal
                title='Send inn til revisjon?'
                description={`Sende inn ${sectionIdToText(sectionId)} - Krav ${manualSubCriterion.originalId} til revisjon?`}
                show={showConfirmModal}
                onConfirm={lockCriterion}
                loading={isLoadingLock}
                onClose={() => setShowConfirmModal(false)}
            />

            <Tabs
                className='max-w-md'
                tabs={authorizedTabs}
                value={tab}
                onChange={setTab}
            />
            <hr />
            <div className={`px-10 pb-10 pt-8 ${styles.root}`}>
                <Collapse
                    visible={tab === TAB_REVIEW}
                >
                    {
                        subCriterionId === mountedReview &&
                        <ReviewModule
                            projectId={projectId}
                            subCriterionId={subCriterionId}
                            refreshKey={reviewModuleRefreshKey}
                            onClose={onReviewModuleClose}
                        />
                    }
                </Collapse>
                <Collapse
                    visible={tab === TAB_TIMELINE}
                >
                    {
                        subCriterionId === mountedSubCriterionId &&
                        <div>
                            <TimeLineModule
                                projectId={projectId}
                                subCriterionId={subCriterionId}
                                disableHeader={false}
                                goTo={event}
                            />
                        </div>
                    }
                </Collapse>
                <Collapse
                    visible={tab === 0}
                >
                    <h6>
                        {sectionIdToText(sectionId)}
                    </h6>
                    <div className='flex items-center'>
                        <StatusIcon
                            size='lg'
                            className='mr-2'
                            status={subCriterionMeta.status}
                        />
                        <LockIcon
                            size='lg'
                            className='mr-2'
                            isLocked={Boolean(subCriterionMeta.locked)}
                        />
                        <h5 className='mb-0'>
                            Krav {manualSubCriterion.originalId}
                        </h5>
                        <div className='flex-grow' />
                        {subCriterionMeta.status !== STATUSES.approved &&
                            <DeadlineIcon
                                size='lg'
                                className='mr-5'
                                isDateTextVisible={true}
                                freeze={true}
                                deadlineStatus={deadlineIconWithPlacement(calculateOneDeadline(subCriterionMeta.deadline), deadlineIconPlacements.SUB_CRITERIA_MODULE)}
                            />
                        }
                        {
                            userRole === ROLES.owner && !archived &&
                            <Button
                                disabled={Boolean(subCriterionMeta.locked)}
                                disableElevation
                                onClick={() => setShowConfirmModal(true)}
                            >
                                <Icon className='mr-2' icon='lock' />
                                {Boolean(subCriterionMeta.locked) ? 'Låst' : 'Send til revisjon'}
                            </Button>
                        }
                    </div>
                    <div>
                        <div className='p-4'>
                            <div className='font-bold text-base'>Beskrivelse:</div>
                            {
                                manualSubCriterion.criteriaDescription &&
                                <p>
                                    {manualSubCriterion.criteriaDescription}
                                </p>
                            }
                            {
                                manualSubCriterion.subCriteria &&
                                <ul className='ml-4'>
                                    {
                                        manualSubCriterion.subCriteria.map(sub => (
                                            <p key={sub.id}>
                                                {sub.internalId}
                                                {". " + sub.subCriteriaDescription}
                                            </p>
                                        ))
                                    }
                                </ul>
                            }
                        </div>
                    </div>
                </Collapse>

            </div>
        </div>
    )
};

SubCriterionModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string.isRequired,
    subCriterionMeta: PropTypes.object.isRequired,
    manualData: PropTypes.object.isRequired,
};

export default SubCriterionModule;
