import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Config
import { deadlineIconPlacements } from './data/config';
import { dateStyles } from '~/data/config';

// Utils
import { dateToDateString } from '~/utils/date';
import { setIconColor } from './utils'

// Components
import Icon from '~/components/base/Icon';

// Styles
import style from './DeadlineIcon.module.css';

const DeadlineIcon = ({
    className,
    deadlineStatus,
    canSetDeadline,
    isDateTextVisible,
    freeze,
    parentControlsState,
    setDateTextVisibilityViaParent,
    ...rest
}) => {
    /**
     * We have the setDateTextVisibilityViaParent option because the the 
     * DeadlineIcon may be wrapped in a Button-component based on the role of the user.
     * If that's the case we want to show the date-text when the user hovers on the button, not the icon.
     */
    const [dateTextVisibility, setDateTextVisibility] = useState(false);

    useEffect(() => {
        setDateTextVisibility(isDateTextVisible);
    }, [isDateTextVisible])

    /**
     * @param {Boolean} allowedToChangeState 
     * @description Sets the visibility state through this functional component
     * or the parent.
     */
    const hideDateText = (allowedToChangeState) => {
        if (freeze) { return }

        if (!parentControlsState) {
            setDateTextVisibility(false);
        } else if (allowedToChangeState) {
            setDateTextVisibilityViaParent(false);
        }
    };

    const showDateText = () => {
        if (freeze) { return }

        if (!parentControlsState) {
            setDateTextVisibility(true);
        }
    };

    // The text that should be displayed when hovering the deadline-icon:
    const deadlineHoverText =
        deadlineStatus.deadline ? // Is there a deadline?
            dateToDateString(deadlineStatus.deadline, dateStyles.LONG) :
            deadlineStatus.overviewDescription ? // Is there overviewDescription?
                deadlineStatus.overviewDescription :
                'Ingen tidsfrist'; // Nothing found.

    // The hover-message of the DeadlineIcon will have different placements depending on where it is rendered.
    let deadlineClasses = '';

    /**
     * @param {String} classNameToAdd Class that should be added to the deadlineClasses.
     */
    const addToDeadlineClass = (classNameToAdd) => deadlineClasses += ` ${classNameToAdd}`;

    if (deadlineStatus.placement === deadlineIconPlacements.CRITERIA_ITEM) {
        addToDeadlineClass(style.criteria);

        if (canSetDeadline) {
            addToDeadlineClass(style.topmargin);
        }
    }
    else if (deadlineStatus.placement === deadlineIconPlacements.SUB_CRITERIA_MODULE) {
        addToDeadlineClass(style.subcriteriamodule);
    }
    else if (deadlineStatus.placement === deadlineIconPlacements.SUB_CRITERIA_ITEM) {
        addToDeadlineClass(style.subcriteria);
    }

    return (
        <span className='relative text-black' >
            {dateTextVisibility ?
                <b
                    onMouseEnter={() => hideDateText(true)}
                    className={deadlineClasses}
                >
                    {deadlineHoverText}
                </b>
                : null
            }
            <Icon
                className={className}
                icon={'clock'}
                color={setIconColor(deadlineStatus)}
                onMouseEnter={() => showDateText()}
                onMouseLeave={() => hideDateText(false)}
                {...rest}
            />
        </span >
    )
};

DeadlineIcon.propTypes = {
    className: PropTypes.string,
    deadlineStatus: PropTypes.object.isRequired,
    showDateText: PropTypes.bool,
    canSetDeadline: PropTypes.bool,
    isDateTextVisible: PropTypes.bool,
    freeze: PropTypes.bool,
    parentControlsState: PropTypes.bool,
    setDateTextVisibilityViaParent: PropTypes.func,
}

export default DeadlineIcon;