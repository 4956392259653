import React from 'react';

const TableBody = ({children}) => {

    return (
        <tbody>
            { children }
        </tbody>
    )
};

export default TableBody;