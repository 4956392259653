import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from '~/components/base/Buttons';
import DeadlineIcon from './DeadlineIcon';

const DeadlineIconButton = ({
    className,
    deadlineStatus,
    canSetDeadline,
    ...rest
}) => {
    const [dateTextVisibility, setDateTextVisibility] = useState(false);

    return (
        <Button
            className='m-auto'
            variant='icon'
            onMouseEnter={() => setDateTextVisibility(true)}
            onMouseLeave={() => setDateTextVisibility(false)}
            {...rest}
        >
            <DeadlineIcon
                deadlineStatus={deadlineStatus}
                size='lg'
                isDateTextVisible={dateTextVisibility}
                canSetDeadline={canSetDeadline}
                parentControlsState={true}
                setDateTextVisibilityViaParent={setDateTextVisibility}
            />
        </Button>
    )
};

DeadlineIconButton.propTypes = {
    className: PropTypes.string,
    deadlineStatus: PropTypes.object.isRequired,
    canSetDeadline: PropTypes.bool,
}

export default DeadlineIconButton;