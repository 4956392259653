import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Core components
import Icon from '~/components/base/Icon';

// Styles
import style from './TopBarIcon.module.css';

const TopBarIcon = ({
	to,
	variant,
	className,
	tabIndex,
	isActive,
	activeClassName,
	hoverText,
	icon,
}) => {
	const ref = useRef(null);
	const [pos, setPos] = useState({ fromRight: 0, fromTop: 0 });
	const [visible, setVisible] = useState(false);
	let textWidth = 0;

	/**
	 * We want to get the length of the text inserted, and this is
	 * a little "hack" that can quickly give us the length of the text:
	 */
	useEffect(() => {
		if (hoverText) {
			const measureElement = document.createElement('i');
			measureElement.innerText = hoverText;
			measureElement.style = 'display: inline-block;';
			document.body.appendChild(measureElement);
			textWidth = measureElement.scrollWidth;
			document.body.removeChild(measureElement);
		}
	}, []);

	useEffect(() => {
		if (hoverText && ref.current) {
			const { right, top } = ref.current.getBoundingClientRect();
			setPos({
				fromRight: window.innerWidth - right,
				fromTop: top,
			});
			ref.current.style.setProperty('--width', `${textWidth + 20}px`);
		}
	}, [ref]);

	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	return (
		<>
			<NavLink
				ref={ref}
				to={to}
				isActive={isActive}
				variant="icon"
				className={`${className} ${
					hoverText ? 'relative' : ''
				} hover:bg-gray-250 hover:text-black`}
				tabIndex={tabIndex}
				activeClassName={activeClassName}
				onMouseEnter={show}
				onMouseLeave={hide}
				exact
			>
				<Icon icon={icon}  />
				{hoverText && visible ? (
					<div
						onMouseEnter={hide}
						className={`${style.hover} ${
							pos.fromRight < 40 ? style.center : ''
						}`}
					>
						{hoverText}
					</div>
				) : null}
			</NavLink>
		</>
	);
};

export default TopBarIcon;
