import React, { useRef, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

// Core components
import Icon from '~/components/base/Icon';
import { Button } from '~/components/base/Buttons';

// Styles
import style from './ButtonHover.module.css';

const ButtonHover = forwardRef(({
	onClick,
	variant,
	className,
	activeClassName,
	hoverText,
	icon,
	...props
}, ref) => {
	const buttonRef = ref;
	const [pos, setPos] = useState({ fromRight: 0, fromTop: 0 });
	const [visible, setVisible] = useState(false);
	const [textWidth, setTextWidth] = useState(0);


	/**
	 * We want to get the length of the text inserted, and this is
	 * a little "hack" that can quickly give us the length of the text:
	 */
	useEffect(() => {
		if (hoverText) {
			const measureElement = document.createElement('i');
			measureElement.innerText = hoverText;
			measureElement.style = 'display: inline-block;';
			document.body.appendChild(measureElement);
			setTextWidth(measureElement.scrollWidth);
			document.body.removeChild(measureElement);
		}
	}, [hoverText]);

	useEffect(() => {
		if (hoverText && buttonRef && buttonRef.current) {
			const { right, top } = buttonRef.current.getBoundingClientRect();
			setPos({
				fromRight: window.innerWidth - right,
				fromTop: top,
			});
			buttonRef.current.style.setProperty('--width', `${textWidth + 20}px`);
		}
	}, [buttonRef]);

	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	return (
		<>
			<Button
				ref={buttonRef}
				variant={variant}
				onClick={onClick}
				className={`${className} ${hoverText ? 'relative' : ''}`}
				onMouseEnter={show}
				onMouseLeave={hide}
				{...props}
			>
				{props.children}
				{hoverText && visible ? (
					<div
						onMouseEnter={hide}
						className={`${style.hover} ${
							pos.fromRight < 40 ? style.center : ''
						}`}
					>
						{hoverText}
					</div>
				) : null}
			</Button>
		</>
	);
})

ButtonHover.propTypes = {
	onClick: PropTypes.func,
	variant: PropTypes.string,
	className: PropTypes.string,
	activeClassName: PropTypes.string,
	hoverText: PropTypes.string,
	icon: PropTypes.string
};

export default ButtonHover;
