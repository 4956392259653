import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Store import
import { useSelector, useDispatch } from 'react-redux';
import EventSelectors from '~/store/events/selectors';
import EventActions from '~/store/events/actions';

// Core components
import WarningModule from '~/components/modules/WarningModule';

const NotificationList = ({
    project,
}) => {
    const dispatch = useDispatch();

    const events = useSelector(EventSelectors.getProjectNotifications(project.id))

    useEffect(() => {
        if(events.length > 0) {
            return;
        }

        EventActions.fetchNotifications(project.id, {
            limit: 3,
        })(dispatch)

    }, [events.length, project.id])


    return (
        <div className='mb-4'>
            <h5 className='font-semibold text-lg'>
                { project.title }
            </h5>
            <div className='mt-3'>
                <WarningModule
                    dense
                    events={events}
                    projectId={project.id}
                />
            </div>
        </div>
    )
}

NotificationList.propTypes = {
    project: PropTypes.object.isRequired,
}

export default NotificationList;