import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './CriterionItem.module.css';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// ROUTES
import { goToProjectCriteria } from '~/ROUTES';

// Utils
import { calculateIsLocked, calculateStatus, calculateMultipleDeadlines, calculateOneDeadline, deadlineIconWithPlacement } from '~/utils/subCriteria';

// Components
import ActionBtns from '~/components/miscellaneous/ActionBtns';
import { DOCUMENT_MODE_CRITERIA } from '~/components/modals/FileSystemModal/FileSystemModal';

// Config
import { deadlineIconPlacements } from '~/components/miscellaneous/DeadlineIcon/data/config';

const getAllSubCriteriaMetaData = (criteriaInfo, criterionMetaData) => {
    return ((criteriaInfo || {})
        .criteria || [])
        .map(subCriterion => ({ [subCriterion.id]: {} }))
        .reduce((acc, val) => ({ ...val, ...acc }), criterionMetaData.subCriteria)
};

const CriterionItem = ({
    id,
    manualCriteriaInSection,
    criterionMetaData,
    onReviewCriterion,
    onReviewSubCriterion,
    onLockCriterion,
    onLockSubCriterion,
    onFileSystemOpen,
    onShowDatePicker,
    criteriaItemsRefs,
    userRole,
    archived,
}) => {
    const criteriaInfo = manualCriteriaInSection.find(sc => sc.id === id) || {};
    const otherSubCriterionIds = manualCriteriaInSection.filter(sc => sc.id !== id).map(sc => sc.criteria.map(subC => subC.id)).flat();
    const params = useParams();
    const history = useHistory();
    const criterionRef = useRef(null);

/**
 * Instead of having an event-listener on each component (bad practice), we push the information about the
 * component to the CriteriaModule (parent) so that we can use event-delegation instead of
 * having multiple eventListeners.
 */
    useEffect(() => {
        if (criteriaItemsRefs.current && criterionRef.current) {
            criteriaItemsRefs.current.push({ element: criterionRef, title: criteriaInfo.title })
        }
    }, [criterionRef]);

    const allSubCriteriaMetaData = getAllSubCriteriaMetaData(criteriaInfo, criterionMetaData);

    const reviewSubCriterion = (subCriterionId) => {
        if (onReviewSubCriterion) {
            onReviewSubCriterion(subCriterionId);
        }
    }

    const lockCriterion = () => {
        if (onLockCriterion) {
            onLockCriterion(criterionMetaData.id, getAllSubCriteriaMetaData(criteriaInfo, criterionMetaData));
        }
    }

    const lockSubCriterion = (subCriterionId) => {
        if (onLockSubCriterion) {
            onLockSubCriterion(criterionMetaData.id, subCriterionId);
        }
    }

    const showDocuments = (directoryId, diretoryFilters = [], subCriterionId = null) => {
        if (onFileSystemOpen) {
            onFileSystemOpen(directoryId, {
                directoriesToExclude: diretoryFilters,
                criterionId: id,
                subCriterionId: subCriterionId,
            });
        }
    }

    const getSubCriterionMetaData = (subCriterionId) => {
        return ((criterionMetaData.subCriteria || {})[subCriterionId] || {});
    }

    const isSubCriterionLocked = (subCriterionId) => {
        return !!(getSubCriterionMetaData(subCriterionId).locked);
    }

    const subCriterionStatus = (subCriterionId) => {
        return getSubCriterionMetaData(subCriterionId).status;
    }

    const subCriterStatusDeadline = (subCriterionId) => {
        const deadline = getSubCriterionMetaData(subCriterionId).deadline;
        return calculateOneDeadline(deadline);
    }




    return (
        <div className='mt-8 mb-6 paper' ref={criterionRef}>
            <h6>{criteriaInfo && criteriaInfo.title}</h6>
            <div className='flex items-center mb-8'>
                <h6 className='font-bold text-base mb-0 mr-8'>
                    {criteriaInfo && criteriaInfo.credits} poeng
                </h6>
                <ActionBtns
                    onLock={lockCriterion}
                    onShowDocuments={() => showDocuments(
                        criterionMetaData.id.split('_').slice(0, 2).join("_"), // "man_01__01" => "man_01" 
                        otherSubCriterionIds, // Exclude these directories
                    )}
                    onShowDatePicker={() => onShowDatePicker(criterionMetaData.id)}
                    isLocked={calculateIsLocked(allSubCriteriaMetaData)}
                    deadlineStatus={deadlineIconWithPlacement(calculateMultipleDeadlines(allSubCriteriaMetaData), deadlineIconPlacements.CRITERIA_ITEM)}
                    status={calculateStatus(allSubCriteriaMetaData)}
                    userRole={userRole}
                    archived={archived}
                />
            </div>
            {
                criteriaInfo && criteriaInfo.criteria &&
                criteriaInfo.criteria
                    .map((subCriterion, index) => (
                        <div key={subCriterion.id}
                            className='mb-10'
                        >
                            <div
                                className={`${styles.content} mb-8`}
                            >
                                <div className={styles.undercontent} onClick={() => { history.push(goToProjectCriteria(params.id, params.categoryId, params.sectionId, subCriterion.originalId)) }}>
                                    <p>
                                        <strong>{subCriterion.originalId || 0}.</strong>
                                    </p>

                                    <div>
                                        <div className={`mr-20`}>
                                            {
                                                subCriterion.criteriaDescription &&
                                                <p>
                                                    {subCriterion.criteriaDescription}
                                                </p>
                                            }
                                            {
                                                subCriterion.subCriteria &&
                                                <ul className='ml-4'>
                                                    {
                                                        subCriterion.subCriteria.map(sub => (
                                                            <p key={sub.id}>
                                                                {sub.internalId}
                                                                {". " + sub.subCriteriaDescription}
                                                            </p>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <ActionBtns
                                    isLocked={isSubCriterionLocked(subCriterion.id)}
                                    deadlineStatus={deadlineIconWithPlacement(subCriterStatusDeadline(subCriterion.id), deadlineIconPlacements.SUB_CRITERIA_ITEM)}
                                    status={subCriterionStatus(subCriterion.id)}
                                    onReview={() => reviewSubCriterion(subCriterion.id)}
                                    onLock={() => lockSubCriterion(subCriterion.id)}
                                    onShowDatePicker={() => onShowDatePicker(criterionMetaData.id, subCriterion.id)}
                                    onShowDocuments={() => showDocuments(subCriterion.id, [], subCriterion.id)}
                                    userRole={userRole}
                                    archived={archived}
                                />
                            </div>
                            {
                                index < criteriaInfo.criteria.length - 1 ?
                                    <hr /> : null
                            }
                        </div>
                    ))
            }
        </div>
    )
}

CriterionItem.propTypes = {
    id: PropTypes.string.isRequired,
    manualCriteriaInSection: PropTypes.array.isRequired,
    criterionMetaData: PropTypes.object.isRequired, // The metadata for the criterion
    onReviewCriterion: PropTypes.func,
    onReviewSubCriterion: PropTypes.func.isRequired,
    onLockCriterion: PropTypes.func.isRequired,
    onLockSubCriterion: PropTypes.func.isRequired,
    onFileSystemOpen: PropTypes.func.isRequired,
    onShowDatePicker: PropTypes.func.isRequired,
    userRole: PropTypes.string,
}


export default CriterionItem;
