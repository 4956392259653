import React, { useRef, useState, useEffect } from 'react';
import styles from './PartialCollapse.module.css';

const getElementHeight = (el) => {
    if (!el.current) {
        return 'auto';
      }
    return el.current.scrollHeight;
}

const PartialCollapse = ({visible, partial, children}) => {
    const rootRef = useRef();
    const [heightTimeout, setHeightTimeout] = useState(null);

    useEffect(() => {
        if(visible) {
            rootRef.current.style.minHeight = `${getElementHeight(rootRef)}px`; // MinHeight is used the content can grow larger if necessary

            // To allow nested collapses or height changes, height needs to be set to 'auto', sometime after minHeight is set
            setHeightTimeout(setTimeout(() => {
                rootRef.current.style.height = 'auto';
            }, 300))
        } else {
            rootRef.current.style.minHeight = 0;
            rootRef.current.style.maxHeight = partial; // height to hide content

            if(heightTimeout) {
                clearTimeout(heightTimeout);
            }
        }

    }, [visible, rootRef])

    return (
        <div
            className={styles.root}
            ref={rootRef}
        >
            { children }
        </div>
    )
};

export default PartialCollapse;