import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './FileSystemModule.module.css';
import { roles as ROLES } from '~/data/config'

// Store
import store from '~/store';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import Collapse from '~/components/base/Collapse';
import Tabs from '~/components/base/Tabs';
import Icon from '~/components/base/Icon';

// Components
import FileSystem from '~/components/modules/FileSystem';
import DocumentUpload from '~/components/modules/DocumentUpload';
import { useSelector } from 'react-redux';

// Constants
const TAB_FILE_SYSTEM = 0;
const TAB_DOCUMENT_UPLOAD = 1;
const TAB_DOCUMENT_UPLOAD_FEEDBACK = 2;
const TABS = [{value: TAB_FILE_SYSTEM, label: 'Filer'}, {value: TAB_DOCUMENT_UPLOAD, label: 'Last opp'}]

const FileSystemModule = ({
    projectId, 
    subCriterionId, 
    subCriterionMeta,
    manualData, 
    userRole, 
    ...rest
}) => {

    const documentUploadRef = useRef(null);
    const archived = useSelector(ProjectSelectors.getCurrentArchived);

    const [tab, setTab] = useState(TAB_FILE_SYSTEM);
    const [uploadError, setUploadError] = useState(null);

    useEffect(() => {
        if(tab === TAB_DOCUMENT_UPLOAD) {
            documentUploadRef.current.resetDocuments({
                sectionId: manualData.sectionId,
                criterionId: manualData.criterionId,
                subCriteria: { [subCriterionId]: true }, 
            });
        }
        if(tab !== TAB_DOCUMENT_UPLOAD_FEEDBACK) {
            setUploadError(null);
        }
    }, [tab])
    

    useEffect(() => {
        setTab(TAB_FILE_SYSTEM);
    }, [subCriterionId, subCriterionMeta.locked])

    const onNewFile = () => {
        if(subCriterionMeta.locked) {
            return;
        }
        setTab(TAB_DOCUMENT_UPLOAD);
    }

    const onUploadStatus = (error) => {
        setUploadError(error);
        setTab(TAB_DOCUMENT_UPLOAD_FEEDBACK);
    }

    const HAS_WRITE_ACCCESS = (userRole === ROLES.owner || userRole === ROLES.member) && !archived;
    const authorizedTabs = HAS_WRITE_ACCCESS ? TABS : TABS.filter(t => t.value !== TAB_DOCUMENT_UPLOAD);

    if(authorizedTabs.length > 1) {
        authorizedTabs[1].disabled = Boolean(subCriterionMeta.locked);
    }

    return (
        <div className='bg-white rounded-lg shadow-md'>
            <Tabs
                className='max-w-md'
                tabClassName={styles.tab}
                tabs={authorizedTabs}
                onChange={setTab}
                value={tab}
            />
            <hr />
            <div className='p-10'>
                <Collapse
                    visible={tab === TAB_FILE_SYSTEM}
                >
                    <FileSystem 
                        projectId={projectId}
                        directoryId={subCriterionId}
                        onUploadDocument={onNewFile}
                    />
                </Collapse>
                <Collapse
                    visible={tab === TAB_DOCUMENT_UPLOAD}
                >
                    <div>
                        <h6 className='font-bold mb-2'>Last opp en fil</h6>
                        <DocumentUpload
                            ref={documentUploadRef}
                            className='mt-4'
                            projectId={projectId}
                            uploadConfig={{
                                sectionId: manualData.sectionId,
                                criterionId: manualData.criterionId,
                                subCriteria: { [subCriterionId]: true }, 
                            }}
                            onDocumentUpload={() => onUploadStatus(null)}
                            onUploadError={(err) => onUploadStatus(err)}
                            disableSelection
                        />
                    </div>
                </Collapse>
                <Collapse
                    visible={tab === TAB_DOCUMENT_UPLOAD_FEEDBACK}
                >
                    <div className='p-8 flex flex-col items-center'>
                        <Icon 
                            icon={uploadError ? 'times-circle' : 'check-circle'} 
                            size='5x' 
                            color={uploadError ? 'var(--color-error)' : 'var(--color-success)'}    
                        />
                        <h6 className='text-center mb-10'>
                            {
                                uploadError === null 
                                    ? 'Filen ble lastet opp' 
                                    : 'Det oppstod en feil under opplasting'
                            }
                        </h6>
                    </div>

                </Collapse>
            </div>
            
        </div>
    )
};

FileSystemModule.propTypes = {
    projectId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string.isRequired,
    subCriterionMeta: PropTypes.object.isRequired,
    manualData: PropTypes.object.isRequired,
    userRole: PropTypes.string,
}

export default FileSystemModule;