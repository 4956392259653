import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Avatar.module.css';

// Store
import UserSelectors from '~/store/user/selectors';
import UserActions from '~/store/user/actions';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../Icon';
import Spinner from '../Spinner';
import { getProfilePictureURL } from '~/utils/text/getProfilePictureURL';

const Avatar = ({
    className,
    src,
    size = 'md',
    userId,
    firstName ='K',
    lastName = 'V',
    initials,
    update,
    isLoading,
    children
}) => {
    const [imageState, setImageState] = useState({
        URL:null,
        hash: Date.now()
    });
    const [innerIsLoading, setInnerIsLoading] = useState(true);

    const checkImageExists = (imageUrl, callBack) => {
        var imageData = new Image();
        imageData.onload = () => {
            callBack(true);
        };
        imageData.onerror = () => {
            callBack(false);
        };
        imageData.src = imageUrl;
    }

    const getInitials = () => {
        if (initials) {
            return initials;
        } else {
            return `${firstName[0]}${lastName[0]}`
        }
    }

    useEffect(() => {
        setImageState({
            URL:null,
            hash:Date.now()
        });
        setInnerIsLoading(true);
        const tempURL = getProfilePictureURL(userId);
        checkImageExists(tempURL, (exists) => {
            if(exists) {
                setImageState({
                    URL:tempURL,
                    hash:Date.now()
                });
            }
            setInnerIsLoading(false);
        });

    }, [update])


    if(isLoading || innerIsLoading) {
        return <Spinner></Spinner>
    }
        
    return (
        <div className={`
            ${styles.root} 
            rounded-full items-center justify-center 
            ${styles[size] || ''} 
            ${className}
        `}>
            {imageState.URL ? 
                <img 
                    src={`${imageState.URL}&v=${imageState.hash}`} 
                    className={styles.img} 
                />
            : 
                <div className={styles.initials}>
                    { getInitials() }
                </div>
            }
            {children}
        </div>
    )
};

Avatar.propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
    userId: PropTypes.string,
    isLoading: PropTypes.bool,
}

export default Avatar;