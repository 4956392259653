import { MUTATIONS } from './reducer';
import * as firebase from '../../services/firebase';
import { statuses as STATUSES, functions as FUNCTIONS } from '~/data/config';
import DocumentSelectors from './selectors';
import store from '../index';
import { v4 } from 'uuid';
import { mapDocumentMeta } from './mappers';

// ------- DOCUMENTS --------
const setDocument = (projectId, document, merge = false) => ({
    type: MUTATIONS.SET_DOCUMENT,
    projectId: projectId,
    payload: document,
    merge: merge,
});

const setDocumentMeta = (projectId, docMeta) => ({
    type: MUTATIONS.SET_DOCUMENT_META,
    projectId: projectId,
    payload: docMeta,
});

const setDirectory = (projectId, directory, merge = false) => ({
    type: MUTATIONS.SET_DIRECTORY,
    projectId: projectId,
    payload: directory,
    merge: merge,
})

const setDirectoryData = (projectId, data) => ({
    type: MUTATIONS.SET_DIRECTORY_DATA,
    projectId: projectId,
    payload: data,
})

const setDocumentList = (projectId, docIds) => ({
    type: MUTATIONS.SET_DOC_LIST,
    projectId: projectId,
    payload: docIds,
});

const setAttachmentCode = (projectId, attachmentCode, reservation) => ({
    type: MUTATIONS.SET_ATTACHMENT_CODE,
    projectId: projectId,
    attachmentCode: attachmentCode,
    payload: reservation,
});

const deleteAttachmentCode = (projectId, attachmentCode) => ({
    type: MUTATIONS.DELETE_ATTACHMENT_CODE,
    projectId: projectId,
    payload: attachmentCode,
})

const clearState = () => ({
    type: MUTATIONS.CLEAR_STATE,
})

/**
 * UploadDocument is a function that uploads a single file to firebase and creates a document out of it.
 * This function also allows for storing extra documentMeta on the document and assigning the document to
 * a specified directory.
 * @param {String} projectId The id of the project
 * @param {File} documentFile The file to upload
 * @param {String} documentNote A single note describing the document
 * @param {Array<Object>} directoryCfgs An array of directory-configurations, describing which directory to assign the document to.
 * <pre><code>
 *    directoryCfg {
 *       directoryId: string,
 *       metaData: { // Extra meta data
 *          sectionId: string,
 *          criterionId: string,
 *          subCriterionId: string,
 *          attachmentCode: string, // The attachment code of the document
 *       }
 *    }
 * </code></pre>
 */
const uploadDocument = (projectId, documentFile, documentNote, directoryCfgs = []) => (dispatch) => {
    
    // First, upload document
    return firebase.storage
        .ref(`/projects/${projectId}/documents`)
        .child(documentFile.name.replace(/(\.[\w\d_-]+)$/i, `-${new Date().getTime()}$1`))
        .put(documentFile)
        .then((snapshot) => {

            // The document was successfully uploaded
            // Store the document-information and assign it to a directory 
            return firebase.functions.httpsCallable(FUNCTIONS.DOCUMENT_CREATE)({
                projectId: projectId,
                fileName: documentFile.name,
                source: snapshot.metadata.fullPath,
                bucket: snapshot.metadata.bucket,
                createdAt: snapshot.metadata.timeCreated,
                note: documentNote,
                directoryCfgs: directoryCfgs,
            })
            .then((res) => {
                const data = res.data;
                dispatch(setDocument(projectId, data));
                
                if(data.metaData instanceof Array) {
                    for(let metaData of data.metaData) {
                        dispatch(setDocumentMeta(projectId, metaData));
                    }
                }

                if(data.updatedDirectories) {
                    for(let dir of Object.values(data.updatedDirectories)) {
                        dispatch(setDirectory(projectId, dir, true))
                    }
                }
            })
        })
        .then(() => {
            // Delete the attachmentCodes in the 'directoryCfgs' in the store, as they were just used.
            const attachmentCodes = directoryCfgs
                .filter(cfg => cfg.metaData && cfg.metaData.attachmentCode)
                .map(cfg => cfg.metaData.attachmentCode);
            
            attachmentCodes.forEach((attachmentCode) => dispatch(deleteAttachmentCode(projectId, attachmentCode)))
        })
}

const fetchDocuments = (projectId, page, limit, query = null) => (dispatch) => {
    const payload = {
        projectId: projectId,
        page: page,
        limit: limit
    };

    if(query) {
        payload.query = query;
    }

    return firebase.functions.httpsCallable(FUNCTIONS.DOCUMENT_LIST)(payload)
    .then((res) => {
        const documents = res.data;
        documents.forEach((doc) => {
            (doc.documentMetas || []).forEach((docMeta) => dispatch(setDocumentMeta(projectId, docMeta)));
            dispatch(setDocument(projectId, doc));
        });
        return documents;
    })
}

const fetchDirectory = (projectId, directoryId, depth = 3) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.DIRECTORY_GET)({
        projectId: projectId,
        directoryId: directoryId,
        depth: depth,
    })
    .then((res) => {
        const data = res.data;
        dispatch(setDirectoryData(projectId, data));
    });
}

const createNewDirectory = (projectId, parentDirectoryId, name) => (dispatch) => {
    const newDirectoryId = parentDirectoryId ? ''.concat(parentDirectoryId, '__', v4()) : v4();
    const newDirectory = {
        id: newDirectoryId,
        name: name,
        parent: parentDirectoryId,
        permanent: false,
        documents: {}, // No documents in this directory to begin with
    };

    return firebase
        .directoryCollection(projectId)
        .doc(newDirectoryId)
        .set(newDirectory)
        .then((dirRef) => {
            // Add the new directory to the store
            dispatch(setDirectory(projectId, {...newDirectory, directories: []}));

            // Update the parent directory in the store
            const parentDirectory = DocumentSelectors.getDirectoryById(projectId, parentDirectoryId)(store.getState());
            if(parentDirectory && parentDirectory.directories instanceof Array) {
                parentDirectory.directories.unshift(newDirectoryId);
                dispatch(setDirectory(projectId, parentDirectory));
            }

        })

}

const downloadFile = (projectId, documentId) => (dispatch) => {
    // Check if the document data is already in the store
    const document = DocumentSelectors.getDocumentById(projectId, documentId)(store.getState());
    let docPromise = null;
    if(document) {
        docPromise = Promise.resolve(document);
    } else {
        docPromise = firebase.projectCollection
            .doc(projectId)
            .collection('documents')
            .doc(documentId)
            .get()
            .then((docRef) => {
                const document = {id: docRef.id, ...docRef.data()};
                dispatch(setDocument(projectId, document));
                return document;
            });
    }

    return docPromise
        .then((doc) => {
            return firebase.storage.ref(doc.source)
                .getDownloadURL()
        })
        .then((url) => {
            window.open(url, '_blank');
        })
}

const downloadFileBySource = (source) => {
    return firebase.storage.ref(source)
        .getDownloadURL()
}

const lockDocumentById = (projectId, documentMetaId) => (dispatch) => {

    const uid = firebase.auth.currentUser.uid;

    const update = {
        locked: new Date().toISOString(),
        lockedBy: uid,
        status: STATUSES.pending,
    };

    return firebase.documentMetaCollection(projectId)
        .doc(documentMetaId)
        .update(update)
        .then(() => {
            // Update the store with the new changes
            const oldDocument = DocumentSelectors.getDocumentMetaById(projectId, documentMetaId)(store.getState());
            if(!oldDocument) {
                return;
            }

            dispatch(setDocumentMeta(projectId, {
                id: documentMetaId,
                ...oldDocument,
                ...update,
            }))
        })
}

const reviewDocumentById = (projectId, documentMetaId, status, statusNote) => (dispatch) => {
    const uid = firebase.auth.currentUser.uid;

    const update = {
        status: status,
        statusNote: statusNote,
        statusChangedBy: uid,
    };

    // If the status is "approved", let the document remain locked.
    if(status !== STATUSES.approved) {
        update.locked = null;
        update.lockedBy = null;
    }

    return firebase.documentMetaCollection(projectId)
        .doc(documentMetaId)
        .update(update)
        .then(() => {
            // Update the store with the new changes
            const oldDocument = DocumentSelectors.getDocumentMetaById(projectId, documentMetaId)(store.getState());
            if(!oldDocument) {
                return;
            }

            dispatch(setDocumentMeta(projectId, {
                id: documentMetaId,
                ...oldDocument,
                ...update,
            }))
        })
        .catch(console.error);
}

const reserveAttachmentCode = (projectId, sectionId) => (dispatch) => {

    return firebase.functions.httpsCallable(FUNCTIONS.DOCUMENT_ATTACHMENT_CODE)({
        projectId: projectId,
        requestSectionCode: sectionId,
    })
    .then((res) => {
        const data = res.data; // {attachmentCode, reservedTo, reservedBy}
        dispatch(setAttachmentCode(projectId, data.attachmentCode, data));

        return data;
    })
};

/**
 * cancelAllAttachmentCodes cancels the attachment-code reservations that are stored in the
 * store. All those reservations are created by the user, but not actually used and have to be
 * made available for other users to use.
 * @param {*} projectId - The id of the project
 * @returns {Promise} Returns a Promise.all of all the function-calls for cancelling an attachmentCode.
 */
const cancelAllAttachmentCodes = (projectId) => (dispatch) => {

    const attachmentCodesByKey = DocumentSelectors.getAttachmentCodesByKey(projectId)(store.getState());
    const attachmentCodes = Object.keys(attachmentCodesByKey);

    const promises = attachmentCodes
        .map((attachmentCode) => 
            firebase.functions.httpsCallable(FUNCTIONS.DOCUMENT_ATTACHMENT_CODE)({
                projectId: projectId,
                cancelCode: attachmentCode, 
            })
        );
    
    return Promise.all(promises)
        .then((values) => {
            // Remove the cancelled attachmentCodes from the store
            attachmentCodes.forEach((attachmentCode) => 
                dispatch(deleteAttachmentCode(projectId, attachmentCode))
            );

            return values;
        })
}

const fetchDocumentMetaBySubCriterion = (projectId, subCriterionId) => (dispatch) => {
    return firebase.documentMetaCollection(projectId)
        .where('metaData.subCriterionId', '==', subCriterionId)
        /* .orderBy('createdAt', 'desc') */
        .get()
        .then((docMetaRef) => {
            // Store the fetched documentMetas in the store
            const docMetas = docMetaRef.docs.map(docMeta => ({id: docMeta.id, ...docMeta.data()}));
            docMetas.forEach((docMeta) => dispatch(setDocumentMeta(projectId, docMeta)))
            return docMetas.map(mapDocumentMeta);
        })

}


export default {
    setDocument,
    setDocumentList,

    uploadDocument,
    downloadFile,
    downloadFileBySource,
    fetchDirectory,
    fetchDocuments,
    fetchDocumentMetaBySubCriterion,
    createNewDirectory,

    lockDocumentById,
    reviewDocumentById,

    reserveAttachmentCode,
    setAttachmentCode,
    deleteAttachmentCode,
    cancelAllAttachmentCodes,

    clearState,
}