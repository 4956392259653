import React from 'react';
import PropTypes from 'prop-types';
import styles from './Badge.module.css';

const Badge = ({
    content
}) => {

    return (
        <div className={`bg-red-600 ${styles.root} text-xs text-white`}>
            { content }
        </div>
    )
}

Badge.propTypes = {
    content: PropTypes.any,
}

export default Badge;