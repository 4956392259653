/*
    This component converts errormessages from parameters to
    readable messages for the user. If the errormessage is
    not known to the function, the message will remain 
    'Ukjent feilmelding...'
*/

const userErrors = {
    MISSING_ARGUMENT: 'Mangler e-post eller bruker-ID',
    MISSING_PROJECT: 'Finner ikke prosjektet',
    USER_DOES_NOT_EXIST: 'Brukeren eksisterer ikke...',
    MEMBER_EXISTS: 'Brukeren er allerede i prosjektet',
    USER_MISSING: 'Brukeren eksisterer ikke...',
}

export default (errorMessage) => {
    return userErrors[errorMessage] || errorMessage;
}
