import React, { useState } from 'react';

// Components
import { Select } from "~/components/base/Select";
import { Button } from '~/components/base/Buttons';
import TextField from '~/components/base/TextField';

// Data
import certifications from '../../../../data/certifications.json';
import buildingTypes from '../../../../data/buildingTypes.json';

const CERTIFICATIONS = certifications.map(c => ({
    label: c.name,
    value: c.name
}))

const BUILDINGTYPES = buildingTypes.map(bt => ({
    label: bt.name,
    value: bt.name
}))

const ERROR_MESSAGES = {
    title: "Prosjekt navn må spesifiseres!",
    address: "Adresse må spesifiseres!",
    certification: "Planlagt sertifisering må velges!",
    buildingType: "Bygningstype må velges!",
}

const Step01 = ({onChange, isLoading }) => {
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [certificationType, setCertificationType] = useState(null);
    const [buildingType, setBuildingType] = useState(null);

    const [errorMessages, setErrorMessages] = useState({})

    const setValue = (name, value, setFunction, errorObject = null) => {
        setFunction(value);

        if(!value) {
            if(errorObject) {
                errorObject[name] = ERROR_MESSAGES[name];
            } else {
                setErrorMessages({...errorMessages, [name]: ERROR_MESSAGES[name]});
            }
        } else {
            const newErrorMessages = errorMessages;
            if(newErrorMessages[name]) {
                delete newErrorMessages[name];
                setErrorMessages(newErrorMessages);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        // Validate input
        setErrorMessages({});
        const errorObject = {};
        setValue("title", title, setTitle, errorObject);
        setValue("address", address, setAddress, errorObject);
        setValue("certification", certificationType, setCertificationType, errorObject);
        setValue("buildingType", buildingType, setBuildingType, errorObject);

        if((Object.keys(errorObject).length > 0)) {
            setErrorMessages(errorObject);
            return;
        }
        if(!onChange) {
            return;
        }

        // Emit change
        onChange({
            title,
            address,
            certificationType,
            buildingType,
        })
    }

    return (
        <div>
            <div className='paper'>
                <form onSubmit={onSubmit}>

                    <TextField
                        className='w-full'
                        label='Prosjekt navn'
                        placeholder='Prosjekt navn'
                        value={title}
                        onChange={(e) => setValue("title", e.target.value, setTitle)}
                        required
                    />

                    <TextField
                        className='w-full'
                        label='Adresse'
                        placeholder="Adresse"
                        value={address}
                        onChange={(e) => setValue("address", e.target.value, setAddress)}
                        required
                    />

                    <p className='subtitle1 font-semibold'>Planlagt sertifiseringsnivå</p>
                    <Select
                        value={certificationType}
                        onChange={(value) => setValue("certification", value, setCertificationType)}
                        placeholder='Sertifiseringsnivå'
                        data={CERTIFICATIONS}
                    />
                    {
                        errorMessages && errorMessages.certification ?
                        <div className='bg-red-400 p-3 mb-3 text-white shadow rounded'>
                            <p>{ errorMessages.certification }</p>
                        </div> : null
                    }

                    <p className='subtitle1 font-semibold'>Planlagt sertifiseringsnivå</p>
                    <Select
                        value={buildingType}
                        onChange={(value) => setValue("buildingType", value, setBuildingType)}
                        placeholder='Bygningstype'
                        data={BUILDINGTYPES}
                    />
                    {
                        errorMessages && errorMessages.buildingType ?
                        <div className='bg-red-400 p-3 mb-3 text-white shadow rounded'>
                            <p>{ errorMessages.buildingType }</p>
                        </div> : null
                    }

                    <div className='flex flex-row-reverse'>
                        <Button
                            color='primary'
                            type='submit'
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            Neste
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default Step01;