import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// Root reducers
import user from './user/reducer';
import projects from './project/reducer';
import documents from './documents/reducer';
import events from './events/reducer';
import occurrences from './occurrence/reducer';

const rootReducer = combineReducers({
    user,
    projects,
    documents,
    events,
    occurrences,
});

const middlewares = [thunk];

/**
 * Only use the redux-logger if code is in development.
 */
if (process.env.NODE_ENV === 'development') {
  //  middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middlewares)
    )
)

store.subscribe(console.log);

export default store;