import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FileSystem.module.css';
import { statuses as STATUSES, roles as ROLES } from '~/data/config';
import store from '~/store';

// State imports
import { useSelector } from 'react-redux';
import DocumentSelectors from '~/store/documents/selectors';
import ProjectSelectors from '~/store/project/selectors';

// Core components
import Icon from '~/components/base/Icon';
import DirectoryItem from './components/DirectoryItem';
import FileItem from './components/FileItem';

/**
 * A context to easily maintain a state between the directories
 */
export const FileSystemContext = React.createContext({
    createNewDirectory: false,
    newDirectoryName: '',
    selectedDirectory: null,
    canWrite: {}, // Boolean values with directoryId keys
});

const createEmptyFileSystemContext = (directoryId) => ({
    selectedDirectory: directoryId,
    canWrite: {}
})

/**
 * FileSystem is a component that displays the file-hierarchy based on a given directoryId 
 */
const FileSystem = ({
    projectId,
    directoryId = -1,
    directoriesToExclude = [],
    onUploadDocument,
}) => {
    const [systemState, setSystemState] = useState(createEmptyFileSystemContext(directoryId));
    const userRole = useSelector(ProjectSelectors.getProjectRoleById(projectId));
    const archived = useSelector(ProjectSelectors.getCurrentArchived);
    const closestPermDir = useSelector(DocumentSelectors.getClosestPermanentDirParent(projectId, systemState.selectedDirectory || ''));

    useEffect(() => {
        // If there is no selected directory, set it to be the root
        if(!systemState.selectedDirectory) {
            setSystemState({
                ...systemState,
                selectedDirectory: directoryId || -1,
            })
        }

        //checkIfSelectedDirIsWritable();
        
    }, [systemState])

    useEffect(() => {
        checkIfSelectedDirIsWritable();

    }, [closestPermDir])

    useEffect(() => {
        // Reset the entire file system
        setSystemState(createEmptyFileSystemContext(directoryId));
    }, [directoryId])

    const toggleCreateNewDirectory = () => {
        setSystemState({
            ...systemState,
            createNewDirectory: !Boolean(systemState.createNewDirectory),
        });
    }

    const onNewDocument = () => {
        const closestPermDirParent = DocumentSelectors.getClosestPermanentDirParent(projectId, systemState.selectedDirectory)(store.getState());
        const directoryMetaData = (closestPermDirParent || {}).metaData || {};

        const docUploadMetaData = {
            directoryId: systemState.selectedDirectory,
            ...directoryMetaData
        };

        if(onUploadDocument) {
            onUploadDocument(docUploadMetaData);
        }
    }

    const checkIfSelectedDirIsWritable = () => {
        if(!systemState.selectedDirectory) {
            return;
        }
        if(archived) {
            setSystemState({
                ...systemState,
                canWrite: {
                    ...(systemState.canWrite),
                    [systemState.selectedDirectory]: false
                }
            }); 
            return;
        }

        let canWrite = true;
        
        // Check if one is able to write to the directory by checking if the subCriterion is locked or not.
        const directory = closestPermDir;
        if(directory && directory.permanent && directory.metaData && directory.metaData.subCriterionId && directory.metaData.criterionId) {
            // The directory belongs to a subCriterion - check if it is locked or not.
            const criterionMeta = ProjectSelectors.getCriterionById(directory.metaData.criterionId)(store.getState());

            canWrite = !Boolean((((criterionMeta || {}).subCriteria || {})[directory.metaData.subCriterionId] || {}).locked);
        }

        setSystemState({
            ...systemState,
            canWrite: {
                ...(systemState.canWrite),
                [systemState.selectedDirectory]: canWrite
            }
        });         
    }

    const HAS_WRITE_ACCESS = userRole === ROLES.owner || userRole === ROLES.member;
    const CAN_WRITE = systemState.canWrite[systemState.selectedDirectory];

    return (
        <div className={styles.root}>

            <div className={`grid grid-cols-3 py-1 px-2 border-b border-gray-300`} style={{backgroundColor: '#F6F6F6'}}>
                <p className='font-semibold text-base mb-0'>Vedlegg</p>
                <p className='font-semibold text-base mb-0'>Filnavn</p>
                {
                    HAS_WRITE_ACCESS &&
                    <div className='grid grid-cols-2 items-center justify-end items-center'>
                        <div className='flex items-center justify-end'>
                            <p 
                                className={`underline mr-2 mb-0 font-semibold ${CAN_WRITE ? 'cursor-pointer' : 'cursor-not-allowed text-gray-400'}`}
                                onClick={CAN_WRITE ? toggleCreateNewDirectory: null}
                            >
                                { systemState.createNewDirectory ? 'Fjern' : 'Ny mappe'}
                            </p>
                            <Icon icon='folder-plus' size='sm' />
                        </div>
                        <div className='flex items-center justify-end'>
                            <p 
                                className={`underline mr-2 mb-0 font-semibold ${CAN_WRITE ? 'cursor-pointer' : 'cursor-not-allowed text-gray-400'}`}
                                onClick={CAN_WRITE ? onNewDocument : null}
                            >
                                Last opp fil
                            </p>
                            <Icon icon='upload' size='sm' />
                        </div>
                    </div>
                }
            </div>
           
            <FileSystemContext.Provider value={{systemState, setSystemState}}>
                <DirectoryItem
                    projectId={projectId}
                    directoryId={directoryId}
                    isRoot={true}
                    directoriesToExclude={directoriesToExclude}
                />

            </FileSystemContext.Provider>
           
        </div>
    )
};

FileSystem.propTypes = {
    projectId: PropTypes.string.isRequired,

    /**
     * The id of the directory to render. Default is -1, which is the root directory.
     */
    directoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

    /**
     * Array of directory-ids to exlude from render.
     */
    directoriesToExclude: PropTypes.array,

    /**
     * A callback for when a document was successfully uploaded.
     */
    onUploadDocument: PropTypes.func.isRequired,
}

export default FileSystem;