import React, { useState, useEffect, useMemo } from 'react';
import firebase from 'firebase'
import styles from './index.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import ROUTES from '../../ROUTES';
import qs from 'query-string';

import { auth } from '../../services/firebase'

// Store
import { useSelector } from 'react-redux';
import selectors from '../../store/selectors';

// Page components
import LogInForm from './components/LogInForm';

const Login = () => {
    const history = useHistory();

    const [state, setState] = useState({
        email: "",
        password: "",
        error: false
    })
    const [continuePATH, setContinuePATH] = useState(null);
    const location = useLocation();


    const queryParams = useMemo(() => qs.parse(location.search) || {}, [location.search]);

    useEffect(() => {
        if (queryParams.continue) {
            setContinuePATH(queryParams.continue);
        }
    })

    const isAuthenticated = useSelector(selectors.user.isLoggedIn);


    const isEmailSignInLink = auth.isSignInWithEmailLink(window.location.href)

    //callbacks
    function handleChange(event) {
        const value = event.target.value;

        setState({
            ...state,
            error: false,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        // Redirect to dashboard if already logged in
        if (isAuthenticated) {
            if (continuePATH) {
                history.replace(continuePATH);
            } else {
                history.replace(ROUTES.DASHBOARD);
            }
        }
    }, [isAuthenticated]);


    const loginwithFirebase = (e) => {
        if (e) {
            e.preventDefault();
        }

        isEmailSignInLink
            ?
            auth
                .signInWithEmailLink(state.email, window.location.href)
                .then(user => auth.currentUser.updatePassword(state.password))
                .then(() => history.push(ROUTES.DASHBOARD))
                .catch(err => setState({ ...state, error: true }) || console.log("Error?", err))
            :
            firebase.auth()
                .signInWithEmailAndPassword(state.email, state.password)
                .then(() => {
                    if (continuePATH) {
                        history.push(continuePATH);
                    } else {
                        history.push(ROUTES.DASHBOARD);
                    }
                })
                .catch(err => {
                    setState({
                        ...state,
                        error: true
                    })
                    console.log("password or email is incorrect")
                })
    };

    return (
        <div className={styles.root}>
            <div className={styles.name}>
                <LogInForm isEmailSignInLink={isEmailSignInLink} onChange={handleChange} onClick={loginwithFirebase} error={state.error} />
            </div>

        </div>
    )
};

export default Login;