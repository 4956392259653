import React from 'react';
import PropTypes from 'prop-types';
import { reactions as REACTIONS } from '~/data/config';

// Core components
import Popup from '~/components/base/Popup';



const REACTION_LIST = Object.entries(REACTIONS)
    .map(([reactionType, emoji]) => ({ value: reactionType, label: emoji }))

const ReactionTooltip = ({
    anchorEl,
    isOpen,
    onClose,
    onSelect,
    ...rest
}) => {

    const onReactionSelect = (reaction) => {
        if (onSelect) {
            onSelect(reaction);
        }
    }

    return (
        <Popup
            anchorEl={anchorEl}
            isOpen={isOpen}
            onClickAway={onClose}
            {...rest}
        >
            <div className='bg-white rounded-md shadow-md grid grid-cols-3 z-10'>
                {
                    REACTION_LIST.map((reaction) => (
                        <div
                            className='p-2 cursor-pointer hover:bg-gray-200'
                            key={reaction.value}
                            tabIndex='0'
                            onClick={() => onReactionSelect(reaction.value)}
                        >
                            {
                                reaction.label
                            }
                        </div>
                    ))
                }
            </div>
        </Popup>
    )
};

ReactionTooltip.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
}

export default ReactionTooltip;