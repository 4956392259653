import React from 'react';
import PropTypes from 'prop-types';

// Core components
import Modal from '~/components/base/Modal';
import { Button } from '~/components/base/Buttons';
import Spinner from '~/components/base/Spinner';

const ConfirmationModal = ({
    show,
    onClose,
    onConfirm,
    children,
    title,
    description,
    disconfirmText = 'Nei',
    confirmText = 'Ja',
    loading,
    ...rest
}) => {

    return (
        <Modal
            {...rest}
            show={show}
            onBackdropClick={loading ? null : onClose}
        >
            <div className='paper' style={{width: '100vw', maxWidth: 420, minHeight: 230 }}>
                {
                    loading ?
                        <div className='absolute-center'>
                            <Spinner size='lg' />
                        </div>
                    :
                    <>
                        {
                            children ?
                            children :
                            <div>
                                <h4 className='text-center'>{ title }</h4>
                                <h6 className='text-center font-light'>{ description }</h6>
                            </div>
                        }

                        <div
                            className='flex items-center justify-center w-full mt-6'
                        >
                            <Button
                                style={{minWidth: 68 }}
                                className='mx-2'
                                variant='contained'
                                color='secondary'
                                onClick={onClose}
                                >
                                { disconfirmText }
                            </Button>
                            <Button
                                style={{minWidth: 68}}
                                className='mx-2'
                                variant='contained'
                                color='primary'
                                onClick={onConfirm}
                            >
                                { confirmText }
                            </Button>
                        </div>
                    </>
                }
            </div>
        </Modal>
    )
};

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    description: PropTypes.any,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    disconfirmText: PropTypes.string,
    confirmText: PropTypes.string,
    loading: PropTypes.bool,
    ...Modal.propTypes,
}

export default ConfirmationModal;