

/*
    "splitId" splits a subcriteria id by "_" and returns the categoryId, sectionId, and the orignal subCriteria id.
    For example:
        "man_01_02_asdfasdf" => ["man", "man_01", "02"]
*/

const splitId = (subCriteriaId) => {
    const splits = subCriteriaId.split("_");

    const results = [];
    if(splits.length > 0) {
        results.push(splits[0]) // Category Id
    }
    if(splits.length > 1) {
        results.push(''.concat(splits[0],'_', splits[1])); // Section Id
    }
    if(splits.length > 2) {
        results.push(splits[3]);
    }
    return results;
};

export default splitId;