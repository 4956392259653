import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './services/firebase';
import './docs';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Global CSS
import './assets/css/tailwind.css';

// Configure Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import faConfig from './faConfiguration';
faConfig(library);

require('dotenv').config();



ReactDOM.render(<App />, document.getElementById('kvist'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
