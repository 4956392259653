const fileRegexCollection = {
    zipFolder: {
        regex: /rar|zip/ig,
        faIcon: 'file-archive'
    },
    wordFile: {
        regex: /doc[xmb]|dot[xm]|txt/ig,
        faIcon: 'file-alt'
    },
    textFile: {
        regex: /txt/ig,
        faIcon: 'file-alt'
    },
    pdfFile: {
        regex: /pdf/ig,
        faIcon: 'file-pdf'
    },
    imageFile:
    {
        regex: /png|jpg|jpeg|jpeg 2000|jpeg xr|jfif|exif|tiff|gif|bmp|p[pgbn]m|svg|webp|hei[fc]|ico|pgf|bpg|drw|pam|/ig,
        faIcon: 'file-image'
    },
    dwgFile:
    {
        regex: /dwg/ig,
        faIcon: 'file'
    },
    excelFile: {
        regex: /xls[xmb]|xlt[xm]|xl[alw]|xlam/ig,
        faIcon: 'file-excel'
    },
    powerpointFile: {
        regex: /pp[ts]|pot[xm]|ppt[xm]|ppam|ppsx|ppsm|sld[xm]/ig,
        faIcon: 'file-powerpoint'
    },
    csvFile: {
        regex: /csv/ig,
        faIcon: 'file-csv'
    },
    fallback: {
        regex: /.*/ig,
        faIcon: 'file'
    }
}

/**
 * 
 * @param {Object} extension - object containing the different extension-types
 * @returns The name of the icon for the extension
 */
const DOCUMENT_ICON = (extension) => {
    for (const filetype in fileRegexCollection) {
        const fileObject = fileRegexCollection[filetype]

        if (fileObject.regex.test(extension) === true) {
            return fileObject.faIcon;
        }
    }
}

export default DOCUMENT_ICON;


/**
kon zippet mappe - rar, zip
dokument-ikon - doc plus alle varianter av word filtyper
pdf-ikon - pdf
bildeikon - png, jpg, svg, Iphone varianten (HEIC elns?) ||| file-image
dwg icon - .dwg fil
excel icon - excel,
kommaseparert - csv
 */