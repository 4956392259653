export const mapOccurrence = (occurrence) => ({
    ...occurrence,
    eventAction: occurrence.eventAction,
    eventFamily: occurrence.eventFamily,
    timestamp: occurrence.timestamp,
    createdBy: occurrence.createdBy,

    sectionId: occurrence.sectionId,
    criterionId: occurrence.criterionId,
    subCriterionId: occurrence.subCriterionId,

    users: occurrence.users || {},
    children: occurrence.children || {},
})