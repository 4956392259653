import user from './user/selectors';
import projects from './project/selectors';
import documents from './documents/selectors';
import events from './events/selectors';
import occurrences from './occurrence/selectors';

export default {
    user,
    projects,
    documents,
    events,
    occurrences,
}