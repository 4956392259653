const getEventState = (state) => state.events;

// ------- EVENTS -----------

export const getProjectEvents = (projectId) => (state) => 
    Object
        .values(getEventState(state).events[projectId] || {});

export const getProjectEventsByCategory = (projectId, categoryId) => (state) =>
    getProjectEvents(projectId)(state)
        .filter(event => event.categoryId === categoryId)
        .sort((eventA, eventB) => eventA.timestamp > eventB.timestamp);


// ---------- NOTIFICATIONS --------------


export const getProjectNotificationsByKey = (projectId) => (state) => getEventState(state).notifications[projectId] || {};

export const getProjectNotifications = (projectId) => (state) => Object.values(getProjectNotificationsByKey(projectId)(state));

export const getProjectNotificationById = (projectId, notificationId) => (state) => getProjectNotificationsByKey(projectId)(state)[notificationId]; 

export const getProjectNotificationCounts = (projectId) => (state) => getEventState(state).notificationCount[projectId] || {};

export const getProjectNotificationsBySection = (projectId, sectionId) => (state) =>
    getProjectNotifications(projectId)(state)
        .filter(notf => notf.sectionId === sectionId)
        .sort((a, b) => -(a.timestamp || '').localeCompare(b.timestamp))

export default {
    getProjectEvents,
    getProjectEventsByCategory,
    getProjectNotificationsBySection,
    getProjectNotifications,
    getProjectNotificationById,
    getProjectNotificationsByKey,
    getProjectNotificationCounts,
}