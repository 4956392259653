import React, {useState, useEffect} from 'react';
import styles from './index.module.css';
import {useHistory} from 'react-router-dom';
import ROUTES from '../../ROUTES';
import action from '../../store/user/actions';
import UserActions from '~/store/user/actions';

import CreateUserForm from './components/CreateUserForm';

const CreateUser = () => {
    const history = useHistory();

    const [state, setState] = useState({
        email: "",
        firstname: "",
        lastname: "",
        password :"",
        password2: "",
        buttonDisabled: true,
        error: false
    })

    //Check if all fields are filled and then make the button appear clickable
    function manageButton() {
        if (
            state.password !== "" && 
            state.password2 !== "" && 
            state.email !== "" &&
            state.firstname !== "" &&
            state.lastname !== ""
            ) {
            state.buttonDisabled = false;
        }
        else {
            state.buttonDisabled = true;
        }
    }
    manageButton();

    //callbacks
    function handleChange(event) {
        const value = event.target.value;

        setState({
            ...state,
            error: false,
            [event.target.name]: value
        })

        manageButton();
    }

    const createUserWithFirebase = (e) => {
        if(e) {
            e.preventDefault();
        }

        //Check if the two password fields match, if they do not match, throw an error
        if (state.password !== state.password2) {
            setState({
                ...state,
                error: true
            })
        } else {
            //Use built-in firebase function to create user with email and password
            UserActions.createUser(state.email, state.firstname, state.lastname, state.password)
            .then(() => {
                history.push(ROUTES.DASHBOARD);
            })
            .catch((error) => {
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                } else {
                    alert(errorMessage);
                }
                console.log(error);
            })
            
        }        
    };

    return (
        <div className={styles.root}>
            <div className={styles.name}>
                <CreateUserForm buttonDisabled={state.buttonDisabled} onChange={handleChange} onClick={createUserWithFirebase} error={state.error}/>
            </div>
        </div>
    )
}

export default CreateUser;