import React from 'react';
import PropTypes from 'prop-types';

// Core components
import Icon from '~/components/base/Icon';
import { Button } from '~/components/base/Buttons';

const ClipableLink = ({
    link
}) => {

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(link);
    }

    return (
        <div className='flex items-center'>
            <div className='font-light mr-2 text-gray-600'>
                { link }
            </div>
            <div>
                <Button
                    variant='icon'
                    onClick={copyLinkToClipboard}
                >
                    <Icon
                        icon='clipboard'
                    />
                </Button>
            </div>
        </div>
    )
}

ClipableLink.propTypes = {
    link: PropTypes.string.isRequired,
}

export default ClipableLink;