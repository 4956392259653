import React from 'react';
import PropTypes from 'prop-types';
import styles from '../index.module.css';

// Store
import { useDispatch } from 'react-redux';
import DocumentActions from '~/store/documents/actions';

// Utils
import { dateToDateString } from '~/utils/date';
import { extractDocumentSubCriteria, extractAttachmentCodes } from '../dataExtractors';

// Config 
import { dateStyles } from '~/data/config';


const DocumentTable = ({
    projectId,
    documents,
    isLoading,
}) => {
    const dispatch = useDispatch();

    const downloadDoc = (doc) => {
        DocumentActions.downloadFile(projectId, doc.id)(dispatch);
    };

    return (
        <div>
            <div className='grid grid-cols-5'>
                <div className='font-bold col-span-2'>
                    Filnavn
                </div>
                <div className='font-bold text-center'>
                    Date uploaded
                </div>
                <div className='font-bold text-center'>
                    Vedlegg
                </div>
                <div className='font-bold text-center'>
                    Krav
                </div>
            </div>
            {
                !isLoading && documents && documents.map((doc) => (
                    <div key={doc.id}>
                        <div
                            className={`grid grid-cols-5 py-2 ${styles['document-row']}`}
                        >
                            <div
                                className='font-light col-span-2 my-auto cursor-pointer hover:underline'
                                onClick={() => downloadDoc(doc)}
                            >
                                {doc.fileName}
                            </div>
                            <div className='font-light text-center m-auto'>
                                {dateToDateString(new Date(doc.createdAt), dateStyles.SHORT)}
                            </div>
                            <div className='font-light text-center m-auto'>
                                {
                                    extractAttachmentCodes(doc)
                                        .map((attachmentCode) => (
                                            <div className='capitalize' key={attachmentCode}>
                                                {
                                                    attachmentCode.split("_").join(" ")
                                                }
                                            </div>
                                        ))
                                }
                            </div>
                            <div className='font-light text-center grid grid-cols-1 m-auto'>
                                {
                                    Object.entries(extractDocumentSubCriteria(doc))
                                        .map(([sectionId, subCriterionNumber]) => (
                                            <div key={''.concat(sectionId, subCriterionNumber)}>
                                                <div className='capitalize'> {sectionId}</div>
                                                <div className='text-center'> {subCriterionNumber.join(', ')}</div>

                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                        <hr />
                    </div>
                ))
            }
        </div>
    )
};

DocumentTable.propTypes = {
    projectId: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
};

export default DocumentTable;
