import { functions, db, auth } from '../../services/firebase';
import { MUTATIONS } from './reducer';
import { mapOccurrence } from './mappers';
import { v4 as uuidv4 } from 'uuid';
import project from '../project/reducer';
import OccurrenceSelectors from './selectors';
import store from '..';

// Config
import {
    functions as FUNCTIONS,
    collections as COLLECTIONS,
    eventTypes as EVENT_ACTIONS
} from '../../data/config';


const clearState = () => ({
    type: MUTATIONS.CLEAR_STATE,
})

/**
 * @param {Object|Array} occurrence 
 */
const setOccurrence = (projectId, storageLocation, occurrence) => ({
    type: MUTATIONS.SET_OCCURRENCE,
    projectId: projectId,
    storageLocation: storageLocation,
    payload: occurrence,
})

const setOccurrenceMessage = (projectId, occurrenceId, message) => ({
    type: MUTATIONS.SET_OCCURRENCE_MESSAGE,
    projectId: projectId,
    occurrenceId: occurrenceId,
    payload: message,
})


/**
 * 
 * @param {String} projectId 
 * @param {Object} options 
 * @param {Object} options.sectionId 
 * @param {Object} options.subCriterionId
 */
const fetchOccurrences = (projectId, options = {}) => (dispatch) => {
    return functions.httpsCallable(FUNCTIONS.OCCURRENCE_LIST)({
        projectId: projectId,
        sectionId: options.sectionId,
        subCriterionId: options.subCriterionId,
        limit: options.limit,
        fromTime: options.fromTime
    })
        .then((result) => {
            const data = result.data;
            const storageLocation = options.subCriterionId || options.sectionId;
            dispatch(setOccurrence(projectId, storageLocation, data));
            return data.map(occ => mapOccurrence(occ));
        });
}

const listenToOccurrences = (projectId, options = {}) => (dispatch) => {
    let sectionId = options.sectionId;
    const subCriterionId = options.subCriterionId;
    let storeBy = sectionId;
    if (subCriterionId) {
        sectionId = subCriterionId.split("_").slice(0, 2).join("_"); // "ene_01__1" => "ene_01"
        storeBy = subCriterionId;
    } else if (!sectionId) {
        console.warn('[listenToOccurrences] - SectionId or SubCriterionId is required.')
        return;
    }

    const timestamp = options.timestamp || new Date().toISOString();

    let query = db.collection(COLLECTIONS.PROJECTS)
        .doc(projectId)
        .collection(COLLECTIONS.SECTIONS)
        .doc(sectionId)
        .collection(COLLECTIONS.OCCURRENCES);

    if (subCriterionId) {
        query = query.where('subCriterionId', '==', subCriterionId)
    } else {
        query = query
            .where("eventAction", "in", [
                EVENT_ACTIONS.CRITERION_STATUS_CHANGE,
                EVENT_ACTIONS.COMMENT_ON_REVISION,
                EVENT_ACTIONS.SECTION_COMMENT,
            ]);
    }

    const updatedAtUnSubFunc = query
        .where("updatedAt", ">", timestamp)
        .onSnapshot((snapshot) => {
            const docs = snapshot.docChanges().map(change => ({ id: change.doc.id, ...change.doc.data() }))
            dispatch(setOccurrence(projectId, storeBy, docs))
        });

    const timestampUnSubFunc = query
        .where("timestamp", ">", timestamp)
        .onSnapshot((snapshot) => {
            const docs = snapshot.docChanges().map(change => ({ id: change.doc.id, ...change.doc.data() }))
            dispatch(setOccurrence(projectId, storeBy, docs))
        });

    return [updatedAtUnSubFunc, timestampUnSubFunc];
}

const addCommentOnRevision = (projectId, sectionId, occurrenceId, message, customMessageId = null) => (dispatch) => {
    const messageId = customMessageId || uuidv4();
    const unpublishedMessage = {
        userId: auth.currentUser.uid,
        message: message,
        timestamp: new Date().toISOString(),
        id: messageId,
        isLoading: true,
    };
    dispatch(setOccurrenceMessage(projectId, occurrenceId, unpublishedMessage));

    return functions.httpsCallable(FUNCTIONS.REVISION_COMMENT_ADD)({
        projectId: projectId,
        sectionId: sectionId,
        occurrenceId: occurrenceId,
        message: message,
        messageId: messageId,
    })
        .then((result) => {
            return result.data;
            /* const messageResult = result.data;
            const newMessage = messageResult.message;
            newMessage.isLoading = false;
            dispatch(setOccurrenceMessage(projectId, occurrenceId, newMessage));
            return newMessage; */
        })
        .catch((error) => {
            unpublishedMessage.isError = true;
            dispatch(setOccurrenceMessage(projectId, occurrenceId, unpublishedMessage));
            throw new Error(error);
        })
}

const addCommentAsOccurence = (projectId, sectionId, message, customOccurrenceId = null) => dispatch => {
    const occurrenceId = customOccurrenceId || uuidv4();
    const unpublishedMessage = {
        eventAction: EVENT_ACTIONS.SECTION_COMMENT,
        users: { [auth.currentUser.uid]: {} },
        createdBy: auth.currentUser.uid,
        message: message,
        timestamp: new Date().toISOString(),
        id: occurrenceId,
        isLoading: true,
    };
    dispatch(setOccurrence(projectId, sectionId, unpublishedMessage));

    return functions.httpsCallable(FUNCTIONS.COMMENT_ADD)({
        projectId: projectId,
        sectionId: sectionId,
        message: message,
        messageId: occurrenceId,
    })
        .then((result) => {
            const messageResult = result.data;
            // const newMessage = messageResult.message;
            dispatch(setOccurrence(projectId, sectionId, messageResult));


            return messageResult;
        });
}

/**
 * Edits the content of a message. This can be a normal section-message or a message inside a
 * message-bank. 
 * @param {String} projectId - The id of the project 
 * @param {String} sectionId  - The id of the section where the messange belong
 * @param {String} occurrenceId  - The id of the occurrence-message or the occurrence-message-bank.
 * @param {String} message - The new message
 * @param {String} messageId - The id of the message inside the messageBank. This indicates that the message
 * lies inside a messageBank. If defined, the occurrenceId needs to be the id of the message-bank.
 * @param {String} reaction - The id of the reaction to toggle
 */
const editComment = (projectId, sectionId, occurrenceId, message = null, messageId = null, reaction = null) => (dispatch) => {

    if (reaction) {
        if (messageId) {
            const oldOccurrence = OccurrenceSelectors.getOccurrenceByChildId(projectId, occurrenceId)(store.getState());
            let oldMessage = oldOccurrence.messages[messageId] || {};
            oldMessage = addTempReactionInMessage(oldMessage, reaction, auth.currentUser.uid);
            (oldOccurrence.messages || {})[messageId] = oldMessage;
            dispatch(setOccurrence(projectId, null, oldOccurrence));
        } else {
            let oldOccurrence = OccurrenceSelectors.getOccurenceById(projectId, occurrenceId)(store.getState());
            oldOccurrence = addTempReactionInMessage(oldOccurrence, reaction, auth.currentUser.uid);
            dispatch(setOccurrence(projectId, null, oldOccurrence));
        }
    }

    return functions.httpsCallable(FUNCTIONS.OCCURRENCE_COMMENT_EDIT)({
        projectId: projectId,
        sectionId: sectionId,
        occurrenceId: occurrenceId,
        message: message,
        reaction: reaction,
        messageId: messageId,
    })
        .then((result) => {
            const newMessage = result.data;
            if (messageId) {
                // Store the message inside a message-bank
                const oldOccurrence = OccurrenceSelectors.getOccurrenceByChildId(projectId, occurrenceId)(store.getState());
                if (!oldOccurrence || !Array.isArray(oldOccurrence.messageIds)) {
                    return;
                }
                oldOccurrence.messages[messageId] = {
                    ...(oldOccurrence.messages[messageId] || {}),
                    ...newMessage
                };
                dispatch(setOccurrence(projectId, null, oldOccurrence));
                return;
            }
            // Store the message inside an normal occurrence
            const oldOccurrence = OccurrenceSelectors.getOccurenceById(projectId, occurrenceId)(store.getState());
            const newOccurrence = {
                ...oldOccurrence,
                ...newMessage
            }
            dispatch(setOccurrence(projectId, null, newOccurrence));
            return newMessage;
        })

}


// ----- HELPERS -------
const addTempReactionInMessage = (messageObject, reaction, uid) => {
    const reactions = messageObject.reactions || {};
    const reactionObject = reactions[reaction] || {};
    const reactionUsers = reactionObject.users || {};
    messageObject.reactions = {
        ...reactions, [reaction]: {
            count: (reactionObject.count || 0) + (reactionUsers[uid] ? -1 : 1),
            users: {
                ...(reactionObject.users || {}),
                [uid]: "",
            },
            isLoading: true,
        }
    }
    return messageObject;
}

export default {
    clearState,
    fetchOccurrences,
    listenToOccurrences,
    addCommentOnRevision,
    addCommentAsOccurence,
    editComment,
}