import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { goToProjectCategory, goToProjectSection } from '../../../../ROUTES';
import store from '~/store';


// Store imports
import ProjectSelectors from '../../../../store/project/selectors';
import ProjectActions from '~/store/project/actions';
import UserSelectors from '~/store/user/selectors';

// Data imports
import SECTIONS from '../../../../data/sections.json';
import { statuses as STATUSES, roles as ROLES, member as MEMBER } from '~/data/config';
import MANUAL_CATEGORIES from '../../../../data/categories.json';


// Components
import Icon from '~/components/base/Icon';

// Component components
import CategoryItem from '~/components/miscellaneous/CategoryItem'
import { Button } from '~/components/base/Buttons';
import { faAudioDescription } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '~/components/modals/ConfirmationModal';

// Utils
import { checkIfAccessToCategory, checkIfAccessToSection } from '~/utils/access';
import sectionsToCategoryByKey from '~/utils/category/sectionsToCategoryByKey';
import checkIfOwner from '~/utils/access/checkIfOwner';


const CategoryList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const manual = useSelector(ProjectSelectors.getCurrentManual);
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const userMemberData = useSelector(UserSelectors.getCurrentUserMemberData);
    const criteriaMetas = useSelector(ProjectSelectors.getCurCriteriaMetaData);

    const categoriesByKey = useMemo(() => {
        if (!project) { return {}; }
        return sectionsToCategoryByKey(Object.keys(project.scope || {}));
    }, [project])

    // Sets the project categories in the same order as the manualcategories
    const PROJECT_CATEGORIES = useMemo(() => {
        const sortedCategoriesByKey = MANUAL_CATEGORIES.reduce((prev, category) => {
            return categoriesByKey[category.key] ? { ...prev, [category.key]: categoriesByKey[category.key] } : prev
        }, {})
        return Object.entries(sortedCategoriesByKey).map(([categoryId, categoryName]) => ({
            key: categoryId,
            name: categoryName,
        }));
    }, [categoriesByKey])


    // Set the categories present in the project first, then the rest of the unused categories
    const MANUAL_CATEGORIES_SORTED = useMemo(() => {
        return [...PROJECT_CATEGORIES,
        ...MANUAL_CATEGORIES
            .reduce((prev, category) => categoriesByKey[category.key] ? prev : [...prev, category], [])
        ]
    })


    // State
    const [curCategory, setCurCategory] = useState(null);

    const [editMode, setEditMode] = useState(false);

    const [newSections, setNewSections] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [error, setError] = useState(null);


    const goToCategory = (category) => {
        history.push(goToProjectCategory(project.id, category))
        setCurCategory(category);
    }

    const goToSection = (category, section) => {
        history.push(goToProjectSection(project.id, category, section))
    }

    const editSection = (sectionId, value) => {
        setNewSections({
            ...newSections,
            [sectionId]: value,
        })
    }

    const hasAllSections = () => {
        return manual.sections ?
            manual.sections.every((section) => {
                return project.scope[section.id];
            })
            : false;
    }

    const saveNewSections = () => {
        setIsLoading(true);
        ProjectActions.addSectionAndCriteriaToProject(project.id, { sections: newSections })(dispatch)
            .then((result) => {
                closeEditMode();
            })
            .catch((error) => {
                setError(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const checkIfAnySectionsInCategory = (categoryId) => {
        return [...Object.keys(project.scope), ...Object.keys(newSections)]
            .some((key) => {
                return key.startsWith(categoryId)
            });
    }

    const closeEditMode = () => {
        setEditMode(false);
        setShowConfirmationModal(false);
        setNewSections({});
    }

    const openConfirmationModal = () => {
        if (Object.values(newSections).every((value) => !value)) {
            closeEditMode();
            return;
        }
        setShowConfirmationModal(true);
    }

    const calculateSectionStatus = (sectionId) => {
        const manualSubCriteria = ProjectSelectors.getAllManualSubCriteriaBySectionId(sectionId)(store.getState())
        const subCriteriaMetaById = Object.values(criteriaMetas)
            .filter(c => c.sectionId === sectionId)
            .map(c => c.subCriteria || {})
            .reduce((acc, val) => ({ ...acc, ...val }), {})

        // Check if all criterias is approved
        if (manualSubCriteria.every(c => (subCriteriaMetaById[c.id] || {}).status === STATUSES.approved)) {
            return <Icon icon='check-circle' color='var(--color-success)' />
        }
        // Check if some criterias are disapproved
        else if (manualSubCriteria.some(c => (subCriteriaMetaById[c.id] || {}).status === STATUSES.disapproved)) {
            return <Icon icon='times-circle' color='var(--color-error)' />
        }
        // Check if some criterias are pending
        else if (manualSubCriteria.some(c => (subCriteriaMetaById[c.id] || {}).status === STATUSES.pending)) {
            return <Icon icon='hourglass-half' color='var(--color-pending)' />
        }
        return null;
    }

    return (
        <>
            {editMode ?
                <div>
                    {
                        MANUAL_CATEGORIES_SORTED
                            .filter(cat => checkIfAccessToCategory(project, manual, userMemberData, cat.key))
                            .map(cat => (
                                <CategoryItem
                                    category={cat.name}
                                    key={cat.key}
                                    onClick={() => setCurCategory(cat.key === curCategory ? null : cat.key)}
                                    isOpen={cat.key === curCategory}
                                    weak={!checkIfAnySectionsInCategory(cat.key)}
                                >
                                    <div className='mb-1 cursor-pointer hover:bg-gray-250' onClick={() => goToCategory(cat.key)}>
                                        <p>Sammendrag</p>
                                        <hr />
                                    </div>
                                    {
                                        manual &&
                                        manual.sections
                                            .filter(s => s.category === cat.key && checkIfAccessToSection(project, userMemberData, s.id))
                                            .map(s =>
                                                <div
                                                    className={`mb-1 cursor-pointer hover:bg-gray-250`}
                                                    key={s.id} onClick={() => project.scope[s.id] ? goToSection(cat.key, s.id) : editSection(s.id, !newSections[s.id])}>
                                                    <div className='flex items-center'>
                                                        <p
                                                            className={`flex-grow ${(project.scope[s.id] || newSections[s.id]) ? '' : 'text-gray-500'}`}
                                                        >
                                                            {s.id.toUpperCase() + ' - ' + SECTIONS[s.id]}
                                                        </p>
                                                        {
                                                            !project.scope[s.id] &&
                                                            <Icon
                                                                icon={!newSections[s.id] ? 'plus' : 'minus'}
                                                                color={!newSections[s.id] ? '#2C7A7B' : 'red'}
                                                            />
                                                        }
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                    }
                                </CategoryItem>
                            ))
                    }
                </div>
                :
                <>
                    {
                        PROJECT_CATEGORIES
                            .filter(cat => checkIfAccessToCategory(project, manual, userMemberData, cat.key))
                            .map(cat => (
                                <CategoryItem
                                    category={cat.name}
                                    key={cat.key}
                                    onClick={() => setCurCategory(cat.key === curCategory ? null : cat.key)}
                                    isOpen={cat.key === curCategory}
                                >
                                    <div className='cursor-pointer hover:bg-gray-250' onClick={() => goToCategory(cat.key)}>
                                        <p>Sammendrag</p>
                                        <hr />
                                    </div>
                                    {
                                        manual &&
                                        manual.sections
                                            .filter(s => (project.scope[s.id] || editMode) && s.category === cat.key && checkIfAccessToSection(project, userMemberData, s.id))
                                            .map(s =>
                                                <div className='mb-0 p-1 mx-2 px-2 cursor-pointer hover:bg-gray-250' key={s.id} onClick={() => goToSection(cat.key, s.id)}>
                                                    <div className='flex items-center'>
                                                        <p className='flex-grow'>{s.id.toUpperCase() + ' - ' + SECTIONS[s.id]}</p>
                                                        {
                                                            calculateSectionStatus(s.id)
                                                        }
                                                    </div>
                                                </div>)
                                    }

                                </CategoryItem>
                            ))
                    }
                </>
            }

            {
                checkIfOwner(project) && !hasAllSections() && !project.archived &&
                <div className={`fixed bottom-0 left-0 p-2 bg-white`}>
                    <div className='flex items-center'>
                        <div className='p-1'>
                            <Button
                                variant='text'
                                onClick={() => !editMode ? setEditMode(true) : closeEditMode()}
                            >
                                <div className=''>
                                    <Icon icon={editMode ? 'arrow-left' : 'plus'} />
                                </div>
                            </Button>
                        </div>
                        {editMode &&
                            <>
                                <div className='p-1'>
                                    <Button
                                        variant='text'
                                        onClick={openConfirmationModal}
                                    >
                                        <div className=''>
                                            <Icon icon='save' />
                                        </div>
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
            <ConfirmationModal
                show={showConfirmationModal}
                title='Er du sikker?'
                loading={isLoading}
                onConfirm={() => saveNewSections()}
                onClose={() => setShowConfirmationModal(false)}
                description={error ? `ERROR: ${error}, Prøv igjen? ` :
                    <>
                        <b className='p-2'>Disse emnene vil bli lagt til i prosjektet: </b>
                        {
                            Object.entries(newSections)
                                .reduce((prev, [sectionId, value]) => {
                                    if (!value) {
                                        return prev;
                                    }
                                    return [...prev,
                                    <div
                                        className='border-b-2 p-1' key={sectionId}
                                    >
                                        {sectionId.toUpperCase() + ' - ' + SECTIONS[sectionId]}
                                    </div>
                                    ];
                                }, [])
                        }
                    </>
                }
            />
        </>)
};

export default CategoryList;