import * as firebase from '../../services/firebase';
import { roles as ROLES } from '~/data/config';
import { SECTION_META_DATA_KEY, CRITERIA_META_DATA_KEY } from './reducer';


// ----- PROJECTS ----------

const getProjectState = (state) => state.projects;

const getProjects = (state) => Object.values(getProjectState(state).projects).filter((project) => !project.archived);

const getArchivedProjects = (state) => Object.values(getProjectState(state).projects).filter((project) => project.archived);

const getCurrentArchived = (state) => getProjectState(state).projects[getProjectState(state).curProject].archived;

const getProjectById = (id) => (state) => getProjectState(state).projects[id];

const getCurrentProject = (state) => getProjectState(state).projects[getProjectState(state).curProject];

const getProjectMetaById = (projectId) => (state) => (getProjectState(state).projectMeta[projectId] || {});

const getProjectPhases = (state) => getCurrentProject(state).phases || {};

const getProjectProgressPlan = (projectId) => (state) => getProjectMetaById(projectId)(state).progressPlan || null;

// ------ SECTIONS & CRITERIA -------

const getSectionById = (projectId, sectionId) => (state) => (getProjectState(state)[SECTION_META_DATA_KEY][projectId] || {})[sectionId];

const getCurrentSectionMetaData = (sectionId) => (state) => (getProjectState(state)[SECTION_META_DATA_KEY][getProjectState(state).curProject] || {})[sectionId];

const getCurrentSectionMetas = (state) => (getProjectState(state)[SECTION_META_DATA_KEY][getProjectState(state).curProject] || {});

const getCurCriteriaMetaData = (state) => getProjectState(state)[CRITERIA_META_DATA_KEY][getProjectState(state).curProject] || {};

const getProjectCriteriaMetaDataBySection = (sectionId) => (state) => {
    const curProject = getCurrentProject(state);
    const criteriaMetaByKey = getCurCriteriaMetaData(state);
    return Object.keys((curProject.scope || {})[sectionId] || {})
        .sort((a, b) => {
            const criterionNum1 = parseInt(a.split('_')[2]); // man_01_05_<uuid> -> 05
            const criterionNum2 = parseInt(b.split('_')[2]); // man_01_06_<uuid> -> 06
            return criterionNum1 - criterionNum2;
        })
        .map(criterionId => criteriaMetaByKey[criterionId] || {});
};

const getCurCriteriaMetaDataBySectionAsArray = (sectionId) => (state) =>
    Object.values(getCurCriteriaMetaData(state))
        .filter(c => c.sectionId === sectionId);

const getCriterionMetaDataByProject = (projectId) => (state) => getProjectState(state)[CRITERIA_META_DATA_KEY][projectId] || {};

const getCriterionById = (criterionId) => (state) => getCurCriteriaMetaData(state)[criterionId];

const getSubCriterionById = (subCriterionId) => (state) => {
    const manualCriterion = getManualCriterionBySubCriterionId(subCriterionId)(state);
    if (!manualCriterion) {
        return null;
    }
    return ((getCriterionById(manualCriterion.id)(state) || {}).subCriteria || {})[subCriterionId];
}

/**
 * getAllDataBySubCriterionId extracts the corresponding section, criterion and subCriterion manual-data
 * by given subCriterionId.
 * @param {String} subCriterionId - The id of the subCriterion
 * @returns {Object} All the data related to the given subCriterion by key.
 */
const getAllManualDataBySubCriterionId = (subCriterionId) => (state) => {
    const sectionId = subCriterionId.split("_").slice(0, 2).join("_"); // "man_01__1" => "man_01"
    const section = (getCurrentManual(state) || { sections: [] }).sections.find(s => s.id === sectionId) || {};
    const assessmentCriteria = section.assessmentCriteria || [];
    const criterion = assessmentCriteria.find(criteria => (criteria.criteria || []).some(subCriterion => subCriterion.id === subCriterionId)) || {};
    return {
        sectionId,
        section,
        criterionId: criterion.id,
        subCriterionId,
        subCriterion: (criterion.criteria || []).find(subCriterion => subCriterion.id === subCriterionId) || {},
    }
}

const getCurCategory = (state) => getProjectState(state).curCategory;

const getCurSection = (state) => getProjectState(state).curSection;

// ------ MANUAL ---------

const getManualByVersion = (version) => (state) => getProjectState(state).manuals[version];

const getCurrentManual = (state) => getProjectState(state).manuals[getProjectState(state).curManualVersion];

const getManualSectionById = (sectionId) => (state) => getCurrentManual(state).sections.find(section => section.id === sectionId);

const getManualSubCriterionById = (subCriterionId) => (state) => {
    const criterion = getManualCriterionBySubCriterionId(subCriterionId)(state);
    if (!criterion) {
        return null;
    }
    return (criterion.criteria || []).find(subCriterion => subCriterion.id === subCriterionId);
}

const getManualCriterionBySubCriterionId = (subCriterionId) => (state) => {
    const manual = getCurrentManual(state) || {};
    const sectionId = subCriterionId.split("_").slice(0, 2).join("_"); // "man_01__01" => "man_01"
    const section = (manual.sections || []).find(section => section.id === sectionId) || {};
    const assessmentCriteria = section.assessmentCriteria || [];
    return assessmentCriteria.find(criteria => (criteria.criteria || []).some(subCriterion => subCriterion.id === subCriterionId));
}

const getAllManualSubCriteriaBySectionId = (sectionId) => (state) => {
    const manual = getCurrentManual(state) || {};
    const section = (manual.sections || []).find(section => section.id === sectionId) || {};
    return (section.assessmentCriteria || [])
        .map(criterion => criterion.criteria || [])
        .reduce((acc, subCriteria) => ([...acc, ...subCriteria]), [])
}


// ------ METRICS --------

const getCurDocAggregations = (state) => (getProjectState(state).metrics[getProjectState(state).curProject] || {}).docAggregations || {};
const getCurCreditAggregations = (state) => (getProjectState(state).metrics[getProjectState(state).curProject] || {}).creditAggregations || {};


// ------ ROLES ---------

const getCurProjectRole = (state) => {
    const curProjectId = getProjectState(state).curProject;
    return getProjectRoleById(curProjectId)(state);
}

const getProjectRoleById = (projectId) => (state) => {
    if (!firebase.auth.currentUser) return;
	const project = getProjectById(projectId)(state);
    const uid = firebase.auth.currentUser.uid;
    const projectRole = ((project || {}).roles || {})[uid];

    // If the user is not in the project's "roles", the user has no access to the project
    if (!projectRole) {
        return null;
    }

    // Check if a section is active.
    const curSectionId = getCurSection(state);
    if (!curSectionId || projectRole === ROLES.owner || projectRole === ROLES.auditor) {
        return projectRole;
    }

    // A section is active, check if user has access to that section
    const section = getCurrentSectionMetaData(curSectionId)(state);
    if (!section || !section.roles) {
        return null;
    }

    return section.roles[uid] || null;
}

export default {
	getProjects,
	getArchivedProjects,
	getCurrentArchived,
	getProjectById,
	getProjectMetaById,
	getProjectPhases,
	getProjectProgressPlan,
	getManualByVersion,
	getManualCriterionBySubCriterionId,
	getAllManualSubCriteriaBySectionId,
	getAllManualDataBySubCriterionId,
	getManualSectionById,
	getManualSubCriterionById,
	getCurrentManual,
	getCurrentProject,
	getCurrentSectionMetaData,
	getCurrentSectionMetas,
	getCurCriteriaMetaData,
	getProjectCriteriaMetaDataBySection,
	getCurCriteriaMetaDataBySectionAsArray,
	getSectionById,
	getCriterionById,
	getCriterionMetaDataByProject,
	getSubCriterionById,
	getCurCategory,
	getCurSection,
	getCurDocAggregations,
	getCurCreditAggregations,
	getCurProjectRole,
	getProjectRoleById,
};