import React, { useState } from 'react';

// Components
import Icon from '~/components/base/Icon';
import SuccessModal, { REQUEST_SUCCESS, REQUEST_FAILED } from '~/views/projectAddUserView/components/SuccessModal';

// Store
import UserActions from '~/store/user/actions';

// Style
import styles from '../index.module.css';


// messages
const SUBJECTS = {
    details: "Detaljer",
    changePassword: "Endre passord...",
    emailSent: "Vi har sendt deg en E-post om tilbakestilling av passord"
}

const errorMessages = {
    updateError: 'Kunne ikke sende E-post'
}

const UserSettings = () => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    // Send password reset email
    const sendEmail = () => {
        setShowSuccessModal(true);
        setIsLoading(true);
        UserActions.updatePassword()
            .then(() => {
                setRequestStatus(REQUEST_SUCCESS);
            })
            .catch(() => {
                setRequestStatus(REQUEST_FAILED);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
    <div className = 'items-center w-'>
        <div className = 'border-b border-black p-2'>
            <b>{SUBJECTS.details}</b>
        </div>
        <div className = {`p-2 flex items-center ${styles.overcontent}`} onClick = {sendEmail}>
            <div className = 'p-2'>
                <Icon icon = 'key'/>
            </div>
            <div className = 'p-2'>
                {SUBJECTS.changePassword}
            </div>
        </div>
        <SuccessModal
            show = {showSuccessModal}
            isLoading = {isLoading}
            status = {requestStatus}
            onClose = {() => setShowSuccessModal(false)}
            errorMessage = {errorMessages.updateError}
            successMessage = {SUBJECTS.emailSent}
        />
    </div>
    )
}

export default UserSettings;