/**
 * @description Calculates how far you're currently between two dates
 * @param {DateObject} startDate 
 * @param {DateObject} endDate 
 * @returns {Number}
 */

function calculatePercentageBetweenTwoDates(startDate, endDate) {
    const today = new Date();
    return ((today - startDate) / (endDate - startDate)) * 100;
}

export default calculatePercentageBetweenTwoDates;