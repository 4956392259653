import {MUTATIONS, mapManual } from './reducer';
import ProjectSelectors from './selectors';
import * as firebase from '../../services/firebase';
import store from '../index';


const setManual = (manual) => ({
    type: MUTATIONS.SET_MANUAL,
    payload: manual,
});

const setLatestManual = (manual) => ({
    type: MUTATIONS.SET_LATEST_MANUAL,
    payload: manual,
})

const useManual = (manualVersion) => ({
    type: MUTATIONS.USE_MANUAL,
    payload: manualVersion,
});

const fetchManualByVersion = (dispatch, version) => {
    const manual = ProjectSelectors.getManualByVersion(version)(store.getState());
    if(manual) {
        return Promise.resolve(manual);
    }

    return firebase.manualCollection
        .where("version", "==", version.toString())
        .orderBy("createdAt")
        .limit(1)
        .get() 
        .then((manuals) => {
            if(manuals.empty) {
                return Promise.reject();
            }

            const manualByVersion = manuals.docs[0].data();
            dispatch(setManual(manualByVersion));
            return mapManual(manualByVersion);
        });
}

const fetchLatestManual = (dispatch) => {
    return firebase.manualCollection
        .orderBy("createdAt", 'desc')
        .limit(1)
        .get()
        .then((manuals) => {
            if(manuals.empty) {
                return Promise.reject();
            }

            const manual = manuals.docs[0].data();
            return mapManual(manual);
        })
}
    
export default {
    setManual,
    setLatestManual,
    useManual,
    fetchManualByVersion,
    fetchLatestManual,
}