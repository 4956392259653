// Import from /actions
import user from './user/actions';
import projects from './project/actions';
import documents from './documents/actions';
import events from './events/actions';
import occurrences from './occurrence/actions';

export default {
    user,
    projects,
    documents,
    events,
    occurrences,
}