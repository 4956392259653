import { mapNotification, mapNotificationCount } from './mappers';

export const MUTATIONS = {
    SET_EVENTS: 'EVENTS_SET_EVENTS',
    SET_EVENT: 'EVENTS_SET_EVENT',

    SET_NOTIFICATIONS: 'EVENTS_SET_NOTIFICATIONS',
    SET_NOTIFICATION: 'EVENTS_SET_NOTIFICAITON',

    SET_NOTIFICATION_COUNTS: 'EVENTS_SET_NOTIFICATION_COUNTS',
    DECREASE_NOTIFICATION_COUNTS: 'EVENTS_DECREASE_NOTIFICATION_COUNTS',


    CLEAR_STATE: 'EVENTS_CLEAR_STATE',
}


const initialState = {
    events: {}, // By project id, event-id - { [projectId]: { [eventId]: { ... } } }

    notifications: {}, // By projectId - { [projectId]: { [notificationId]: { ... } } }


    notificationCount: {}, // By projectId - { [projectId]: { ... } }
}

const reducer = (state = initialState, action) => {

    const payload = action.payload;

    switch(action.type) {
        
        case MUTATIONS.SET_EVENTS: {
            const projectId = action.projectId;
            
            // If payload is not an array, return
            if(!(payload instanceof Array)) {
                return state;
            }
            
            const newEvents = payload.map(event => mapEvent(event));

            const newEventsAsObject = newEvents
                .map(event => ({[event.id]: event}))       
                .reduce((acc, event) => ({...acc, ...event}), {})
            const projectEvents = Object.assign({}, state.events[projectId] || {}, newEventsAsObject);


            return {
                ...state,
                events: {
                    ...state.events,
                    [projectId]: projectEvents,
                }
            }
        }
        
        case MUTATIONS.SET_EVENT: {
            const projectId = action.projectId;
            const newEvent = mapEvent(payload);
            const projectEvents = Object.assign({}, state.events[projectId] || {}, {[newEvent.id]: newEvent});

            return {
                ...state,
                events: {
                    ...state.events,
                    [projectId]: projectEvents,
                }
            }
        }

        case MUTATIONS.SET_NOTIFICATIONS: {
            const projectId = action.projectId;
            const notificationsById = payload
                .map(mapNotification)
                .reduce((acc, notf) => ({...acc, [notf.id]: notf}), {});
            const projectNotifications = Object.assign({}, state.notifications[projectId] || {}, notificationsById);
            
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [projectId]: projectNotifications,
                }
            }
        }

        case MUTATIONS.SET_NOTIFICATION: {
            const projectId = action.projectId;
            const newNotification = mapNotification(payload);
            const projectNotifications = Object.assign({}, state.notifications[projectId] || {}, {[newNotification.id]: newNotification});

            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [projectId]: projectNotifications,
                }
            }
        }

        case MUTATIONS.SET_NOTIFICATION_COUNTS: {
            const projectId = action.projectId;
            const projectNotificationCount = Object.assign({}, state.notificationCount[projectId] || {}, mapNotificationCount(payload))

            if(!state.notificationCount.time || new Date().getTime() - state.notificationCount.time > 10000)  {
                return {
                    ...state,
                    notificationCount: {
                        ...state.notificationCount,
                        [projectId]: projectNotificationCount,
                        time: action.time
                    }
                }
            } else {
                return state;
            }
        }

        case MUTATIONS.DECREASE_NOTIFICATION_COUNTS: {
            const projectId = action.projectId;
            const key = payload;
            const projectNotificationCount = Object.assign({}, state.notificationCount[projectId] || {});
            projectNotificationCount[key] = projectNotificationCount[key] > 0 ? projectNotificationCount[key] - 1 : 0;

            return {
                ...state,
                notificationCount: {
                    ...state.notificationCount,
                    [projectId]: projectNotificationCount,
                }
            }
        }

        case MUTATIONS.CLEAR_STATE: {
            return initialState;
        }

        default:
            return state;
    }

}

const mapEvent = (data) => ({
    id: data.id,
    eventType: data.eventType,
    timestamp: data.timestamp,
    user: data.user,
    payload: data.payload,
})

export default reducer;