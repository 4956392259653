export const roles = {
    owner: 'owner',
    member: 'member',
    auditor: 'auditor',
};

export const statuses = {
    approved: 'APPROVED',
    disapproved: 'DISAPPROVED',
    pending: 'PENDING',
    none: null,
};

export const deadlineStatuses = {
    overdue: 'OVERDUE',
    active: 'ACTIVE',
    none: null,
};

export const dateStyles = {
    SHORT: 'short',
    MEDIUM: 'medium',
    LONG: 'long',
}

export const firebaseStorage = {
    BASE_URL: 'https://firebasestorage.googleapis.com/v0/b/',
    AVATAR: 'avatars',
    STANDARD_PROFILE_NAME: 'avatar.png'
}

export const eventTypes = {
    CRITERION_SENT_FOR_REVISION: 'CRITERION_SENT_FOR_REVISION',
    CRITERION_STATUS_CHANGE: 'CRITERION_STATUS_CHANGE',
    CRITERION_NEW_DOCUMENTATION: 'CRITERION_NEW_DOCUMENTATION',
    CRITERION_DEADLINE_CHANGE: 'CRITERION_DEADLINE_CHANGE',

    // Occurrence types
    COMMENT_ON_REVISION: 'COMMENT_ON_REVISION',
    SECTION_COMMENT: 'SECTION_COMMENT',

    // Document events
    DOCUMENT_CREATED: 'DOCUMENT_CREATED',

    // Member events
    MEMBER_ADD: 'MEMBER_ADD',
    ROLE_CHANGE: 'ROLE_CHANGE',
    ACCESS_GIVEN: 'ACCESS_GIVEN',
    ACCESS_REVOKED: 'ACCESS_REVOKED',
    RESPONSIBILITY_GIVEN: 'RESPONSIBILITY_GIVEN',
    RESPONSIBILITY_REVOKED: 'RESPONSIBILITY_REVOKED',
    REMINDER: 'REMINDER',
    PHASE_REMINDER: 'PHASE_REMINDER',
};

export const eventFlags = {
    BOOKMARK: 'BOOKMARK',
    IGNORE: 'IGNORE',
    NONE: 'NONE',
}


export const functions = {
    PROJECT_CREATE: 'projects-create',
    PROJECT_EDIT: 'projects-edit',
    PROJECT_PHASES_UPSERT: 'projects-phasesUpsert',
    PROJECT_PREANALYSIS_PARSE: 'projects-preanalysisParse',
    PROJECT_EXPORT: 'projects-export',
    PROJECT_EXPORT_ACCESS_REQUEST: 'projects-exportAccessRequest',
    PROJECT_ARCHIVE: 'projects-archive',
    PROJECT_EXPORT_ACCESS_GRANT: 'projects-exportAccessGrant',

    DOCUMENT_CREATE: 'documents-create',
    DOCUMENT_LIST: 'documents-documentList',
    DIRECTORY_GET: 'documents-directoryGet',
    DOCUMENT_ATTACHMENT_CODE: 'documents-documentAttachmentCode',

    NOTIFICATION_SEEN_ALL: 'events-notificationsSeenAll',
    NOTIFICATION_REMINDER: 'events-notificationsReminder',
    NOTIFICATION_REMINDME: 'events-remindersCreate',
    NOTIFICATION_LIST: 'events-list',
    NOTIFICATION_FLAG: 'events-notificationsFlag',
    OCCURRENCE_LIST: 'occurrence-list',
    OCCURRENCE_COMMENT_EDIT: 'occurrence-commentEdit',
    COMMENT_ADD: 'occurrence-commentAdd',
    REVISION_COMMENT_ADD: 'occurrence-revisionCommentAdd',
    USER_CREATE: 'user-userCreate',
    USER_EDIT: 'user-edit',
    USER_ACCESS_EDIT: 'user-accessEdit',

    CRITERIA_SUB_CRITERION_REVIEW: 'criteria-subCriterionReview',
    CRITERIA_LOCK: 'criteria-lock',
    CRITERIA_DEADLINE_SET: 'criteria-deadlineSet',
}

export const collections = {
    PROJECTS: 'projects',
    PROJECT_META: 'project_meta',
    SECTIONS: 'sections',
    CRITERIA: 'criteria',
    DOCUMENTS: 'documents',
    DOCUMENTS_META: 'document_meta',
    DIRECTORIES: 'directories',
    EVENTS: 'events',
    METRICS: 'metrics',
    USERS: 'users',
    MEMBERS: 'members',
    NOTIFICATIONS: 'notifications',
    MANUALS: 'manuals',
    OCCURRENCES: 'occurrences',
};

export const reactions = {
    THUMBSUP: '👍',
    THUMBSDOWN: '👎',
    LAUGH: '😄',
    HOORAY: '🎉',
    CONFUSED: '😕',
    HEART: '❤️',
    ROCKET: '🚀',
    EYES: '👀',
}

export const member = {
    SECTIONS_VALUE: {
        ACCESS: 'ACCESS',
        RESPONSIBLE: 'RESPONSIBLE',
        REVOKED: 'REVOKED'
    },
};

export const projectPages = {
	FRONTPAGE: 'Frontside',
	ACCESS: 'Tilganger',
	DOCUMENTS: 'Dokumenter',
	MANUAL: 'Manual',
};