import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { collections as COLLECTIONS } from '~/data/config';

const settings = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(settings);

// Firebase utils
const db = firebase.firestore()
const storage = firebase.storage();
const auth = firebase.auth()
const functions = firebase.functions()

// Firebase collections
const userCollection = db.collection(COLLECTIONS.USERS);
const projectCollection = db.collection(COLLECTIONS.PROJECTS);
const manualCollection = db.collection(COLLECTIONS.MANUALS);
const documentMetaCollection = (projectId) => projectCollection.doc(projectId).collection(COLLECTIONS.DOCUMENTS_META);
const directoryCollection = (projectId) => projectCollection.doc(projectId).collection(COLLECTIONS.DIRECTORIES);
const memberCollection = (projectId) => projectCollection.doc(projectId).collection(COLLECTIONS.MEMBERS);
const notificationCollection = (projectId, UID) => memberCollection(projectId).doc(UID).collection(COLLECTIONS.NOTIFICATIONS);
const sectionCollection = (projectId) => projectCollection.doc(projectId).collection(COLLECTIONS.SECTIONS);

// Local development
if(process.env.NODE_ENV !== 'production') {
    // Enable for testing functions that are under development
    // functions.useFunctionsEmulator('http://localhost:5000');
}


export {
    settings,
    db,
    auth,
    storage,
    functions,
    userCollection,
    projectCollection,
    manualCollection,
    documentMetaCollection,
    directoryCollection,
    memberCollection,
    notificationCollection,
    sectionCollection,
}