const MS_PER_DAY = 1000 * 60 * 60 * 24

/**
 * 
 * @param {Date} dateA 
 * @param {Date} dateB 
 */
export const diffInDays = (dateA, dateB) => {
    const utcA = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
    const utcB = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());

    return Math.floor((utcB - utcA)/MS_PER_DAY);
}
