import React from 'react';
import PropTypes from 'prop-types';

//Config
import { roles as ROLES } from '~/data/config';
import { statuses as STATUSES } from '~/data/config';

// Core Components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';

// Components
import StatusIcon from '~/components/miscellaneous/StatusIcon';
import LockIcon from '~/components/miscellaneous/LockIcon';
import { DeadlineIcon, DeadlineIconButton } from '~/components/miscellaneous/DeadlineIcon';

const ActionBtns = ({
    className = '',
    isLocked,
    deadlineStatus,
    status,
    onReview,
    onLock,
    onShowDocuments,
    onShowDatePicker,
    userRole,
    archived,
}) => {

    const CAN_LOCK = (userRole === ROLES.owner || userRole === ROLES.member) && !archived;
    const CAN_REVIEW = userRole === ROLES.auditor && !archived;
    const CAN_SET_DEADLINE = userRole === ROLES.owner && !archived;
    const IS_LOCKED = Boolean(isLocked) && !archived;
    const IS_APPROVED = status === STATUSES.approved;

    return (
        <div className={`${className} grid grid-cols-4 items-center justify-center`}>
            {!IS_APPROVED ?
                <div className='relative' style={{ justifySelf: 'center' }}>
                    {
                        CAN_SET_DEADLINE ?
                            <DeadlineIconButton
                                onClick={onShowDatePicker}
                                deadlineStatus={deadlineStatus}
                                canSetDeadline={CAN_SET_DEADLINE}
                            />
                            :
                            <DeadlineIcon
                                deadlineStatus={deadlineStatus}
                                size='lg'
                                canSetDeadline={CAN_SET_DEADLINE}
                            />
                    }
                </div>
                : null
            }
            <div>
                <Button
                    className='m-auto'
                    variant='icon'
                    onClick={onShowDocuments}
                >
                    <Icon icon='file-alt' size='lg' />
                </Button>
            </div>
            <div>
                {
                    CAN_LOCK && !IS_LOCKED ?
                        <Button
                            className='m-auto'
                            variant='icon'
                            onClick={onLock}
                        >
                            <LockIcon isLocked={IS_LOCKED} size='lg' />
                        </Button>
                        :
                        <div
                            className='pa-2 flex justify-center'
                        >
                            <LockIcon isLocked={IS_LOCKED} size='lg' />
                        </div>
                }
            </div>
            <div>

                {
                    ((CAN_REVIEW && IS_LOCKED) || (!CAN_REVIEW)) && onReview && !archived ?
                        <Button
                            className='m-auto'
                            variant='icon'
                            onClick={onReview}
                        >
                            <StatusIcon size='lg' status={status} />
                        </Button>
                        :
                        Boolean(status) &&
                        <div
                            className='pa-2 flex justify-center'
                        >
                            <StatusIcon size='lg' status={status} />
                        </div>
                }

            </div>
        </div>
    )
};

ActionBtns.propTypes = {
    className: PropTypes.string,
    isLocked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    status: PropTypes.string,
    deadlineStatus: PropTypes.object,
    onReview: PropTypes.func,
    onLock: PropTypes.func.isRequired,
    onShowDocuments: PropTypes.func.isRequired,
    onShowDatePicker: PropTypes.func.isRequired,
    userRole: PropTypes.string,
}


export default ActionBtns;