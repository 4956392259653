import React from 'react';
import PropTypes from 'prop-types';

const KvistLogo = ({
    className = '',
    width = '8rem',
    onClick
}) => {


    return (
        <svg
            className={className}
            onClick={() => onClick && onClick()}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            viewBox="0 0 1241.85 359.12"
        >
            <g
                id="kvist_logo_mørkegrå"
                transform="translate(-62.15 -203.94)"
            >
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M326.42,382.44h40.45l59.46,126.03,59.35-126.01,40.46-.02-84.4,179.32H410.96Z"
                    fill="#319795"
                >
                </path>
                <rect id="Rectangle_47"
                    data-name="Rectangle 47"
                    width="36.87"
                    height="29.93"
                    transform="translate(672.94 316.56)"
                    fill="#319795"
                >
                </rect>
                <rect
                    id="Rectangle_48"
                    data-name="Rectangle 48"
                    width="36.87"
                    height="179.32"
                    transform="translate(672.94 382.44)"
                    fill="#319795"
                >
                </rect>
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M1189.39,203.94V351.6l-44.32-52.93-29.46,20.18,73.78,87.38-.19,156.83h36.84V460.7l77.96-7.38-3.75-33.17-74.26,7,.07-223.21Z"
                    fill="#319795"
                >
                </path>
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M991.64,477.96c20.25,17.19,16.45,34.25,13.5,46.39-4.35,17.99-23.38,36.31-55.39,38.21-34.74,2.06-68.56-23.14-68.56-23.14l23.6-25.5c3.06,1.96,26.26,16.56,41.93,15.66,24.67-1.36,23.94-19.82,23.94-19.82-.37-4.12-2.66-7.25-9.15-11.14-5.46-3.33-14.01-7.18-27.07-12.6l-.15-.07-2.58-1.2-4.13-1.93-.26-.1c-3.1-1.36-6.01-2.69-8.7-4.06a108.229,108.229,0,0,1-20.5-12.9c-22.16-18.12-17.81-35.81-14.53-48.32,4.76-17.92,24.16-35.91,56.2-37.24,34.78-1.46,68.04,24.31,68.04,24.31l-24.12,25.11c-3.02-2.03-25.89-17.02-41.6-16.39-24.67.93-24.34,19.42-24.34,19.42.37,5.29,3.87,9.04,15.42,14.93,4.94,2.56,11.36,5.49,19.62,9.14l.63.27,2.73,1.3.44.2a163.181,163.181,0,0,1,20.14,9.34l.15.1A90.194,90.194,0,0,1,991.64,477.96Z"
                    fill="#319795"
                >
                </path>
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M62.15,203.94H99.08l-.02,253.97,64.63-74.41h46.68l-69.11,80.21,74.25,99.35H171.1L99.06,467.44l.06,95.62H62.15Z"
                    fill="#319795"
                >
                </path>
            </g>
        </svg>
    )
}

KvistLogo.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    onClick: PropTypes.func,
}

export default KvistLogo;