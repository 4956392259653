/**
 * Converts a subCriterionId to a criterionId based on the project scope.
 * 
 * @param {String} subCriterionId - The subCriterionId for the corresponding criterionId.
 * @param {Object} projectScope - The scope of the project - "project.scope" 
 */
export const criterionIdBySubCriterionId = (subCriterionId, projectScope = {}) => {
    const sectionId = subCriterionId.split("_").slice(0, 2).join("_");
    const criterionObject = Object.entries(projectScope[sectionId] || {})
        .find(([criterionId, subCriteria]) => 
            subCriteria.some((subCritId) => subCritId === subCriterionId)
        );
    return criterionObject && criterionObject.length > 0 ? criterionObject[0] : null;
}