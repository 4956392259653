import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AuthService from '~/services/authService';
import { statuses as STATUSES } from '~/data/config';

// Store imports
import { useSelector } from 'react-redux';
import UserSelectors from '~/store/user/selectors';
import ProjectSelectors from '~/store/project/selectors';

// Components
import Spinner from '~/components/base/Spinner';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';
import { groupByArray } from '~/utils/type/object';
import { criterionIdBySubCriterionId } from '~/utils/project/scope';
import { dateToDateString, timeLeft } from '~/utils/date';
import { goToProjectSection, goToProjectCriteria } from '~/ROUTES';
import { Link } from 'react-router-dom';
import timeAgo from '~/utils/date/timeAgo';
import StatusIcon from '~/components/miscellaneous/StatusIcon';


const ResponsibilityList = ({
    projectId,
    targetUser,
    isLoading = false,
}) => {
    const userInFocus = targetUser || AuthService.currentUserId();

    // Selectors
    const project = useSelector(ProjectSelectors.getProjectById(projectId));
    const member = useSelector(UserSelectors.getMemberById(userInFocus, projectId));
    const criteriaMetaData = useSelector(ProjectSelectors.getCriterionMetaDataByProject(projectId));

    /**
     * @typedef {Object} SectionResponsibility
     * @property {String} sectionId
     * @property {Boolean} hasAllSubCriterionIds
     * @property {String} commonDeadline
     * @property {Array.<Object>} subCriteria
     */
    const [
        completedResponsibilityList,
        uncompletedResponsibilityList,
    ] = useMemo(() => {
        /**
         * @type {Member}
         */

        // Merge the responsibilities with the SubCriterionMetaData from "criteriaMetaData".
        const responsibilities = Object.entries(member.criteriaResponsible || {})
            .filter(([subCriterionId, isResponsible]) => isResponsible)
            .map(([subCriterionId, _]) => {
                const sectionId = subCriterionId.split("_").slice(0, 2).join("_");
                const criterionId = criterionIdBySubCriterionId(subCriterionId, project.scope);

                return {
                    id: subCriterionId,
                    subCriterionNumber: subCriterionId.split("_").slice(-1).join(""),
                    sectionId: sectionId,
                    ...(((criteriaMetaData[criterionId] || {}).subCriteria || {})[subCriterionId] || {}),
                }
            })
            .filter(subCriterionObject => subCriterionObject);

        // Group by sectionId, and check if responsible for all subCriterionIds in each section
        const groupAndMapResponsibilitiesBySection = (responsibilityList) => {
            const groupedBySectionId = groupByArray(responsibilityList, (obj) => obj.sectionId);
            const responsibilitiesBySection = Object.entries(groupedBySectionId)
                .map(([sectionId, subCriteriaList]) => {

                    const totalSubCriterionIdsInSection = Object.values(project.scope[sectionId] || {})
                        .reduce((prev, subCritId) => ([...prev, subCritId]), []);

                    // If one is responsible for all the subCriterions in the section
                    const hasAllSubCriterionIds = subCriteriaList.length === totalSubCriterionIdsInSection.length;

                    // Check if all subCriterions has the same deadline
                    let commonDeadline = null;
                    if (subCriteriaList.length > 0) {
                        const deadline = subCriteriaList[0].deadline || null;
                        commonDeadline = subCriteriaList.every(subCrit => subCrit.deadline === deadline) ?
                            deadline :
                            null;
                    }

                    return {
                        sectionId: sectionId,
                        hasAllSubCriterionIds: hasAllSubCriterionIds,
                        commonDeadline: commonDeadline,
                        subCriteria: subCriteriaList,
                    }
                })

            return responsibilitiesBySection;
        }

        const completedResponsibilities = responsibilities.filter(subCriterionMeta => subCriterionMeta.status === STATUSES.approved);
        const uncompletedResponsibilities = responsibilities.filter(subCriterionMeta => subCriterionMeta.status !== STATUSES.approved);

        const completedGroupBySection = groupAndMapResponsibilitiesBySection(completedResponsibilities);
        const uncompletedGroupBySection = groupAndMapResponsibilitiesBySection(uncompletedResponsibilities);

        return [completedGroupBySection, uncompletedGroupBySection];
    }, [projectId, targetUser])

    const sectionPageURL = (sectionId) => {
        return goToProjectSection(
            projectId,
            sectionId.split("_").slice(0, 1).join(""),
            sectionId,
        );
    }

    const subCriterionPageURL = (sectionId, subCriterionNumber) => {
        return goToProjectCriteria(
            projectId,
            sectionId.split("_").slice(0, 1).join(""),
            sectionId,
            subCriterionNumber,
        )
    }

    const displayDeadline = (deadline) => {
        const deadlineDate = new Date(deadline || null);
        deadlineDate.setHours(23, 59, 59);
        const now = new Date();
        if (deadlineDate > now) {
            const diff = (deadlineDate - now) / (1000 * 60 * 60 * 24); // Diff in days
            const color = diff <= 2 ? 'text-error' : diff <= 7 ? 'text-pending' : 'text-black';

            return <>
                <span>har tidsfrist {diff <= 7 ? 'om' : 'den'} </span>
                <span className={`font-semibold ${color}`}>
                    {diff <= 10 ? timeLeft(deadlineDate) : dateToDateString(deadlineDate)}
                </span>
            </>
        } else {
            return <>
                <span>gikk ut</span>&nbsp;
                <span className='text-error font-semibold'>
                    for {timeAgo(deadlineDate)}
                </span>
            </>
        }
    }

    const IS_TARGET_USER_CURRENT_USER = userInFocus === AuthService.currentUserId();

    return (
        <div className='paper pt-5'>
            <h5 className='mb-1'>Gjøremål</h5>
            <div className='text-gray-600 mb-4'>
                {
                    IS_TARGET_USER_CURRENT_USER ?
                        'Dine gjøremål:'
                        :
                        ''.concat(member.firstName, ' ', member.lastName, ' sine gjøremål:')

                }
            </div>

            {
                /*  isLoading ?
                 <div className='absolute-spinner'>
                     <Spinner />
                 </div>
                 : */
                <div>
                    {
                        uncompletedResponsibilityList.length > 0 ?
                            uncompletedResponsibilityList.map((sectionResponsibility) => (
                                <div
                                    className='mb-2 text-lg'
                                    key={sectionResponsibility.sectionId}
                                >
                                    <Link
                                        className='my-2 hover:underline cursor-pointer'
                                        to={sectionResponsibility.subCriteria.length === 1 ?
                                            subCriterionPageURL(sectionResponsibility.sectionId, sectionResponsibility.subCriteria[0].subCriterionNumber) :
                                            sectionPageURL(sectionResponsibility.sectionId)
                                        }
                                    >
                                        {
                                            ((!sectionResponsibility.hasAllSubCriterionIds
                                                && sectionResponsibility.commonDeadline)
                                                || sectionResponsibility.subCriteria.length === 1) &&
                                            <>
                                                <span className='font-semibold'>
                                                    Krav {sectionResponsibility.subCriteria.map(subCrit => subCrit.subCriterionNumber).join(", ")}
                                                </span>
                                                &nbsp;i&nbsp;
                                            </>
                                        }
                                        <span className='font-semibold'>
                                            {sectionIdToText(sectionResponsibility.sectionId)}
                                        </span>&nbsp;

                                        {
                                            sectionResponsibility.commonDeadline ?
                                                <span className='font-light'>
                                                    {displayDeadline(sectionResponsibility.commonDeadline)}
                                                </span>
                                                :
                                                sectionResponsibility.subCriteria.length > 1 ?
                                                    <span className='font-light'>
                                                        : {sectionResponsibility.subCriteria.length} gjøremål
                                            </span>
                                                    :
                                                    <span className='font-light'>
                                                        er ditt ansvar
                                            </span>
                                        }
                                    </Link>
                                    {
                                        (!sectionResponsibility.commonDeadline && sectionResponsibility.subCriteria.length > 1) &&
                                        <div className='ml-4'>
                                            {
                                                (sectionResponsibility.subCriteria || [])
                                                    .map((subCriterionMeta) => (
                                                        <div key={subCriterionMeta.id}>
                                                            <Link
                                                                className='hover:underline'
                                                                to={subCriterionPageURL(
                                                                    sectionResponsibility.sectionId,
                                                                    subCriterionMeta.subCriterionNumber
                                                                )}
                                                            >
                                                                <StatusIcon
                                                                    className='mr-2'
                                                                    status={subCriterionMeta.status}
                                                                />
                                                                <span className='font-semibold'>
                                                                    Krav&nbsp;
                                                                    {subCriterionMeta.subCriterionNumber}
                                                                </span>&nbsp;
                                                                {
                                                                    subCriterionMeta.deadline ?
                                                                        displayDeadline(subCriterionMeta.deadline) :
                                                                        <span className='font-light'>har ingen tidsfirst</span>
                                                                }
                                                            </Link>
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    }
                                </div>
                            ))
                            :
                            <div>
                                <h6 className='text-center my-4'>
                                    {
                                        (IS_TARGET_USER_CURRENT_USER ? 'Du ' : ''.concat(member.firstName, ' ', member.lastName, ' '))
                                        + 'har ingen gjøremål for øyeblikket'
                                    }
                                </h6>
                            </div>
                    }
                    {
                        completedResponsibilityList.length > 0 &&
                        <div className='my-4'>
                            <hr />
                            <div className='text-gray-600 mb-4 mt-6'>
                                Fullførte gjøremål:
                            </div>
                            {
                                completedResponsibilityList.map(((sectionResponsibility) => (
                                    <div
                                        key={sectionResponsibility.sectionId}
                                        className='text-lg text-gray-600'
                                    >
                                        <Link
                                            className='my-2 hover:underline cursor-pointer'
                                            to={sectionPageURL(sectionResponsibility.sectionId)}
                                        >
                                            <StatusIcon
                                                className='mr-2'
                                                status={STATUSES.approved}
                                            />
                                            I&nbsp;
                                            <span className='font-semibold'>
                                                {sectionIdToText(sectionResponsibility.sectionId)}
                                            </span>&nbsp;
                                            er krav&nbsp;
                                            <span className='font-semibold'>
                                                {
                                                    sectionResponsibility.subCriteria.map(subCrit => subCrit.subCriterionNumber).join(", ")
                                                }
                                            </span>&nbsp;
                                            godkjent
                                        </Link>
                                    </div>
                                )))
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
};

ResponsibilityList.propTypes = {
    projectId: PropTypes.string.isRequired,
    targetUser: PropTypes.string,
    isLoading: PropTypes.bool,
}

export default ResponsibilityList;