import React from 'react';
import PropTypes from 'prop-types';
import AuthService from '~/services/authService';

// Components
import Spinner from '~/components/base/Spinner';

// Module components
import NotificationStreamItem from './components/NotificationStreamItem';
import { Button } from '~/components/base/Buttons';

const NotificationStream = ({
    projectId,
    isLoading = false,
    notifications = [],
    hasNextPage = false,
    onFetchNextPage,
    isLoadingNextPage = false,
    targetUser,
}) => {
    const userInFocus = targetUser || AuthService.currentUserId();
    const IS_TARGET_USER_CURRENT_USER = userInFocus === AuthService.currentUserId();

    return (
        <div className='bg-white rounded-lg shadow-md'>
            <div className='p-10 pt-5'>
                <h5>Varslingsstrøm</h5>


                {
                    isLoading ?
                    <div className='absolute-spinner'>
                        <Spinner />
                    </div>
                    :
                    <div className='mt-2'>
                        {
                            notifications && notifications.map((notf) => (
                                <NotificationStreamItem
                                    key={notf.id}
                                    projectId={projectId}
                                    item={notf}
                                    disableActions={!IS_TARGET_USER_CURRENT_USER}
                                />
                            ))
                        }
                    </div>
                }
            </div>
            {
                (!isLoading && hasNextPage) &&
                <div>
                    <hr />
                    <Button
                        className='w-full'
                        variant='text'
                        disabled={isLoadingNextPage}
                        loading={isLoadingNextPage}
                        onClick={onFetchNextPage}
                    >
                        Hent flere
                    </Button>
                </div>
            }
        </div>
    )
};

NotificationStream.propTypes = {
    projectId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    notifications: PropTypes.array,
    hasNextPage: PropTypes.bool,
    onFetchNextPage: PropTypes.func,
    isLoadingNextPage: PropTypes.bool,
}

export default NotificationStream;