import { eventTypes as EVENT_TYPES } from '~/data/config';
import sectionIdToText from '../text/sectionIdToText';
import sectionArrayToText from '../text/sectionArrayToText';
import extractStatusText from '../text/extractStatusText';
import roleToText from '../text/roleToText';

// State imports
import { useSelector } from 'react-redux';
import UserSelectors from '~/store/user/selectors';
import { diffInDays } from '../date/diffInDays';

/**
 * This method converts a notification to a readable text that describes the event.
 * @param {import('~/store/events/mappers').Notification} notification
 */
export default (notification, projectId) => {
    const timestamp = new Date(notification.timestamp);
    const payload = notification.payload || {};
    const sectionId = notification.sectionId || '';
    const categoryId = notification.categoryId || '';
    const id = notification.user || '';

    // SubCriteria data for the notification with subCriteria data.
    const subCriteria = (payload.subCriterionId || []);
    

    switch(notification.eventAction) {

        case EVENT_TYPES.CRITERION_SENT_FOR_REVISION: {
            if(subCriteria.length > 1) {
                return `${sectionIdToText(sectionId)}: Flere krav har blitt sendt til revisjon!`;
            }
            const subCriterionNumber = (subCriteria.length > 0 ? subCriteria[0] : '').split("_").slice(-1)[0];
            return `${sectionIdToText(sectionId)}: Krav ${subCriterionNumber} ble sendt til revisjon!`;
        }

        case EVENT_TYPES.CRITERION_STATUS_CHANGE: {
            const subCriterionNumber = (subCriteria.length > 0 ? subCriteria[0] : '').split("_").slice(-1)[0];
            const status = payload.status || null;
            return `${sectionIdToText(sectionId)}: Krav ${subCriterionNumber} ble ${extractStatusText(status).toLowerCase()} av revisor!`;
        }
        
        case EVENT_TYPES.DOCUMENT_CREATED: {
            let text = 'Nytt dokument har blitt lastet opp';
            if(subCriteria && subCriteria.split) {
                const subCriterionNumber = (subCriteria || '').split("_").slice(-1)[0];
                text += `til krav ${subCriterionNumber}`;
            }
            return `${sectionIdToText(sectionId)}: ${text}`;
        }

        case EVENT_TYPES.REMINDER: {
            const user = useSelector(UserSelectors.getMemberById(id, projectId)) || {};
            return `${sectionIdToText(sectionId)}: Hvordan går det? ${user.firstName ? `Hilsen ${user.firstName} ${user.lastName}` : ''}`;
        }

        case EVENT_TYPES.CRITERION_DEADLINE_CHANGE: {
            const subCriterionNumber = (subCriteria.length > 0 ? subCriteria[0] : '').split("_").slice(-1)[0];
            return `${sectionIdToText(sectionId)}: Krav ${subCriterionNumber} har fått ny tidsfrist.`;
        }

        case EVENT_TYPES.MEMBER_ADD: {
            return `Hei, velkommen`;
        }

        case EVENT_TYPES.ROLE_CHANGE: {
            return `Din rolle er blitt endret til ${roleToText(payload)}`
        }
        
        case EVENT_TYPES.ACCESS_REVOKED: {
            return `Du har blitt fjernet fra ${sectionArrayToText(sectionId, payload)}`;
        }

        case EVENT_TYPES.RESPONSIBILITY_REVOKED: {
            return `Du har ikke ansvar i ${sectionArrayToText(sectionId, payload)}`;
        }

        case EVENT_TYPES.ACCESS_GIVEN: {
            return `Du har fått tilgang til ${sectionArrayToText(sectionId, payload)}`;
        }

        case EVENT_TYPES.RESPONSIBILITY_GIVEN: {
            return `Du har fått ansvar i ${sectionArrayToText(sectionId, payload)}`;
        }

        case EVENT_TYPES.PHASE_REMINDER: {
            const nextPhase = payload.nextPhase;
            const currentPhase = payload.currentPhase;

            const diff = diffInDays(new Date(), new Date(nextPhase.start));

            if(diff <= 0) {
                return `Fase ${currentPhase.phaseNumber} er over og fase ${nextPhase.phaseNumber} har begynt.`
            }

            return `Det er ${diff} dag${diff > 1 ? 'er' : ''} igjen av fase ${currentPhase.phaseNumber}!`;
        }

        default: {
            return '';
        }
    }

    return 'Best text';
}
