import { statuses as STATUSES } from '~/data/config';

/**
 *  "calculateStatus" takes in all the subCriteria-metadata for a given criterion and
 *  calculates the current status of the criterion itself.
 * 
 * @example
 *  USAGE: Is for example used in CriterionItem
 *  subCriteriaMetaData = {
 *      <SUB_CRITERION_ID>: {
 *          locked: ...,
 *          status: ...,
 *          ...
 *      }
 *  }
 * @param {Object} subCriteriaMetaData - The subCriteria metadata
 * @returns {String} The status of the criterion. Either APPROVED, DISAPPROVED, PENDING or null.
 */

const calculateStatus = (subCriteriaMetaData) => {
    
    if(!subCriteriaMetaData) {
        return STATUSES.none;
    }

    const subCriteriaMetaDataList = Object.values(subCriteriaMetaData);

    // Check if anyone has been disapproved
    const hasAnyDisapproved = subCriteriaMetaDataList
        .some(subCriterion => subCriterion.status === STATUSES.disapproved);

    if(hasAnyDisapproved) {
        return STATUSES.disapproved;
    }
    
    // Check if anyone is in progress
    const isAnyPending = subCriteriaMetaDataList
        .some(subCriterion => subCriterion.status === STATUSES.pending);

    if(isAnyPending) {
        return STATUSES.pending;
    }

    // Check if ALL is approved
    return subCriteriaMetaDataList
        .every(subCriterion => subCriterion.status === STATUSES.approved)
        ? STATUSES.approved 
        : STATUSES.none;
};

export default calculateStatus;