export const isObjEqual = (a, b) => {
    
    const propNamesA = Object.getOwnPropertyNames(a);
    const propNamesB = Object.getOwnPropertyNames(b);

    if(propNamesA.length !== propNamesB.length) {
        return false;
    }

    // Merge all the property-names into one list
    const propNames = Object.keys(
        [...propNamesA, ...propNamesB].reduce((prev, name) => ({
            ...prev,
            [name]: true,
        }), {})
    );

    for(let propName of propNames) {
        if(a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}

/**
 * @param {Array<any>} elems 
 * @param {Function} keyFunc
 * @returns {Object.<any>} 
 */
export const groupBy = (elems, keyFunc) => {
    return elems.reduce((prev, elem) => ({
        ...prev,
        [keyFunc(elem)]: elem,
    }), {})
}

/**
 * @param {Array<any>} elems 
 * @param {Function} keyFunc
 * @returns {Object.<Array.<any>>} 
 */
export const groupByArray = (elems, keyFunc) => {
    return elems.reduce((prev, elem) => {
        const key = keyFunc(elem);
        return {
            ...prev,
            [key]: [...(prev[key] || []), elem],
        }
    }, {});
};