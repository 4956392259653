import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './FileSystemModal.module.css';

// Components
import Modal from '~/components/base/Modal';
import Collapse from '~/components/base/Collapse';
import FileSystem from '~/components/modules/FileSystem';
import DocumentUpload from '~/components/modules/DocumentUpload';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';

const DOCUMENT_LIST_TAB = 0;
const DOCUMENT_UPLOAD_TAB = 1;

export const DOCUMENT_MODE_CRITERIA = 'criteria';
export const DOCUMENT_MODE_SECTION = 'section';

const FileSystemModal = ({
    show,
    projectId,
    directoryId,
    directoriesToExclude = [],
    config = {}, // { sectionId, criterionId }
    onClose, 
    ...rest
}) => {
    const documentUploadRef = useRef();
   
    // State
    const [tab, setTab] = useState(DOCUMENT_LIST_TAB);
    const [docUploadConfig, setDocUploadConfig] = useState({});

    useEffect(() => {
        setDocUploadConfig(config);
    }, [config])
    
    const goToDocumentUploadTab = (metaData) => {
        const newDocUploadConfig = {
            ...docUploadConfig,
            ...metaData,
        };
        if(metaData.subCriterionId) {
            newDocUploadConfig.subCriteria = {[metaData.subCriterionId]: true};
        }

        setDocUploadConfig(newDocUploadConfig);
        if(documentUploadRef.current && documentUploadRef.current.resetDocuments) {
            documentUploadRef.current.resetDocuments(newDocUploadConfig);
        }
        setTab(DOCUMENT_UPLOAD_TAB);
    }

    const goToFileSystemTab = () => {
        setDocUploadConfig(config);
        setTab(DOCUMENT_LIST_TAB);
    }

    return (
        <Modal
            show={show}
            onBackdropClick={onClose}
            {...rest}
        >
            <div className={`${styles.root} paper relative`}>
                <h4>Dokumentasjon - { sectionIdToText(config.sectionId) }</h4>
                {

                    <div>
                        <Collapse
                            visible={tab === DOCUMENT_UPLOAD_TAB}
                        >
                            <DocumentUpload
                                ref={documentUploadRef}
                                projectId={projectId}
                                uploadConfig={docUploadConfig}
                                onDocumentUpload={goToFileSystemTab}
                                onClose={goToFileSystemTab}
                            /> 
                        </Collapse>
                        <Collapse visible={tab === DOCUMENT_LIST_TAB}>
                            <FileSystem
                                projectId={projectId}
                                directoryId={directoryId}
                                directoriesToExclude={directoriesToExclude}
                                onUploadDocument={goToDocumentUploadTab}
                            />
                        </Collapse>
                    </div>
                }
            </div>
        </Modal>
    )
}

FileSystemModal.propTypes = {
    projectId: PropTypes.string.isRequired,
    directoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onClose: PropTypes.func.isRequired,
    
    /**
     * The directories to exclude in the view. Useful for excluding directories from other subCriterions
     * in the same criterion.
     */
    directoriesToExclude: PropTypes.array,

    /**
     * A config for document upload. Contains a sectionId and a criterionId - 
     * which automatically sets which criterion the document should belong to.
     */
    config: PropTypes.object.isRequired,
}

export default FileSystemModal;