
import * as firebase from '~/services/firebase';
import { roles as ROLES} from '~/data/config';


export default (project) =>{
    return (
        project.roles[firebase.auth.currentUser.uid] === ROLES.owner
    )
}
