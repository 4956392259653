import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Litepicker from 'litepicker';

// Styles
import styles from './LiteDatePicker.module.css';

// Components
import Icon from '~/components/base/Icon';
import { Button } from '~/components/base/Buttons';

// Utils
import isDateEqual from '~/utils/date/isDateEqual';

const DatePicker = ({
    className = '',
    variant,
    color,
    options,
    date,
    onChange,
    disabled,
    children,
    lockDates,
    ...rest
}) => {
    const containerRef = useRef();
    const pickerRef = useRef();
    const stateRef = useRef({
        date: null,
        onChange,
    });

    useEffect(() => {
        const picker = new Litepicker({
            ...options,
            element: containerRef.current,
            onSelect: onDateChange,
            singleMode: true,
            lockDaysFormat: 'date',
            disallowLockDaysInRange: true,
        })
        if(date) {
            picker.setDate(date);
        }

        pickerRef.current = picker;

    }, [containerRef]);

    useEffect(() => {
        /**
         * @type {Litepicker}
         */
        const picker = pickerRef.current;

        if(lockDates) {
            picker.setLockDays(lockDates);
        } else {
            picker.setLockDays([]);
        }

    }, [lockDates])

    useEffect(() => {
        if(!pickerRef.current) {
            return;
        }

        /**
         * @type {Litepicker}
         */
        const picker = pickerRef.current;
        const currentDate = picker.getDate();

        if(isDateEqual(currentDate, date)) {
            return;
        }

        const dateChanged = picker.getDate() !== date;
        if(dateChanged) {
            picker.setDate(date);
            stateRef.current.date = date;
        }

        if(!date) {
            picker.clearSelection();
        }

    }, [date])

    useEffect(() => {
        stateRef.current.onChange = onChange;
    }, [onChange])


    const onDateChange = (newDate) => {
        // Get data from ref, as the onSelect-function is an event-listener
        const { date, onChange } = stateRef.current || {};

        if(+newDate === +date) {
            return;
        }

        if(onChange) {
            onChange(newDate);
        }
    }

    const disabledStyling = disabled ? 'bg-gray-200 cursor-not-allowed pointer-events-none' : 'cursor-pointer';

    return (
        <Button
            className={`
            ${className}
            ${disabledStyling}
            `}
            variant={variant}
            color={color}
            ref={containerRef}
            {...rest}
        >
            <Icon
                className='pointer-events-none'
                icon={
                    date ?
                    'clock' :
                    ['far', 'clock']
                }
            />
            {
                children
            }
        </Button>
    )
};

DatePicker.propTypes = {
    className: PropTypes.string,
    options: PropTypes.object,
    date: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default DatePicker;