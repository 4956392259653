import React from 'react';
import PropTypes from 'prop-types';
import styles from './TimelineModal.module.css';

// Core components
import Modal from '~/components/base/Modal';
import { Button } from '~/components/base/Buttons';
import TimelineModule from '~/components/modules/TimelineModule';

const TimelineModal = ({
    projectId,
    subCriterionId,
    show,
    onClose,
    ...rest
}) => {

    return (
        <Modal
            show={show}
            onBackdropClick={onClose}
        >
            <div className={`${styles.root} paper`}>
                <TimelineModule
                    projectId={projectId}
                    subCriterionId={subCriterionId}
                    {...rest}
                />
                <div className='grid justify-end mt-4'>
                    <Button
                        variant='text'
                        onClick={onClose}
                    >
                        Lukk
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

TimelineModal.propTypes = {
    projectId: PropTypes.string.isRequired,
    subCriterionId: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default TimelineModal;