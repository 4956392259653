export const MUTATIONS = {
    SET_USER: 'USER_SET_USER',
    SET_USER_MEMBER_DATA: 'USER_SET_USER_MEMBER_DATA',
    SET_CURRENT_USER_INFO: 'USER_SET_CURRENT_USER_INFO',

    CLEAR_STATE: 'USER_CLEAR_STATE',
    SET_MEMBERS: 'USER_SET_MEMBERS',
    SET_PROFILE_PICTURE: 'USER_SET_PROFILE_PICTURE',
}

const initialState = {
    isLoggedIn: false,
    user: null,
    userInfo: null,
    profilePicture: null,


    members: {}, // { [projectId]: { [memberId]: { ... } } }
}

const reducer = (state = initialState, action) => {

    const payload = action.payload;

    switch(action.type) {
        case MUTATIONS.SET_USER: {
            return {
                ...state,
                user: Boolean(payload) ? payload : null,
                isLoggedIn: Boolean(payload),
            };
        }

        case MUTATIONS.SET_CURRENT_USER_INFO: {
            return {
                ...state,
                userInfo: Boolean(payload) ? payload : null
            };
        }

        case MUTATIONS.CLEAR_STATE: {
            return initialState;
        }

        case MUTATIONS.SET_MEMBERS: {
            const projectId = action.projectId;
            const members = payload;
            const oldProjectMembers = (state.members[projectId] || {});
            const newProjectMembers = members
                .map(member => ({[member.id]: member}))
                .reduce((acc, member) => ({...acc, ...member}), {});

            const projectMembers = Object.assign({}, oldProjectMembers, newProjectMembers);
            
            return {
                ...state,
                members: {
                    ...state.members,
                    [projectId]: projectMembers    
                }
            }
        }

        default:
            return state;
    }

}

export default reducer;