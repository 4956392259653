import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import ROUTES, {
	goToProjectDocuments,
	goToProjectAccess,
	goToProjectManual,
	goToProjectDashboard,
} from '~/ROUTES';

// Config
import { projectPages as PROJECT_PAGES } from '~/data/config';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Project components
import { Button } from '~/components/base/Buttons';
import Icon from '~/components/base/Icon';
import { checkIfOwnerOrAuditor } from '~/utils/access';
import checkIfOwner from '~/utils/access/checkIfOwner';

const TopBar = () => {
		const project = useSelector(ProjectSelectors.getCurrentProject);
		const projectTitle = project.title || '';
    const category = useSelector(ProjectSelectors.getCurCategory);
		const [curPage, setCurPage] = useState('');

		useEffect(() => {
			const currentURLPaths = window.location.pathname.split('/').slice(1);
			switch (currentURLPaths.length) {
				case 2:
					setCurPage(PROJECT_PAGES.FRONTPAGE);
					break;
				case 3:
					const pageRoute = currentURLPaths[2];
					let translated = '';
					switch (`/${pageRoute}`) {
						case ROUTES.ACCESS:
							translated = PROJECT_PAGES.ACCESS;
							break;
						case ROUTES.DOCUMENTS:
							translated = PROJECT_PAGES.DOCUMENTS;
						case ROUTES.MANUAL:
							translated = PROJECT_PAGES.MANUAL;
					}
					setCurPage(translated);
				default:
					break;
			}
		}, []);

		if (!project) {
        return null;
    }

    return (
			<div className={`flex items-center mb-4`}>
				<h5 className="my-0 mb-1 mr-4">
					{curPage === PROJECT_PAGES.FRONTPAGE ? (
						<>{projectTitle}</>
					) : (
						<Link
							className="hover:underline text-2xl"
							to={goToProjectDashboard(project.id)}
						>
							{projectTitle}
						</Link>
					)}{' '}
					- {curPage}
				</h5>

				<div className="flex-grow" />

				<div
					className={`grid grid-cols-${
						checkIfOwner(project) ? '3' : '2'
					} gap-5`}
				>
					<NavLink
						className="bg-white shadow px-2 py-2 rounded flex items-center justify-center hover:bg-gray-250 hover:text-black"
						variant="text"
						to={goToProjectManual(
							project.id,
							category ? category.toUpperCase() : null
						)}
						exact
						tabIndex={0}
						activeClassName="bg-primary text-white"
					>
						<Icon icon="book" className="mr-2" />
						BREEAM Manual
					</NavLink>
					<NavLink
						className="bg-white shadow px-2 py-2 rounded flex items-center justify-center hover:bg-gray-250 hover:text-black"
						variant="text"
						to={goToProjectDocuments(project.id)}
						exact
						tabIndex={0}
						activeClassName="bg-primary text-white"
					>
						<Icon icon="archive" className="mr-2" />
						Opplastede filer
					</NavLink>
					{checkIfOwner(project) && (
						<NavLink
							className="bg-white shadow px-2 py-2 rounded flex items-center justify-center hover:bg-gray-250 hover:text-black"
							variant="text"
							to={goToProjectAccess(project.id)}
							exact
							tabIndex={0}
							activeClassName="bg-primary text-white"
						>
							<Icon icon="user-friends" className="mr-2" />
							Behandle tilganger
						</NavLink>
					)}
				</div>
			</div>
		);
};

export default TopBar;