import React from 'react';
import styles from './index.module.css';
import { useHistory } from 'react-router-dom';
import { goToUserSettings } from '~/ROUTES';

// Store imports
import UserActions from '~/store/user/actions';

// Components
import Icon from '~/components/base/Icon';
import Sidebar from '~/components/navigation/Sidebar';
import { Button } from '~/components/base/Buttons';

// Subcomponents
import NotificationList from '../NotificationList';

const DashboardSidebar = ({
    projects,
}) => {
    const history = useHistory();

    const openUserSettings = () => {
        history.push(goToUserSettings());
    }


    return (
        <Sidebar className={`${styles.root} p-6`}>
            <div className='flex items-center flex-row-reverse'>
                <div
                    className='flex items-center cursor-pointer'
                    onClick={UserActions.signOut}
                >
                    <Icon icon='sign-out-alt' className='mr-2' />
                    <p className='mb-0 underline'>Logg ut</p>
                </div>
                <div className='flex-grow' />
                <Button
                    className='mr-4'
                    variant='icon'
                    color='black'
                    onClick={openUserSettings}
                >
                    <Icon icon='user' />
                </Button>
            </div>

            <div className='mt-4'>
                <h4 className='font-light'>
                    Varsler
                </h4>
                {
                    projects && projects
                        .filter(project => project.id)
                        .map((project) => (
                        <NotificationList
                            key={project.id}
                            project={project}
                        />
                    ))
                }
            </div>
        </Sidebar>
    )
};

export default DashboardSidebar;