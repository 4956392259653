import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Store
import ProjectActions from '~/store/project/actions';

// Core components
import { Button } from '~/components/base/Buttons';

const ProjectExportButton = ({
    projectId,
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [downloadURL, setDownloadURL] = useState(null);

    const onExport = () => {
        if(isLoading) {
            return;
        }

        setIsLoading(true);
        setDownloadURL(null);
        ProjectActions.exportProject(projectId)
            .then((url) => {
                // This might get blocked by browser
                window.open(url, '_blank');

                // Make the url visible
                setDownloadURL(url);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <div>
            <Button
                disableElevation
                variant='outlined'
                onClick={onExport}
                loading={isLoading}
                disabled={isLoading}
            >
                Eksporter prosjekt
            </Button>
            {
                isLoading &&
                <div className='ml-4 my-2 font-light text-gray-600'>
                    Pakker sammmen hele prosjektet - dette kan ta litt tid.
                </div>
            }
            {
                downloadURL &&
                <div className='ml-4 my-2 font-light'>
                    <a href={downloadURL} className='text-primary underline'>
                        Klikk her for å laste ned
                    </a>
                </div>
            }
        </div>
    )
}

ProjectExportButton.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default ProjectExportButton;