import React, {useState, useEffect, Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '~/components/base/Modal';
import Icon from '~/components/base/Icon';
import Spinner from '~/components/base/Spinner';

// Constants
export const REQUEST_SUCCESS = 0;
export const REQUEST_FAILED = 1;

const SuccessModal = ({
    isLoading,
    show,
    status,
    errorMessage,
    onClose,
    successMessage,
}) => {

    return (
        <Modal
            show={show}
            onBackdropClick={isLoading ? null : onClose}
            errorMessage={errorMessage}
        >
            {
                isLoading 
                
                ? 
                
                <div className={'paper'}>
                    <Spinner />
                </div>

                :

                status === REQUEST_SUCCESS ?

                <div className={'paper'}>
                    <h3>Suksess <Icon icon='check' color='var(--color-success)'/></h3>
                   {successMessage}
                </div> 
                :
                <div className={'paper'}>
                    <h3>Feil! <Icon icon='times-circle' color='var(--color-error)'/></h3>
                    Det oppstod en feil. <br/>
                    Feilmelding: {errorMessage}
                </div>
            }
            
        </Modal>
    )
}

SuccessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    status: PropTypes.number,
    ...Modal.propTypes
}

export default SuccessModal;