
import {member as MEMBER} from '~/data/config';
import checkIfOwnerOrAuditor from './checkIfOwnerOrAuditor';


const checkIfAccessToSection = (project, userMemberData, sectionId) => {
    return (
        checkIfOwnerOrAuditor(project)
        || userMemberData.sections[sectionId] === MEMBER.SECTIONS_VALUE.ACCESS
        || userMemberData.sections[sectionId] === MEMBER.SECTIONS_VALUE.RESPONSIBLE
    )
}

export default checkIfAccessToSection;