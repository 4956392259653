

/**
  @description  "calculateIsLocked" takes in all the subCriteria-metadata for a given criterion and
    calculates the current lock-status of the criterion itself.

    USAGE: Is for example used in CriterionItem

    subCriteriaMetaData = {
        <SUB_CRITERION_ID>: {
            locked: ...,
            status: ...,
            ...
        }
    }
 * @param {Object} subCriteriaMetaData 
 */

const calculateIsLocked = (subCriteriaMetaData) => {
    return Object.values((subCriteriaMetaData || {})).every(subCriterion => Boolean(subCriterion.locked));
};

export default calculateIsLocked;