import React, { useState, useMemo, useEffect } from "react";
import ROUTES, { goToProjectAccess } from '~/ROUTES';
import {useHistory} from 'react-router-dom';
import { roles as ROLES } from '~/data/config';

// Styles
import styles from './AddUserForm.module.css';

// Components
import { Button } from '~/components/base/Buttons';
import TextField from '~/components/base/TextField';
import { Select } from "~/components/base/Select";
import Checkbox from "~/components/base/Checkbox";
import Icon from "~/components/base/Icon";
import Collapse from "~/components/base/Collapse";
import Tabs from '~/components/base/Tabs';
import Spinner from '~/components/base/Spinner';

// Store imports
import { useSelector } from 'react-redux';
import ProjectSelectors from '~/store/project/selectors';

// Utils
import sectionIdToText from '~/utils/text/sectionIdToText';

// Data
import categoriesByName from '~/data/categories.json';


const AddUserForm = (props) => {
    const history = useHistory();
    const project = useSelector(ProjectSelectors.getCurrentProject);
    const manual = useSelector(ProjectSelectors.getCurrentManual);

    // State
    const [state, setState] = useState({
        role: '',
        email: '',
    })
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSections, setSelectedSections] = useState({}); // {  [sectionId]: true }

    // --- CONSTANTS ---

    /**
     * All categories by key - { [categoryId]: [sectionId, sectionId2]}
     */
    const categories = useMemo(() => {
        const sections = Object.keys(project.scope);
        const categoriesByKey = sections.reduce((prev, sectionId) => {
            const categoryId = sectionId.split("_").slice(0, 1)[0];
            return {
                ...prev,
                [categoryId]: [...(prev[categoryId] || []), sectionId],
            }
        }, {});

        return categoriesByKey; // [ [categoryId, listOfSectionIds ] ]
    }, [])


    const categoryNameByKey = useMemo(() => {
        return categoriesByName.reduce((prev, { name, key }) => {
            return {
                ...prev,
                [key]: name
            }
        }, {});
    }, [])


    // ----- FUNCTIONS -------

    const goToAccessView = () => {
        history.push(goToProjectAccess(project.id));
    }

    const data = [
        {value: ROLES.owner, label: 'Eier'},
        {value: ROLES.member, label: 'Medlem'},
        {value: ROLES.auditor, label: 'Revisor'}
    ]

    const handleChange = (value) => {
        setState({role: value})
        props.onChange({
            target: {
                name: 'role',
                value: value
            }
        })
    }

    const onSectionChange = (sectionId, value) => {
        setSelectedSections({
            ...selectedSections,
            [sectionId]: value,
        })
    }

    const toggleCategoryValue = (categoryId) => {
        // Get all section ids in the category
        const sectionIds = categories[categoryId] || [];

        // Extract the values of the subCriteria from the state (is everyone true or false?)
        const newValue = !(sectionIds.map(sectionId => Boolean(selectedSections[sectionId])).every(value => value === true));

        // Set state with all of those section-ids values set to the found value ^^
        const newValues = sectionIds.reduce((prev, sectionId) => ({...prev, [sectionId]: newValue}), {})

        setSelectedSections({
            ...selectedSections,
            ...newValues,
        })
    }

    const isCategoryAllChecked = (categoryId) => {
        const sectionIds = categories[categoryId] || [];
        return (sectionIds.map(sectionId => Boolean(selectedSections[sectionId])).every(value => value === true));
    }


    const toggleAllCategories = () => {
        // New Object for storing the new values for each section
        const newValue = Object.entries(categories)
        .some(([id, value]) => {
            // Get all section ids in the category
            const sectionIds = categories[id] || [];

            // Extract the values of the subCriteria from the state (is everyone true or false?)
            return !(sectionIds.map(sectionId => Boolean(selectedSections[sectionId])).every(value => value === true));

        })
        // Set every section to the new value
        const newValues = Object.entries(categories).reduce((prev, [id, value]) => {
            return {...prev, ...categories[id].reduce((prevSections, sectionId) => ({...prevSections, [sectionId]:newValue}), {})}
        }, {});
        // Set new state
        setSelectedSections({
            ...selectedSections,
            ...newValues,
        })
    }

    const isEverythingChecked = () => {
        return Object.entries(categories)
        .every(([id, value]) => {
            return isCategoryAllChecked(id);
        })
    }

    return (
        <div  className="paper">
            <h4>Legg til en ny bruker</h4>
            <form

                onSubmit={e => {e.preventDefault(); }}
            >
                <TextField
                    label='E-post'
                    type='text'
                    icon='user'
                    name='email'
                    placeholder='bruker@mail.com'
                    onChange={props.onChange}
                    required
                />
                <Select
                    value={state.role}
                    label='Rolle'
                    placeholder='Rolle'
                    data={data}
                    name='role'
                    onChange={handleChange}
                />

                {
                    state.role === ROLES.member ?
                    <div className='mb-6'>
                        <div className='border-b-2 border-black p-2 grid-cols-2'>
                                        <div
                                            className='flex items-center bg-white'
                                        >
                                            <Checkbox
                                                value={isEverythingChecked()}
                                                onChange={() => toggleAllCategories()}
                                            />
                                            <div
                                                className='flex items-center w-full cursor-pointer'
                                                onClick={() => toggleAllCategories()}
                                            >
                                                <div className=''>
                                                    <b>
                                                        Alle kategorier
                                                    </b>
                                                </div>
                                                <div className='flex-grow' />
                                            </div>
                                        </div>
                                    </div>
                        {
                            Object.entries(categories)
                                .map(([categoryId, sectionIds]) => (
                                    <div key={categoryId} className='border-b p-2 grid-cols-2'>
                                        <div
                                            className='flex items-center bg-white'
                                        >
                                            <Checkbox
                                                value={isCategoryAllChecked(categoryId)}
                                                onChange={() => toggleCategoryValue(categoryId)}
                                            />
                                            <div
                                                className='flex items-center w-full cursor-pointer'
                                                onClick={() => setSelectedCategory(selectedCategory === categoryId ? null : categoryId)}
                                            >
                                                <div className=''>
                                                    {
                                                        categoryNameByKey[categoryId].slice(0, 1).toUpperCase() +
                                                        categoryNameByKey[categoryId].slice(1)
                                                    }
                                                </div>
                                                <div className='flex-grow' />
                                                <div className={'px-6'}>
                                                    {
                                                        Object.keys(selectedSections)
                                                            .filter((sectionId) =>
                                                                sectionId.startsWith(categoryId) && selectedSections[sectionId]
                                                            ).length
                                                    } av { sectionIds.length }
                                                </div>
                                                <Icon icon={selectedCategory === categoryId ? 'chevron-up' : 'chevron-down'}/>
                                            </div>
                                        </div>

                                           <Collapse
                                                visible={selectedCategory === categoryId}
                                           >
                                               <div className='px-4'>
                                                {
                                                    (sectionIds || [])
                                                        .map((sectionId) => (
                                                            <div className='flex items-center capitalize' key={sectionId}>
                                                                <Checkbox
                                                                    onChange={(value) => onSectionChange(sectionId, value)}
                                                                    value={Boolean(selectedSections[sectionId])}
                                                                />
                                                                {
                                                                    sectionIdToText(sectionId).toLowerCase()
                                                                }
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                           </Collapse>
                                    </div>
                                ))
                        }
                    </div>

                    :
                    null
                }


                <Button
                    className='inline-flex ml-none'
                    type='button'
                    disabled={props.buttonDisabled || project.archived}
                    onClick={() => {
                        if (state.email !== '' && state.role !== '') {
                            props.submit(selectedSections)
                        } else {
                            console.log("Both fields are required")
                        }
                    }}
                >
                    Legg til bruker
                </Button>

                <Button
                    className='inline-flex float-right'
                    type='button'
                    variant='text'
                    onClick={goToAccessView}
                >
                    Avbryt
                </Button>

            </form>
        </div>
    )
}

export default AddUserForm